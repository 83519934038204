/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components used to create a google map
import withStyles from "@material-ui/core/styles/withStyles";
import {withRouter} from 'react-router-dom';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";
import { Api } from "PNH/services/Api";
import SearchIcon from "@material-ui/icons/Search";

import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle";


const hexToRgb = (input) => {
  input = input + "";
  input = input.replace("#", "");
  let hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error("input is not a valid hex color.");
  }
  if (input.length === 3) {
    let first = input[0];
    let second = input[1];
    let last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase(input);
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];
  return (
    parseInt(first, 16) +
    ", " +
    parseInt(second, 16) +
    ", " +
    parseInt(last, 16)
  );
};

class MainSearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      with_city: "",
      city: {},
      with_sport_id: "",
      with_price_greater: "",
      with_price_less: "",
      with_tags: "",
      sport: {},
    };
  }

  render() {
    return (
      <GridContainer
        style={{
          background: "white",
          borderRadius: "15px",
          boxShadow:
            "0 16px 24px 2px rgba(" +
            hexToRgb("#000") +
            ", 0.14), 0 6px 30px 5px rgba(" +
            hexToRgb("#000") +
            ", 0.12), 0 8px 10px -5px rgba(" +
            hexToRgb("#000") +
            ", 0.2)",
        }}
      >
        <GridItem xs={12} sm={6} md={3}>
          <AsyncSelector
            apiMethod={Api.sports.index}
            apiShowMethod={Api.sports.show}
            labelKey="name"
            label="Dyscyplina"
            name="sports"
            defaultValue={this.state.with_sport_id}
            value={
              this.state.sport && {
                id: this.state.sport.id,
                label: this.state.sport.name,
              }
            }
            onChange={(value, data) => {
              this.setState({
                with_sport_id: value,
                sport: data,
              });
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <AsyncSelector
            apiMethod={Api.cities.index}
            apiShowMethod={Api.cities.show}
            labelKey="city"
            label="Lokalizacja"
            name="city"
            defaultValue={this.state.with_city}
            value={
              this.state.city && {
                id: this.state.city.city,
                label: this.state.city.city,
              }
            }
            onChange={(value, data) => {
              this.setState({
                with_city: value,
                city: data,
              });
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={10} md={5}>
          <CustomInput
                labelText="Szukaj: Kraków, MultiSport, z trenerem..."
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "with_tags",
                  value: this.state.with_tags,
                  name: "with_tags",
                  onChange: (e) => {
                    this.setState({ with_tags: e.target.value });
                  },
                }}
              />
        </GridItem>
        <GridItem
          xs={12}
          sm={2}
          md={1}
          style={{
            paddingTop: 15,
            textAlign: "center",
          }}
        >
          <Button
            justIcon
            simple
            color="primary"
            onClick={()=> this.props.history.push(`/szukaj?with_sport_id=${this.state.with_sport_id}&with_city=${this.state.with_city}&with_price_greater=${this.state.with_price_greater}&with_tags=${this.state.with_tags}`)}
            style={{ paddingTop: 10 }}
          >
            <SearchIcon style={{ width: 35, height: 35 }} />
          </Button>
        </GridItem>
      </GridContainer>
    );
  }
}

MainSearchBar.propTypes = {
  classes: PropTypes.object,
};

export default withRouter(withStyles(contactsStyle)(MainSearchBar));
