import React from "react";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import notificationsModal from "assets/jss/material-kit-pro-react/modalStyle";
import stylesExtended from "PNH/assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import Primary from "components/Typography/Primary";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Warning from "components/Typography/Warning";
import {AddToBasketStep} from "./addToBasketStep";
import {AddSportLevelStep} from "../ModalSteps/AddSportLevelStep";
import {Api} from "PNH/services/Api";
import {WrongLevelStep} from "../ModalSteps/WrongLevelStep";
import {ActiveStep} from "../ModalSteps/ActiveStep";


const styles = {
    ...notificationsModal,
    ...stylesExtended,
};


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class Form extends React.Component {
    constructor(props) {
        super(props);


        props.setFieldValue("afterSave", () => {
            this.addToBasketModal(false);
        });

        this.state = {
            activeStep: 0,
            addToBasketModal: false,
            players: props.values.players
        };

    }

    setActiveStep = async (props) => {
        ActiveStep(props, (params) => this.setState(params))
    }

    addToBasketModal = (open) => {
        this.setState({addToBasketModal: open}, () => {
            open && this.setActiveStep(this.props)
        });
    };


    renderModal = () => {
        const {classes, values, handleChange, handleSubmit, activity, data, refreshHeader, setFieldValue, errors} = this.props;
        const {isSubmitting, activeStep, userLevel, players} = this.state

        return (
            <>
                {this.state.addToBasketModal && (
                    <Dialog
                        classes={{
                            root: classes.modalRoot,
                            paper: classes.modal,
                        }}
                        open={this.state.addToBasketModal}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={() => this.addToBasketModal(false)}
                    >
                        {activeStep == 1 && <AddSportLevelStep toggleModal={this.addToBasketModal} afterSave={() => {
                            this.setActiveStep(this.props)
                        }} activity={activity} classes={classes}/>}
                        {activeStep == 2 && <WrongLevelStep data={data} refreshHeader={refreshHeader}
                                                            toggleModal={this.addToBasketModal} activity={activity}
                                                            nextStep={() => this.setState({activeStep: 3})}
                                                            classes={classes} userLevel={userLevel}/>}
                        {activeStep == 3 &&
                            <AddToBasketStep addToBasketModal={this.addToBasketModal} activity={activity}
                                             values={values} classes={classes} handleChange={handleChange} data={data}
                                             players={players} handleSubmit={handleSubmit}
                                             setFieldValue={setFieldValue}
                                             errors={errors}
                                             isSubmitting={isSubmitting}/>}
                    </Dialog>
                )}
            </>
        );
    };

    canRegister(currentUser) {
        return currentUser.roles.filter((r) => r.name == 'user_role_activity_registration').length > 0
    }

    simple() {
        const {activity, data} = this.props;
        const {currentUser} = data
        if (currentUser && currentUser.id && !this.canRegister(currentUser)) {
            return null;
        }
        return (
            <>
                {(!activity.bought || this.state.players.length > 0) &&
                    currentUser.id &&
                    currentUser.account_completed && (
                        <Button
                            justIcon
                            color="primary"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.addToBasketModal(true);
                            }}
                        >
                            <ShoppingCart/>
                        </Button>
                    )}
                {!currentUser.id && (
                    <Button
                        justIcon
                        color="primary"
                        onClick={() => {
                            this.props.getStateUpdates().openLoginModal()
                        }}
                    >
                        <ShoppingCart/>
                    </Button>
                )}

                {this.renderModal()}
            </>
        );
    }

    full() {
        const {activity, data, classes} = this.props;
        const {currentUser} = data

        if (currentUser && currentUser.id && !this.canRegister(currentUser)) {
            return <Card>
                <CardBody> <Warning>
                    <b>
                        Na Twoje konto została nałożona blokada, skontaktuj się z administratorem.
                    </b>
                </Warning></CardBody>
            </Card>;
        }
        return (
            <>
                {currentUser &&
                    currentUser.id &&
                    !currentUser.account_completed && (
                        <h4 className={classes.textCenter}>
                            <Warning>
                                <b>
                                    Aby zapisać się na zajęcia, dokończ proces rejestracji.
                                    Uzupełnij swój profil i potwierdź nr telefonu.
                                </b>
                            </Warning>
                            <h6 className={classes.textCenter}>
                                {" "}
                                <Button round={true} color="primary" onClick={() => {
                                    this.props.getStateUpdates().openCompleteAccountModal()
                                }}>
                                    Dokończ proces rejestracji
                                </Button>
                            </h6>
                        </h4>
                    )}
                {(!activity.bought || this.state.players.length > 0) &&
                    this.props.data.currentUser.id &&
                    this.props.data.currentUser.account_completed && (
                        <Button
                            round
                            fullWidth={true}
                            color="primary"
                            onClick={() => {
                                this.addToBasketModal(true);
                            }}
                        >
                            Dodaj do koszyka &nbsp; <ShoppingCart/>
                        </Button>
                    )}

                {!this.props.data.currentUser.id && (
                    <Button
                        round
                        fullWidth={true}
                        color="primary"
                        onClick={() => {
                            this.props.getStateUpdates().openLoginModal()
                        }}
                    >
                        Dodaj do koszyka &nbsp; <ShoppingCart/>
                    </Button>
                )}

                {activity.bought && this.state.players.length === 0 && (
                    <Primary>
                        <b> Ty i Twoi znajomi są już zapisani na zajęcia. Do zobaczenia!</b>
                    </Primary>
                )}

                {this.renderModal()}
            </>
        );
    }

    render() {

        if (this.props.simple) {
            return this.simple();
        } else {
            return this.full();
        }
    }
}

export default withStyles(styles)(Form);
