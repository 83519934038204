import React from "react";
import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
  identifier: Yup.string()
    .required("Pole Wymagane")
    .nullable(),
  password: Yup.lazy((value) =>
    !value
      ? Yup.string()
      : Yup.string()
          .min(6, "Min 6 znaków")
          .required("Pole Wymagane")
  ),
  password_confirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Hasła muszą do siebie pasować"
  ),
});

export default ValidationSchema;
