/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui icons
import Check from "@material-ui/icons/Check";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";
import Primary from "components/Typography/Primary";

import freeDemoStyle from "assets/jss/material-kit-pro-react/views/presentationSections/freeDemoStyle";

const liStyle = {
  lineHeight: '50px',
  borderBottom: '1px solid rgba(255, 255, 255, .3)',
}

class OrganizerSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
          <GridContainer style={{position: 'relative', zIndex: '2'}}>
            <GridItem
              md={5}
              sm={8}
              style={{ marginBottom: 50 }}
            >
              <div>
                <h2 style={{ marginBottom: 0, color: 'white' }}>
                  <b>Zostań organizatorem</b>
                </h2>
                <Primary>
                  <h4>
                    <b>dodaj swój trening!</b>
                  </h4>
                </Primary>
                <h5 className={classes.description} style={{ color: 'white', marginTop: 30 }}>
                  Grasz co tydzień w siatkówkę, piłkę nożną, piłkę ręczną lub
                  koszykówkę? Zrzucasz się po równo na obiekt sportowy?
                  Chciałbyś móc honorować kartę MultiSport na swoim treningu?
                  Czekasz na dzień w którym Ty i Twoja drużyna zagracie w pełnym
                  składzie?
                </h5>
              </div>

            </GridItem>
            <GridItem md={7} sm={12} >
              <ul style={{
                 color: 'white', listStyle: "none",
                padding: "0px",

              }} >
                <li>
                  <Primary>
                    <h3>
                      <b style={{ color: "white", marginBottom: 20 }}>Korzyści:</b>
                    </h3>
                  </Primary>
                </li>
                <li style={liStyle}>
                  <b>30000+</b> Graczy
                </li>
                <li style={liStyle}>
                  <Check
                    className={classNames(
                      classes.cardIcons,
                      classes.primaryColor
                    )}
                  />{" "}
                  Funkcjonalne narzędzie do organizacji treningów
                </li>
                <li style={liStyle}>
                  <Check
                    className={classNames(
                      classes.cardIcons,
                      classes.primaryColor
                    )}
                  />{" "}
                  Karta MultiSport na Twoim treningu
                </li>
                <li style={liStyle}>
                  <Check
                    className={classNames(
                      classes.cardIcons,
                      classes.primaryColor
                    )}
                  />{" "}
                  System powiadomień
                </li>
                <li style={liStyle}>
                  <Check
                    className={classNames(
                      classes.cardIcons,
                      classes.primaryColor
                    )}
                  />{" "}
                  Wysoka frekwencja na zajęciach
                </li>
              </ul>
            </GridItem>
            <GridItem xs={12} md={5} sm={8}>
              {this.props.data.currentUser.id && (
                <Button
                  href="/dodaj-zajecia"
                  color="primary"
                  target="_blank"
                  round
                  fullWidth
                >
                  Dodaj swój pierwszy trening
                </Button>
              )}
              {!this.props.data.currentUser.id && (
                <Button
                  onClick={() => {
                    document.getElementById("login-button").click();
                  }}
                  color="primary"
                  target="_blank"

                  round
                >
                  Zaloguj się i dodaj zajęcia
                </Button>
              )}
            </GridItem>
            <GridItem xs={12} md={7} sm={4} />
          </GridContainer>
    );
  }
}

OrganizerSection.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(freeDemoStyle)(OrganizerSection);
