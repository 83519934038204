import React, { useCallback, useEffect, useState } from "react";
import { Api } from "PNH/services/Api";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import formRegular from "PNH/assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "PNH/assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Table from "components/Table/Table";
import TablePagination from "@material-ui/core/TablePagination";
import SportsFootballIcon from '@material-ui/icons/SportsFootball';
import { Avatar } from "@material-ui/core";
import Hidden from '@material-ui/core/Hidden';

const styles = {
    ...formRegular,
    ...stylesExtended,
};

const tableRow = (row) => {
    const url =
    row.bought && row.hidden ? row.hidden_url : row.url;
    return [
        row.sport.name,
       <Hidden smDown> {row.localization.label}</Hidden>,
        row.date,
        <Hidden smDown>{row.start_at + '-' + row.stop_at}</Hidden>,
        <Button href={url} round color="primary" size='sm'>Oceń</Button>
    ]
}



const OpinionsTable = () => {
    const [data, setData] = useState({ rows: [], total: 0 })
    const [page, setPage] = useState(0)
    const getData = useCallback(() => {
        Api.activities.participants.opinions.all_without_opinion({ page: page }).then((response) => {
            if (response.ok) {
                setData(response.data)
            }
        })
    })

    useEffect(() => getData(), [])
    useEffect(() => getData(), [page])

    if (data.total <= 0) {
        return null
    }

    return <>
        <GridContainer style={{
            backgroundColor: '#62CEA4',
            color: 'white',
            borderTopLeftRadius: '6px',
            borderTopRightRadius: '6px',
        }}
            justifyContent="center"
            alignItems="center">
            <GridItem xs="2" lg={1} justifyContent="center"
                alignItems="center" container >
                <Avatar style={{
                    backgroundColor: 'white',
                    borderRadius: '16px',
                    width: '56',
                    color: '#62CEA4',
                    height: '56'
                }}> <SportsFootballIcon /></Avatar>

            </GridItem>
            <GridItem xs="10" lg={11} style={{ textAlign: 'left' }}>
                <h3><strong>Oceń poziom gry!</strong></h3>
                <p>Pomóż nam ulepszyć zajęcia i oceń anonimowo poziom gry innych uczestników.</p>
            </GridItem>
        </GridContainer>
        <GridContainer style={{
            border: '1px #DDDDDD solid',
            borderBottomLeftRadius: '16px',
            borderBottomRightRadius: '16px',
            padding: '16px',
            paddingTop: "30px",
        }}>
            <GridItem xs={12}>

            <Table
                tableHead={[
                    <b>Dyscyplina</b>,
                    <Hidden smDown> <b>Lokalizacja</b></Hidden>,
                    <b>Data</b>,
                    <Hidden smDown><b>Godzina</b></Hidden>,
                    "",
                ]}
                striped
                tableData={data.rows.map(tableRow)}
            />
            <TablePagination
                rowsPerPageOptions={[5]}
                component="div"
                page={page}
                rowsPerPage={5}
                count={data.total}
                onChangePage={setPage}
                labelRowsPerPage="Ilość na stronie"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} z ${count}`}
            /> 
           </GridItem>
        </GridContainer>

    </>

}

export const WithoutOpinionTable = withStyles(styles)(OpinionsTable);