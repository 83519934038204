/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import InfoArea from "components/InfoArea/InfoArea";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui icons
import CardMembership from "@material-ui/icons/CardMembership";
import CardGiftCard from "@material-ui/icons/CardGiftcard";
import AttachMoney from "@material-ui/icons/AttachMoney";
import QuestionAnswer from "@material-ui/icons/QuestionAnswer";

import featuresStyle from "assets/jss/material-kit-pro-react/views/pricingSections/featuresStyle";

function SectionFeatures(props) {
  const { classes } = props;
  return (
    <div className={classes.featuresSection}>
      <div className={classes.textCenter}>
        <h3 className={classes.title}>Co warto wiedzieć?</h3>
      </div>
      <GridContainer>
        <GridItem md={4} sm={4} className={classes.mlAuto}>
          <InfoArea
            title="Czy mogę dokonać zwrotu środków?"
            description="Tak, możesz dokonać zwrotu środków na konto bankowe. Wystarczy napisać do nas na kontakt@pilkanahali.pl"
            icon={CardMembership}
            iconColor="info"
          />
        </GridItem>
        <GridItem md={4} sm={4} className={classes.mrAuto}>
          <InfoArea
            title="Na co mogę wykorzystać dodatkowe środki?"
            description="Bonus można wykorzystać na wydarzenia oznaczone logo PNH zarówno zapisując siebie lub znajomego na zajęcia."
            icon={CardGiftCard}
            iconColor="success"
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={4} sm={4} className={classes.mlAuto}>
          <InfoArea
            title="W jaki sposób mogę zapłacić?"
            description="W PNH możesz zapłacić m.in. szybkim przelewem internetowym lub kartą MasterCard, Visa. Płatności odbywają się za pośrednictwem Przelewy24"
            icon={AttachMoney}
            iconColor="success"
          />
        </GridItem>
        <GridItem md={4} sm={4} className={classes.mrAuto}>
          <InfoArea
            title="Czego dotyczy kaucja?"
            description="Kaucja dotyczy osób zapisujących się za pośrednictwem partnerów zewnętrznych m.in. Kart MultiSport, zajęć darmowych oraz zajęć Organizatorów. Czytaj więcej nt. kaucji."
            icon={QuestionAnswer}
            iconColor="rose"
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

SectionFeatures.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(featuresStyle)(SectionFeatures);
