/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";


import errorPageStyle from "assets/jss/material-kit-pro-react/views/errorPageStyles";
import SectionHeaders from "./SectionHeaders";
import SectionEvents from "./SectionEvents";
import SectionFeatures from "./SectionFeatures";
import SectionBlogs from "./SectionBlogs";
import SectionAboutUs from "./SectionAboutUs";

import { Footer } from "PNH/layouts/Footer";

class AboutUsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [1],
    };
    this.handleToggle = this.handleToggle.bind(this);
  }
  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <SectionHeaders {...rest} />
        <SectionFeatures {...rest} />
        <SectionBlogs {...rest} />
        <SectionEvents {...rest} />
        <SectionAboutUs {...rest} />
        <hr />
        <Footer {...this.props} />
      </div>
    );
  }
}

AboutUsPage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(errorPageStyle)(AboutUsPage);
