/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {withRouter} from 'react-router-dom';
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import archBanner from "PNH/assets/img/arch_banner.jpg";

import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle";

function SectionEvents({ ...props }) {
  const { classes, ...rest } = props;

  return (
    // we've set the className to cd-section so we can make smooth scroll to it
    <div
      className={classes.pageHeader}
      style={{ backgroundImage: `url("${archBanner}")` }}
    >
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            <h2 className={classes.title} style={{ color: "white" }}>
              Eventy <br /> nie mają przed nami tajemnic
            </h2>
            <h4>
              Sporty drużynowe to nie jedyna ścieżka naszej działalności! W
              historii zapisały się u nas integracje firmowe, imprezy dla
              dzieci, pikniki, turnieje, dni miast i wiele innych.
            </h4>
            <br />
            <Button
              color="danger"
              size="lg"
              onClick={()=> props.history.push('/s/organizacja-eventow-i-integracji-firmowych')}
              target="_blank"
              rel=""
            >
              Zobacz nasze realizacje
            </Button>
          </GridItem>
          <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
            <GridContainer alignItems="flex-end" style={{ height: "100%" }}>
              <GridItem>
                <div
                  className={classes.iframeContainer}
                  style={{ boxShadow: "none" }}
                >
                  <iframe
                    src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fmyhivepolska%2Fvideos%2F419057165367379%2F&show_text=0&width=560"
                    height="250"
                    scrolling="no"
                    frameborder="0"
                    allowTransparency="true"
                    allowFullScreen="false"
                    title="Eventy"
                    style={{ boxShadow: "none" }}
                  />
                </div>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

SectionEvents.propTypes = {
  classes: PropTypes.object,
};

export default withRouter(withStyles(headersStyle)(SectionEvents));
