import React from "react";
import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Pole Wymagane"),
  gender: Yup.string().required("Pole Wymagane"),

});

export default ValidationSchema;
