import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Api } from "../../services/Api";
/*eslint-disable*/
// nodejs library to set properties for components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Mail from "@material-ui/icons/Mail";
import Slide from "@material-ui/core/Slide";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomInput from "components/CustomInput/CustomInput";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ResetPasswordModal from "./ResetPasswordModal";
import { FBButton } from "./FBLogin"
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Link} from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class LoginModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginModal: false,
      resetPasswordModal: false,
      showPassword: false,
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.props.getStateUpdates()["openLoginModal"] =  () => this.handleClickOpen('loginModal')
  }
  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  }
  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }
  handleClose = () => {
    this.setState({ loginModal: false, resetPasswordModal: false });
  };
  handleClosePopover(state) {
    this.setState({
      [state]: false,
    });
  }
  handleClickButton(state) {
    this.setState({
      [state]: true,
    });
  }
  form() {
    const { classes, buttonClasses } = this.props;
    return (
        <>
        {/* BUTTON LOGIN MODAL */}

        {/* LOGIN MODAL START */}
        <Dialog
           classes={{
            root: classes.modalRoot,
            paper: classes.modal,
          }}
          open={this.state.loginModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose()}
          aria-labelledby="login-modal-slide-title"
          aria-describedby="login-modal-slide-description"
        >
          {!this.state.resetPasswordModal && this.formik()}
          {this.state.resetPasswordModal && (
            <ResetPasswordModal
              handleClose={this.handleClose}
              
              {...this.props}
            />
          )}
        </Dialog>
        {/* LOGIN MODAL END */}
      </>
    );
  }

  formik = () => {
    const { classes } = this.props;
    return (
      <Formik
        initialValues={{ email: "", password: "" }}
        onSubmit={async (values) => {
          Api.users.login({ user: values }).then((response) => {
            if (response.ok) {
              localStorage.setItem('token', response.headers.authorization);
              localStorage.setItem('autoAccountCompleteModal', false);
              const params = window.location.search ? "&" : "?";
              window.location.href = `${window.location.pathname}${window.location.search}${params}notification_msg=Jesteś zalogowany!&notification_type=success`
            } else {
              window.setNotifications([
                {
                  type: "alert",
                  msg: "Niepoprawne hasło lub e-mail, spróbuj ponownie.",
                },
              ]);
            }
          });
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Błędny adres email")
            .required("Pole wymagane"),
          password: Yup.string().required("Pole wymagane"),
        })}
      >
        {(formik) => {
          return (
            <Card plain className={classes.modalSignupCard}>
              <DialogTitle
                id="signup-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <Button
                  justIcon
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="transparent"
                  style={{ position: "absolute", color: '#999', top: "15px", right: "15px" }}
                  onClick={() => this.handleClose(false)}
                >
                  <Close className={classes.modalClose} />
                </Button>

                <div className={classes.textCenter}>
                  <h3>
                    Zaloguj się
                  </h3>
                </div>
              </DialogTitle>
              <DialogContent
                id="login-modal-slide-description"
                className={classes.modalBody}
              >
                <form>
                  <div className={classes.textCenter}>

                    <FBButton />

                    <h4 style={{ marginTop: "15px" }}>lub</h4>
                  </div>

                    <CustomInput
                      id="login-modal-email"
                      labelText="Email"
                      noErrorIcon
                      error={formik.errors["email"]}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: formik.handleChange,
                        name: "email",
                        value: formik.values.email,
                        endAdornment: (
                          <InputAdornment position="start">
                            <Mail className={classes.icon} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      id="login-modal-pass"
                      labelText="Hasło"
                      noErrorIcon
                      error={formik.errors["password"]}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: this.state.showPassword ? "text" : "password",
                        name: "password",
                        value: formik.values.password,
                        onChange: formik.handleChange,
                        endAdornment: (
                          <InputAdornment position="start">
                            <Icon>lock_outline</Icon>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormControlLabel
                      classes={{
                        label: classes.label,
                      }}
                      style={{marginTop: "-20px"}}
                      control={
                        <Checkbox
                          value={this.state.showPassword}
                          tabIndex={-1}
                          onClick={() =>
                            this.setState({showPassword: !this.state.showPassword})
                          }
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      label="Pokaż hasło"
                    />
                </form>
              </DialogContent>
              <DialogActions
                className={
                  classes.modalFooter + " " + classes.justifyContentCenter
                }
              >
                <GridContainer justify="center">
                  <GridItem xs={12} className={classes.textCenter}>
                    <Button
                      color="primary"
                      size="small"
                      round
                      onClick={formik.submitForm}
                    >
                      Zaloguj
                    </Button>
                  </GridItem>
                  <GridItem xs={12} className={classes.textCenter}>
                    <Button
                      simple
                      color="transparent"
                      style={{marginTop: '30px'}}
                      onClick={() => {
                        this.setState({ resetPasswordModal: true });
                      }}
                    >
                      Odzyskaj Hasło
                    </Button>
                  </GridItem>
                  <GridItem xs={12} className={classes.textCenter} style={{marginTop: '20px'}}>
                    <p>Masz problem z logowaniem? <br/>Zapoznaj się znaszą stroną  <Link to={"s/rejestracja-i-logowanie"} target={'_blank'}>pomocy</Link>.</p>
                  </GridItem>
                </GridContainer>
              </DialogActions>
            </Card>
          );
        }}
      </Formik>
    );
  };
  render() {
    return <>{this.form()}</>;
  }
}

export default withStyles(javascriptStyles)(LoginModal);
