import React from "react";
import * as Yup from "yup";
import moment from "moment";

Yup.addMethod(Yup.boolean, "requiredIf", function(list, message) {
  return this.test("requiredIf", message, function(value) {
    const { path, createError } = this;

    // check if any in list contain value
    // true : one or more are contains a value
    // false: none contain a value
    var anyHasValue = list.some((value) => {
      // return `true` if value is not empty, return `false` if value is empty
      return document.querySelector(`input[name="${value}"]`).checked;
    });

    // returns `CreateError` current value is empty and no value is found, returns `false` if current value is not empty and one other field is not empty.
    return !value && !anyHasValue ? createError({ path, message }) : true;
  });
});

const ValidationSchema = Yup.object().shape(
  {
    terms: Yup.boolean()
      .required("Pole Wymagane")
      .oneOf([true], "Pole Wymagane"),
    localization_id: Yup.number().required("Pole Wymagane"),
    location_object_id: Yup.number().required("Pole Wymagane"),
    sport_id: Yup.number().required("Pole Wymagane"),
    date: Yup.date()
      .min(moment.utc().format("YYYY-MM-DD"), `Błędna data`)
      .required("Pole Wymagane"),
    publish_date: Yup.date().required("Pole Wymagane"),
    start_at: Yup.string().required("Pole Wymagane"),
    stop_at: Yup.string()
      .required("Pole Wymagane")
      .test("is-greater", "end time should be greater", function(value) {
        const { start_at } = this.parent;
        if (!start_at || !value) {
          return false;
        }
        const start_at_time =
          start_at.length < 6
            ? moment.utc({
                h: start_at.split(":")[0],
                m: start_at.split(":")[1],
              })
            : moment.utc({
                h: moment.utc(start_at).hours(),
                m: moment.utc(start_at).minutes(),
              });
        const stop_at_time =
          value && value.length < 6
            ? moment.utc({ h: value.split(":")[0], m: value.split(":")[1] })
            : moment.utc({
                h: moment.utc(value).hours(),
                m: moment.utc(value).minutes(),
              });
        return moment(stop_at_time).isAfter(moment(start_at_time));
      }),
    description: Yup.string().required("Pole Wymagane"),
    reserved_spots: Yup.number()
      .min(0, "Minimalna wartość 0")
      .lessThan(Yup.ref("max_players"), "Wartość większa od maksymalnej"),
    max_players: Yup.number()
      .required("Pole Wymagane")
      .min(1, "Minimalna wartość 1")
      .moreThan(Yup.ref("min_players"), "Wartość większa od minimalnej"),

    min_players: Yup.number()
      .required("Pole number")
      .min(1, "Minimalna wartość 1"),
    price: Yup.number()
      .required("Pole Wymagane")
      .min(0, "Minimalna wartość 0"),
    rental_price: Yup.number()
      .required("Pole Wymagane")
      .min(0, "Minimalna wartość 0"),
    pay_by_cash: Yup.boolean().when(
      ["pay_by_online_transfer", "pay_by_benefit", "pay_by_ok_system", "pay_by_prepaid", "pay_by_fitprofit"],
      {
        is: ( pay_by_online_transfer, pay_by_benefit, pay_by_ok_system, pay_by_prepaid, pay_by_fitprofit) =>
          !pay_by_online_transfer && !pay_by_benefit && !pay_by_ok_system && !pay_by_prepaid && !pay_by_fitprofit,
        then: Yup.boolean().oneOf([true], 'Field must be checked').required("Pole Wymagane"),
      }
    ),
  },
  [
    [
      "pay_by_cash",
      "pay_by_online_transfer",
      "pay_by_benefit",
      "pay_by_ok_system",
      "pay_by_prepaid",
      "pay_by_fitprofit"
    ],
  ]
);

export default ValidationSchema;
