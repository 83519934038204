/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import {withRouter} from 'react-router-dom';
// nodejs library that concatenates classes

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "PNH/components/Header/Header";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import aboutUs from "PNH/assets/img/about_us.jpg";

import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle";

function SectionHeaders({ ...props }) {
  const { classes, ...rest } = props;

  return (
    // we've set the className to cd-section so we can make smooth scroll to it
    <>
      <Header
        absolute
        color="transparent"
        brand="Material Kit PRO React"
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{ backgroundImage: `url("${aboutUs}")` }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <h1 className={classes.title}>Setki zajęć sportowych.</h1>
              <h4 className={classes.title}>
                DLA&nbsp;CIEBIE | DLA&nbsp;ZNAJOMYCH | DLA&nbsp;FIRM
              </h4>
              <h4>
                <b>Dołącz do PilkaNaHali.pl. Graj z nami przez cały rok! </b>
                Wybieraj spośród 600, a czasem nawet 800 treningów w miesiącu!
                Organizujemy i udostępniamy zajęcia sportowe - znajdź swoją
                drużynę i miejsce do gry.
              </h4>
              <br />
              <Button
                color="danger"
                size="lg"
                onClick={() => {
                  if (props.data.currentUser.id) {
                    props.history.push('/szukaj')
                  } else {
                    document.getElementById("register-modal").click();
                  }
                }}
              >
                Klikasz.Grasz - zapraszamy!
              </Button>
              <Button
                justIcon
                color="white"
                simple
                target="_blank"
                href="https://www.facebook.com/PilkaNaHalipl/"
              >
                <i className="fab fa-facebook-square" />
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </>
  );
}

SectionHeaders.propTypes = {
  classes: PropTypes.object,
};

export default withRouter(withStyles(headersStyle)(SectionHeaders));
