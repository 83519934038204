/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// nodejs library that concatenates classes
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "PNH/components/Header/Header";
import { Api } from "PNH/services/Api";
// sections for this page

import { Footer } from "PNH/layouts/Footer";

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle";
import { ParticipantsForm } from "./Participants/index";
import { Link } from "react-router-dom";
import {SummaryForm} from "./Summary";

class ParticipantsList extends React.Component {
  state = {
    activity: null,
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    this.getData();
  }

  getData = async () => {
    Api.activities.show(this.props.match.params.id).then((response) => {
      this.setState({ activity: response.data });
    });
  };

  render() {
    const { classes, creator = false } = this.props;
    const { activity } = this.state;
    return (
      <>
        <Header brand="PNH" fixed color="white" {...this.props} />

        <div className={classNames(classes.main)} style={{ marginTop: 75 }}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem>
                <h3
                  style={{
                    fontWeight: 900,
                    marginBottom: 40,
                    marginTop: 30,
                  }}
                >
                  {" "}
                  Informacje i lista obecności
                  <h6>
                    <Link to="/account-settings">Konto</Link >{" "}
                    <Link to="/koordynowane-zajecia">Koordynowane Zajęcia</Link> >
                    Informacje i lista
                  </h6>
                </h3>
              </GridItem>
              <GridItem>
                {activity && (
                  <GridItem>
                    <h5>
                      <b>{activity.sport.name}</b>
                      <br />
                      {activity.localization.name} -{" "}
                      {activity.localization.street}{" "}
                      {activity.localization.city}
                    </h5>
                    <hr />
                    <p>
                      <b>Data:</b> {activity.date}
                      <br />
                      <b>Godzina: </b> {activity.start_at}-{activity.stop_at}
                      <br />
                      <b>Cena:</b> {activity.price}
                      <br />
                      <b>Min. ilość graczy:</b> {activity.min_players}
                      <br />
                      <b>Max. ilość graczy:</b> {activity.max_players}
                      <br />
                      <b>Miejsca zarezerwowane:</b> {activity.reserved_spots}
                      <br />
                      <b>Zajęte miejsca: </b> {activity.all_reserved_spots}
                      <br />
                      <b>Link: </b> <br />
                      <Link
                        to={
                          activity.hidden ? activity.hidden_url : activity.url
                        }
                      >
                        www.pilkanahali.pl
                        {activity.hidden ? activity.hidden_url : activity.url}
                      </Link>
                      <br />
                    </p>
                    <hr />
                  </GridItem>
                )}
              </GridItem>
            </GridContainer>

            {this.state.activity && (
              <ParticipantsForm
                afterSave={this.getData}
                activity={this.state.activity}
                activityID={this.state.activity.id}
                {...this.props}
              />
            )}

            {this.state.activity && this.state.activity.activity_type === "organizer" &&  <SummaryForm activityID={this.state.activity.id} {...this.props}/> }
          </div>
        </div>

        <Footer {...this.props} />
      </>
    );
  }
}

ParticipantsList.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(presentationStyle)(ParticipantsList);
