/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Header from "PNH/components/Header/Header";
// sections for this page

import { BoughtActivities } from "./Sections/FiltersForm/index";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui icons
import { Footer } from "PNH/layouts/Footer";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceStyle";

class BoughtActivitiesPage extends React.Component {
  state = {
    fetch: 1,
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Header
          key={`ac-he-${this.state.fetch}`}
          brand="PNH"
          fixed
          color="white"
          {...this.props}
        />

        <div className={classNames(classes.main)} style={{ marginTop: 75 }}>
          <BoughtActivities
            refreshHeader={() => {
              this.setState({ fetch: this.state.fetch + 1 });
            }}
            {...this.props}
          />
        </div>

        <Footer {...this.props} />
      </div>
    );
  }
}

BoughtActivitiesPage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(BoughtActivitiesPage);
