/*eslint-disable*/
import React, { useState } from "react";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import { TemplateView } from "../TemplateView";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const RenderDialog = ({ isOpenModal = true, toggleModal, afterSave, closeAfterSave = true, classes, templateID, ...props }) => (
    <Dialog
        maxWidth='md'
        classes={{
            root: classes.modalRoot,
            paper: classes.modal,
        }}
        open={isOpenModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={toggleModal}
    >

        <DialogContent className={classes.modalBody} >
            <Button
                justIcon
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px"
                }}
                onClick={() => toggleModal(false)}
            >
                <Close className={classes.modalClose} />
            </Button>
            <TemplateView id={templateID} />
            
        </DialogContent>
        <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
        </DialogActions>
    </Dialog>
)

export function CreateActivitiesFromTemplateModal(props) {
    const [isOpenModal, toggleModal] = useState(false);
    return (
        <div style={{ textAlign: 'right' }}>
            <Button color="primary" size="sm" onClick={() => toggleModal(true)} >Dodaj zajęcia</Button>
            { isOpenModal && <RenderDialog isOpenModal={isOpenModal} toggleModal={toggleModal} {...props} />}
        </div>
    );
}






