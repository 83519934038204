import React from "react";
import GridItem from "components/Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { I18n } from "PNH/components/I18n";


export const LimitationsSection = ({ handleChange, classes, values, errors, setFieldValue }) => {
    return <>
          <GridItem xs={12}>
            <hr/>
          </GridItem>
          <GridItem xs={12} md={3}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
              error={errors.gender}
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                Płeć
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                
                value={values.gender}
                onChange={handleChange}
                inputProps={{
                  name: "gender",
                }}
              >
                {["males", "females", "unisex"].map((g) => (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={g}
                  >
                    {I18n.t(g)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} md={3} lg={3}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                error={errors.lv}
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                Poziom
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={values.lv}
               
                onChange={handleChange}
                inputProps={{
                  name: "lv",
                }}
              >
                {[
                  "free",
                  "beginners",
                  "medium",
                  "intermediate",
                  "advanced",
                ].map((lv) => (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={lv}
                  >
                    {I18n.t(lv)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
    </>
}