/*eslint-disable*/
import React from "react";
import Button from "components/CustomButtons/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import { I18n } from "PNH/components/I18n";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { SimilarActivitiesSlider } from "./SimilarActivities";
import { Hidden } from "@material-ui/core";

const levelRequired = (activity, userLevel) => {
    if (activity.verify_lv || (userLevel.check_on_pnh && activity.activity_type == 'pnh') || (userLevel.check_on_organizer && activity.activity_type == 'organizer')) {
        return true;
    }
    return false
}

const RenderDialog = ({ toggleModal, nextStep, activity, classes, userLevel, data, refreshHeader, ...props }) => (
    <>
        <DialogContent className={classes.modalBody} style={{ width: "100%", paddingTop: 30 }}>
            {levelRequired(activity, userLevel) && <h3 style={{ textAlign: "center" }}><strong>Oops!</strong></h3>}
            {!levelRequired(activity, userLevel) && <h3 style={{ textAlign: "center" }}><strong>Jesteś pewna/y?</strong></h3>}

            <span style={{ position: 'absolute', top: '10px', right: '10px' }}>
                <Button
                    justIcon
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    color="transparent"
                    onClick={() => toggleModal(false)}
                >
                    <Close className={classes.modalClose} />
                </Button>
            </span>
            <GridContainer>
                {levelRequired(activity, userLevel) && <GridItem xs={12} style={{ textAlign: "center", marginTop: 20 }}>
                    <p>Nie możesz zapisać się na zajęcia dla osób grających na poziomie <strong>"{I18n.t(activity.lv)}"</strong>. Swój poziom oceniłaś/eś jako <strong>“{I18n.t(userLevel.level)}”</strong>.
                        Możesz kontynuować aby zapisać swoich znajomych </p>
                </GridItem>}
                {!levelRequired(activity, userLevel) && <GridItem xs={12} style={{ textAlign: "center", marginTop: 20 }}>
                    <p>Zapisujesz się na zajęcia dla osób grających na poziomie <strong>"{I18n.t(activity.lv)}"</strong>. Swój poziom oceniłaś/eś jako <strong>“{I18n.t(userLevel.level)}”</strong>.
                        Czy na pewno chcesz się zapisać na te zajęcia? </p>
                </GridItem>}
                <GridItem xs={12} style={{ textAlign: "center", marginTop: 20 }}>
                    <Button type="submit" color="primary" link href="/s/poziom-sportowy" target='blank'>
                        Zobacz szczegółowe opisy poziomów
                    </Button>
                </GridItem>
                <GridItem xs={12}>
                    <div style={{ width: '370px', marginLeft: 'auto', marginRight: 'auto' }}>
                        <Hidden mdDown implementation="css" className={classes.hidden}>
                            <SimilarActivitiesSlider key={`r2-${activity.id}`} activity={activity} userLevel={userLevel} showItems="1" data={data} refreshHeader={refreshHeader} classes={classes} />
                        </Hidden>
                    </div>
                </GridItem>
                <GridItem xs={12} style={{ textAlign: "center", marginTop: 20 }}>
                    <Button type="submit" round color="primary" href={`/szukaj?with_sport_id=${activity.sport.id}&with_lv=${userLevel.level}&with_tags=${activity.localization.city}`} target='blank'>
                        Zobacz inne zajęcia
                    </Button>
                </GridItem>
                <GridItem xs={12} style={{ textAlign: "center", marginTop: 20 }}>
                    <Button color="white" round onClick={nextStep}>
                        Chcę kontynuować
                    </Button>
                </GridItem>
            </GridContainer>
        </DialogContent>
        <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
        </DialogActions>
    </>
)

export function WrongLevelStep
    (props) {
    return (
        <RenderDialog {...props} />
    );
}
