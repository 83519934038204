/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ActivityCard from "PNH/components/Activity/ActivityCard";
import { Api } from "PNH/services/Api";
import Carousel from "react-slick";
import Placeholder from "PNH/assets/img/image_placeholder.jpg"
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

const ActivitiesSlider = ({ activity, userLevel, classes, showItems = null, xlItems = 6, lgItems = 4, ...props }) => {

  const [firstSection, setFirstSection] = useState([])

  const show = () => {
    if (isWidthUp("xl", props.width)) {
      return xlItems;
    }
    if (isWidthUp("lg", props.width)) {
      return lgItems;
    }
    if (isWidthUp("md", props.width)) {
      return 3;
    }
    if (isWidthUp("sm", props.width)) {
      return 2;
    }
    if (isWidthUp("xs", props.width)) {
      return 1;
    }
    return 1;
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: showItems != null ? showItems : show(),
    slidesToScroll: 1,
    autoplay: false,
    swipe: true,
    arrows: true,
  };


  useEffect(() => {
    Api.activities.index({
      limit: 12,
      with_free_spots: true,
      with_no_id: activity.id,
      with_canceled: false,
      with_sport_id: activity.sport.id,
      with_not_bought_by: props.data.currentUser.id,
      with_lv: userLevel?.level, with_tags: activity.localization.city
    }).then(({ ok, data }) => {
      if (ok) {
        if (data.rows.length < showItems) {
          Api.activities.index({
            limit: 12,
            with_free_spots: true,
            with_no_id: activity.id,
            with_canceled: false,
            with_sport_id: activity.sport.id,
            with_not_bought_by: props.data.currentUser.id,
            with_tags: activity.localization.city
          }).then((response) => {
            setFirstSection(response.data.rows);
          })
        } else {
          setFirstSection(data.rows);
        }
      }
    })
  }, [])




  if (!firstSection) {
    return null;
  }

  return (
    <GridContainer>
      <GridItem xs={12} className={classes.marginAuto}>
        <Carousel {...settings}>
          {firstSection.map((el, index) => {
            if (!el.id) {
              return <div style={{ paddingLeft: 15, paddingRight: 15 }}><img src={Placeholder} alt="placeholder-activity" key={`pl-${index}`} style={{ maxWidth: "95%" }} /></div>
            }
            return (
              <GridItem key={el.id}>
                <ActivityCard
                  activity={el}
                  {...props}
                  afterSave={props.refreshHeader}
                />
              </GridItem>
            );
          })}
        </Carousel>
      </GridItem>
    </GridContainer>
  );
}

export const SimilarActivitiesSlider = withWidth()(ActivitiesSlider)

