import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import Edit from "@material-ui/icons/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import TablePagination from "@material-ui/core/TablePagination";
import { Api } from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import {withRouter} from 'react-router-dom';
import Tooltip from "@material-ui/core/Tooltip";

import moment from "moment";
import ListAltIcon from "@material-ui/icons/ListAlt";
import Primary from "components/Typography/Primary";

import extendedTablesStyle from "PNH/assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";

class ActivitiesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 0,
      limit: 10,
      total: 0,
      with_localization_id: props.localizationID || "",
      with_date_less: "",
      with_date_greater: moment()
        .utc()
        .subtract(5, "days")
        .format("YYYY-MM-DD"),
      with_tags: "",
    };
  }

  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    const params = () => {
      if (this.props.creator) {
        return { created: true };
      }
      return { coordinated: true };
    };

    Api.activities
      .index({
        page: this.state.page,
        limit: this.state.limit,
        with_date_less: this.state.with_date_less,
        with_date_greater: this.state.with_date_greater,
        with_tags: this.state.with_tags,
        ...params(),
      })
      .then((response) => {
        const tableContent = response.data.rows.map(this.mapRows);
        this.setState({ data: tableContent, total: response.data.total });
      });
  };
  mapRows = (row) => {
    const { classes } = this.props;
    return [
      <span style={{ whiteSpace: "nowrap" }}>
        <b> {row.date}</b> <br /> {row.start_at}
        {"-"}
        {row.stop_at}
      </span>,
      <span style={{ whiteSpace: "nowrap" }}>
        <Primary>
          <b>{row.sport.name}</b>
        </Primary>
      </span>,
      <div style={{ minWidth: 250 }}>
        {row.localization.name} <br /> {row.localization.street}{" "}
        {row.localization.city}
      </div>,
      <>
        {row.all_reserved_spots}/{row.max_players}
      </>,
      row.list_checked ? "Tak" : "Nie",
      row.confirmed ? "Zweryfikowano" : "Weryfikacja",

      <span style={{ whiteSpace: "nowrap" }}>
        <Button
          color="primary"
          simple
          onClick={() => {
            window.location.href = `/lista-obecnosci/${row.id}`;
          }}
          key={`edit_${row.id}`}
          className={classes.actionButton}
        >
          <ListAltIcon className={classes.icon} />
        </Button>

        {this.props.creator && (
          <>
            { !row.read_only && (<Button
              color="success"
              simple
              onClick={() => {
                window.location.href = `/zajecia-edycja/${row.id}`;
              }}
              key={`edit_${row.id}`}
              className={classes.actionButton}
            >
              <Edit className={classes.icon} />
            </Button> )}
            <Tooltip
              id="tooltip-top"
              title="Kopiuj"
              placement="bottom"
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                color="success"
                simple
                onClick={() => {
                  window.location.href = `/dodaj-zajecia/${row.id}`;
                }}
                key={`edit_${row.id}`}
                className={classes.actionButton}
              >
                <FileCopyIcon className={classes.icon} />
              </Button>
            </Tooltip>
          </>
        )}
      </span>,
    ];
  };
  changePage = (event, page) => {
    this.setState({ page: page }, this.getData);
  };

  onChangeRowsPerPage = (event) => {
    this.setState({ limit: event.target.value, page: 0 }, this.getData);
  };

  handleChange = (event) => {
    this.setState(
      { [event.target.name]: event.target.value, page: 0 },
      this.getData
    );
  };

  filters = () => {
    const { classes, creator = false } = this.props;
    return (
      <>
        <GridItem xs={12}>
          {creator && (
            <Button
              style={{ float: "right" }}
              color="success"
              onClick={() => {
                this.props.history.push(`/dodaj-zajecia`)
              }}
            >
              Dodaj
            </Button>
          )}
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={6} md={3}>
                  <CustomInput
                    id={`with_date_greater`}
                    labelText="Data od"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      placeholder: "Szukaj ROK-M-D...",
                      type: "text",
                      name: `with_date_greater`,
                      value: this.state[`with_date_greater`],
                      onChange: this.handleChange,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <CustomInput
                    id={`with_date_less`}
                    labelText="Data do"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      placeholder: "Szukaj ROK-M-D...",
                      type: "text",
                      name: `with_date_less`,
                      value: this.state[`with_date_less`],
                      onChange: this.handleChange,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    id={`with_tags`}
                    labelText="Szukaj..."
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      placeholder: "Szukaj ROK-M-D...",
                      type: "text",
                      name: `with_tags`,
                      value: this.state[`with_tags`],
                      onChange: this.handleChange,
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </>
    );
  };
  render() {
    const { classes, filters } = this.props;
    return (
      <GridContainer>
        {!filters && this.filters()}
        <GridItem xs={12}>

              <Table
                striped
                tableHead={[
                  <b>Data</b>,
                  <b>Dyscyplina</b>,
                  <b>Lokalizacja</b>,
                  <b>Zapisanych</b>,
                  <b>Sprawdzono&nbsp;listę</b>,
                  <b>Status&nbsp;Zajęć</b>,
                  "",
                ]}
                tableData={this.state.data}
              />
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                page={this.state.page}
                rowsPerPage={this.state.limit}
                count={this.state.total}
                onChangePage={this.changePage}
                onChangeRowsPerPage={this.onChangeRowsPerPage}
                labelRowsPerPage="Ilość"
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} z ${count}`
                }
              />
    
        </GridItem>
      </GridContainer>
    );
  }
}

ActivitiesTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(extendedTablesStyle)(ActivitiesTable));
