import React from "react";
import {Api} from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import formRegular from "PNH/assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "PNH/assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "PNH/assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import {Link} from "react-router-dom";
import {howKnowAboutUsValues, UsersFunctions} from "./index";
import Face from "@material-ui/icons/Face";
import InputAdornment from "@material-ui/core/InputAdornment";
import {RecordVoiceOver} from "@material-ui/icons";
import Email from "@material-ui/icons/Email";
import LocationCityIcon from '@material-ui/icons/LocationCity';
import EventIcon from '@material-ui/icons/Event';
import Danger from "../../../../../components/Typography/Danger";
import Warning from "../../../../../components/Typography/Warning";
import {hasUserRole} from "../../../../services/Helpers";
import ShortTextIcon from '@material-ui/icons/ShortText';

const styles = {
    ...formRegular,
    ...stylesExtended,
    ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});



class Form extends React.Component {
    state = {
        deleteModal: false,
    };

    constructor(props) {
        super(props);
        this.fetch();
    }

    deleteModal = (open) => {
        this.setState({deleteModal: open});
    };

    fetch = () => {
        if (this.props.values.id) {
            Api.users.show(this.props.values.id).then((response) => {
                if (response.ok) {
                    const howKnowAboutUsHolder = howKnowAboutUsValues.includes(response.data.how_know_about_us) ? response.data.how_know_about_us : "INNE"

                    this.props.setValues({
                        id: response.data.id,
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        email: response.data.email,
                        birth_date: response.data.birth_date,
                        city: response.data.city,
                        gender: response.data.gender,
                        how_know_about_us: response.data.how_know_about_us,
                        how_know_about_us_holder:  howKnowAboutUsHolder,
                        show_how_know_about_us:  howKnowAboutUsHolder == "INNE",
                    });
                } else {
                    window.setNotifications([
                        {type: "alert", msg: "Coś poszło nie tak"},
                    ]);
                }
            });
        }
    };

    render() {
        const {
            classes,
            values,
            errors,
            handleChange,
            setFieldValue,
            handleSubmit,
        } = this.props;

        const disabled = !hasUserRole(this.props.data.currentUser, "user_role_edit_profile")

        return (
            <form onSubmit={handleSubmit}>
                <GridContainer>
                    <GridItem xs={12}>
                        <CustomInput
                            labelText=<span>Email <Danger>*</Danger></span>
                        error={errors.email}
                        formControlProps={{
                        fullWidth: true,
                    }}
                        inputProps={{
                        value: values.email,
                        name: "email",
                        onChange: handleChange,
                        type: "email",
                        endAdornment: (
                            <InputAdornment position="end" className={classes.inputAdornment}>
                                <Email className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                        ),
                    }}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <CustomInput
                            labelText=<span>Imię <Danger>*</Danger></span>
                        error={errors.first_name}
                        formControlProps={{
                        fullWidth: true,
                    }}
                        inputProps={{
                        value: values.first_name,
                        name: "first_name",
                        disabled: disabled,
                        onChange: handleChange,
                        type: "text",
                        endAdornment: (
                            <InputAdornment position="end" className={classes.inputAdornment}>
                                <Face className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                        )
                    }}

                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <CustomInput
                            labelText=<span>Naziwsko <Danger>*</Danger></span>
                        error={errors.last_name}
                        formControlProps={{
                        fullWidth: true,
                    }}
                        inputProps={{
                        value: values.last_name,
                        name: "last_name",
                        onChange: handleChange,
                        disabled: disabled,
                        type: "text",
                        endAdornment: (
                            <InputAdornment position="end" className={classes.inputAdornment}>
                                <RecordVoiceOver className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                        ),
                    }}
                        />
                    </GridItem>

                    <GridItem xs={12}>
                        <CustomInput
                            labelText=<span>Data Urodzenia <Danger>*</Danger></span>
                        error={errors.birth_date}
                        formControlProps={{
                        fullWidth: true,
                    }}
                        inputProps={{
                        placeholder: "RRRR-MM-DD",
                        value: values.birth_date,
                        name: "birth_date",
                        onChange: handleChange,
                        disabled: disabled,
                        type: "text",
                        endAdornment: (
                            <InputAdornment position="end" className={classes.inputAdornment}>
                                <EventIcon className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                        ),
                    }}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <CustomInput
                            labelText=<span>Miasto <Danger>*</Danger></span>
                        error={errors.city}
                        formControlProps={{
                        fullWidth: true,
                    }}
                        inputProps={{
                        value: values.city,
                        name: "city",
                        onChange: handleChange,
                        type: "text",
                        endAdornment: (
                            <InputAdornment position="end" className={classes.inputAdornment}>
                                <LocationCityIcon className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                        ),
                    }}
                        />
                    </GridItem>

                    <GridItem xs={12}>
                        <FormControl fullWidth className={classes.selectFormControl}>
                            <InputLabel
                                htmlFor="simple-select"
                                className={classes.selectLabel}
                            >
                                <span>Płeć <Danger>*</Danger></span>
                            </InputLabel>
                            <Select
                                MenuProps={{
                                    className: classes.selectMenu,
                                }}
                                classes={{
                                    select: classes.select,
                                }}
                                value={values.gender}
                                onChange={handleChange}
                                disabled={disabled}
                                inputProps={{
                                    name: "gender",
                                }}
                            >
                                <MenuItem
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected,
                                    }}
                                    value="female"
                                >
                                    Kobieta
                                </MenuItem>
                                <MenuItem
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected,
                                    }}
                                    value="male"
                                >
                                    Mężczyzna
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControl fullWidth className={classes.selectFormControl}>
                            <InputLabel
                                htmlFor="simple-select"
                                className={classes.selectLabel}
                            >
                                Skąd o nas wiesz?
                            </InputLabel>
                            <Select
                                MenuProps={{
                                    className: classes.selectMenu,
                                }}
                                classes={{
                                    select: classes.select,
                                }}
                                value={values.how_know_about_us_holder}
                                onChange={(e) => {
                                    const value = e.target.value.toUpperCase()
                                    console.log(value)

                                    setFieldValue("how_know_about_us_holder", value)

                                    if (value == "INNE") {
                                        setFieldValue("show_how_know_about_us", true)
                                        setFieldValue("how_know_about_us", "")
                                    } else {
                                        setFieldValue("show_how_know_about_us", false)
                                        setFieldValue("how_know_about_us", value)
                                    }
                                }}
                                disabled={disabled}
                                inputProps={{
                                    name: "how_know_about_us_holder",
                                }}
                            >
                                {
                                    howKnowAboutUsValues.map((v) => {
                                        return <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected,
                                            }}
                                            value={v}
                                        >
                                            {v}
                                        </MenuItem>
                                    })
                                }


                            </Select>
                        </FormControl>
                    </GridItem>
                    {values.show_how_know_about_us && <GridItem xs={12}>
                        <CustomInput
                            labelText="Inne źródło:"
                        formControlProps={{
                        fullWidth: true,
                    }}
                        inputProps={{
                        value: values.how_know_about_us,
                        name: "how_know_about_us",
                        disabled: disabled,
                        onChange: handleChange,
                        type: "text",
                        endAdornment: (
                            <InputAdornment position="end" className={classes.inputAdornment}>
                                <ShortTextIcon className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                        )
                    }}

                        />
                    </GridItem>}
                    <GridItem xs={12}>
                        <Danger>* pola wymagane</Danger>
                    </GridItem>
                    <GridItem xs={12}>
                        {disabled && <GridItem xs={12} style={{textAlign: 'center'}}>
                            Na tym koncie znajdują się rezerwacje zajęć. W celu zmiany danych, innych niż email oraz
                            miasto, prosimy o <Link to={"s/kontakt"}> kontakt z obsługą klienta</Link>.
                        </GridItem>}
                        <GridItem xs={12} style={{textAlign: 'center'}}>
                            <Button type="submit" color="primary" round={true}>
                                Zapisz
                            </Button>
                        </GridItem>
                    </GridItem>
                </GridContainer>
            </form>
        );
    }
}

export default withStyles(styles)(Form);
