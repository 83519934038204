import React from "react";
import GridItem from "components/Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Datetime from "react-datetime";
import moment from "moment";
import Dates from "../Dates";


export const DateAndTimeSection = ({ handleChange, classes, values, errors, setFieldValue, ...props }) => {
    return <>
        <GridItem xs={12}>
            <hr />
        </GridItem>
        <>
            <GridItem xs={12}>
                <Dates  handleChange={handleChange} classes={classes} values={values} errors={errors} setFieldValue={setFieldValue} {...props} />
            </GridItem>
        </>
    </>
}