import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import SectionProducts from "./SectionProducts";
import { Api, serializeParams } from "PNH/services/Api";
import moment from "moment";

export const ActivitiesFunctions = {
  index: (values, formik) => {
    return Api.activities
      .index({ ...values, ...{ data: [] } })
      .then((response) => {
        if (response.ok) {
          formik.setFieldValue("data", response.data);
          const params = { ...values, ...{ data: [] } };
          // eslint-disable-next-line 
          history.pushState(
            {},
            "wykupione-zajecia",
            "/wykupione-zajecia?" + serializeParams(params)
          );
          window.scrollTo(0, 0);
        }
      });
  },

  defaultValues: (props) => {

    const urlParams = new URLSearchParams(window.location.search);
    return {
      page: urlParams.get("page") || 0,
      limit: 8,
      bought_activities: "true",
      data: [],
      with_tags: urlParams.get("with_tags") || "",
      with_price_greater: urlParams.get("with_price_greater") || "",
      with_price_less: urlParams.get("with_price_less") || "",
      with_date_greater:
        urlParams.with_date_greater ||
        moment()
          .utc()
          .format("YYYY-MM-DD"),
      with_date_less: urlParams.get("with_date_less") || "",
      with_localization_id: urlParams.get("with_localization_id") || "",
      localization: {},
      with_sport_id: urlParams.get("with_sport_id") || "",
      sport: {},
      with_start_at_greater: urlParams.get("with_start_at_greater") || "00:00",
      with_start_at_less: urlParams.get("with_start_at_less") || "23:59",
      with_lv: urlParams.get("with_lv") || "",
      with_pay_by_benefit: urlParams.get("with_pay_by_benefit") || "",
      with_pay_by_ok_system: urlParams.get("with_pay_by_ok_system") || "",
      with_promo: urlParams.get("with_promo") || "",
      with_coach: urlParams.get("with_coach") || "",
      with_activity_type: urlParams.get("with_activity_type") || "",
      with_gender: urlParams.get("with_gender") || "",
      with_archive: urlParams.get("with_archive") || "",
      with_city: urlParams.get("with_city") || "",
      with_free_spots: urlParams.get("with_free_spots") || "",
      with_min_participants: urlParams.get("with_min_participants") || "",
      city: {},
      afterSave: props.afterSave,
    };
  },
};

export const BoughtActivities = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...ActivitiesFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
    ActivitiesFunctions.index(values, formikProps)
      .then((response) => { })
      .then(() => {
        formikProps.setTouched({});
      });
  },
})(SectionProducts);
