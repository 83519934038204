/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Suspense } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
// react components for routing our app without refresh
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// core components
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import sectionsStyle from "assets/jss/material-kit-pro-react/views/presentationSections/sectionsStyle";
import carouselStyle from "assets/jss/material-kit-pro-react/views/componentsSections/carouselStyle";

// images array used in rendering a function for this section
import ActivityCard from "PNH/components/Activity/ActivityCard";
import { Api } from "PNH/services/Api";
import Carousel from "react-slick";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import Placeholder from "PNH/assets/img/image_placeholder.jpg"


class LastWatched extends React.Component {
  state = {
    firstSection: [],
  };

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    const lc = localStorage.getItem('lastWatched')
    const lastWatched =   !!lc ? lc.split(',') : []
    if(lastWatched.length > 0) {
      Api.activities.index({ limit: 12, with_canceled: false, with_ids: lastWatched }).then(({ ok, data }) => {
        if (ok) {
          this.setState({ firstSection: data.rows });
        }
      })
    }
  };

  renderContainerFluid(collection, cssClass) {
    if (!collection) {
      return null;
    }
    const { classes, xlItems = 6, lgItems = 4, mdItems = 3, smItems = 2, xsItems = 1 } = this.props;

    const show = () => {
      if (isWidthUp("xl", this.props.width)) {
        return xlItems;
      }
      if (isWidthUp("lg", this.props.width)) {
        return lgItems;
      }
      if (isWidthUp("md", this.props.width)) {
        return mdItems;
      }
      if (isWidthUp("sm", this.props.width)) {
        return smItems;
      }
      if (isWidthUp("xs", this.props.width)) {
        return xsItems;
      }
      return 1;
    };

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: show(),
      slidesToScroll: 1,
      autoplay: false,
      swipe: true,
      arrows: true,
    };

    return (
      <GridContainer>
        <GridItem xs={12} className={classes.marginAuto}>
          <Carousel {...settings}>
            {collection.map((el, index) => {
                if(!el.id){
                    return <div style={{paddingLeft: 15, paddingRight: 15}}><img src={Placeholder} alt="placeholder-activity" key={`pl-${index}`} style={{maxWidth: "95%"}}/></div>
                }
                return (
                    <GridItem key={el.id}>
                    <ActivityCard
                        activity={el}
                        {...this.props}
                        afterSave={this.props.refreshHeader}
                    />
                    </GridItem>
                );
            })}
          </Carousel>
        </GridItem>
      </GridContainer>
    );
  }
  render() {
    const { classes } = this.props;
    if (this.state.firstSection.length === 0) {
      return null
    }

    return <GridContainer alignItems="center" justify="center" >
      <GridItem xs={12} sm={8} md={8} lg={8}>
        <GridContainer justifyContent="space-between">
          <GridItem sm><h3 style={{ fontWeight: '900' }}>Ostatnio oglądane</h3></GridItem>
        </GridContainer>
      </GridItem>
      <GridItem xs={12} sm={8} md={8} lg={8} >
        <Suspense fallback={<img src={Placeholder} alt="placeholder" />}>
          <>
            {this.renderContainerFluid(
              this.state.firstSection,
              classes.photoGallery
            )}
          </>
        </Suspense>
      </GridItem>
    </GridContainer>
  }
}

LastWatched.propTypes = {
  classes: PropTypes.object,
};

export default withStyles({ ...sectionsStyle, ...carouselStyle })(
  withWidth()(LastWatched)
);
