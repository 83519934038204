/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, {Suspense, useCallback, useEffect, useState} from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "PNH/components/Header/Header";
import Parallax from "components/Parallax/Parallax";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import {PlaceHolder} from "PNH/components/placeholder"
// sections for this page
import {Footer} from "PNH/layouts/Footer";
import withWidth, {isWidthUp} from "@material-ui/core/withWidth";
import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
import Prefooter from "./Sections/SectionPreFooter";
import bg7 from "PNH/assets/img/banner-v.png";
import preFooter from "assets/jss/material-kit-pro-react/views/componentsSections/preFooter";
import Cities from "./Sections/Cities";
import pilki from "PNH/assets/img/pilki.png"
import MainSearchBar from "./Sections/MainSearchBar";
import OrganizerSection from "./Sections/OrganizerSection";
import PartnersSection from "./Sections/PartnersSection";
import {BlogSection} from "./Sections/BlogSection";
import {Api} from "../../services/Api";
import Placeholder from "../../../assets/img/image_placeholder.jpg";
import MainSlider from "./Sections/ActivitiesSliders/MainSlider";
import PromoSlider from "./Sections/ActivitiesSliders/PromoSlider";
import NewActivitiesSlider from "./Sections/ActivitiesSliders/NewActivitiesSlider";
import JoinUsSection from "./Sections/JoinUsSection";

const styles = {
    ...preFooter,
    ...presentationStyle
}

const MainPage = ({...props}) => {
    const [fetch, setFetch] = useState(1)
    const [tiles, setTiles] = useState([])

    const {classes, width, ...rest} = props;

    useEffect(() => {
        Api.mainPageTiles.index({limit: 50}).then(r => {
            setTiles(r.data.rows)
        })
    }, [])

    const refreshHeader = useCallback(
        () => {
            setFetch(fetch + 1)
        }
    )

    const ballsMargin = () => {
        if (isWidthUp("xl", width)) {
            return "500px";
        }
        if (isWidthUp("lg", width)) {
            return "500px";
        }
        if (isWidthUp("md", width)) {
            return "750px";
        }
        if (isWidthUp("sm", width)) {
            return "1450px";
        }
        if (isWidthUp("xs", width)) {
            return "1500px";
        }
        return "500px";
    };


    return <>
        <Header
            key={`ac-he-${fetch}`}
            brand="Klikasz. Grasz."
            fixed
            color="white"
            changeColorOnScroll={{
                height: 200,
                color: "white",
            }}
            {...rest}
        />
        <Parallax
            small
            image={require("../../assets/img/banner.jpg")}
            className={classes.parallax}
        >
            <div
                style={{
                    zIndex: 10,
                    position: "absolute",
                    bottom: 30,
                    width: "100%",
                }}
            >
                <GridContainer alignItems="center" justify="center">
                    <GridItem xs={11} sm={10} md={10}>
                        <h2 style={{color: "white"}}>
                            <b>W co chcesz zagrać?</b>
                        </h2>
                        <Suspense fallback={<div></div>}>
                            <MainSearchBar/>
                        </Suspense>
                    </GridItem>
                </GridContainer>
            </div>
        </Parallax>
        <BlogSection tiles={tiles} section="not_logged_in_1" {...props}/>
        <div className={classNames(classes.main)}>
            <div style={{
                backgroundImage: `url(${pilki})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPositionY: ballsMargin()
            }}>
                <JoinUsSection
                    {...props}
                    refreshHeader={refreshHeader}
                />
            </div>
        </div>

        <BlogSection tiles={tiles} section="not_logged_in_2" {...props}/>
        <Suspense fallback={<img src={Placeholder} alt="placeholder"/>}>
            <MainSlider refreshHeader={refreshHeader} {...props} />
        </Suspense>
        <BlogSection tiles={tiles} section="not_logged_in_3" {...props}/>
        <Suspense fallback={<img src={Placeholder} alt="placeholder"/>}>
            <PromoSlider refreshHeader={refreshHeader} {...props} />
        </Suspense>
        <BlogSection tiles={tiles} section="not_logged_in_4" {...props}/>
        <Suspense fallback={<img src={Placeholder} alt="placeholder"/>}>
            <NewActivitiesSlider refreshHeader={refreshHeader} {...props} />
        </Suspense>
        <BlogSection tiles={tiles} section="not_logged_in_5" {...props}/>

        <div className={classNames(classes.main)}>
            <GridContainer alignItems="center" justify="center" style={{marginTop: '60px', padding: '20px',}}>
                <GridItem xs={12} sm={10} md={8}>
                    <GridContainer>
                        <GridItem className={"rounded-container " + classNames(
                            classes.subscribeLine,
                            classes.subscribeLineImage
                        )}
                                  style={{
                                      padding: '55px',
                                      backgroundImage: `url(${bg7})`,
                                      backgroundRepeat: 'no-repeat',
                                      backgroundSize: 'cover',
                                      backgroundPosition: '50% 75%'
                                  }}
                        >
                            <Suspense fallback={<div></div>}>
                                <OrganizerSection {...props} />
                            </Suspense>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
        </div>
        <BlogSection tiles={tiles} section="not_logged_in_6" {...props}/>
        <GridContainer alignItems="center" justify="center" style={{marginTop: '60px', padding: '20px',}}>
            <GridItem xs={12} sm={10} md={8}>
                <Suspense fallback={<PlaceHolder/>}>
                    <PartnersSection/>
                </Suspense>
            </GridItem>
        </GridContainer>
        <Suspense fallback={<div></div>}>
            <Prefooter/>
        </Suspense>
        <Footer {...props} />

    </>


}


MainPage.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(styles)(withWidth()(MainPage));
