/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";

import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle";

import cardBlog4 from "PNH/assets/img/localization3.jpg";
import blog5 from "PNH/assets/img/localization1.jpg";
import blog7 from "PNH/assets/img/localization2.jpg";
import Primary from "components/Typography/Primary";

function SectionBlogs({ ...props }) {
  const { classes, ...rest } = props;
  return (
    <div className="cd-section" {...rest}>
      {/* Blogs 2 START */}
      <div className={classes.blog}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={12}
              md={10}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <h2 className={classes.title} style={{ textAlign: "center" }}>
                Szukasz obiektu do gry?
              </h2>
              <h5 style={{ textAlign: "center" }}>
                Pomożemy! Halę mamy nie tylko w nazwie! Od 8 lat udostępniamy
                hale, boiska piłkarskie, boiska sezonowe np. do gry w siatkówkę,
                obiekty sportowe pod integracje firmowe <br />
                <br />
                <b>
                  Sprawdzone obiekty i dostępne terminy - zapraszamy do kontaktu
                  <br />
                  <Primary>
                    <h4>
                      <b>kontakt@pilkanahali.pl</b>
                    </h4>
                  </Primary>
                </b>
                <br />
                <br />
                Miesięcznie realizujemy tego typu usługi dla blisko 100 klientów
                firmowych.
              </h5>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={4} md={4}>
                  <Card plain blog>
                    <CardHeader plain image>
                      <a href="#pablito" onClick={(e) => e.preventDefault()}>
                        <img src={cardBlog4} alt="..." />
                      </a>
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${cardBlog4})`,
                          opacity: "1",
                        }}
                      />
                    </CardHeader>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <Card plain blog>
                    <CardHeader plain image>
                      <a href="#pablito" onClick={(e) => e.preventDefault()}>
                        <img src={blog5} alt="..." />
                      </a>
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${blog5})`,
                          opacity: "1",
                        }}
                      />
                    </CardHeader>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <Card plain blog>
                    <CardHeader plain image>
                      <a href="#pablito" onClick={(e) => e.preventDefault()}>
                        <img src={blog7} alt="..." />
                      </a>
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${blog7})`,
                          opacity: "1",
                        }}
                      />
                    </CardHeader>
                  </Card>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Blogs 2 END */}
    </div>
  );
}

SectionBlogs.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(blogsStyle)(SectionBlogs);
