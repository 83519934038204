/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomInput from "components/CustomInput/CustomInput";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Rose from "components/Typography/Rose";
import Primary from "components/Typography/Primary";
import { Api } from "PNH/services/Api";

// @material-ui icons
import Mail from "@material-ui/icons/Mail";

import bg7 from "PNH/assets/img/banner.jpg";

import styles from "assets/jss/material-kit-pro-react/views/componentsSections/preFooter";

class SectionPreFooter extends React.Component {
  state = { email: "", hide: false };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <div
          className={classNames(
            classes.subscribeLine,
            classes.subscribeLineImage
          )}
          style={{ backgroundImage: `url(${bg7})` }}
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={6}
                md={6}
                className={classNames(classes.mlAuto, classes.mrAuto)}
              >
                <div className={classes.textCenter}>
                  <h3 className={classes.title}>Zapisz się do newslettera</h3>
                  <p className={classes.description}>
                    <Rose>
                      <b>Chcesz być na bieżąco?</b>
                    </Rose>
                  </p>
                </div>
                <Card raised className={classes.card}>
                  <CardBody className={classes.cardBody}>
                    {!this.state.hide && (
                      <form>
                        <GridContainer>
                          <GridItem xs={12} sm={6} md={6} lg={8}>
                            <CustomInput
                              value={this.state.email}
                              id="emailPreFooter"
                              formControlProps={{
                                fullWidth: true,
                                className: classes.cardForm,
                              }}
                              inputProps={{
                                onChange: (e) => {
                                  this.setState({ email: e.target.value });
                                },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Mail />
                                  </InputAdornment>
                                ),
                                placeholder: "Email...",
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6} lg={4}>
                            <Button
                              color="primary"
                              block
                              className={classes.subscribeButton}
                              onClick={() => {
                                if (
                                  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                                    this.state.email
                                  )
                                ) {
                                  Api.newsletter
                                    .create({
                                      newsletter: { email: this.state.email },
                                    })
                                    .then(({ ok }) => {
                                      if (ok) {
                                        this.setState({ hide: true });
                                      }
                                    });
                                }
                              }}
                            >
                              Zapisz
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </form>
                    )}
                    {this.state.hide && (
                      <Primary>
                        <h4>
                          <b>Dziękujemy za zapis!</b>
                        </h4>
                      </Primary>
                    )}
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

SectionPreFooter.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(SectionPreFooter);
