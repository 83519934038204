import React from "react";
import { Api } from "PNH/services/Api";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import formRegular from "PNH/assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "PNH/assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "PNH/assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import { DateAndTimeSection } from "./FormSections/DateAndTime";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";


const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteModal: false,
      fetchActivity: false,
    };

  }

  form() {
    const {
      classes,
      values,
      errors,
      handleChange,
      setFieldValue,
      handleSubmit,
      isSubmitting,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <GridContainer>
          <GridItem xs={12}>
            <h4>
              <b>Dodaj zajęcia z szablonu</b>
            </h4>
          </GridItem>

          <DateAndTimeSection values={values} classes={classes} errors={errors} handleChange={handleChange} setFieldValue={setFieldValue} {...this.props}/>

        </GridContainer>
        <GridContainer justify="space-between">
        <GridItem xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      checked={values.terms}
                      onClick={() => setFieldValue("terms", !values.terms)}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label={
                    <span
                      style={{
                        color: !!errors["terms"] ? "red" : "inherit",
                      }}
                    >
                      Akceptuję{" "}
                      <a
                        href="https://pnh-public.s3.eu-central-1.amazonaws.com/regulamin.pdf"
                        target="_blank"
                      >
                        postanowienia i regulamin
                      </a>
                      . *
                    </span>
                  }
                />
              </GridItem>
          <GridItem xs={12} sm style={{ textAlign: "right" }}>
            <Button
              type="submit"
              color="primary"
              disabled={!values.terms || isSubmitting}
            >
              Dodaj
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    );
  }

  render() {
    const { fetchActivity, fetchLocalizations, fetchLocations } = this.state;
    if (!fetchActivity ) {
      return this.form();
    } else {
      return <h4>Loading...</h4>;
    }
  }
}

export default withStyles(styles)(Form);
