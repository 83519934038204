import React, { useCallback, useEffect, useState } from "react";
import { Api } from "PNH/services/Api";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import formRegular from "PNH/assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "PNH/assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import Tooltip from "@material-ui/core/Tooltip";
import VerifiedIcon from '@material-ui/icons/VerifiedUser';
import Primary from "components/Typography/Primary";
import { I18n } from "PNH/components/I18n";

const styles = {
  ...formRegular,
  ...stylesExtended,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const updateValue = (index, values, setFieldValue, value) => {
  const objects_attributes = [...values.user_level_opinion];
  objects_attributes[index]['level'] = value;
  setFieldValue("user_level_opinion", objects_attributes);
}


const FormWrapper = ({
  values,
  errors,
  setFieldValue,
  handleSubmit,
  data,
  toggleModal,
}) => {
  const [hide, setHide] = useState(true)
  const [activity, setActivity] = useState({})

  const skipModal = useCallback(()=>{
    const userParticipant = activity.activity_participants.filter((pRow) => {
      return pRow.user.id == data.currentUser.id
    })
    Api.activities.participants.opinions.markOpinionAsSkipped(userParticipant[0].id)
    toggleModal(false)
  })

  const getActivity = useCallback(() => {
    Api.activities.participants.opinions.getLastActivityWithoutOpinion().then((r) => {
      if (r.ok && r.data) {
        setFieldValue('activity', r.data)
        setFieldValue('activit_id', r.data.id)
        setActivity(r.data)
        toggleModal(true)
      }
    })
  })

  const getData = useCallback(() => {
    if (!!!activity.id) {
      return false
    }
    if (activity.activity_participants.filter((pRow) => {
      return pRow.user.id == data.currentUser.id
    }).length == 0) {
      setHide(true)
    } else {
      Api.activities.participants.opinions.index(activity.id).then(response => {
        if (response.data.total == 0) {
          setHide(false)
          setFieldValue("user_level_opinion", activity.activity_participants.filter((pRow) => {
            return pRow.user.id !== data.currentUser.id
          }).map((row) => {
            return { level: activity.lv, activity_participant_id: row.id, activity_participant: row.user }
          }))
        } else {
          setHide(false)
          setFieldValue("user_level_opinion", response.data.rows)
        }
      })
    }
  })


  useEffect(() => {
    getActivity()
    setFieldValue('afterSave', () => {
      values.afterSave();
      getData();
    })
  }, [])

  useEffect(() => {
    getData()
  }, [activity])

  if (hide) {
    return null
  }

  return <>
    <form onSubmit={handleSubmit} >
      <GridContainer
        justifyContent="center"
        alignItems="center">
        <GridItem xs="12" style={{ textAlign: 'center', marginTop: '-25px' }}>
          <h3><strong>Oceń poziom gry!</strong></h3>
          <p style={{ marginTop: 20, marginBottom: 20 }}>Pomóż nam ulepszyć zajęcia i oceń anonimowo poziom gry innych uczestników.</p>
        </GridItem>
      </GridContainer>

      <GridItem xs={12} style={{ borderTop: '1px #ddd solid', borderBottom: '1px #ddd solid', padding: 20 }}>
        <GridContainer>
          <GridItem xs="6" sm='12' md='6'>
            <p style={{margin: 0}}>
              <b> {activity.sport.name}</b> <br />
              {activity.localization.name} {activity.localization.street} {activity.localization.city}
            </p>
          </GridItem>
          <GridItem xs="6" sm='4' md='2'>
            <p style={{margin: 0}}>
              <b>Data:</b>
              <br />
              {activity.date}
            </p>
          </GridItem>
          <GridItem xs="6" sm='4' md='2'>
            <p style={{margin: 0}}>
              <b>Godzina:</b>
              <br />
              {activity.start_at}-{activity.stop_at}
            </p>
          </GridItem>
          <GridItem xs="6" sm='4' md='2'>
            <p style={{margin: 0}}>
              <b>Poziom:</b>
              <br />
              {I18n.t(activity.lv)}
            </p>
          </GridItem>
        </GridContainer>

      </GridItem>


      <GridContainer style={{
        padding: '16px',
        paddingTop: "30px",
      }}>
        {values.user_level_opinion.map((row, index) => formRow(row, values, index, setFieldValue, data.currentUser))}

        <GridItem xs={12} style={{ textAlign: "center" }} spacing="0">
          <Button fullWidth type="submit" color="primary" link href="/s/poziom-sportowy" target='blank'>
            Zobacz szczegółowe opisy poziomów
          </Button>
        </GridItem>

        <GridItem xs={12} style={{ textAlign: "center", marginTop: 30 }}>
          <Button type="submit" color="primary" round>
            Oceń poziom gry
          </Button>
        </GridItem>

        <GridItem xs={12} style={{ textAlign: "center", marginTop: 30 }}>
          <Button color="transparent" round onClick={skipModal}>
            Później
          </Button>
        </GridItem>
      </GridContainer>
    </form >
  </>
}

const formRow = (participant, values, index, setFieldValue, currentUser) => {
  let userLevel = false;
  if (!!values.sport) {
    userLevel = participant.activity_participant.user_sport_levels_attributes.filter((row) => values.sport.id == row.sport_id)[0]
  } else {
    userLevel = false
  }

  if(participant.activity_participant.id == currentUser.id) {
    return null
  }

  return <>
    <GridItem xs={12} md={4} style={{ marginTop: 15, paddingLeft: 0, marginBottom: 5, textAlign: 'left' }}>
      {participant.activity_participant.label}&nbsp;{userLevel && userLevel.verified && userLevel.level == values.activity.lv && <Tooltip title={`Poziom zweryfikowany przez PNH`} classes={{ tooltip: this.props.classes.tooltip }}>
        <span><Primary ><VerifiedIcon style={{ fontSize: 13 }} /></Primary></span>
      </Tooltip>}
    </GridItem>
    {['beginners', "medium", "intermediate", "advanced",].map((g) => (
      <GridItem xs={3} md={2} style={{ marginBottom: 10, paddingLeft: 0, paddingRight: 0 }}>
        <div style={{ backgroundColor: '#F5F5F5', margin: 0, padding: 0 }}>
          <Button fullWidth color={participant.level == g ? "primary" : 'transparent'} onClick={() => updateValue(index, values, setFieldValue, g)} style={{ margin: 0, fontSize: 8 }}>{I18n.t(g)}</Button>
        </div>
      </GridItem>
    ))}
  </>
}


export const Form = withStyles(styles)(FormWrapper);