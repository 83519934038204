/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

const wizardModal = (theme) => ({
  wizardModal: {
    backgroundColor: "transparent!important",
    padding: "45px",
    boxShadow: "none",
    overflow: "unset",
    maxWidth: "600px",
    [theme.breakpoints.down("md")]: {
      padding: "15px",
      width: "calc(100% - 2px)",
      margin: "10px",
      minHeight: '0px',
    }
  },
});

export default wizardModal;
