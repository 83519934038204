import React, {useEffect} from "react";
import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import {WsManager} from "PNH/services/Ws";
import moment from "moment";

const CHANNEL_NAME = "Client::ActivitiesChannel"

export const ActivitiesFunctions = {
  receivedMsg: (data) => {
    let type = 'alert';
    switch(data.code) {
      case 200:
         type = 'success';
        break;
      case 202:
         type = 'warning';
        break;
      default:
         type = 'alert';
    }

    window.setNotifications([
      { type: type, msg: data.status },
    ]);

    if(data.all) {
      let url = `/moje-zajecia?notification_type=${type}&notification_msg=${data.status}`;
     window.location.href = url
    }
  },

  connectedToChannel: (data)=>{},

  disconnectedToChannel:  (data) => {},


  create: (values, formik) => {
    values.afterSave();
    formik.setFieldValue("afterSave", () => {});
    formik.setTouched({});
    ActivitiesFunctions.ws.send({params: {...values, action: 'create'}})
    setTimeout(() => formik.setSubmitting(false), 3000);
  },

  defaultValues: (props) => {
    const defaultValues = {
      id: "",
      template_id: props.templateID,
      expired_at: moment(props.expired_at),
      date_from: moment().format("YYYY-MM-DD"),
      date_to: moment().format("YYYY-MM-DD"),
      days: [],
      afterSave: () => {},
    };
    return { ...defaultValues, ...props.defaults };
  },
};

const FormWrapper = ({...props}) => {
  useEffect(() => {
    const ws = new WsManager()
    ws.connect(CHANNEL_NAME, {
      connected: ActivitiesFunctions.connectedToChannel,
      disconnected: ActivitiesFunctions.disconnectedToChannel,
      received: ActivitiesFunctions.receivedMsg
    })
    ActivitiesFunctions.ws  = ws

    return function disconnect() {
      ws.unsubscribe()
      ws.disconnect()
    }
  }, []);

  return <Form {...props}/>
}


export const ActivitiesFromTemplateForm = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...ActivitiesFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
    if (!values.id || formikProps.props.copy) {
      ActivitiesFunctions.create(values, formikProps)
    } else {
      ActivitiesFunctions.update(values, formikProps)
    }
  },
})(FormWrapper);
