import React, { useEffect } from "react";
import Button from "components/CustomButtons/Button";
import { Api } from "PNH/services/Api";

export const FBButton = () => {
    const onLoginClick = () => {
        window.FB.getLoginStatus((response) => {
            if (response.status === 'connected') {
                auth(response)
             } else if (response.status === 'not_authorized' || response.status === 'unknown') {
                window.FB.login((response) => {
                    auth(response)
                }, {scope: 'email', return_scopes: true})
             } 
          });
    };

    const auth = (response) => {
        Api.users.FBlogin(response).then((response) => {
            if (response.ok) {
                localStorage.setItem('token', response.headers.authorization);
                const params = window.location.search ? "&" : "?";
                window.location.href = `${window.location.pathname}${window.location.search}${params}notification_msg=Jesteś zalogowany!&notification_type=success`
            } else {
                window.setNotifications([
                    {
                    type: "alert",
                    msg: "Niepoprawne hasło lub e-mail, spróbuj ponownie.",
                    },
                ]);
            }
        })
    }

    useEffect(() => {
        window.fbAsyncInit = () => {
            window.FB.init({
                appId            : process.env.REACT_APP_FB_ID,
                autoLogAppEvents : true,
                xfbml            : true,
                version          : 'v14.0'
            });
        };
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }, []);

    return (
        <Button
        color="facebook"
        onClick={onLoginClick}
        >
            <i
            className="fab fa-facebook-square"
            style={{ marginRight: "15px" }}
            />
        Kontynuuj Z Facebook
      </Button>
    );
};
