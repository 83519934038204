import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import Edit from "@material-ui/icons/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

import TablePagination from "@material-ui/core/TablePagination";
import {Api} from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";

import {withRouter} from 'react-router-dom';




import extendedTablesStyle from "PNH/assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import {I18n} from "../../../components/I18n";

class MyGroupsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            page: 0,
            limit: 10,
            total: 0,
            with_name: "",
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = async () => {
             Api.competition_groups
            .indexCurrentUser({
                page: this.state.page,
                limit: this.state.limit,
                with_name: this.with_name,
            })
            .then((response) => {
                const tableContent = response.data.rows.map(this.mapRows);
                this.setState({data: tableContent, total: response.data.total});
            });
    };
    mapRows = (row) => {
        const {classes} = this.props;
        return [
            row.name,
            `${row.private ? "Prywatna, " : ""}${row.hidden ? "Ukryta " : ""}`,
            I18n.t(row.competition_group_type),
            <Button
                color="primary"
                simple
                onClick={() => {
                    window.location.href = `/moje-grupy/${row.id}`;
                }}
                key={`edit_${row.id}`}
                className={classes.actionButton}
            >
                Zobacz szczegóły
            </Button>

        ];
    };
    changePage = (event, page) => {
        this.setState({page: page}, this.getData);
    };

    onChangeRowsPerPage = (event) => {
        this.setState({limit: event.target.value, page: 0}, this.getData);
    };

    handleChange = (event) => {
        this.setState(
            {[event.target.name]: event.target.value, page: 0},
            this.getData
        );
    };

    filters = () => {
        const {classes, creator = false} = this.props;
        return (
            <>

                <GridItem xs={12}>
                    <Card>
                        <CardBody>
                            <GridContainer>

                                <GridItem xs={12} sm={12} >
                                    <CustomInput
                                        id={`with_name`}
                                        labelText="Szukaj..."
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            placeholder: "Szukaj...",
                                            type: "text",
                                            name: `with_name`,
                                            value: this.state[`with_name`],
                                            onChange: this.handleChange,
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </>
        );
    };

    render() {
        const {classes, filters} = this.props;
        return (
            <GridContainer>
                {!filters && this.filters()}
                <GridItem xs={12}>

                    <Table
                        striped
                        tableHead={[
                            <b>Nazwa</b>,
                            <b>Typ</b>,
                            <b>Rodzaj</b>,
                            "",
                        ]}
                        tableData={this.state.data}
                    />
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        page={this.state.page}
                        rowsPerPage={this.state.limit}
                        count={this.state.total}
                        onChangePage={this.changePage}
                        onChangeRowsPerPage={this.onChangeRowsPerPage}
                        labelRowsPerPage="Ilość"
                        labelDisplayedRows={({from, to, count}) =>
                            `${from}-${to} z ${count}`
                        }
                    />

                </GridItem>
            </GridContainer>
        );
    }
}

MyGroupsTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(extendedTablesStyle)(MyGroupsTable));
