/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// nodejs library that concatenates classes
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "PNH/components/Header/Header";
// sections for this page

import { Footer } from "PNH/layouts/Footer";

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle";
import { ActivitiesForm } from "./ActivitiesFrom/index";
import MainContainer from "../../../layouts/MainContainer";
import { Link } from "react-router-dom";

class ActivitiesFormPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Header brand="PNH" fixed color="white" {...this.props} />

        <div className={classNames(classes.main)} style={{ marginTop: 75 }}>
          <div className={classes.container}>
            <GridContainer>
              <h3
                style={{
                  fontWeight: 900,
                  marginBottom: 40,
                  marginTop: 30,
                }}
              >
                {" "}
                Zajęcia
                <h6>
                  <Link to="/account-settings">Konto</Link> >{" "}
                  <Link to="/moje-zajecia">Moje Zajęcia</Link> >{" "}
                  {this.props.new
                    ? "Dodawanie"
                    : "Edycja"}
                </h6>
              </h3>
            </GridContainer>
            <ActivitiesForm {...this.props} />
          </div>
        </div>

        <Footer {...this.props} />
      </>
    );
  }
}

ActivitiesFormPage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(presentationStyle)(ActivitiesFormPage);
