import React from "react"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle";
import Button from "components/CustomButtons/Button";

const Tile = ({ classes, tile }) => {
   return <a
            href={tile.url}>
            <Card
                small
                background={!!tile.picture}
                style={{
                    backgroundColor: 'white',
                    backgroundImage: "url(" + process.env.REACT_APP_API_URL + tile.picture + ")",
                }}
            >
                <CardBody small background className={classes.cardBody}>
                    <Link
                        to={tile.url}>
                        {tile.name && <h2 className={!!!tile.picture ? classes.cardTitle : classes.cardTitleWhite}>{tile.name}</h2>}
                        {tile.description && <p style={{ textAlign: 'center' }}>{tile.description}</p>}
                    </Link>
                    {tile.button_text && <Button href={tile.url} round color="primary">{tile.button_text}</Button>}
                </CardBody>
            </Card>
        </a>
}

export const SimpleTile = withStyles(profilePageStyle)(Tile)