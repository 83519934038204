import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";

export const Functions = {
  update: (values, formik) => {
    return Api.activities.edit_summary(values.id, { activity: values })
      .then((response) => {
        if (response.ok) {
          formik.setValues({
            ...Functions.defaultValues(formik.props),
            ...response.data,
          });
          window.setNotifications([{ type: "success", msg: "Dodano" }]);
        } else {
          window.setNotifications([
            { type: "alert", msg: "Nie udało się zapisać zmian" },
          ]);
        }
      });
  },

  defaultValues: (props) => {
    return {
      id: props.activityID,
      all_participants_count: '',
      cash_paid_participants_count: '',
      online_paid_participants_count: '',
      benefit_paid_participants_count: '',
      benefit_paid_participants_verified: '',
      fitprofit_paid_participants_count: '',
      fitprofit_paid_participants_verified: '',
      ok_system_paid_participants_count: "",
      verified_by_admin: '',
      final_price: '',
      afterSave: () => {},
    };
  },
};

export const SummaryForm = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...Functions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
    Functions.update(values, formikProps)
      .then((response) => {
        values.afterSave(response);
        formikProps.setFieldValue("afterSave", () => {});
      })
      .then(() => {
        formikProps.setTouched({});
      });
  },
})(Form);
