import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";
import moment from "moment";

export const ActivitiesFunctions = {
  create: (values, formik) => {
    return Api.activity_templates
      .create({ activity_template: values })
      .then((response) => {
        if (response.ok) {
          window.setNotifications([
            { type: "success", msg: "Dodano" },
          ]);
          let url = `/moje-szablony`;
          formik.props.history.push(url)
        } else {
          window.setNotifications([
            { type: "alert", msg: "Nie udało się zapisać zmian" },
          ]);
        }
      });
  },
  delete: (formik) => {
    return Api.activity_templates.delete(formik.values.id).then((response) => {
      if (response.ok) {
        window.setNotifications([
          { type: "success", msg: "Usunięto" },
        ]);
        // let url = `/activities`;
        // formik.history.push(url)
      } else {
        window.setNotifications([
          { type: "alert", msg: "Nie udało się usunąć" },
        ]);
      }
    });
  },

  defaultValues: (props) => {
    const defaultValues = {
      id: "",
      name: '',
      localization_id: props.location_id || "",
      location_object: "",
      sport_id: "",
      description: "",
      hidden: "",
      price: "",
      notes: "",
      date: "",
      publish_date: moment().format("YYYY-MM-DD H:m"),
      lv: "",
      gender: "",
      localizations: "",
      reserved_spots: 0,
      max_players: 0,
      min_players: 0,
      sports: [],
      start_at: "",
      stop_at: "",
      baner_image: "",
      thumb_image: "",
      custom_tags: "",
      supplement: "",
      supplement_medicover: "",
      ok_system_code: "",
      change_to_public_at: "",
      event_type: "",
      discount: "",
      rental_price: "",
      max_price: "",
      max_benefit_price: "",
      verify_lv: false,
      week_days: [],
      afterSave: () => { },
    };
    return { ...defaultValues, ...props.defaults };
  },
};

export const ActivityTemplatesForm = withFormik({
  validationSchema: validationSchema,
  enableReinitialize: false,
  mapPropsToValues: (props) => {
    return { ...ActivitiesFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
    ActivitiesFunctions.create(values, formikProps)
      .then((response) => {
        values.afterSave(response);
        formikProps.setFieldValue("afterSave", () => { });
      })
      .then(() => {
        formikProps.setTouched({});
        formikProps.setSubmitting(false);
      });
  },
})(Form);