import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { Api } from "../../services/Api";
import LoadingPage from "../../views/LoadingPage/LoadingPage";

function DotpayForm({ match, ...rest }) {
    const form = useRef(null);
    const [ dotpayData, setDotpayData ] = useState({});
    const { id } = match.params;

    useEffect(() => {
       
        Api.users.transactions(id).then(response => {
            if (response.ok) {
                setDotpayData({ url: response.data.url, fields: response.data.fields });
                form.current.submit();
            }
        });
    }, [id]);

    const renderInputs = () => {
        const { fields } = dotpayData;
        return fields && Object.keys(fields).map(key => <input type="hidden" name={key} value={fields[key]}/>)
    };

    return (
        <>
            <LoadingPage {...rest}/>
            <form action={dotpayData.url} method="POST" ref={form}>
                {renderInputs()}
            </form>
        </>
    );
}

export default withRouter(DotpayForm);
