import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ComponentFooter from "components/Footer/Footer";
import Button from "components/CustomButtons/Button";
import logo from "../assets/img/logo.png";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import {makeStyles, createStyles} from "@material-ui/core/styles";
import Primary from "components/Typography/Primary";
import pfr from "../assets/img/pfr.jpg";
import {CookiesBar} from "../components/cookies";

const footerLink = makeStyles(() =>
    createStyles({
        root: {
            color: "black",
            fontSize: "14px",
            lineHeight: "40px",
            "&:hover": {color: "#00a699!important"},
        },
    })
);

export const Footer = ({classes}) => {
    const styles = footerLink().root;
    return (
        <> <CookiesBar/>
            <ComponentFooter
                theme="white"
                content={
                    <div>
                        <GridContainer>
                            <GridItem style={{marginBottom: 25}}>
                                <GridContainer>
                                    <GridItem
                                        xs={12}
                                        sm
                                        style={{textAlign: "left", color: "black"}}
                                    >
                                        <Primary>
                                            <h4>
                                                <b>Dla Ciebie</b>
                                            </h4>
                                        </Primary>
                                        <br/>
                                        <a className={styles} href="/cennik">
                                            Doładowanie konta
                                        </a>
                                        <br/>
                                        <a className={styles} href="/s/multisport-sporty-druzynowe-projekt-organizator">
                                            Zasady dodawania treningów
                                        </a>
                                        <br/>
                                        <a className={styles} href="/s/metody-platnosci">
                                            Metody płatności
                                        </a>
                                        <br/>
                                        <a className={styles} href="/s/pomoc ">
                                            Pomoc
                                        </a>
                                        <br/>
                                        <a className={styles} href="/s/kariera">
                                            Kariera
                                        </a>
                                        <br/>
                                    </GridItem>
                                    <GridItem xs={12} sm style={{textAlign: "left"}}>
                                        <Primary>
                                            <h4>
                                                <b>Dla firm </b>
                                            </h4>
                                        </Primary>
                                        <br/>
                                        <a className={styles} href="/s/wynajem-obiektow-sportowych ">
                                            Znajdź obiekt do gry!
                                        </a>
                                        <br/>
                                        <a
                                            className={styles}
                                            href="/s/organizacja-eventow-i-integracji-firmowych"
                                        >
                                            Organizacja eventów i integracji firmowych
                                        </a>
                                        <br/>
                                        <a className={styles} href="/s/wspolpraca">
                                            Współpraca
                                        </a>
                                        <br/>
                                    </GridItem>
                                    <GridItem
                                        xs={12}
                                        sm
                                        style={{textAlign: "left", color: "black"}}
                                    >
                                        <Primary>
                                            <h4>
                                                <b>Kontakt</b>
                                            </h4>
                                        </Primary>
                                        <p>
                                            <b>Adres:</b> Al. Ks. J. Poniatowskiego 1, 03-901 Warszawa{" "}
                                        </p>
                                        <p>
                                            <b>Mail:</b> kontakt@pilkanahali.pl
                                        </p>
                                        <p>
                                            <b>Infolinia:</b> + 48226022035
                                        </p>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12}>
                                <GridContainer justify="center">
                                    <GridItem xs={12} md={8}>
                                        <img src={pfr} alt="pfr" style={{maxWidth: "80%"}}/>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem>
                                <hr/>
                            </GridItem>
                            <GridItem xs={12} md={4}>
                                <div className={classes.left}>
                                    <a href="/">{<img src={logo} alt="pilkanahali.pl" style={{maxHeight: 30}}/>}</a>
                                </div>
                            </GridItem>
                            <GridItem xs={12} md={8}>
                                <GridContainer justify="flex-end" alignItems="center">
                                    <GridItem xs={2} md={1}>
                                        <Button
                                            href="https://www.facebook.com/PilkaNaHalipl/"
                                            target="_blank"
                                            color="facebook"
                                            justIcon
                                            simple
                                        >
                                            <i className="fab fa-facebook"/>
                                        </Button>
                                    </GridItem>
                                    <GridItem xs={2} md={1}>
                                        <Button
                                            href="https://www.linkedin.com/company/18034768/"
                                            target="_blank"
                                            color="linkedin"
                                            justIcon
                                            simple
                                        >
                                            <i className="fab fa-linkedin"/>
                                        </Button>
                                    </GridItem>
                                    <GridItem xs={2} md={1}>
                                        <Button
                                            href="https://www.instagram.com/pilkanahali.pl/"
                                            target="_blank"
                                            color="instagram"
                                            justIcon
                                            simple
                                        >
                                            <i className="fab fa-instagram"/>
                                        </Button>
                                    </GridItem>

                                </GridContainer>
                            </GridItem>
                            <GridItem>
                                <List className={classes.list}>
                                    <ListItem className={classes.inlineBlock}>
                                        © 2020 pilkanahali.pl, All rights reserved
                                    </ListItem>
                                    <ListItem className={classes.inlineBlock}>
                                        <a
                                            href="https://pnh-public.s3.eu-central-1.amazonaws.com/regulamin.pdf"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            className={classes.block}
                                        >
                                            Regulamin
                                        </a>
                                    </ListItem>
                                    <ListItem className={classes.inlineBlock}>
                                        <a href="/s/polityka-prywatnosci" className={classes.block}>
                                            Polityka Prywatności
                                        </a>
                                    </ListItem>
                                </List>
                            </GridItem>
                        </GridContainer>

                    </div>
                }
            />
        </>
    );
};
