import React from "react";
import { Api } from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Primary from "components/Typography/Primary";
import Danger from "components/Typography/Danger";

import formRegular from "PNH/assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "PNH/assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "PNH/assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import {Link} from "react-router-dom";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import InputAdornment from "@material-ui/core/InputAdornment";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import {hasUserRole} from "../../../../services/Helpers";
import Warning from "../../../../../components/Typography/Warning";


const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
class Form extends React.Component {
  state = {
    deleteModal: false,
  };

  constructor(props) {
    super(props);
    this.fetch();
  }
  deleteModal = (open) => {
    this.setState({ deleteModal: open });
  };

  fetch = () => {
    if (this.props.values.id) {
      Api.users.show(this.props.values.id).then((response) => {
        if (response.ok) {
          this.props.setValues({
            id: response.data.id,
            fitprofit_id: response.data.fitprofit_id,
            fitprofit_active: response.data.fitprofit_active,
          });
        } else {
          window.setNotifications([
            { type: "alert", msg: "Coś poszło nie tak" },
          ]);
        }
      });
    }
  };
  render() {
    const {
      values,
      errors,
      handleChange,
      handleSubmit,
      classes,
    } = this.props;

    const disabled = !hasUserRole(this.props.data.currentUser, "user_role_edit_fitprofit_card")

    return (
      <form onSubmit={handleSubmit}>
        <GridContainer style={{marginTop: 60}}>
          <GridItem xs={12}>
            <h4>FitProfit
            {values.fitprofit_id && (
              <>
                &nbsp;-&nbsp;
                {values.fitprofit_active ? (
                  <Primary>
                    <h6>
                      <b> Karta Aktywna</b>
                    </h6>
                  </Primary>
                ) : (
                  <Danger>
                    <h6>
                      <b>Karta nieaktywna</b>
                    </h6>
                  </Danger>
                )}
              </>
            )}
            </h4>
            
          </GridItem>
          <GridItem xs={12}>
            <CustomInput
              labelText="Nr karty"
              error={errors.fitprofit_id}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: values.fitprofit_id,
                name: "fitprofit_id",
                onChange: handleChange,
                disabled: disabled,
                type: "fitprofit_id",
                endAdornment: (
                    <InputAdornment position="end" className={classes.inputAdornment}>
                      <CardGiftcardIcon className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                ),
              }}
            />
          </GridItem>
          </GridContainer>
          <GridContainer>
            { disabled && <GridItem xs={12} style={{textAlign: 'center'}}>
               Posiadasz rezerwacje dokonane za pomocą karty FitProfit. W celu zmiany danych, prosimy o <Link to={"s/kontakt"}> kontakt z obsługą klienta</Link>.
            </GridItem> }
            {!disabled && <GridItem xs={12} style={{textAlign: 'center'}}>
              <Button type="submit" disabled={disabled} color="primary" round={true}>
                Zapisz
              </Button>
            </GridItem> }
        </GridContainer>
      </form>
    );
  }
}

export default withStyles(styles)(Form);
