/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import NavPills from "components/NavPills/NavPills";

// sections for this page
import SectionFeatures from "./Sections/SectionFeatures";
import SectionPricing from "./Sections/SectionPricing";
import SectionDeposit from "./Sections/SectionDeposit";

import pricingStyle from "assets/jss/material-kit-pro-react/views/pricingStyle";

class PricingPage extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classNames(classes.main)}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={10} md={9} lg={8}>
              {this.props.data.currentUser.id && (
                <NavPills
                  alignCenter
                  color="primary"
                  tabs={[
                    {
                      tabButton: "Doładowanie Konta",
                      tabContent: <SectionPricing {...this.props} />,
                    },
                    {
                      tabButton: "Doładowanie Kaucji",
                      tabContent: <SectionDeposit {...this.props} />,
                    },
                  ]}
                />
              )}
              {!this.props.data.currentUser.id && (
                <SectionPricing {...this.props} />
              )}
            </GridItem>
          </GridContainer>
          <hr />
          <SectionFeatures />
        </div>
      </div>
    );
  }
}

PricingPage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(pricingStyle)(PricingPage);
