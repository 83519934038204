/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Suspense } from "react";
import { withRouter } from 'react-router-dom';
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// core components
import Button from "components/CustomButtons/Button";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import sectionsStyle from "assets/jss/material-kit-pro-react/views/presentationSections/sectionsStyle";
import carouselStyle from "assets/jss/material-kit-pro-react/views/componentsSections/carouselStyle";

import withWidth from "@material-ui/core/withWidth";
import Cities from "PNH/views/MainPage/Sections/Cities";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Placeholder from "assets/img/image_placeholder.jpg"
import MainSlider from "./ActivitiesSliders/MainSlider";
import PromoSlider from "./ActivitiesSliders/PromoSlider";
import NewActivitiesSlider from "./ActivitiesSliders/NewActivitiesSlider";




class JoinUsSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <>
          <GridContainer alignItems="center" justify="center">
            <GridItem xs={12} sm={8} md={8} lg={8} >
              <GridContainer justifyContent="space-between" alignItems="center">
                <GridItem sm={12} md={8}>
                  <h2 style={{ fontWeight: 700 }}>Dołącz do gry w Twojej okolicy<br /> lub dodaj swoje wydarzenie</h2>
                  <h4 style={{ fontWeight: 500, color: '#484D65', opacity: '0.7' }}>DLA&nbsp;CIEBIE | DLA&nbsp;ZNAJOMYCH | DLA&nbsp;FIRM</h4>

                  <p style={{ marginTop: 30, marginBottom: 30 }}>
                    Skorzystaj z darmowego narzędzia wspierającego organizację składu i treningu.
                  </p>
                  <p >
                    Dołącz do nas i znajdź trening dla siebie, znajomych lub zostań organizatorem!
                    Wybieraj spośród wielu dyscyplin drużynowych takich jak siatkówka, piłka nożna, koszykówka, badminton.
                    Zapisz się na zajęcia z trenerem lub luźną grę.
                    Organizujemy i udostępniamy zajęcia sportowe - znajdź swoją drużynę i miejsce do gry!
                  </p>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>

        </>
    );
  }
}

JoinUsSection.propTypes = {
  classes: PropTypes.object,
};

export default withRouter(withStyles({ ...sectionsStyle, ...carouselStyle })(
  withWidth()(JoinUsSection))
);
