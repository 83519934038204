/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
// nodejs library that concatenates classes
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Warszawa from "PNH/assets/img/warszawa.jpg";
import Krakow from "PNH/assets/img/kafelek_krakow.jpg";
import Gdynia from "PNH/assets/img/kafelek_gdynia.jpg";
import Marki from "PNH/assets/img/marki.png";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle";
import Button from "components/CustomButtons/Button";
import { Link } from "react-router-dom";
import Primary from "components/Typography/Primary"
import { DynamicTile } from "./DynamicTiles";
class Cities extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="left" className={classes.collections}>
        <DynamicTile />
      </GridContainer>
    );
  }
}

Cities.propTypes = {
  classes: PropTypes.object,
};

export default withRouter(withStyles(profilePageStyle)(Cities));
