import React from "react";
import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Pole Wymagane"),
  street: Yup.string().required("Pole Wymagane"),
  city: Yup.string().required("Pole Wymagane"),
  post_code: Yup.string().required("Pole Wymagane"),
});

export default ValidationSchema;
