import React, { Component } from "react";
/*eslint-disable*/
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Assignment from "@material-ui/icons/Assignment";
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";
import Slide from "@material-ui/core/Slide";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CustomInput from "components/CustomInput/CustomInput";
import { Formik } from "formik";
import * as Yup from "yup";
import { Api } from "PNH/services/Api";
import { mapValues } from "lodash";

import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles";
import { FBButton } from "./FBLogin";
import {Link} from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class RegisterModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signupModal: false,
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.props.getStateUpdates()["openRegisterModal"] =  () => this.handleClickOpen('signupModal')
  }
  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  }
  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }
  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }
  handleClosePopover(state) {
    this.setState({
      [state]: false,
    });
  }
  handleClickButton(state) {
    this.setState({
      [state]: true,
    });
  }
  form(formik) {
    const { classes, buttonClasses } = this.props;
    return (
        <>
        {/* SIGNUP MODAL START */}
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal,
          }}
          open={this.state.signupModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose("signupModal")}
          aria-labelledby="signup-modal-slide-title"
          aria-describedby="signup-modal-slide-description"
        >
          <Card plain className={classes.modalSignupCard}>
            <DialogTitle
              id="signup-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                onClick={() => this.handleClose("signupModal")}
                style={{ position: "absolute", top: "15px", right: "15px" }}
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
              <div className={classes.textCenter}>
                <h3>
                  Rejestracja
                </h3>
              </div>
            </DialogTitle>
            <DialogContent
              id="signup-modal-slide-description"
              className={classes.modalBody}
            >
              <GridContainer>
                <GridItem xs={12} className={classes.mrAuto}>
                  <div className={classes.textCenter}>
                    <FBButton />
                    <h4 className={classes.socialTitle}>lub</h4>
                  </div>
                  <form className={classes.form}>
                    <CustomInput
                      labelText="Imię*"
                      noErrorIcon
                      error={formik.errors["first_name"]}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        onChange: formik.handleChange,
                        name: "first_name",
                        value: formik.values.first_name,
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Face className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      labelText="Email*"
                      noErrorIcon
                      error={formik.errors["email"]}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        onChange: formik.handleChange,
                        name: "email",
                        value: formik.values.email,
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      labelText="Hasło*"
                      noErrorIcon
                      error={formik.errors["password"]}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        type: "password",
                        onChange: formik.handleChange,
                        name: "password",
                        value: formik.values.password,
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormControlLabel
                      classes={{
                        label: classes.label,
                      }}
                      control={
                        <Checkbox
                          value={formik.values.terms}
                          error={formik.errors["terms"]}
                          tabIndex={-1}
                          onClick={() =>
                            formik.setFieldValue(
                              "terms",
                              formik.values.terms === "yes" ? "" : "yes"
                            )
                          }
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      label={
                        <span
                          style={{
                            color: !!formik.errors["terms"] ? "red" : "inherit",
                          }}
                        >
                          Akceptuję{" "}
                          <a
                            href="https://pnh-public.s3.eu-central-1.amazonaws.com/regulamin.pdf"
                            target="_blank"
                          >
                            postanowienia i regulamin
                          </a>
                          . *
                        </span>
                      }
                    />
                    <div className={classes.textCenter} style={{marginTop: 20}}>
                      <Button round color="primary" onClick={formik.submitForm}>
                        Zarejestruj się
                      </Button>
                    </div>
                  </form>
                </GridItem>
              </GridContainer>
            </DialogContent>
          </Card>
        </Dialog>
    </>
    );
  }
  render() {
    return (
      <Formik
        initialValues={{
          email: "",
          password: "",
          first_name: "",
          terms: "",
        }}
        onSubmit={async (values, { setErrors, resetForm }) => {
          Api.users.register({ user: values }).then((response) => {
            if (response.ok) {
              this.handleClose("signupModal");
              resetForm();
              window.setNotifications([
                {
                  type: "success",
                  msg:
                    "Aby dokończyć rejestrację, przejdź do poczty i potwierdź swój email.",
                },
              ]);
            } else {
              setErrors(mapValues(response.data.errors, (e) => e[0]));
            }
          });
        }}
        validationSchema={Yup.object().shape({
          first_name: Yup.string().required("Pole Wymagane"),
          terms: Yup.string().required("Pole Wymagane"),
          email: Yup.string()
            .email("Błędny adres email")
            .required("Pole Wymagane"),
          password: Yup.string()
            .required("Pole Wymagane")
            .min(6, "Minimum 6 znaków"),
        })}
      >
        {(props) => {
          return this.form(props);
        }}
      </Formik>
    );
  }
}

export default withStyles(javascriptStyles)(RegisterModal);
