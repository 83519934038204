import React from "react";
import { Api } from "PNH/services/Api";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import formRegular from "PNH/assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "PNH/assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";

import { I18n } from "PNH/components/I18n";
import Danger from "components/Typography/Danger";
const styles = {
  ...formRegular,
  ...stylesExtended,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
class Form extends React.Component {
  render() {
    const {
      values,
      errors,
      setFieldValue,
      handleSubmit,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <h3 style={{ textAlign: "center" }}><strong>Oceń swój poziom gry</strong></h3>
        {values.hideSportSelector && <><h4 style={{ textAlign: "center" }}>{values.sport.name}</h4><h6>Wygląda na to, że jeszcze nie oceniłaś/eś swojego poziomu gry w tej dyscyplinie.
          Zapoznaj się z opisami i wybierz ten, który najlepiej pasuje do Ciebie.</h6></>
        }
        <GridContainer>
          {!values.hideSportSelector && <>
            <GridItem xs={12}>
              <AsyncSelector
                apiMethod={Api.sports.index}
                apiShowMethod={Api.sports.show}
                error={errors.sport_id}
                labelKey="name"
                label="Dyscyplina"
                name="sports"
                defaultValue={values.sport_id}
                value={
                  values.sport && {
                    id: values.sport.id,
                    label: values.sport.name,
                  }
                }
                onChange={(value, data) => {
                  setFieldValue("sport_id", value);
                  setFieldValue("sport", data);
                }}
              />
            </GridItem> </>}
          {
            ['beginners', "medium", "intermediate", "advanced",].map((g) => (
              <GridItem xs={12} style={{ marginBottom: 10 }}>
                <div style={{ backgroundColor: '#F5F5F5', margin: 0, padding: 0 }}>
                  <Button fullWidth color={values.level == g ? "primary" : 'transparent'} onClick={() => setFieldValue('level', g)} style={{ margin: 0 }}>{I18n.t(g)}</Button>
                </div>
              </GridItem>
            ))
          }

          {errors.level && <GridItem xs={12} style={{ textAlign: 'center' }}><Danger>Wybierz swój poziom</Danger></GridItem>}

          <GridItem xs={12} style={{ textAlign: "center", paddingLeft: 0, paddingRight: 0 }}>
            <Button type="submit" color="primary" link href="/s/poziom-sportowy" target='blank'>
              Zobacz szczegółowe opisy poziomów
            </Button>
          </GridItem>

          <GridItem xs={12} style={{ textAlign: "center" }}>
            <Button type="submit" color="primary" round>
              Zapisz swój poziom gry
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    );
  }
}

export default withStyles(styles)(Form);
