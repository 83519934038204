import React from "react";
import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
  value: Yup.number()
    .max(25, "Maksymalna kwota doładowania 25")
    .min(0.01, "Brak kaucji na koncie")
    .required("Pole Wymagane"),
  terms: Yup.string().required("Pole Wymagane"),
});

export default ValidationSchema;
