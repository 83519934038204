/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes

import { GroupActivities } from "./Sections/FiltersForm/index";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";


import styles from "assets/jss/material-kit-pro-react/views/ecommerceStyle";

class GroupActivitiesTab extends React.Component {
  state = {
    fetch: 1,
  };

  render() {
    const { classes } = this.props;
    return (
        <GroupActivities
            id = {this.props.competition_group_id}
            refreshHeader={() => {
                this.setState({ fetch: this.state.fetch + 1 });
            }}
            {...this.props}
        />
    );
  }
}

GroupActivitiesTab.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(GroupActivitiesTab);
