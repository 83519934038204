import React from "react";
import * as Yup from "yup";
import moment from "moment";

const ValidationSchema = Yup.object().shape({
  description: Yup.string().required("Pole Wymagane"),
  name: Yup.string().required("Pole Wymagane"),
  competition_group_type: Yup.string().required("Pole Wymagane"),
});

export default ValidationSchema;
