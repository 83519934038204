import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";

export const DepositFunctions = {
  add: (values, _formik) => {
    return Api.users.depositReturn().then((response) => {
      if (response.ok) {
        window.location.href = "/account-settings?notification_msg=Zwrócono kaucję&notification_type=success"
      }
    });
  },

  checkDeposit: (formik) => {
    return Api.users.depositCheck().then((response) => {
      if (response.ok) {
        formik.setFieldValue("check", response.data.check);
      }
    });
  },

  defaultValues: (props) => {
    return {
      value: props.data.currentUser.account_deposit_value,
      terms: false,
      check: false,
      afterSave: () => {},
    };
  },
};

export const ReturnDepositForm = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...DepositFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);

    DepositFunctions.add(values, formikProps)
      .then((response) => {
        values.afterSave(response);
        formikProps.setFieldValue("afterSave", () => {});
      })
      .then(() => {
        formikProps.setTouched({});
        formikProps.setSubmitting(false);
      });
  },
})(Form);
