/*eslint-disable*/
import React, { useState } from "react";
import { SportLevelOpinionForm } from "./index";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const RenderDialog = ({ isOpenModal = true, toggleModal, afterSave, closeAfterSave = true, classes, ...props }) => (
    <Dialog
        maxWidth='md'
        classes={{
            root: classes.modalRoot,
            paper: classes.modal,
        }}
        open={isOpenModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={toggleModal}
    >
        <DialogTitle disableTypography className={classes.modalHeader} style={{ textAlign: 'right', paddingBottom: 0 }}>
            <Button
                justIcon
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={() => toggleModal(false)}
            >
                <Close className={classes.modalClose} />
            </Button>
        </DialogTitle>
        <DialogContent className={classes.modalBody} style={{ width: "100%", paddingTop: 0 }}>
            <SportLevelOpinionForm toggleModal={toggleModal} afterSave={() => {
                toggleModal(false)
            }} {...props} />
        </DialogContent>
        <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
        </DialogActions>
    </Dialog>
)

export function AddOpinionModal({hideButton = false, ...props}) {
    const [isOpenModal, toggleModal] = useState(false);
    return (
        <div style={{ textAlign: 'right' }}>
            {!hideButton && <Button color="primary" onClick={() => toggleModal(true)} >Dodaj</Button>}
            <RenderDialog isOpenModal={isOpenModal} toggleModal={toggleModal} {...props} />
        </div>
    );
}






