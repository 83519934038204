import React from "react";
import * as Yup from "yup";
import moment from "moment";

const ValidationSchema = Yup.object().shape({
  localization_id: Yup.number().required("Pole Wymagane"),
  location_object_id: Yup.number().required("Pole Wymagane"),
  sport_id: Yup.number().required("Pole Wymagane"),
  publish_date: Yup.date().required("Pole Wymagane"),
  start_at: Yup.string().required("Pole Wymagane"),
  stop_at: Yup
      .string()
      .required("end time cannot be empty")
      .test("is-greater", "end time should be greater", function(value) {
        const { start_at } = this.parent;
        return moment(value, "HH:mm").isAfter(moment(start_at, "HH:mm"));
      }),
  description: Yup.string().required("Pole Wymagane"),
  name: Yup.string().required("Pole Wymagane"),
  gender: Yup.string().required("Pole Wymagane"),
  lv: Yup.string().required("Pole Wymagane"),
  reserved_spots: Yup.number()
    .min(0, "Minimalna wartość 0")
    .lessThan(Yup.ref("max_players"), "Wartość większa od maksymalnej"),
  max_players: Yup.number()
    .required("Pole Wymagane")
    .min(Yup.ref("min_players"), "Wartość większa od minimalnej"),
  min_players: Yup.number()
    .required("Pole number")
    .min(1, "Minimalna wartość 1"),
  price: Yup.number()
    .required("Pole Wymagane")
    .min(0, "Minimalna wartość 0"),
  max_price: Yup.number().min(0, "Minimalna wartość 0").nullable(true).when(["event_type"], {
    is: (event_type) => event_type === 'third_type',
    then: Yup.number().required("Pole Wymagane").min(0, "Minimalna wartość 0"),
  }),
  max_benefit_price: Yup.number().min(0, "Minimalna wartość 0").nullable(true).when(["event_type"], {
    is: (event_type) => event_type === 'third_type',
    then: Yup.number().required("Pole Wymagane").min(0, "Minimalna wartość 0"),
  }),
  week_days: Yup.array().min(1).required("Pole Wymagane"),
  hours_to_make_visible: Yup.number()
      .min(0, "Minimalna wartość 0"),
  discount: Yup.number()
      .min(0, "Minimalna wartość 0"),

  rental_price: Yup.number()
      .min(0, "Minimalna wartość 0"),
  supplement: Yup.number()
      .min(0, "Minimalna wartość 0"),
  supplement_medicover: Yup.number()
      .min(0, "Minimalna wartość 0"),
  price_on_publish: Yup.number()
      .min(0, "Minimalna wartość 0"),
  supplement_on_publish: Yup.number()
      .min(0, "Minimalna wartość 0"),
  supplement_medicover_on_publish: Yup.number()
      .min(0, "Minimalna wartość 0")
});

export default ValidationSchema;
