import React, { useState, useEffect } from 'react';

// The editor core
import Editor from '@react-page/editor';

import '@react-page/editor/lib/index.css';

// The rich text area plugin
import slate from '@react-page/plugins-slate';
// image
import image from '@react-page/plugins-image';
import spacer from '@react-page/plugins-spacer'
import divider from '@react-page/plugins-divider'
import html5_video from '@react-page/plugins-html5-video'

import background, { ModeEnum } from "@react-page/plugins-background";

// Define which plugins we want to use.
const cellPlugins = [slate(), image, html5_video, divider, spacer, background({
  enabledModes:
    ModeEnum.COLOR_MODE_FLAG |
    ModeEnum.IMAGE_MODE_FLAG |
    ModeEnum.GRADIENT_MODE_FLAG,
})];

export default function PageViewer({ pageBody = null, ...props }) {
  const [value, setValue] =  useState(pageBody);


  return (
    <>
        <Editor readOnly cellPlugins={cellPlugins} value={value} /> 
    </>
  );
}
