import React from "react";

import {PhoneForm} from "../../../views/Users/EditProfile/Phone";


const Step2 = React.forwardRef((props, ref) => {
    React.useImperativeHandle(ref, () => ({
        isValidated: () => {
            return isValidated();
        },
        sendState: () => {
            return sendState();
        },
        state: {},
    }));
    const sendState = () => {
        return {};
    };

    const isValidated = () => {
        return true;
    };
    return <>{<PhoneForm {...props} />}</>
});

Step2.displayName = "Step2";

export default Step2;