import React from "react";
import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
  value: Yup.number()
    .max(350, "Maksymalna kwota doładowania 350")
    .min(1, "Kwota doładowania musi być większa od 1")
    .required("Pole Wymagane"),
  terms: Yup.string().required("Pole Wymagane"),
});

export default ValidationSchema;
