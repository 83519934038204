/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Primary from "components/Typography/Primary";
// sections for this page

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle";

import { UsersForm } from "./Form/index";
import { PhoneForm } from "./Phone/index";
import { Link } from "react-router-dom";

class FormGrid extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <GridContainer style={{ marginTop: 75 }}>
        <GridItem xs={12} style={{ marginBottom: 0 }}>
          <div className={classNames(classes.main)} style={{ padding: 15 }}>
            <GridContainer justify="center">
              <GridItem
                xs={12}
                sm={8}
                md={6}
                lg={4}
                style={{ marginBottom: 110 }}
              >
                <h3
                  style={{ fontWeight: 900, marginBottom: 40, marginTop: 30 }}
                >
                  Dane osobowe
                  <h6>
                    <Link to="/account-settings">Konto</Link> > Dane Osobowe
                  </h6>
                </h3>
                <UsersForm {...this.props} />
                <h3
                  style={{ fontWeight: 900, marginBottom: 40, marginTop: 30 }}
                >
                  Telefon Kontaktowy
                </h3>
                <PhoneForm {...this.props} />
              </GridItem>
              <GridItem
                xs={12}
                sm={4}
                md={4}
                lg={2}
                style={{ marginBottom: 110 }}
              >
                <Card>
                  <CardBody>
                    <h6 className={classes.cardCategory}></h6>
                    <h4 className={classes.cardTitle}>
                      <Primary>
                        <h5>
                          {" "}
                          <b>Do czego potrzebujemy Twoją datę urodzenia?</b>
                        </h5>
                      </Primary>
                      <p>Aby zweryfikować prawnie Twój wiek.</p>
                      <Primary>
                        <h5>
                          {" "}
                          <b>Dlaczego potrzebujemy Twój numer telefonu?</b>
                        </h5>
                      </Primary>
                      <p>
                        Numer telefonu pozwoli nam skontaktować się z Tobą w
                        przypadku odwołania zajęć.
                      </p>
                    </h4>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </GridItem>
      </GridContainer>
    );
  }
}

FormGrid.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(presentationStyle)(FormGrid);
