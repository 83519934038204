import React from "react";
import {Api} from "PNH/services/Api";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import formRegular from "PNH/assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "PNH/assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "PNH/assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import {ActivitiesFunctions} from "./index";
import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";
import {SettingsSection} from "./FormSections/Settings";
import {VisibilitySection} from "./FormSections/Visibility";
import {DescriptionSection} from "./FormSections/Description";
import {PaymentsSection} from "./FormSections/Payments";
import {ImagesSection} from "./FormSections/Images";
import {FreespotsSection} from "./FormSections/FreeSpots";
import {LimitationsSection} from "./FormSections/Limitations";
import {CostsSection} from "./FormSections/Costs";
import {DateAndTimeSection} from "./FormSections/DateAndTime";
import {LocationSection} from "./FormSections/Location";
import CustomInput from "components/CustomInput/CustomInput";
import Card from "@material-ui/core/Card"
import CardBody from "../../../../../components/Card/CardBody";
import {Link} from "react-router-dom";
import {I18n} from "../../../../components/I18n";

const styles = {
    ...formRegular,
    ...stylesExtended,
    ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const StyledCard = withStyles({
        root: {
            height: '100%',
            "& h4": {
                fontWeight: 600,
            },
            "&:hover": {
                "& h4": {
                    color: "#1ad1a2"
                },
                "&.disabled h4": {
                    color: "#ddd"
                },
                cursor: "pointer",
            },
            '&.checked h4': {
                color: "#1ad1a2!important"
            },
            '&.disabled h4': {
                color: "#ddd!important"
            },
        },
        checked: {}
    }
)
(Card);

const getTypesConfig = (currentUser) => {
    const available_roles = currentUser.roles.filter((r) => r.name.startsWith("user_role_organizer_type"))
    return available_roles.map(role => {
        if (role.name.endsWith("3")) {
            return "third_type"
        }
        if (role.name.endsWith("2")) {
            return "second_type"
        }
        if (role.name.endsWith("1")) {
            return "first_type"
        }
    })

};

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.fetch();
        this.state = {
            deleteModal: false,
            fetchActivity: !!props.activityID,
            fetchSports: true,
            fetchLocations: true,
        };
        this.fetchLocalizations();
        Api.sports.index().then((response) => {
            if (response.ok) {
                this.props.setFieldValue("sports", response.data.rows);
                this.setState({fetchSports: false});
            }
        });
    }

    fetchLocalizations = () => {
        Api.locations.index().then((response) => {
            if (response.ok) {
                this.props.setFieldValue("localizations", response.data.rows);
                this.setState({fetchLocations: false});
            }
        });
    };

    deleteModal = (open) => {
        this.setState({deleteModal: open});
    };

    fetch = () => {
        if (this.props.activityID) {
            Api.activities.show(this.props.activityID).then((response) => {
                if (response.ok) {

                    Object.keys(response.data).map((k) => {
                        if (this.props.copy && (k == "creator_id" || k == "date" || k == "creator" || k == "publish_date" || k == "id")) {
                            // do nothing
                        } else {
                            this.props.setFieldValue(k, response.data[k]);
                        }
                    });
                    this.setState({fetchActivity: false});
                } else {
                    this.props.setNotifications([
                        {type: "alert", msg: "Coś poszło nie tak"},
                    ]);
                }
            });
        }
    };

    renderAllFields = () => {
        const {
            classes,
            values,
            errors,
            handleChange,
            setFieldValue,
            handleSubmit,
            data
        } = this.props;
        return <>
            <GridItem xs={12}>
                <h4>
                    <b>Ogólne</b>
                </h4>

            </GridItem>
            <GridItem xs={12} md={6}>
                <CustomInput
                    labelText="Nazwa"
                    error={errors.name}
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        multiline: true,
                        value: values.name,
                        name: "name",
                        onChange: handleChange,
                    }}
                />
            </GridItem>
            <GridItem xs={12} md={6}>
                <AsyncSelector
                    apiMethod={Api.sports.index}
                    apiShowMethod={Api.sports.show}
                    labelKey="name"
                    label="Dyscyplina"
                    name="sports"
                    error={errors.sport_id}
                    defaultValue={values.sport_id}
                    value={
                        values.sport && {
                            id: values.sport.id,
                            label: values.sport.name,
                        }
                    }
                    onChange={(value, data) => {
                        setFieldValue("sport", data);
                        setFieldValue("sport_id", value);
                    }}
                />
            </GridItem>

            <LocationSection values={values} classes={classes} errors={errors} handleChange={handleChange}
                             setFieldValue={setFieldValue} {...this.props} />
            <DateAndTimeSection values={values} classes={classes} errors={errors} handleChange={handleChange}
                                setFieldValue={setFieldValue} {...this.props}/>
            <CostsSection values={values} classes={classes} errors={errors} handleChange={handleChange}
                          setFieldValue={setFieldValue}/>
            <LimitationsSection values={values} classes={classes} errors={errors} handleChange={handleChange}
                                setFieldValue={setFieldValue}/>
            <FreespotsSection values={values} classes={classes} errors={errors} handleChange={handleChange}
                              setFieldValue={setFieldValue}/>
            <PaymentsSection currentUser={data.currentUser} values={values} classes={classes} errors={errors}
                             handleChange={handleChange} setFieldValue={setFieldValue}/>
            <DescriptionSection values={values} classes={classes} errors={errors} handleChange={handleChange}
                                setFieldValue={setFieldValue}/>
            <VisibilitySection values={values} classes={classes} errors={errors} handleChange={handleChange}
                               setFieldValue={setFieldValue}/>
            <SettingsSection values={values} classes={classes} errors={errors} handleChange={handleChange}
                             setFieldValue={setFieldValue}/>
            {/* <ImagesSection values={values} classes={classes} errors={errors} handleChange={handleChange} setFieldValue={setFieldValue} />     */}
        </>
    }

    form() {
        const {
            classes,
            values,
            errors,
            handleChange,
            setFieldValue,
            handleSubmit,
            data
        } = this.props;
        const eventTypesRole = getTypesConfig(data.currentUser)
        return (
            <form onSubmit={handleSubmit}>
                <GridContainer>
                    <GridItem xs={12}>
                        <h5>
                            <b>Dodawanie szablonu zajęć</b>
                        </h5>
                        Stwórz swój szablon zajęć, aby móc dodawać zajęcia bez oczekiwania na ich weryfikację. Posiadając
                        zweryfikowany szablon zajęć, będziesz mógł dodawać zajęcia cyklicznie nawet na wiele tygodni
                        do przodu lub na cały sezon. Więc informacji o szablonach znajdziesz&nbsp;<Link
                        to="s/szablony-do-zajec-organizatorskich">tutaj</Link>
                    </GridItem>
                    <GridItem xs={12} style={{marginTop: '30px'}}>
                        <h5>
                            <b>Wybierz swój pakiet</b>
                        </h5>
                    </GridItem>
                    <GridContainer alignItems="stretch">
                        {["third_type", "second_type", "first_type"].map(e_type => {
                            return <GridItem xs={12} md={4} style={{marginBottom: '35px'}} onClick={async () => {
                                if(eventTypesRole.includes(e_type)) {
                                    setFieldValue("event_type", e_type)
                                    setFieldValue('supplement_on_publish', values.supplement)
                                    setFieldValue('supplement_medicover_on_publish', values.supplement_medicover)
                                    setFieldValue('price_on_publish', values.price)
                                    if(e_type == "third_type") {
                                        setFieldValue("price", 0)
                                        setFieldValue('price_on_publish', 0)
                                        setFieldValue('supplement_medicover', "")
                                        setFieldValue('supplement', "")
                                    }

                                }
                            }}>
                                <StyledCard className={`${values.event_type === e_type && "checked"} ${!eventTypesRole.includes(e_type) && "disabled"} `}>
                                    <CardBody style={{height: '100%'}}>
                                        <h4>{I18n.t(e_type)}</h4>
                                        <p>{I18n.t(`${e_type}_copy`)}&nbsp;<Link
                                            to="s/multisport-sporty-druzynowe-projekt-organizator">tutaj</Link></p>
                                    </CardBody>
                                </StyledCard>
                            </GridItem>
                        })}
                    </GridContainer>
                    {values.event_type && this.renderAllFields()}
                </GridContainer>
                <GridContainer justify="space-between">
                    {values.event_type && <GridItem xs={12} sm style={{textAlign: "right"}}>
                        <Button
                            type="submit"
                            color="success"
                            disabled={this.props.isSubmitting}
                        >
                            Zapisz
                        </Button>
                    </GridItem>}
                    <GridItem xs={12}><hr/></GridItem>
                </GridContainer>
            </form>
        );
    }

    render() {
        const {fetchActivity, fetchLocalizations, fetchLocations} = this.state;
        if (!fetchActivity && !fetchLocalizations && !fetchLocations) {
            return this.form();
        } else {
            return <h4>Loading...</h4>;
        }
    }
}

export default withStyles(styles)(Form);
