/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import OkSystem from "PNH/assets/img/medicover.png";
import Multisport from "PNH/assets/img/multisport.jpeg";
import Medicover from "PNH/assets/img/medicover.jpg";
import Benefit from "PNH/assets/img/benefit.jpeg";
import Nais from "PNH/assets/img/nais.jpg";
import Pzu from "PNH/assets/img/pzu.png"
import Fitprofit from "PNH/assets/img/fitprofit.png"
import Primary from "components/Typography/Primary";
import overviewStyle from "assets/jss/material-kit-pro-react/views/presentationSections/overviewStyle";

class PartnersSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <div className={classes.ourClients}>
          <GridContainer justify="center" alignItems="center">
            <GridItem xs={12}>
              <Primary>
                <h3>
                  <b>Honorujemy</b>
                </h3>
              </Primary>
            </GridItem>
            <GridItem xs={6}  sm={3} md={3} lg={3}>
              <img src={OkSystem} alt="Medicover Sport" />
            </GridItem>
            <GridItem xs={6}  sm={3} md={3} lg={3}>
              <img src={Multisport} alt="multisport" />
            </GridItem>
            <GridItem xs={6}  sm={3} md={3} lg={3}>
              <img src={Medicover} alt="medicover" />
            </GridItem>
            <GridItem xs={6}  sm={3} md={3} lg={3}>
              <img src={Benefit} alt="benefit" />
            </GridItem>
            <GridItem xs={6}  sm={3} md={3} lg={3}>
              <img src={Nais} alt="nais" />
            </GridItem>
            <GridItem xs={6}  sm={3} md={3} lg={3}>
              <img src={Pzu} alt="nais" />
            </GridItem>
            <GridItem xs={6}  sm={3} md={3} lg={3}>
              <img src={Fitprofit} alt="nais" />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

PartnersSection.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(overviewStyle)(PartnersSection);
