import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import {Form} from "./Form";
import { Api } from "PNH/services/Api";

export const UsersFunctions = {
  add: (values, formik) => {
    return Api.competition_groups.teams
      .add_player(values.group_id, values.id, { competition_teams_users: { user_id: values.user_id, role: 0 } })
      .then((response) => {
        if (response.ok) {
          formik.setFieldValue("users", response.data.rows)
          window.setNotifications([{ type: "success", msg: "Dodano" }]);
        } else {
          window.setNotifications([
            { type: "alert", msg: "Nie udało się zapisać zmian" },
          ]);
        }
      });
  },
  remove: (id, groupId, team_id, formik, reload) => {
    return Api.competition_groups.teams
      .remove_player(id, groupId, team_id)
      .then((response) => {
        if (response.ok) {
          console.log(response)
          formik.setFieldValue("users", response.data.rows)
          reload()
          window.setNotifications([{ type: "success", msg: "Usunięto" }]);
        } else {
          window.setNotifications([
            { type: "alert", msg: "Nie udało się usunąć" },
          ]);
        }
      });
  },

  update: (id, groupId, team_id, role, formik, reload) => {
    return Api.competition_groups.teams
        .update_player(groupId, team_id, id, {competition_teams_users: {role: role}})
        .then((response) => {
          if (response.ok) {
            formik.setFieldValue("users", response.data.rows)
            reload()
            window.setNotifications([{ type: "success", msg: "Zmieniono rolę" }]);
          } else {
            window.setNotifications([
              { type: "alert", msg: "Nie udało się zmienić roli" },
            ]);
          }
        });
  },

  defaultValues: (props) => {
    return {
      id: props.match.params.id,
      group_id: props.match.params.competition_group_id,
      action: "add",
      user_id: "",
      group_user_id: "",
      users: [],
      user: {},
      afterSave: () => {},
    };
  },
};

export const UsersForm = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...UsersFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
    UsersFunctions[values.action](values, formikProps)
      .then((response) => {
        values.afterSave(response);
        formikProps.setFieldValue("afterSave", () => {});
      })
      .then(() => {
        formikProps.setTouched({});
      });
  },
})(Form);
