import React, {useEffect} from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import Header from "PNH/components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import loadingPageStyle from "assets/jss/material-kit-pro-react/views/loadingPageStyles";

import image from "PNH/assets/img/404.jpeg";
import {Footer} from "PNH/layouts/Footer";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Api} from "../../../services/Api";


const ConfirmAccountPage = (props) => {
    useEffect(() => {
        const {confirmation_token} = props.data.urlParams;
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;

        Api.users.confirmAccount(confirmation_token).then(response => {
            window.location.href = '/?notification_msg=Potwierdzono użytkownika&notification_type=success'
        });
    }, [])

    const {classes, ...rest} = props;
    return (
        <div>
            <Header
                absolute
                color="transparent"
                brand="Material Kit PRO React"
                {...rest}
            />
            <div
                className={classes.pageHeader}
                style={{
                    backgroundImage: "url(" + image + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "top center",
                }}
            >
                {/* <div className={classes.container}> */}
                <div className={classes.contentCenter}>
                    <GridContainer>
                        <GridItem md={12}>
                            <CircularProgress size={100} thickness={4} className={classes.progress}/>
                        </GridItem>
                    </GridContainer>
                </div>
                {/* </div> */}
            </div>
            <Footer {...props} />
        </div>
    );

}


export default withStyles(loadingPageStyle)(ConfirmAccountPage);
