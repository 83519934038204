/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import { Api } from "PNH/services/Api";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "PNH/components/Header/Header";
import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle";
import PageViewer from "PNH/components/Pages/Viewer";
import { Footer } from "PNH/layouts/Footer";


class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      body: "",
      fetch: true,
      wishpond_id: "",
    };
    this.fetch();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    const script = document.createElement("script");

    script.src = "//cdn.wishpond.net/connect.js?merchantId=1235729&writeKey=115aa48bea7d";
    script.async = true;

    document.body.appendChild(script);
  }

  fetch = () => {
    Api.pages.show(window.location.pathname).then((response) => {
      if (response.ok) {
        this.setState({ id: response.data.id, body: JSON.parse(response.data.body), wishpond_id: response.data.wishpond_id, fetch: false });
      } else {
        // window.location.href = "/404";
      }
    });
  };

  renderContent() {
    const { classes, ...rest } = this.props;
    const {wishpond_id, body} = this.state
    if(!!wishpond_id){
      return <>
       <div style={{ marginTop: 95 }}>
          <div class="wishpond-campaign" data-wishpond-id={wishpond_id} data-wishpond-href={`https://embedded.wishpondpages.com/lp/${wishpond_id}/`}></div>
        </div>
      </>
    }
    if(!!body) {
      return <div className={classes.container} style={{ marginTop: 95 }}>
            <PageViewer  pageBody={body} readOnly />
          </div>
    }
    return <></>
  }



  render() {
    const { classes, ...rest } = this.props;
    return (
      <>
        <Header
          fixed
          color="white"
          changeColorOnScroll={{
            height: 200,
            color: "white",
          }}
          {...rest}
        />

          {!this.state.fetch && this.renderContent()}

        <hr />
        <Footer {...this.props} />
      </>
    );
  }
}

Page.propTypes = {};

export default withStyles(presentationStyle)(Page);
