




/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import errorPageStyle from "assets/jss/material-kit-pro-react/views/errorPageStyles";

import { Footer } from "PNH/layouts/Footer";
import Header from "PNH/components/Header/Header";
class RentAObject extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    const script = document.createElement("script");

    script.src = "//cdn.wishpond.net/connect.js?merchantId=1235729&writeKey=115aa48bea7d";
    script.async = true;

    document.body.appendChild(script);
  }
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
           <Header
          fixed
          color="white"
          changeColorOnScroll={{
            height: 200,
            color: "white",
          }}
          {...rest}
        />
        <div style={{marginTop: 50}}>
            <div class="wishpond-campaign" data-wishpond-id="2684058" data-wishpond-href="https://embedded.wishpondpages.com/lp/2684058/"></div>
        </div>
        <hr />
        <Footer {...this.props} />
      </div>
    );
  }
}

RentAObject.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(errorPageStyle)(RentAObject);
