import React from "react";
import { Api } from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import formRegular from "PNH/assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "PNH/assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "PNH/assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import Primary from "components/Typography/Primary";
import InputAdornment from "@material-ui/core/InputAdornment";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import PhoneIcon from '@material-ui/icons/Phone';
import DialpadIcon from '@material-ui/icons/Dialpad';
import Danger from "../../../../../components/Typography/Danger";

const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
class Form extends React.Component {
  state = {
    disableSender: false,
  };

  constructor(props) {
    super(props);
    this.fetch();
  }
  deleteModal = (open) => {
    this.setState({ deleteModal: open });
  };

  fetch = () => {
    if (this.props.values.id) {
      Api.users.show(this.props.values.id).then((response) => {
        if (response.ok) {
          this.props.setValues({
            id: response.data.id,
            phone: response.data.phone,
            confirmed_phone: response.data.confirmed_phone,
          });

        } else {
          window.setNotifications([
            { type: "alert", msg: "Numer telefonu zajęty" },
          ]);
        }
      });
    }
  };
  render() {
    const {
      classes,
      touched,
      values,
      errors,
      handleChange,
      setFieldValue,
      initialValues,
      handleSubmit,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <GridContainer>
          <GridItem xs={12}>
            <CustomInput
              labelText=<span>Nr telefonu <Danger>*</Danger></span>
              error={errors.phone}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: values.phone,
                name: "phone",
                onChange: (e) => {
                  handleChange(e)
                  setFieldValue("show_save_button", true)
                },
                type: "text",
                endAdornment: (
                    <InputAdornment position="end" className={classes.inputAdornment}>
                      {values.confirmed_phone && !values.show_save_button && <Primary>  <VerifiedUserIcon className={classes.inputAdornmentIcon} /> </Primary>}
                      {(!values.confirmed_phone || values.show_save_button) &&  <PhoneIcon className={classes.inputAdornmentIcon} />}
                    </InputAdornment>
                ),
              }}
            />
          </GridItem>

          {values.show_save_button && <GridItem xs={12}>
            <GridContainer justify="center">
              <GridItem xs={12} style={{textAlign: 'center'}}>
                <Button type="submit" round={true}  color="primary">
                  Zapisz
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem> }

        </GridContainer>
        <GridContainer>
          {!values.confirmed_phone && (
            <>
              <GridItem xs={12} style={{marginTop: '40px'}}>
                <h4>
                 <strong>Przeprowadź weryfikację nr telefonu <Danger>*</Danger></strong>
                </h4>
                <p>
                  Weryfikacja numeru telefonu jest niezbędna, aby móc się zapisywać na
                  zajęcia.
                </p>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}  style={{marginTop: '40px'}}>
                <p>
                  Wprowadź kod otrzymany w wiadomości tekstowej, którą wyślemy na {values.phone}. Kod powinien przyjść w ciągu minuty.
                </p>
              </GridItem>

              <GridItem xs={12} >
                <CustomInput
                  labelText="Kod"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: values.token,
                    name: "token",
                    onChange: handleChange,
                    type: "text",
                    endAdornment: (
                        <InputAdornment position="end" className={classes.inputAdornment}>
                          <DialpadIcon className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                    ),
                  }}
                />
              </GridItem>

              <GridItem xs={12} style={{textAlign: 'center'}}>
                <Button
                    color="primary"
                    round={true}
                    onClick={() => {
                      Api.users
                          .confirm_phone_token({ user: { token: values.token } })
                          .then(({ ok }) => {
                            if (ok) {
                              setFieldValue("confirmed_phone", true);
                              window.setNotifications([
                                { type: "success", msg: "Numer zweryfikowany" },
                              ]);
                            }
                          });
                    }}
                >
                  Weryfikuj
                </Button>
              </GridItem>
              <GridItem xs={12} style={{textAlign: 'center'}}>
                <Button
                    disabled={this.state.disableSender}
                    link={true}
                    onClick={() => {
                      this.setState({ disableSender: true }, () => {
                        Api.users.getToken().then(r => {
                          if(r.ok) {
                            window.setNotifications([
                              { type: "success", msg: "Kod został wysłany" },
                            ]);
                          }
                        });
                        setTimeout(() => {
                          this.setState({ disableSender: false });
                        }, 3500);
                      });
                    }}
                >
                  Wyślij nowy kod
                </Button>
              </GridItem>

            </>
          )}
          <GridItem xs={12}>
            <Danger>* pola wymagane</Danger>
          </GridItem>
        </GridContainer>
      </form>
    );
  }
}

export default withStyles(styles)(Form);
