/*eslint-disable*/
import React, { useState } from "react";
import { SportLevelForm } from "../../Users/SportLevelPage/Form";
import Button from "components/CustomButtons/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";



const RenderDialog = ({ toggleModal, afterSave = () => { }, activity, classes, ...props }) => (
  <>
    <span style={{ position: 'absolute', top: '10px', right: '10px' }}>
      <Button
        justIcon
        className={classes.modalCloseButton}
        key="close"
        aria-label="Close"
        color="transparent"
        onClick={() => toggleModal(false)}
      >
        <Close className={classes.modalClose} />
      </Button>
    </span>
    <DialogContent className={classes.modalBody} style={{ width: "100%", paddingTop: 0 }}>
      <SportLevelForm sport_id={activity.sport.id} sport={activity.sport} hideSportSelector afterSave={() => {
        afterSave();
      }} {...props} />
    </DialogContent>
    <DialogActions
      className={classes.modalFooter + " " + classes.modalFooterCenter}
    >
    </DialogActions>
  </>
)

export function AddSportLevelStep(props) {
  return (
    <RenderDialog {...props} />
  );
}






