import React from "react";
import CustomInput from "components/CustomInput/CustomInput";
import GridItem from "components/Grid/GridItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { I18n } from "PNH/components/I18n";
import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";
import { Api } from "PNH/services/Api";

export const SettingsSection = ({handleChange, classes, values, errors, setFieldValue}) => {
    return <>
        <GridItem xs={12}>
            <h5>
                <b>Ustawienia</b>
            </h5>
            <hr />
        </GridItem>
        {["coach", "deposit_disabled"].map(
            (p) => (
                <GridItem xs={12} sm={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                tabIndex={-1}
                                checked={values[p]}
                                onClick={() => setFieldValue(p, !values[p])}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot,
                                }}
                            />
                        }
                        classes={{
                            label: classes.label,
                            root: classes.labelRoot,
                        }}
                        label={I18n.t(p)}
                    />
                </GridItem>
            )
        )}
        <GridItem xs={12}>
            <CustomInput
                labelText="Notatki"
                error={errors.notes}
                formControlProps={{
                    fullWidth: true,
                }}
                inputProps={{
                    multiline: true,
                    value: values.notes,
                    name: "notes",
                    onChange: handleChange,
                }}
            />
        </GridItem>
    </>
}