import React, { useEffect, useState } from "react"
import { Api } from "PNH/services/Api"
import Primary from "components/Typography/Primary"
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Accordion from "components/Accordion/Accordion";
import PageViewer from "PNH/components/Pages/Viewer";
import { I18n } from "PNH/components/I18n"
import { ActivitiesFromTemplateForm } from "./CreateActivities/index";
import Warning from "components/Typography/Warning";
import moment from "moment";

export const TemplateView = ({ id }) => {
    const [template, setTemplate] = useState({})

    useEffect(() => {
        Api.activity_templates.show(id).then((r) => {
            if (r.ok) {
                setTemplate(r.data)
            }
        })
    }, [])

    if (!template.id) {
        return null
    }

    return <>
        <GridItem xs={12}>
            <Primary>
                <h2>
                    {template.name}
                </h2>

            </Primary>
            <p>Ważny do <b>{template.expired_at}</b></p>
        </GridItem>
        <GridItem xs={12}>
           {template.approved_by_admin && template.expired_at >= moment().format("YYYY-MM-DD") && <ActivitiesFromTemplateForm expired_at={template.expired_at} templateID={template.id} /> }
           {!template.approved_by_admin && <Warning>Szablon oczekuje na weryfikację</Warning> }
            {template.expired_at < moment().format("YYYY-MM-DD")  && <Warning>Szablon utracił ważność</Warning>}
        </GridItem>
        <GridItem xs={12}>
            <h4>
                <b>Ustawienia szablonu</b>
            </h4>
            <hr />
            <h6 style={{ marginTop: "-10px" }}>Tagi: #{template.tags}</h6>
            {template.note}
        </GridItem>
        <GridItem xs={12} >
            <Accordion
                active={[0, 1]}
                activeColor="rose"
                collapses={[
                    {
                        title: "Ogólne",
                        content: (
                            <>
                                <GridContainer
                                    justfy="space-between"
                                    style={{ whiteSpace: "nowrap" }}
                                >
                                    <GridItem xs={6} sm={3}>
                                        <p>
                                            <b>Cena: </b>
                                            <br />
                                            {template.price} PLN
                                        </p>
                                    </GridItem>
                                    <GridItem xs={6} sm={3}>
                                        <p>
                                            <b>Dzień tyg: </b>
                                            <br />
                                           {template.week_days.map((d) => {
                                            return ["Nd", "Pn", "Wt", "Śr", "Czw", "Pt", "Sob"][d] 
                                           }).join(", ")}
                                        </p>
                                    </GridItem>
                                    <GridItem xs={6} sm={3}>
                                        <p>
                                            <b>Godzina: </b>
                                            <br />
                                            {template.start_at} - {template.stop_at}
                                        </p>
                                    </GridItem>

                                    <GridItem xs={6} sm={3}>
                                        <p>
                                            <b>Ilość miejsc: </b>
                                            <br />
                                            {template.all_reserved_spots}/
                                            {template.max_players} (min: {template.min_players})
                                        </p>
                                    </GridItem>

                                    <GridItem xs={6} sm={3}>
                                        <p>
                                            <b> {template.verify_lv ? "Wymagany" : 'Zalecany'} poziom: </b>
                                            <br />
                                            {I18n.t(template.lv || "free")}<br />

                                        </p>
                                    </GridItem>
                                    <GridItem xs={6} sm={3}>
                                        <p>
                                            <b>Płeć: </b>
                                            <br />
                                            {I18n.t(template.gender || "unisex")}
                                        </p>
                                    </GridItem>
                                    <GridItem xs={6} sm={3}>
                                        <p>
                                            <b>Trener: </b>
                                            <br />
                                            {template.coach ? "Tak" : "Nie"}
                                        </p>
                                    </GridItem>

                                    <GridItem xs={6} sm={3}>
                                        <p>
                                            <b>Multisport: </b>
                                            <br />
                                            {template.pay_by_benefit ? "Tak" : "Nie"}
                                        </p>
                                    </GridItem>
                                    <GridItem xs={6} sm={3}>
                                        <p>
                                            <b>Medicover Sport: </b>
                                            <br />
                                            {template.pay_by_ok_system
                                                ? template.ok_system_code
                                                : "Nie"}
                                        </p>
                                    </GridItem>
                                    <GridItem xs={6} sm={3}>
                                        <p>
                                            <b>FitProfit: </b>
                                            <br />
                                            {template.pay_by_fitprofit ? "Tak" : "Nie"}
                                        </p>
                                    </GridItem>

                                </GridContainer>
                            </>
                        ),
                    },
                    {
                        title: "Lokalizacja",
                        content: (
                            <>
                                <GridContainer>
                                    <GridItem xs>
                                        <h5>
                                            <b>Adres</b>
                                        </h5>
                                        <p>
                                            {template.localization &&
                                                template.localization.name}{" "}
                                            <br />
                                            {template.localization &&
                                                template.localization.street}{" "}
                                            {template.localization &&
                                                template.localization.city}
                                        </p>
                                        <p>
                                            {template.localization &&
                                                template.localization.description}
                                        </p>
                                    </GridItem>
                                    <GridItem xs>
                                        <h5>
                                            <b>Obiekt:</b>
                                        </h5>
                                        <p>
                                            {template.location_object &&
                                                I18n.t(
                                                    template.location_object.object_type
                                                )}{" "}
                                            <br />
                                            {template.location_object &&
                                                template.location_object.description}
                                        </p>
                                    </GridItem>
                                </GridContainer>


                            </>
                        ),
                    },
                    {
                        title: "Widoczność",
                        content: (<GridContainer>
                            <GridItem xs={6} sm>
                                <p>
                                    <b>Ukryte: </b>
                                    <br />
                                    {template.hidden ? "Tak" : "Nie"}
                                </p>
                            </GridItem>
                            {template.hidden && <GridItem xs={6} sm>
                                <p>
                                    <b>Zmień na publiczne x godzin przed: </b>
                                    <br />
                                    {template.hours_to_make_visible}
                                </p>
                            </GridItem>}
                            {template.hidden && <GridItem xs={6} sm>
                                <p>
                                    <b>Cena po odkryciu: </b>
                                    <br />
                                    {template.price_on_publish}
                                </p>
                            </GridItem>}
                            {template.hidden && <GridItem xs={6} sm>
                                <p>
                                    <b>Dopłata Benefitu po odkryciu: </b>
                                    <br />
                                    {template.supplement_on_publish}
                                </p>
                            </GridItem>}
                            {template.hidden && <GridItem xs={6} sm>
                                <p>
                                    <b>Dopłata Medicover po odkryciu: </b>
                                    <br />
                                    {template.supplement_medicover_on_publish}
                                </p>
                            </GridItem>}
                            {template.hidden && <GridItem xs={6} sm>
                                <p>
                                    <b>Dopłata FitProfit po odkryciu: </b>
                                    <br />
                                    {template.supplement_fitprofit_on_publish}
                                </p>
                            </GridItem>}
                        </GridContainer>)
                    },
                    {
                        title: "Opis",
                        content: (
                            <p style={{ whiteSpace: "break-spaces" }}>
                                {template.description}
                                {template.page_id && template.page && <PageViewer pageBody={JSON.parse(template.page.body)} />}
                            </p>
                        ),
                    },
                ]}
            />
        </GridItem>
    </>
}