import React from "react";
import { Api } from "PNH/services/Api";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import formRegular from "PNH/assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "PNH/assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "PNH/assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";

import { UsersFunctions } from "./index";
import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";
import CloseIcon from "@material-ui/icons/Close";
import Primary from "components/Typography/Primary";

import avatar from "PNH/assets/img/placeholder.jpg";

const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
class Form extends React.Component {
  state = {
    deleteModal: false,
  };

  constructor(props) {
    super(props);
    this.fetch();
  }
  deleteModal = (open) => {
    this.setState({ deleteModal: open });
  };

  fetch = () => {
    if (this.props.values.id) {
      Api.users.show(this.props.values.id).then((response) => {
        if (response.ok) {
          this.props.setValues({
            ...UsersFunctions.defaultValues(this.props),
            ...{
              users_allowed_to_registers:
                response.data.users_allowed_to_registers,
            },
          });
        } else {
          window.setNotifications([
            { type: "alert", msg: "Coś poszło nie tak" },
          ]);
        }
      });
    }
  };
  render() {
    const {
      classes,
      values,
      errors,
      handleChange,
      setFieldValue,
      handleSubmit,
    } = this.props;
    return (
      <GridContainer>
        <GridItem>
          <h4
            style={{
              fontWeight: 900,
              marginTop: 30,
              marginBottom: 40,
            }}
          >
            Ustawienia zapisów na zajęcia
          </h4>
        </GridItem>
        <GridItem xs={12}>
          Dodaj osobę uprawnioną do zapisywania Cię na zajęcia:
          <GridContainer jusify="space-between">
            <GridItem xs>
              <AsyncSelector
                apiMethod={Api.users.index}
                labelKey="label"
                label="Użytkownik"
                name="user"
                value={
                  values.user && {
                    id: values.user.id,
                    label: values.user.label,
                  }
                }
                onChange={(value, data) => {
                  setFieldValue("user", data);
                  setFieldValue("user_id", value);
                }}
              />
            </GridItem>
            <GridItem xs>
              <Button
                color="primary"
                round={true}
                disabled={!values.user_id}
                onClick={async (e) => {
                  await setFieldValue("action", "add");
                  handleSubmit(e);
                }}
              >
                Dodaj
              </Button>
            </GridItem>

            <GridItem xs={12}>
              <GridContainer jusify="space-between">
                {!values.users_allowed_to_registers[0] && (
                  <Primary>
                    <h5>
                      <b>
                        Nie masz jeszcze osób upoważnionych do zapisania Cię na
                        zajęcia
                      </b>
                    </h5>
                  </Primary>
                )}
                {values.users_allowed_to_registers[0] && (
                  <GridItem xs={12}>
                    <Primary>
                      <h5>
                        <b>Osoby upoważnione</b>
                      </h5>
                    </Primary>
                  </GridItem>
                )}
                {values.users_allowed_to_registers.map((u) => {
                  return (
                    <GridItem
                      key={`uar-${u.id}`}
                      xs={12}
                      sm={6}
                      md={4}
                      style={{ fontSize: 10, fontWeight: 600 }}
                    >
                      <GridContainer style={{marginTop: 10}}>
                        <GridItem xs style={{ maxWidth: 50 }}>
                          <img src={avatar} style={{ maxWidth: 30 }} />
                        </GridItem>
                        <GridItem xs style={{ padding: 0, lineHeight: "15px" }}>
                          {u.label.split(",")[0]}
                          <br />
                          {u.label.split(",")[1]}
                          <div
                            style={{ maxWidth: 50, cursor: "pointer", position: "absolute", right: 0, top: -5 }}
                          >
                            <Button
                              justIcon
                              simple
                              color="danger"
                              size="sm"
                              onClick={async (e) => {
                                await setFieldValue("action", "remove");
                                await setFieldValue("user_id", u.id);
                                handleSubmit(e);
                              }}
                            >
                              <CloseIcon />
                            </Button>
                          </div>
                        </GridItem>
                        
                      </GridContainer>
                    </GridItem>
                  );
                })}
              </GridContainer>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(Form);
