/*!

=========================================================
* Material Kit PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import "assets/scss/material-kit-pro-react.scss?v=1.9.0";
import "PNH/assets/css/pnh.scss";
import Application from "PNH/layouts/Application";
// pages for this product
var oldLog = console.error
window.console.error = function (message) {
  if(typeof message.startsWith !== "function"){
   return oldLog.apply(console, arguments);
  }
  if (message.startsWith('Material-UI:')) {
    return false
  }
  if (!message.includes('Warning')) {
    return oldLog.apply(console, arguments);
  }
  if (!message.startsWith('Material-UI:') && message.includes('Warning')) {
    return console.warn(console, arguments)
  }
};

(function (w, d, s, l, i) {
  var ga_coockies = localStorage.getItem("ga_coockies")
  if(ga_coockies == 'true' || ga_coockies == null) {
    w[l] = w[l] || []; w[l].push({
      'gtm.start':
          new Date().getTime(), event: 'gtm.js'
    }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
  }

})(window, document, 'script', 'dataLayer', 'GTM-TDVXVS4');

ReactDOM.render(
  <Application />,
  document.getElementById("root")
);

(function (h, o, t, j, a, r) {
  var hotjar_coockies = localStorage.getItem("hotjar_coockies")
  if(hotjar_coockies == 'true' || hotjar_coockies == null) {
    h.hj = h.hj || function () {
      (h.hj.q = h.hj.q || []).push(arguments)
    };
    h._hjSettings = {hjid: 827165, hjsv: 6};
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  }
})(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

