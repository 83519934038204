/*eslint-disable*/
import React from "react";
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "PNH/components/Header/Header";
// sections for this page
import LevelPage from "./page"
import { Footer } from "PNH/layouts/Footer";

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle";

class SportLevel extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }

    render() {
        const { classes } = this.props;
        return (
            <>
                <Header brand="PNH" fixed color="white" {...this.props} />
                <div className={classNames(classes.main)}>
                    <div className={classes.container}>
                        <div style={{ marginTop: 75 }}>
                            <LevelPage {...this.props} />
                        </div>
                    </div>
                </div>

                <Footer {...this.props} />
            </>
        );
    }
}

SportLevel.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(presentationStyle)(SportLevel);
