/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "PNH/components/Header/Header";
// sections for this page


import { Footer } from "PNH/layouts/Footer";

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle";
import FormGrid from "./FormGrid";

class EditBenefit extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Header brand="PNH" fixed color="white" {...this.props} />

        <FormGrid modalButton={false} {...this.props} />

        <Footer {...this.props} />
      </>
    );
  }
}

EditBenefit.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(presentationStyle)(EditBenefit);
