/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "PNH/components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// sections for this page

import {Footer} from "PNH/layouts/Footer";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle";
import Button from "components/CustomButtons/Button";
import ActivityCard from "PNH/components/Activity/ActivityCard";
import moment from "moment";
import Primary from "components/Typography/Primary";
import {
    EditProfileCard,
    EditPasswordCard,
    EditNotificationsCard,
    TransactionsCard,
    ActivitiesCard,
    CreatedActivities,
    CoordinatorActivities,
    ActivitiesObservedCard,
    SportLevelCard, BenefitsCard
} from "./Cards/EditProfileCard";
import {Api} from "PNH/services/Api";
import {Link} from "react-router-dom";
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";
import Warning from "../../../../components/Typography/Warning";

class AccountPage extends React.Component {
    state = {
        activities: [],
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        this.fetch();
    }

    fetch = () => {
        Api.activities
            .index({
                bought_activities: true,
                with_date_greater: moment()
                    .utc()
                    .format("YYYY-MM-DD"),
                limit: 3,
            })
            .then(({data}) => {
                this.setState({activities: data.rows});
            });
    };

    render() {
        const {classes} = this.props;
        const currentUser = this.props.data.currentUser
        return (
            <>
                <Header brand="Klikasz. Grasz." fixed color="white" {...this.props} />
                <div className={classes.container}>
                    <GridContainer
                        style={{marginTop: 20, padding: "70px 33px 0px 60px"}}
                    >
                        <GridItem xs={12}>
                            <GridContainer>
                                <GridItem
                                    xs={12}
                                    sm={4}
                                    md={2}
                                    lg={2}
                                    style={{display: "flex"}}
                                >
                                    <AccountCircleIcon
                                        style={{
                                            width: 150,
                                            height: 150,
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={8} md={10} lg>
                                    <div
                                        className={classes.brand}
                                        style={{
                                            color: "black",
                                            textAlign: "left",
                                        }}
                                    >
                                        <h3 style={{fontWeight: 900}}>
                                            {this.props.data.currentUser.first_name}{" "}
                                            {this.props.data.currentUser.last_name}
                                        </h3>

                                        <p>
                                            <h5
                                                style={{fontWeight: 900, color: "rgb(0, 166, 153)", marginBottom: 0}}
                                            >
                                                Stan konta: {this.props.data.currentUser.account_value}
                                                zł
                                            </h5>
                                            <h6 style={{fontWeight: 200, textTransform: "none", marginTop: 0}}>
                                                <b>Główne:{" "}</b> {`${this.props.data.currentUser.account_balance} zł `}
                                                <b style={{marginLeft: 10}}>Bonus:{" "}</b> {`${this.props.data.currentUser.account_bonus_value} zł `}
                                                <b style={{marginLeft: 10}}>Ważny
                                                    do:{" "}</b> {this.props.data.currentUser.bonus_expiration_date}
                                                <br/>
                                                <span style={{color: "rgb(0, 166, 153)"}}>
                          <b>Kaucja:{" "}</b>{`${this.props.data.currentUser.account_deposit_value} zł `}
                        </span>
                                            </h6>
                                        </p>

                                        <GridContainer alignItems="baseline" justify="flex-end">
                                            <GridItem
                                                xs={12}
                                                style={{marginTop: 25, marginBottom: 20}}
                                            >
                                                <Button
                                                    onClick={() => {
                                                        window.location.href = "/cennik";
                                                    }}
                                                    color="primary"
                                                    style={{position: "absolute", right: 10, top: -35}}
                                                >
                                                    Doładuj konto
                                                </Button>
                                            </GridItem>
                                        </GridContainer>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </div>

                <div className={classNames(classes.main)} style={{padding: 15}}>
                    <div className={classes.container}>
                        <GridContainer>
                            {!currentUser.account_completed && (
                                <GridItem xs={12} >
                                    <Card>
                                        <CardBody style={{textAlign: "center"}}>
                                            <h4 >
                                                <Warning>
                                                    <b>
                                                        Aby zapisać się na zajęcia, dokończ proces rejestracji.
                                                        Uzupełnij swój profil i potwierdź nr telefonu.
                                                    </b>
                                                </Warning>
                                                <h6 className={classes.textCenter}>
                                                    {" "}
                                                    <Button round={true} color="primary" onClick={() => {
                                                        this.props.getStateUpdates().openCompleteAccountModal()
                                                    }}>
                                                        Dokończ proces rejestracji
                                                    </Button>
                                                </h6>
                                            </h4>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            )}
                            <GridItem xs={12}>
                                <h3 style={{fontWeight: 900}}>Strefa gracza</h3>
                            </GridItem>
                            <GridItem xs={12}>
                                <GridContainer justifyItems="center">
                                    <GridItem xs={12} sm={6} md={6} lg={4}>
                                        <ActivitiesCard {...this.props} />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={4}>
                                        <ActivitiesObservedCard {...this.props} />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={4}>
                                        <TransactionsCard {...this.props} />
                                    </GridItem>
                                </GridContainer>
                                <GridItem xs={12}>
                                    <h3 style={{fontWeight: 900}}>Strefa organizatora</h3>
                                    <h6>
                                        Dodawaj, edytuj i sprawdzaj listę zapisanych na swoich
                                        zajęciach
                                    </h6>
                                </GridItem>
                                <GridContainer justifyItems="center">
                                    <GridItem xs={12} sm={6} md={6} lg={4}>
                                        <CreatedActivities {...this.props} />
                                    </GridItem>
                                </GridContainer>
                                <GridItem xs={12}>
                                    <h3 style={{fontWeight: 900}}>Twój Profil</h3>
                                    <h6>Zmieniaj ustawienia profilu</h6>
                                </GridItem>
                                <GridContainer justifyItems="center">
                                    <GridItem xs={12} sm={6} md={6} lg={4}>
                                        <EditProfileCard {...this.props} />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={4}>
                                        <BenefitsCard {...this.props} />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={4}>
                                        <EditPasswordCard {...this.props} />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={4}>
                                        <SportLevelCard {...this.props} />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12} sm={12}>
                                <GridContainer>
                                    {this.state.activities && (
                                        <>
                                            <GridItem xs={12}>
                                                <h3 style={{fontWeight: 900}}>Najbliższe Zajęcia</h3>
                                                <h6>Widzimy się niedługo</h6>
                                            </GridItem>
                                            {this.state.activities.map((activity) => {
                                                return (
                                                    <GridItem xs={12} sm={6} md={4}>
                                                        <ActivityCard activity={activity} {...this.props} />
                                                    </GridItem>
                                                );
                                            })}
                                        </>
                                    )}
                                    {!this.state.activities && (
                                        <GridItem xs={12}>
                                            <h3>
                                                <b> Nie masz wykupionych zajęć </b>
                                                <h6>
                                                    Znajdź coś dla siebie <Link to="/szukaj">TUTAJ</Link>
                                                </h6>
                                            </h3>
                                        </GridItem>
                                    )}
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
                <Footer {...this.props} />
            </>
        );
    }
}

AccountPage.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(presentationStyle)(AccountPage);
