import React from "react";
import SectionNotifications from "./SectionNotifications";
import { cloneDeep } from "lodash";

class MainContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [{msg: props.data.urlParams.notification_msg, type: props.data.urlParams.notification_type}],
    };
  }

  componentDidMount() {
    window.setNotifications = this.setNotifications;
    setTimeout(() => {
      this.setState({ notifications: [] });
    }, 10000);
  }

  setNotifications = (notifications) => {
    this.setState({ notifications }, () => {
      setTimeout(() => {
        this.setState({ notifications: [] });
      }, 10000);
    });
  };

  render() {
    const props = cloneDeep(this.props);
    delete props.children;
    props.setNotifications = this.setNotifications;
    return (
      <>
        <SectionNotifications notifications={this.state.notifications} />
        {React.Children.map(this.props.children, (child) => {
          return React.cloneElement(child, props);
        })}
      </>
    );
  }
}

export default MainContainer;
