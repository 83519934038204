/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";
import Accordion from "components/Accordion/Accordion";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import pricingStyle from "assets/jss/material-kit-pro-react/views/pricingSections/pricingStyle";

function SectionDeposit(props) {
  const { classes } = props;

  return (
    <GridContainer justify="center">
      <GridItem>
        <h2 style={{ fontWeight: 900, marginTop: 40 }}>Doładowanie kaucji</h2>

        <p>
          Kaucja to ochrona przed nieobecnością, zbyt późnym wypisem z zajęć,
          nieaktywnym pakietem benefitowym np. kartą MultiSport. W sportach
          drużynowych bardzo ważną rolę odgrywa frekwencja. Nie zawiedź drużyny,
          bądź obecny na zajęciach!
        </p>
      </GridItem>
      <GridItem md={4} xs={12} sm={6}>
        <Card raised pricing color="primary">
          <CardBody pricing>
            <h6 className={classes.cardCategory}>Uzupełnij kaucję</h6>
            <h1 className={classes.cardTitleWhite}>
              <small></small>25 <small>zł</small>
            </h1>
            <ul>
              <li>
                Uzupełnij kwotę kaucji do jej pełnej wartości - 25zł. Nie pozwól
                by ominęła Cię przyjemność z gry!
              </li>
            </ul>
            <Button href="/doladowanie-kaucji" color="white" round>
              Uzupełnij kaucję
            </Button>
          </CardBody>
        </Card>
      </GridItem>

      <GridItem md={4} xs={12} sm={6}>
        <Card>
          <CardBody>
            <h6 className={classes.cardCategory}></h6>
            <h4 className={classes.cardTitle}>
              <h3>Zwrot kaucji</h3>
            </h4>
            <p>Zwróć kaucję na swój pakiet złotówek w serwisie</p>
            <Button color="primary" href="/zwrot-kaucji" round>
              Zwrot kaucji
            </Button>
          </CardBody>
        </Card>
      </GridItem>

      <GridItem>
        <Accordion
          active={5}
          activeColor="rose"
          collapses={[
            {
              title: "Jakich zajęć dotyczy Kaucja?",
              content: (
                <ul>
                  <li>
                    zajęć darmowych (wyjątek stanowią zajęcia z piłki nożnej
                    przy ul. Szaserów)
                  </li>
                  <li>
                    płatnych dla osób zapisujących się za pośrednictwem Karty
                    MultiSport
                  </li>
                  <li>zajęć Animatorów - rozliczanych gotówką na miejscu</li>
                </ul>
              ),
            },
            {
              title:
                "W portfelu kaucji możesz posiadać max. 25 zł, a min. 20 zł, w przeciwnym razie….?",
              content: (
                <ul>
                  <li>
                    Jeśli kwota Twojej kaucji spadnie poniżej minimalnej
                    wartości, tj. 20 PLN (np. poprzez rezygnację z zajęć, lub
                    brak obecności), nie będziesz mógł więcej zapisywać się na
                    zajęcia, a dodatkowo, jeśli w przeciągu 48h nie doładujesz
                    swojego portfela przynajmniej do minimalnej kwoty kaucji,
                    zostaniesz automatycznie wypisany ze wszystkich zajęć na
                    jakie byłeś zapisany z użyciem Kaucji.
                  </li>
                  <li>
                    Natomiast, jeśli do pierwszych zajęć, na które jesteś
                    zapisany z użyciem Kaucji, zostało mniej niż 48h, wówczas
                    podejmiemy próbę trzykrotnego skontaktowania się z Tobą
                    drogą mailową/telefoniczną - na dane Użytkownika widniejące
                    w naszym Serwisie - przed wypisaniem Cię ze wszystkich
                    Wydarzeń, na które jesteś zapisany z użyciem Kaucji. Jeżeli
                    podjęte próby będą nieudane, zostaniesz wypisany ze
                    Wszystkich Wydarzeń na które będziesz zapisany z użyciem
                    Kaucji.
                  </li>
                  <li>zajęć Animatorów - rozliczanych gotówką na miejscu</li>
                </ul>
              ),
            },
            {
              title: "Kiedy kaucja podlega potrąceniu?",
              content: (
                <ul>
                  <li>
                    o wartość ceny danego Wydarzenia, w przypadku odnotowania
                    przez Koordynatora lub Animatora Twojej nieobecności na
                    Wydarzeniu,
                  </li>
                  <li>
                    o wartość ceny danego Wydarzenia, w przypadku braku aktywnej
                    karty MultiSport, jeśli korzystasz z tej funkcji płatności,
                    braku rejestracji karty MultiSport w terminalu przed
                    Wydarzeniem lub braku wpisu do raportu Wizyt ( w zależności
                    od lokalizacji: wymagana jest tylko jedna forma weryfikacji:
                    terminal lub lista awaryjna).
                  </li>
                  <li>
                    {" "}
                    w części lub całości od wartości ceny danego Wydarzenia, w
                    przypadku spóźnienia na Wydarzenie zgodnie z § 11 Regulaminu
                    PNH,
                  </li>
                  <li>
                    w wysokości 5% od ceny danego Wydarzenia – jeżeli Twoja
                    rezygnacja z Wydarzenia nastąpiła wcześniej niż na 24
                    godziny przed rozpoczęciem Wydarzenia;
                  </li>
                  <li>
                    w wysokości 50% od ceny danego Wydarzenia – jeżeli Twoja
                    rezygnacja z Wydarzenia nastąpiła nie wcześniej niż na 24
                    godziny przed rozpoczęciem Wydarzenia;
                  </li>
                  <li>
                    w wysokości 70% od ceny danego Wydarzenia – jeżeli Twoja
                    rezygnacja z Wydarzenia nastąpiła nie wcześniej niż na 5
                    godzin przed rozpoczęciem Wydarzenia.
                  </li>
                </ul>
              ),
            },
          ]}
        />
      </GridItem>

      <GridItem className={classes.textCenter}>
        <h5 style={{ fontWeight: 900 }}>
          Nie znalazłeś odpowiedzi na swoje pytania?
        </h5>
        <Button color="primary" href="/s/kaucja" round>
          Czytaj więcej
        </Button>
      </GridItem>
    </GridContainer>
  );
}

SectionDeposit.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(pricingStyle)(SectionDeposit);
