import ActionCable from 'action-cable-react-jwt'

export const WsManager = (function () {
    this.AppCable = {
        consumer: null,
        channels: {}
    }

    WsManager.prototype.connect = function (channelName, functions) {
        this.channelName = channelName

        if (localStorage.getItem('token')) {
            this.AppCable.consumer = ActionCable.createConsumer(process.env.REACT_APP_WS_URL, localStorage.getItem('token').replace('Bearer ', ''))
            this.AppCable.channels[this.channelName] = this.AppCable.consumer.subscriptions.create({channel: this.channelName}, functions)
        } else {
            this.AppCable = {
                consumer: null,
                channels: {}
            }
        }
    }

    WsManager.prototype.send = function (params) {
        if( this.AppCable.channels[this.channelName]) {
            this.AppCable.channels[this.channelName].send(params);

        }else{
            window.setNotifications([
                { type: "warning", msg: "Brak połączenia z serwerem" },
            ]);
        }
    }

    WsManager.prototype.unsubscribe = function () {
        if( this.AppCable.channels[this.channelName]) {
            this.AppCable.channels[this.channelName].unsubscribe();
        }
    }

    WsManager.prototype.disconnect = function () {
        if(this.AppCable.consumer){
            this.AppCable.consumer.disconnect()
        }
    }
})