import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";

export const UsersFunctions = {
  defaultValues: (props) => {
    return {
      id: props.activityID,
      activity: props.activity,
      should_update_verification: false,
      benefit_paid_participants_verified: props.activity.benefit_paid_participants_verified,
      data: [],
      afterSave: () => {},
    };
  },
};

export const ParticipantsForm = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...UsersFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);

    if (values.should_update_verification) {
      Api.activities.update(
        values.id,
        { benefit_paid_participants_verified: values.benefit_paid_participants_verified }
        ).then(response => {
          if (!response.ok) {
            formikProps.props.setNotifications([
              { type: "alert", msg: "Coś poszło nie tak" },
            ]);
          }
        })
    } else {
      Api.activities.check_list(values.id).then((response) => {
        formikProps.setFieldValue("activity", response.data)
        Api.activities.participants
          .index(values.id, { limit: 1000 })
          .then((r) => {
            if (r.ok) {
              formikProps.setFieldValue("data", r.data.rows);
            } else {
              formikProps.props.setNotifications([
                { type: "alert", msg: "Coś poszło nie tak" },
              ]);
            }
          });
        formikProps.props.setNotifications([
          { type: "success", msg: "Lista zostanie zatwierdzona za 12h" },
        ]);
        formikProps.props.afterSave();
      });
    }
  },
})(Form);
