import React from "react";
import { Api } from "PNH/services/Api";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import formRegular from "PNH/assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "PNH/assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "PNH/assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import { Functions } from "./index";

import CustomInput from "components/CustomInput/CustomInput";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from "@material-ui/core/Box";

const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
class Form extends React.Component {
  state = {
    deleteModal: false,
  };

  constructor(props) {
    super(props);
    this.fetch();
  }
  deleteModal = (open) => {
    this.setState({ deleteModal: open });
  };

  fetch = () => {
    if (this.props.activityID) {
      Api.activities.show(this.props.activityID).then((response) => {
        if (response.ok) {
          this.props.setValues({
            ...Functions.defaultValues(this.props),
            ...response.data,
          });
        } else {
          window.setNotifications([
            { type: "alert", msg: "Coś poszło nie tak" },
          ]);
        }
      });
    }
  };
  render() {
    const {
      classes,
      values,
      errors,
      handleChange,
      setFieldValue,
      handleSubmit,
    } = this.props;
    return (
      <>

        <Card>
          <CardBody>
            <p>
              <GridContainer>
                  <GridItem xs={12}>
                      <h3>Raport obecności</h3>
                  </GridItem>
                <GridItem xs={12}>
                  <CustomInput
                      labelText="Ilość osób na zajęciach"
                      error={errors.all_participants_count}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: values.verified_by_admin,
                        type: "number",
                        value: values.all_participants_count,
                        name: "all_participants_count",
                        onChange: handleChange,
                        endAdornment: <InputAdornment position="end">osób</InputAdornment>
                      }}
                  />
                </GridItem>
                  <GridItem xs={12} style={{marginTop: "30px"}}>
                     <h6>Ilość opłat</h6>
                  </GridItem>
                <GridItem xs={4}>
                  <CustomInput
                      labelText="Dotpay"
                      error={errors.online_paid_participants_count}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: values.verified_by_admin,
                        type: "number",
                        value: values.online_paid_participants_count,
                        name: "online_paid_participants_count",
                        onChange: handleChange,
                        endAdornment: <InputAdornment position="end">osób</InputAdornment>
                      }}
                  />
                </GridItem>
                <GridItem xs={4}>
                  <CustomInput
                      labelText="Gotówka:"
                      error={errors.cash_paid_participants_count}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: values.verified_by_admin,
                        type: "number",
                        value: values.cash_paid_participants_count,
                        name: "cash_paid_participants_count",
                        onChange: handleChange,
                        endAdornment: <InputAdornment position="end">osób</InputAdornment>
                      }}
                  />
                </GridItem>
                <GridItem xs={4}>
                  <CustomInput
                      labelText="MultiSport"
                      error={errors.benefit_paid_participants_count}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: values.verified_by_admin,
                        type: "number",
                        value: values.benefit_paid_participants_count,
                        name: "benefit_paid_participants_count",
                        onChange: handleChange,
                        endAdornment: <InputAdornment position="end">osób</InputAdornment>
                      }}
                  />
                </GridItem>
                  <GridItem xs={4}>
                      <CustomInput
                          labelText="Mdedicover Sport"
                          error={errors.ok_system_paid_participants_count}
                          formControlProps={{
                              fullWidth: true,
                          }}
                          inputProps={{
                              disabled: values.verified_by_admin,
                              type: "number",
                              value: values.ok_system_paid_participants_count,
                              name: "ok_system_paid_participants_count",
                              onChange: handleChange,
                              endAdornment: <InputAdornment position="end">osób</InputAdornment>
                          }}
                      />
                  </GridItem>
                <GridItem xs={4}>
                    <CustomInput
                        labelText="FitProfit"
                        error={errors.fitprofit_paid_participants_count}
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            disabled: values.verified_by_admin,
                            type: "number",
                            value: values.fitprofit_paid_participants_count,
                            name: "fitprofit_paid_participants_count",
                            onChange: handleChange,
                            endAdornment: <InputAdornment position="end">osób</InputAdornment>
                        }}
                    />
                </GridItem>

                  <GridItem xs={12}>
                      <h4>Zaznacza administrator</h4>
                  </GridItem>
              </GridContainer>

              <FormControlLabel
                  control={
                    <Checkbox
                        disabled
                        tabIndex={-1}
                        checked={values['verified_by_admin']}
                        onClick={() => setFieldValue('verified_by_admin', !values['verified_by_admin'])}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label={'Zweryfikowane'}
              />
              <FormControlLabel
                  control={
                    <Checkbox
                        disabled
                        tabIndex={-1}
                        checked={values['benefit_paid_participants_verified']}
                        onClick={() => setFieldValue('benefit_paid_participants_verified', !values['benefit_paid_participants_verified'])}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label={'Zgodne z raportem MultiSport'}
              />
                <FormControlLabel
                    control={
                        <Checkbox
                            disabled
                            tabIndex={-1}
                            checked={values['fitprofit_paid_participants_verified']}
                            onClick={() => setFieldValue('fitprofit_paid_participants_verified', !values['fitprofit_paid_participants_verified'])}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                            }}
                        />
                    }
                    classes={{
                        label: classes.label,
                        root: classes.labelRoot,
                    }}
                    label={'Zgodne z raportem fitprofit'}
                />
                {!values.verified_by_admin && <Box display="flex" justifyContent="flex-end">
                <Button
                    color="primary"
                    onClick={handleSubmit}
                >
                  Zapisz
                </Button>
              </Box> }
            </p>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default withStyles(styles)(Form);
