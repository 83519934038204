import React from "react";
import { Api } from "PNH/services/Api";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import { UsersFunctions } from "./index";
import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";
import CloseIcon from "@material-ui/icons/Close";
import Primary from "components/Typography/Primary";
import formRegular from "PNH/assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "PNH/assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "PNH/assets/jss/material-dashboard-pro-react/views/notificationsStyle";

import avatar from "PNH/assets/img/placeholder.jpg";
import PlayersTable from "./Table";
const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
class Form extends React.Component {
  state = {
    deleteModal: false,
  };

  constructor(props) {
    super(props);
    this.fetch();
  }
  deleteModal = (open) => {
    this.setState({ deleteModal: open });
  };

  fetch = () => {
    if (this.props.id) {
      Api.competition_groups.show(this.props.id).then((response) => {
        if (response.ok) {
          this.props.setValues({
            ...UsersFunctions.defaultValues(this.props),
            ...response.data,
          });
        } else {
          window.setNotifications([
            { type: "alert", msg: "Coś poszło nie tak" },
          ]);
        }
      });
    }
  };
  render() {
    const {
      classes,
      values,
      errors,
      handleChange,
      setFieldValue,
      handleSubmit,
    } = this.props;
    return (
      <GridContainer>

        <GridItem xs={12}>
          <h6>Lista graczy ({values.users.length}/{values.group.max_group_size})</h6>
          <GridContainer jusify="space-between">
            <GridItem xs={12} md={9}>
              <AsyncSelector
                apiMethod={(q) =>
                  Api.users.admin.index({
                    ...q,
                  })
                }
                disabled={values.users.length >= values.group.max_group_size}
                labelKey="label"
                label="Zaproś zgraczy"
                name="user"
                value={
                  values.user && {
                    id: values.user.id,
                    label: values.user.label,
                  }
                }
                onChange={(value, data) => {
                  setFieldValue("user", data);
                  setFieldValue("user_id", value);
                }}
              />
            </GridItem>
            <GridItem xs={12} md={3}>
              <Button
                color="primary"
                fullWidth
                disabled={!values.user_id || values.users.length >= values.group.max_group_size}
                onClick={async (e) => {
                  await setFieldValue("action", "add");
                  handleSubmit(e);
                }}
              >
                Zaproś do grupy
              </Button>
            </GridItem>

            <GridItem xs={12}>
              <PlayersTable id={values.id} key={`p-${values.users.length}`} admin={values.group.role === "admin"} usersFunctions={UsersFunctions} formik={this.props}/>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(Form);
