import React from "react";

import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import Button from "../../../../components/CustomButtons/Button";
import Accordion from "../../../../components/Accordion/Accordion";


const Step3 = React.forwardRef((props, ref) => {
    React.useImperativeHandle(ref, () => ({
        isValidated: () => {
            return isValidated();
        },
        sendState: () => {
            return sendState();
        },
        state: {},
    }));
    const sendState = () => {
        return {};
    };

    const isValidated = () => {
        return true;
    };
    return <>
        <h6>
            Zanim zaczniesz zapoznaj się z najczęściej zadawanymi pytaniami.
        </h6>
        <Accordion
            active={[]}
            activeColor="primary"
            collapses={
                [{
                    title: "Programy benefitowe",
                    content: <GridContainer>
                        <GridItem xs={12}>
                            Aby skorzystać z zapisów na zajęcia poprzez partnerów benefitowych, tj. MultiSport,
                            FitProfit czy Medicover, uzupełnij wymagane dane w swoim profilu lub kliknij przycisk poniżej.
                        </GridItem>
                        <GridItem xs={12} style={{textAlign: 'center'}}>
                            <Button color={'primary'} round={true} target={'_blank'} href={'/benefits-settings'}>Programy
                                benefitowe</Button>
                        </GridItem>
                    </GridContainer>
                }, {
                    title: "Organizacja zajęć",
                    content: <GridContainer>
                        <GridItem xs={12}>
                            Jeśli jesteś osobą, która organizuje zajęcia sportowe, wynajmuje i opłaca obiekty sportowe,
                            oraz koordynuje wydarzenia. Jeśli ułatwiasz uprawianie sportów drużynowych w gronie znajomych
                            czy w ramach "pracowniczej piłki". Jesteś tym, kto pragnie zaoszczędzić czas, uniknąć stresów
                            i oszczędzić pieniądze związane z organizacją treningów, jednocześnie zwiększając szanse na
                            pełny skład uczestników na miejscu!
                        </GridItem>
                        <GridItem xs={12} style={{textAlign: 'center', marginTop: '15px'}}>
                            <Button color={'primary'} round={true} target={'_blank'}
                                    href={'/s/multisport-sporty-druzynowe-projekt-organizator'}>Zostań
                                organizatorem z PNH</Button>
                        </GridItem>
                    </GridContainer>
                },
                    {
                        title: "Poziomy zaawansowania na zajęciach",
                        content: <GridContainer>
                            <GridItem xs={12}>
                                Przed zapisaniem się na zajęcia, upewnij się, że wybierasz poziom zgodny z Twoimi umiejętnościami w danej dyscyplinie sportowej.
                                Każde wydarzenie precyzyjnie określa poziom gry w opisie. Zachęcamy do konstruktywnej oceny własnego poziomu i korzystania z naszych tabel,
                                aby zapewnić satysfakcję i radość z gry zarówno Tobie, jak i innym Graczom.
                            </GridItem>
                            <GridItem xs={12} style={{textAlign: 'center', marginTop: '15px'}}>
                                <Button color={'primary'} round={true} target={'_blank'}
                                        href={'s/poziom-sportowy'}>Sprawdź poziom</Button>
                            </GridItem>
                        </GridContainer>
                    },
                    {
                        title: "Gdzie szukać pomocy?",
                        content: <GridContainer>
                            <GridItem xs={12}>
                                Odpowiedzi na najważniejsze pytania i
                                pomoc dotyczącą korzystania z naszych usług, znajdziesz w zakładce pomoc.
                            </GridItem>
                            <GridItem xs={12} style={{textAlign: 'center', marginTop: '15px'}}>
                                <Button color={'primary'} round={true} target={'_blank'}
                                        href={'/s/pomoc'}>Pomoc</Button>
                            </GridItem>
                            <GridItem xs={12}>
                                A jeśli masz
                                jakiekolwiek pytania lub
                                potrzebujesz dodatkowej pomocy, jesteśmy dostępni telefonicznie, mailowo lub za pomocą
                                czatu.
                            </GridItem>
                            <GridItem xs={12} style={{textAlign: 'center', marginTop: '15px'}}>
                                <Button color={'primary'} round={true} target={'_blank'}
                                        href={'/s/kontakt'}>Kontakt</Button>
                            </GridItem>
                        </GridContainer>
                    },

                ]
            }
        />
        <GridContainer>
            <GridItem xs={12} style={{textAlign: 'center', marginTop: '15px'}}>
                <Button color={'primary'} round={true} target={'_blank'}
                        href={'/szukaj'}>Szukaj zajęć</Button>
            </GridItem>
            <GridItem xs={12} style={{textAlign: 'center', marginTop: '15px'}}>
                <Button color={'primary'} simple={true} round={true} onClick={() => window.location.reload()}>Przejdź do
                    portalu</Button>
            </GridItem>
        </GridContainer>
    </>
});

Step3.displayName = "Step3";

export default Step3;