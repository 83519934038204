import React, {useEffect} from "react";
import {Api} from "PNH/services/Api";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import {makeStyles} from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import withStyles from "@material-ui/core/styles/withStyles";
import {GroupType} from "./components/GroupType";
import {dangerColor} from "assets/jss/material-kit-pro-react"
import {Functions} from "./index";

const useStyles = makeStyles(basicsStyle);


export const PrivateIcon = withStyles(theme => ({
    root: {
        color: "#1ad1a2",
        fontSize: '24px'
    },
}))(LockOutlinedIcon);

export const PrivateIconBox = withStyles(theme => ({
    root: {
        backgroundColor: "rgba(98,206,165,0.17)",
        width: '44px',
        height: '44px',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}))(({classes, children}) => <div className={classes.root}>{children}</div>)


const PrivateBox = withStyles({
    root: {
        padding: "10px",
        border: "1px solid #ddd",
        margin: "0px",
    }
})(GridContainer)

export const Form = ({
                         id = null,
                         values,
                         errors,
                         handleChange,
                         setFieldValue,
                         handleSubmit,
                         data,
                         setValues,
                         ...props
                     }) => {

    const fetch = () => {
        if (values.id) {
            Api.competition_groups.show(values.id).then((response) => {
                if (response.ok) {
                   setValues({
                        ...Functions.defaultValues(props),
                        ...response.data,
                    });
                } else {
                    props.setNotifications([
                        {type: "alert", msg: "Coś poszło nie tak"},
                    ]);
                }
            });
        }
    };

    useEffect(fetch, [])

    const user = data.currentUser

    const userAllowedGroups = () => {
        const types = []
        if (user.l_create_competition_group_local_group > 0) {
            types.push("local_group")
        }
        if (user.l_create_competition_group_organizer > 0) {
            types.push("organizer")
        }
        if (user.l_create_competition_group_club > 0) {
            types.push("club")
        }
        if (user.l_create_competition_group_tournament > 0) {
            types.push("tournament")
        }
        if (user.l_create_competition_group_league > 0) {
            types.push("league")
        }
        if (user.l_create_competition_group_b2b > 0) {
            types.push("b2b")
        }
        if (user.l_create_competition_group_open_event > 0) {
            types.push("open_event")
        }
        if (user.l_create_competition_group_other > 0) {
            types.push("other")
        }
        return types
    }


    const groupsConfig = {
        local_group: {
            title: "Grupa lokalna",
            description: "Zapraszaj znajomych na wspólne zajęcia",
            limits: `Możesz stworzyć do ${user.l_create_competition_group_local_group} takich grup - Ilość osób w grupie ${user.l_max_competition_group_local_group_players} - Ilość drużyn ${user.l_max_competition_group_local_group_teams} - Rozmiar drużyn: ${user.l_max_competition_group_local_group_max_players_per_team}`,
        },
        organizer: {
            title: "Grupa organizatorska",
            description: "lorem ipsum",
            limits: `Możesz stworzyć do ${user.l_create_competition_group_organizer} takich grup - Ilość osób w grupie ${user.l_max_competition_group_organizer_players} - Ilość drużyn ${user.l_max_competition_group_organizer_teams} - Rozmiar drużyn: ${user.l_max_competition_group_organizer_max_players_per_team}`,
        },
        club: {
            title: "Grupa klubowa",
            description: "lorem ipsum",
            limits: `Możesz stworzyć do ${user.l_create_competition_group_club} takich grup - Ilość osób w grupie ${user.l_max_competition_group_club_players} - Ilość drużyn ${user.l_max_competition_group_club_teams} - Rozmiar drużyn: ${user.l_max_competition_group_club_max_players_per_team}`,
        },
        tournament: {
            title: "Grupa turniejowa",
            description: "lorem ipsum",
            limits: `Możesz stworzyć do ${user.l_create_competition_group_tournament} takich grup - Ilość osób w grupie ${user.l_max_competition_group_tournament_players} - Ilość drużyn ${user.l_max_competition_group_tournament_teams} - Rozmiar drużyn: ${user.l_max_competition_group_tournament_max_players_per_team}`,
        },
        league: {
            title: "Grupa ligowa",
            description: "lorem ipsum",
            limits: `Możesz stworzyć do ${user.l_create_competition_group_league} takich grup - Ilość osób w grupie ${user.l_max_competition_group_league_players} - Ilość drużyn ${user.l_max_competition_group_league_teams} - Rozmiar drużyn: ${user.l_max_competition_group_league_max_players_per_team}`,
        },
        b2b: {
            title: "Grupa kliencka",
            description: "lorem ipsum",
            limits: `Możesz stworzyć do ${user.l_create_competition_group_b2b} takich grup - Ilość osób w grupie ${user.l_max_competition_group_b2b_players} - Ilość drużyn ${user.l_max_competition_group_b2b_teams} - Rozmiar drużyn: ${user.l_max_competition_group_b2b_max_players_per_team}`,
        },
        open_event: {
            title: "Grupa społeczna",
            description: "lorem ipsum",
            limits: `Możesz stworzyć do ${user.l_create_competition_group_open_event} takich grup - Ilość osób w grupie ${user.l_max_competition_group_open_event_players} - Ilość drużyn ${user.l_max_competition_group_open_event_teams} - Rozmiar drużyn: ${user.l_max_competition_group_open_event_max_players_per_team}`,
        },
        other: {
            title: "Grupa",
            description: "lorem ipsum",
            limits: `Możesz stworzyć do ${user.l_create_competition_group_other} takich grup - Ilość osób w grupie ${user.l_max_competition_group_other_players} - Ilość drużyn ${user.l_max_competition_group_other_teams} - Rozmiar drużyn: ${user.l_max_competition_group_other_max_players_per_team}`,
        },
    }

    const allowedGroups = values.id ? [values.competition_group_type] : userAllowedGroups()

    const classes = useStyles();

    return <form onSubmit={handleSubmit}>
        <GridContainer>
            <GridItem xs={12}>
                <h3>
                    <b>{values.id ? "Edytuj" : "Stwórz"} grupę</b>
                </h3>
            </GridItem>


            <GridItem xs={12} style={{marginTop: '30px'}}>
                <h4>
                    <b>Szczegóły</b>
                </h4>

            </GridItem>
            <GridItem xs={12}>
                <CustomInput
                    labelText="Nazwa"
                    error={errors.name}
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        multiline: true,
                        value: values.name,
                        name: "name",
                        onChange: handleChange,
                    }}
                />
            </GridItem>
            <GridItem xs={12}>
                <CustomInput
                    labelText="Opis"
                    error={errors.description}
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        multiline: true,
                        value: values.description,
                        name: "description",
                        onChange: handleChange,
                    }}
                />
            </GridItem>
            <GridItem xs={12} style={{marginTop: '30px'}}>
                <h4>
                    <b>Prywatność</b>
                </h4>
            </GridItem>
            <GridItem xs={12}>
                <PrivateBox>
                    <GridItem xs={2} md={1}><PrivateIconBox> <PrivateIcon/></PrivateIconBox></GridItem>
                    <GridItem xs={7} md={8}>
                        <h5 style={{marginTop: '0', marginBottom: '-5px'}}>
                            <b>Grupa prywatna</b>
                        </h5>
                        <small> Nie możesz zobaczyć rzeczy w środku, ale możesz wyszukać</small>
                    </GridItem>
                    <GridItem xs={3} style={{justifyContent: "flex-end", display: "flex"}}>
                        <FormControlLabel
                            control={
                                <Switch
                                    color={"primary"}
                                    checked={values.private}
                                    onChange={event => setFieldValue("private", event.target.checked)}
                                    value="checkedA"
                                    classes={{
                                        switchBase: classes.switchBase,
                                        checked: classes.switchChecked,
                                        thumb: classes.switchIcon,
                                        track: classes.switchBar
                                    }}
                                />
                            }
                            classes={{
                                label: classes.label,
                                root: classes.labelRoot
                            }}
                            label=""
                        /></GridItem>
                </PrivateBox>
            </GridItem>
            <GridItem xs={12} style={{marginTop: '30px'}}>
                <h4>
                    {errors.competition_group_type && <b style={{color: dangerColor[0]}}>Rodzaj grupy</b>}
                    {!errors.competition_group_type && <b>Rodzaj grupy</b>}

                </h4>
            </GridItem>
            {allowedGroups.map((key) => {
                return <GroupType id={key} title={groupsConfig[key]?.title} description={groupsConfig[key]?.description}
                                  limits={groupsConfig[key]?.limits} setFieldValue={setFieldValue} values={values}/>
            })}


        </GridContainer>
        <GridContainer justify="space-between">
            <GridItem xs={12} sm style={{textAlign: "center", marginTop: '50px'}}>
                <Button
                    type="submit"
                    color="primary"
                    round={true}
                    disabled={props.isSubmitting}
                >
                    {values.id && "Zapisz zmiany"}
                    {!values.id && "Stwórz grupę"}
                </Button>
            </GridItem>
            <GridItem xs={12}>
                <hr/>
            </GridItem>
        </GridContainer>
    </form>
}



