import React from "react";
import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
  value: Yup.number()
    .max(25, "Maksymalna kwota doładowania 25zł")
    .positive( "Na Twoim koncie znajduję się maksymalna kwota kaucji")
    .required("Pole Wymagane"),
  terms: Yup.string().required("Pole Wymagane"),
});

export default ValidationSchema;
