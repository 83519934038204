
import React, { useEffect, useState } from "react";
import { BuilderComponent, builder, useIsPreviewing } from "@builder.io/react";

// Put your API key here
builder.init(process.env.REACT_APP_BUILDER_KEY);

// set whether you're using the Visual Editor,
// whether there are changes,
// and render the content if found
export default function CatchAllRoute() {
    const [builderContentJson, setBuilderContentJson] = useState(null)

    useEffect(() => {
        builder.get('page', { url: window.location.pathname })
            .promise().then(setBuilderContentJson)
    }, [])

    return <BuilderComponent model="page" content={builderContentJson} />
}