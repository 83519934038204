import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";
import moment from "moment";

export const ActivitiesFunctions = {
  create: (values, formik) => {
    return Api.activities.create({ activity: values }).then((response) => {
      if (response.ok) {
        window.location.href = `/zajecia-edycja/${response.data.id}?notification_msg=Dodano&notification_type=success`;
      } else {
        formik.setNotifications([
          { type: "alert", msg: "Nie udało się zapisać zmian" },
        ]);
      }
    });
  },
  update: (values, formik) => {
    return Api.activities
      .update(values.id, { activity: values })
      .then((response) => {
        if (response.ok) {
          window.setNotifications([{ type: "success", msg: "Zapisano" }]);
        } else {
          window.setNotifications([
            { type: "alert", msg: "Nie udało się zapisać zmian" },
          ]);
        }
      });
  },
  delete: (formik) => {
    return Api.activities.delete(formik.values.id).then((response) => {
      if (response.ok) {
        window.location.href = `/moje-zajecia/?notification_msg=Usunięto&notification_type=success`;
      } else {
        window.setNotifications([
          { type: "alert", msg: "Nie udało się usunąć" },
        ]);
      }
    });
  },

  defaultValues: (props) => {
    const defaultValues = {
      id: props.match.params.id || "",
      localization_id: "",
      location_object: "",
      sport_id: "",
      name: "",
      description: "",
      hidden: "",
      price: "",
      notes: "",
      date: "",
      publish_date: moment().format("YYYY-MM-DD H:m"),
      lv: "",
      gender: "",
      localizations: "",
      reserved_spots: 0,
      max_players: 0,
      min_players: 0,
      creator_id: props.data.currentUser.id,
      sports: [],
      start_at: "",
      stop_at: "",
      baner_image: "",
      thumb_image: "",
      rental_price: '',
      terms: false,
      afterSave: () => {},
    };
    return { ...defaultValues, ...props.defaults };
  },
};

export const ActivitiesForm = withFormik({
  validationSchema: validationSchema,
  enableReinitialize: false,
  mapPropsToValues: (props) => {
    return { ...ActivitiesFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
    if (!values.id) {
      ActivitiesFunctions.create(values, formikProps)
        .then((response) => {
          values.afterSave(response);
          formikProps.setFieldValue("afterSave", () => {});
        })
        .then(() => {
          formikProps.setTouched({});
          formikProps.setSubmitting(false);
        });
    } else {
      ActivitiesFunctions.update(values, formikProps)
        .then((response) => {
          values.afterSave(response);
          formikProps.setFieldValue("afterSave", () => {});
        })
        .then(() => {
          formikProps.setTouched({});
          formikProps.setSubmitting(false);
        });
    }
  },
})(Form);
