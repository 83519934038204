import React from "react";
import notificationsModal from "PNH/assets/jss/material-dashboard-pro-react/modalStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import {Api} from "PNH/services/Api";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import customCheckboxRadioSwitch from "PNH/assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";

const style = {
    ...customCheckboxRadioSwitch,
    ...notificationsModal,
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class CoordinatorModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            coordinatorModal: true,
            terms: false,
        };
    }

    coordinatorModal = (open) => {
        this.setState({coordinatorModal: open});
    };

    renderModal = () => {
        const {classes} = this.props;

        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal,
                }}
                open={this.state.coordinatorModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.coordinatorModal(false)}
            >



                <DialogTitle
                    id="signup-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        style={{ position: "absolute", color: '#999', top: "-5px", right: "0px" }}
                        onClick={() => this.coordinatorModal(false)}
                    >
                        <Close className={classes.modalClose} />
                    </Button>

                    <div  style={{textAlign: "center"}}>
                        <h3>Zostań Koordynatorem zajęć PilkaNaHali.pl</h3>
                    </div>
                </DialogTitle>

                <DialogContent className={classes.modalBody} style={{marginTop: "30px"}}>
                    <GridContainer>
                        <GridItem xs={12}>
                            Ciesz się dodatkowymi bonusami w PNH i wesprzyj nas w dążeniu do podnoszenia standardów naszych zajęć!  <br/><br/>
                            Do Twoich obowiązków należeć będzie, min.
                            <ul>
                                <li>
                                    sprawdzenie listy obecności,
                                </li>
                                <li>
                                    weryfikacja uczestników z pakietami benefitowymi i zarejestrowanie wejść w
                                    terminalu/aplikacji,
                                </li>
                                <li>
                                    pobranie klucza do szatni,
                                </li>
                                <li>
                                    dbałość o sprzęt sportowy,
                                </li>  <li>
                                reprezentowanie nas na treningu.
                                </li>
                            </ul>

                        </GridItem>
                        <GridItem xs={12}>
                            <h6>Czy chciałbyś zostać Koordynatorem na zajęciach?</h6>
                        </GridItem>
                    </GridContainer>
                </DialogContent>
                <DialogActions
                    className={classes.modalFooter + " " + classes.modalFooterCenter}
                >
                    <GridContainer>
                        <GridItem xs={12} style={{textAlign: "center"}}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        tabIndex={-1}
                                        checked={this.state.terms}
                                        onClick={() => this.setState({terms: !this.state.terms})}
                                        checkedIcon={<Check className={classes.checkedIcon}/>}
                                        icon={<Check className={classes.uncheckedIcon}/>}
                                        classes={{
                                            checked: classes.checked,
                                            root: classes.checkRoot,
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.label,
                                    root: classes.labelRoot,
                                }}
                                label={
                                    <span> Zapoznałem się z {" "}<a
                                            href="/s/kim-jest-koordynator"
                                            target="_blank"
                                        >obowiązkami i bonusami</a>. *
                                    </span>
                                }
                            />
                            <Button
                                onClick={() => {
                                    Api.activities.coordinators
                                        .create(this.props.activity.id)
                                        .then(({ok}) => {
                                            if (ok) {
                                                window.setNotifications([
                                                    {
                                                        type: "success",
                                                        msg: "Dziękujemy. Jesteś Koordynatorem zajęć",
                                                    },
                                                ]);
                                                this.setState({coordinatorModal: false}, () => {
                                                    setTimeout(this.props.afterSave, 3000);
                                                });
                                            } else {
                                                this.coordinatorModal(false);
                                                window.setNotifications([
                                                    {
                                                        type: "alert",
                                                        msg: "Brak uprawnień",
                                                    },
                                                ]);
                                            }
                                        });
                                }}
                                round={true}
                                color="primary"
                                disabled={!this.state.terms}
                                className={
                                    classes.modalSmallFooterFirstButton +
                                    " " +
                                    classes.modalSmallFooterSecondButton
                                }
                            >
                                Zostaje koordynatorem zajęć
                            </Button>
                        </GridItem>
                        <GridItem xs={12} style={{textAlign: "center"}}>
                            <Button
                                onClick={() => {
                                    this.coordinatorModal(false);
                                    localStorage.setItem(
                                        `ac_${this.props.data.currentUser.id}${this.props.activity.id}`,
                                        false
                                    );
                                }}
                                color="transparent"
                                className={classes.modalSmallFooterFirstButton}
                            >
                                Zamknij
                            </Button>
                        </GridItem>
                    </GridContainer>

                </DialogActions>
            </Dialog>
        );
    };

    render() {
        if (!this.props.activity || !this.props.data.currentUser || this.props.activity.past) {
            return null;
        }
        const show = localStorage.getItem(
            `ac_${this.props.data.currentUser.id}${this.props.activity.id}`
        );

        if (
            this.props.activity.id &&
            this.props.activity.coordinators.length === 0 &&
            this.props.activity.bought &&
            this.props.data.currentUser.coordinator &&
            show === null
        ) {
            return <>{this.renderModal()}</>;
        } else {
            return null;
        }
    }
}

export default withStyles(style)(CoordinatorModal);
