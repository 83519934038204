import React from "react";
import ActivityCardFull from "./ActivityCardFull";
import ActivityCardHidden from "./ActivityCardHidden";

const ActivityCard = ({activity, ...props}) => {
    if (activity.full_model) {
        return <ActivityCardFull activity={activity} {...props} />
    }

    if (!activity.hidden ) {
        return <ActivityCardFull activity={activity} {...props} />
    }

    return <ActivityCardHidden activity={activity} {...props} />
}

export default ActivityCard