/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// react component used to create nice image meadia player
import ImageGallery from "react-image-gallery";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {withRouter, Link} from 'react-router-dom';
// @material-ui/icons

// core components
import Header from "PNH/components/Header/Header";

import Parallax from "components/Parallax/Parallax";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import Accordion from "components/Accordion/Accordion";

import {Footer} from "PNH/layouts/Footer";

import productStyle from "assets/jss/material-kit-pro-react/views/productStyle";

import {Api} from "PNH/services/Api";
import CoordinatorModal from "./CoordinatorModal/index";
import ListAltIcon from "@material-ui/icons/ListAlt";
import {I18n} from "PNH/components/I18n"
import PageViewer from "PNH/components/Pages/Viewer";
import Tooltip from "@material-ui/core/Tooltip";
import VerifiedIcon from '@material-ui/icons/VerifiedUser';
import Primary from "components/Typography/Primary";
import {SimilarActivitiesSlider} from "./ModalSteps/SimilarActivities";
import {SportLevelOpinionForm} from "./AddOpinionForm";
import SectionCarousel from "./sections/Images";
import {PricingBox} from "./PricingBox";


const addToWatched = (id) => {
    const lc = localStorage.getItem('lastWatched')
    const lastWatched = !!lc ? lc.split(',') : []
    if (id) {
        if (!lastWatched.includes(id.toString())) {
            lastWatched.unshift(id);
            localStorage.setItem('lastWatched', lastWatched.slice(0, 6).join(','))
        }
    }
}

class ActivityPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            colorSelect: "0",
            sizeSelect: "0",
            activity: {},
            activities: [],
            fetch: "1",
        };

    }

    handleSelect = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.load();
        }
    }


    load = () => {
        const params = new URLSearchParams(this.props.location.search);
        const with_id = params.get('with_id');
        if (!with_id) {
            const params = Object.values({
                ...this.props.data.urlParams,
                ...{controller: "", action: "", module: ""},
            });
            this.props.history.push("/szukaj?with_tags=" + params.join(" "))
        } else {
            this.fetch();
        }
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }

    fetch = () => {
        const params = new URLSearchParams(this.props.location.search);
        const with_id = params.get('with_id');
        const access_token = params.get('access_token') || "";
        if (!!with_id && (!this.state.activity.id && this.state.activity.id != with_id)) {
            Api.activities.show(with_id, access_token)
                .then(({data, ok}) => {
                    if (ok) {
                        this.setState({activity: data}, () => {
                            addToWatched(data.id)
                        });
                    } else {
                        window.location.href = '/404'
                    }
                });
        }
    };

    list = () => {
        const {activity} = this.state
        if (!this.state.activity.id) {
            return null;
        }

        let list = Array(this.state.activity.reserved_spots).fill(
            "Miejsce Zarezerwowane"
        );
        return (<>
            <ul style={{listStyleType: "decimal"}}>
                {list.map((r) => {
                    return <li>{r}</li>;
                })}
                {this.state.activity.activity_participants.map((r) => {
                    const userLevel = r.user.user_sport_levels_attributes.filter((row) => row.sport_id == this.state.activity.sport.id)[0]
                    return <li>{r.user.label}&nbsp;{userLevel && userLevel.verified && userLevel.level == this.state.activity.lv &&
                        <Tooltip title={`Poziom zweryfikowany przez PNH`}
                                 classes={{tooltip: this.props.classes.tooltip}}>
                            <span><Primary><VerifiedIcon style={{fontSize: 13}}/></Primary></span>
                        </Tooltip>}</li>;
                })}
            </ul>
        </>);
    };


    infoSection = (activity) => {
        return <Accordion
            active={[0, 1]}
            activeColor="rose"
            collapses={[
                {
                    title: "Ogólne",
                    content: (
                        <>
                            <GridContainer
                                justfy="space-between"
                                style={{whiteSpace: "nowrap"}}
                            >
                                <GridItem xs={6} sm>
                                    <p>
                                        <b>Data:</b>
                                        <br/>
                                        {activity.date}
                                    </p>
                                </GridItem>
                                <GridItem xs={6} sm>
                                    <p>
                                        <b>Godzina: </b>
                                        <br/>
                                        {activity.start_at} - {activity.stop_at}
                                    </p>
                                </GridItem>


                                <GridItem xs={6} sm>
                                    <p>
                                        <b>Płeć: </b>
                                        <br/>
                                        {I18n.t(activity.gender || "unisex")}
                                    </p>
                                </GridItem>
                                <GridItem xs={6} sm>
                                    <p>
                                        <b>Wymagania wiekowe: </b>
                                        <br/>
                                        {activity.min_age >= 18 && activity.max_age >=100 ? "18+ lat" : <>{activity.min_age} - {activity.max_age} lat</>}

                                    </p>
                                </GridItem>
                                <GridItem xs={6} sm>
                                    <p>
                                        <b> {activity.verify_lv ? "Wymagany" : 'Zalecany'} poziom: </b>
                                        <br/>
                                        {I18n.t(activity.lv || "free")}<br/>

                                    </p>
                                </GridItem>
                                <GridItem xs={6} sm>
                                    <p>
                                        <b>Trener: </b>
                                        <br/>
                                        {activity.coach ? "Tak" : "Nie"}
                                    </p>
                                </GridItem>
                                <GridItem xs={6} sm>
                                    <p>
                                        <b>Koordynator: </b>
                                        <br/>
                                        {(activity.coordinators &&
                                                activity.coordinators.map((co) => {
                                                    return <div>{co.label}</div>;
                                                })) ||
                                            "-"}
                                    </p>
                                </GridItem>

                                {activity.competition_type == "sparing"  && <GridItem xs={6} sm>
                                    <p>
                                        <b>Rozmiar drużyn: </b>
                                        <br/>
                                        {activity.min_team_size == activity.max_team_size ? activity.max_team_size : `${activity.min_team_size} - ${activity.max_team_size}` } os.
                                    </p>
                                </GridItem>}



                                {activity.pay_by_ok_system && <GridItem xs={6} sm>
                                    <p>
                                        <b>Medicover Sport Kod: </b>
                                        <br/>
                                        {activity.ok_system_code}
                                    </p>
                                </GridItem>}


                            </GridContainer>
                        </>
                    ),
                },
                {
                    title: `${activity.competition_type == "activity" ? "Zapisani" : "Ilość drużyn" }: ${activity.canceled ? 0 : activity.all_reserved_spots
                    } /
                        ${activity.max_players} (min:${" "}
                        ${activity.min_players}) `,
                    content: <>{!activity.canceled && this.list()}</>,
                },
                {
                    title: "Lokalizacja",
                    content: (
                        <>
                            <h5>
                                <b>Obiekt:</b>
                            </h5>
                            <p>
                                {activity.location_object &&
                                    I18n.t(
                                        activity.location_object.object_type
                                    )}{" "}
                                <br/>
                                {activity.location_object &&
                                    activity.location_object.description}
                            </p>
                            <h5>
                                <b>Adres</b>
                            </h5>
                            <p>
                                {activity.localization &&
                                    activity.localization.name}{" "}
                                <br/>
                                {activity.localization &&
                                    activity.localization.street}{" "}
                                {activity.localization &&
                                    activity.localization.city}
                            </p>
                            <p>
                                {activity.localization &&
                                    activity.localization.description}
                            </p>
                        </>
                    ),
                },
            ]}
        />
    }


    render() {
        const {classes, data, refreshHeader} = this.props;
        const {activity} = this.state;
        const map = activity.localization
            ? () =>
                `https://maps.googleapis.com/maps/api/staticmap?center=${activity.localization.street}+${activity.localization.city}&zoom=13&scale=1&size=400x500&maptype=roadmap&key=AIzaSyBheXsZwKW1fvo_E-Pr7Y5fFT55dQXXKtw&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0x008def%7Clabel:%7C${activity.localization.street}+${activity.localization.city}`
            : () => {
            };
        const images = [
            {
                original: map(),
                thumbnail: map(),
            },
        ];
        if (activity.localization && activity.localization.photos) {
            activity.localization.photos.map((photo) => {
                images.push({
                    original: process.env.REACT_APP_API_URL + photo.url,
                    thumbnail: process.env.REACT_APP_API_URL + photo.url,
                });
            });
        }
        if (activity.location_object && activity.location_object.photos) {
            activity.location_object.photos.map((photo) => {
                images.push({
                    original: process.env.REACT_APP_API_URL + photo.url,
                    thumbnail: process.env.REACT_APP_API_URL + photo.url,
                });
            });
        }

        return (
            <div className={classes.productPage}>
                <Header
                    key={`ac-he-${this.state.fetch}`}
                    brand="PNH"
                    fixed
                    color="white"
                    {...this.props}
                />
                <Parallax image={process.env.REACT_APP_API_URL + activity.baner_image} className={classes.pageHeader}/>
                <div className={classNames(classes.section, classes.sectionWhite)}>
                    <div className={classes.container}>
                        <div className={classNames(classes.main, classes.mainRaised)}>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <GridContainer justfy="space-between">
                                        <GridItem xs={12} md={6}>
                                            <h3 className={classes.title} style={{marginTop: 0}}>
                                                {I18n.t(activity.competition_type)}
                                                {activity.activity_type == "organizer" && " Organizator"}
                                                {activity.activity_type == "pnh" && " PilkaNaHali.pl"}
                                            </h3>
                                            <h2 className={classes.title} style={{marginTop: 0}}>
                                                {activity.localization && activity.localization.city}, {activity.sport && activity.sport.name}{" "}
                                            </h2>
                                            <p style={{color: '#777'}}>
                                                <b>
                                                    {activity.localization && (
                                                        <>
                                                            {" "}
                                                            {activity.localization.name}{" "}
                                                            {activity.localization.city},{" "}
                                                            {activity.localization.street}{" "}
                                                        </>
                                                    )}
                                                    <br/>
                                                    {activity.date} {activity.start_at} - {activity.stop_at}

                                                </b>
                                            </p>
                                            <ImageGallery
                                                showFullscreenButton={true}
                                                showPlayButton={false}
                                                startIndex={0}
                                                items={images}
                                            />
                                            <GridContainer style={{marginTop: 20}}>
                                                <GridItem xs={12}>{
                                                    activity.past && this.props.data.currentUser && this.props.data.currentUser.id &&
                                                    <SportLevelOpinionForm currentUser={this.props.data.currentUser}
                                                                           activity_id={activity.id}
                                                                           activity={activity} {...this.props} />
                                                }</GridItem>
                                            </GridContainer>
                                        </GridItem>
                                        <GridItem
                                            sm
                                            xs={12}
                                            md={6}
                                        >

                                            {activity.id && activity.coordinated && (
                                                <Button
                                                    color="primary"
                                                    round
                                                    onClick={() => {
                                                        window.location.href = `/lista-obecnosci/${activity.id}`;
                                                    }}
                                                    key={`edit_${activity.id}`}
                                                    className={classes.actionButton}
                                                >
                                                    Sprawdź listę obecności &nbsp; <ListAltIcon
                                                    className={classes.icon}/>
                                                </Button>
                                            )}

                                            <PricingBox activity={activity} fetch={this.fetch} updateHeader={() => {
                                                this.setState({fetch: this.state.fetch + 1}, this.fetch)
                                            }} {...this.props}/>
                                            {this.infoSection(activity)}
                                        </GridItem>

                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={12}>
                                    <Accordion
                                        active={[0]}
                                        activeColor="rose"
                                        collapses={[
                                            {
                                                title: "Opis",
                                                content: (
                                                    <p style={{whiteSpace: "break-spaces"}}>
                                                        {activity.description}
                                                        {activity.page_id && activity.page &&
                                                            <PageViewer pageBody={JSON.parse(activity.page.body)}/>}
                                                    </p>
                                                ),
                                            },
                                        ]}
                                    />
                                </GridItem>
                            </GridContainer>
                        </div>
                        <div className={classes.relatedProducts}>
                            <h3 className={classNames(classes.title, classes.textCenter)}>
                                Zobacz również
                            </h3>
                            <GridContainer>
                                <GridItem xs={12} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                                    <center>
                                        {activity.id && <SimilarActivitiesSlider key={`r4-${activity.id}`}
                                                                                 activity={activity}
                                                                                 userLevel={{level: activity.lv}}
                                                                                 lgItems={3} xlItems={3}
                                                                                 {...this.props}
                                                                                 refreshHeader={refreshHeader}
                                                                                 classes={classes}/>}
                                    </center>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </div>
                </div>
                {
                    <CoordinatorModal
                        activity={activity}
                        afterSave={this.fetch}
                        data={data}
                    />
                }
                <Footer {...this.props} />
            </div>
        );
    }
}

ActivityPage.propTypes = {
    classes: PropTypes.object,
};

export default withRouter(withStyles(productStyle)(ActivityPage));
