import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import formRegular from "PNH/assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "PNH/assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "PNH/assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Datetime from "react-datetime";
import moment from "moment";
const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
class Dates extends React.Component {
  render() {
    const {
      classes,
      values,
      errors,
      handleChange,
      setFieldValue,
      handleSubmit,
    } = this.props;
    return (
      <>
        <GridContainer>
          <GridItem xs={12} md={12}>
            <h5>
              <b>Terminy</b>
            </h5>
          </GridItem>
          <GridItem xs={12} md={3}>
            <>
              <InputLabel error={!!errors.date_from} className={classes.label}>
                OD
              </InputLabel>
              <br />
              <FormControl fullWidth style={{ zIndex: 10 }}>
                <Datetime
                  utc
                  locale={moment.locale('pl')}
                  value={values.date_from}
                  dateFormat={"YYYY-MM-DD"}
                  timeFormat={false}
                  filterDate={(date) => {
                    return moment() <= date;
                  }}
                  onChange={(value) => setFieldValue("date_from", value)}
                  inputProps={{ name: "date", autocomplete: "off" }}
                />
              </FormControl>
            </>
          </GridItem>
          <GridItem xs={12} md={3}>
            <>
              <InputLabel error={!!errors.date_to} className={classes.label}>
                DO
              </InputLabel>
              <br />
              <FormControl fullWidth style={{ zIndex: 20 }}>
                <Datetime
                  utc
                  locale={moment.locale('pl')}
                  value={values.date_to}
                  dateFormat={"YYYY-MM-DD"}
                  timeFormat={false}
                  onChange={(value) => setFieldValue("date_to", value)}
                  inputProps={{ name: "date", autocomplete: "off" }}
                />
              </FormControl>
            </>
          </GridItem>

          <GridItem xs={12} md={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                error={!!errors.days}
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                Dzień
              </InputLabel>
              <Select
                multiple
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={values.week_days}
                onChange={handleChange}
                inputProps={{
                  name: "days",
                }}
              >
                {["Nd", "Pn", "Wt", "Śr", "Czw", "Pt", "Sob"].map(
                  (day, index) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={index}
                    >
                      {day}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

export default withStyles(styles)(Dates);
