import React from "react";

import GridContainer from "../../../../../../components/Grid/GridContainer";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../../../../../../components/Grid/GridItem";
import {makeStyles} from "@material-ui/core/styles";
import classNames from "classnames";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
const styles = {
    ...basicsStyle,
    container: {
        backgroundColor: "#F9F9F9",
        border: "1px transparent #ddd",
        margin: "0px",
        padding: "10px",
        marginBottom: '15px',
    },
    active: {
        border: "1px solid #1ad1a2",
    },

}


export const PrivateIcon = withStyles(theme => ({
    root: {
        color: "#1ad1a2",
        fontSize: '24px'
    },
}))(LockOutlinedIcon);

export const PrivateIconBox = withStyles(theme => ({
    root: {
        backgroundColor: "rgba(98,206,165,0.17)",
        width: '44px',
        height: '44px',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}))(({classes, children}) => <div className={classes.root}>{children}</div>)


const PrivateBox = withStyles({
    root: {
        padding: "10px",
        border: "1px solid #ddd",
        margin: "0px",
        backgroundColor: 'white',
        marginTop: '20px'
    }
})(GridContainer)


const useStyles = makeStyles(styles);


export const GroupType = ({id, title, description, limits, setFieldValue, values}) => {
    const classes = useStyles();
    const active = values.competition_group_type == id
    const containerClasses = classNames({
        [classes.container]: true,
        [classes.active]: active
    });

    return <GridItem xs={12}>
        <GridContainer className={containerClasses}>
            <GridItem xs={1}>
                <FormControlLabel
                    control={
                        <Checkbox
                            required={true}
                            tabIndex={-1}
                            checked={active}
                            onClick={() => setFieldValue("competition_group_type", id)}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                            }}
                        />
                    }
                    classes={{ label: classes.label, root: classes.labelRoot }}
                    label=""
                />
            </GridItem>
            <GridItem xs={11}>
                <h5 style={{marginTop: '0', marginBottom: '-5px'}}>
                    <b>{title}</b>
                </h5>
                <small><b>{description}</b></small><br/>
                <small>{limits}</small>
            </GridItem>
            {active && <GridItem xs={12}>
                <PrivateBox>
                    <GridItem xs={2} md={1}><PrivateIconBox> <PrivateIcon/></PrivateIconBox></GridItem>
                    <GridItem xs={7} md={8}>
                        <h5 style={{marginTop: '0', marginBottom: '-5px'}}>
                            <b>Grupa ukryta</b>
                        </h5>
                        <small> nie możesz znaleźć w wynikach wyszukiwania</small>
                    </GridItem>
                    <GridItem xs={3} style={{justifyContent: "flex-end", display: "flex"}}>
                        <FormControlLabel
                            control={
                                <Switch
                                    color={"primary"}
                                    checked={values.hidden}
                                    onChange={event => setFieldValue("hidden", event.target.checked)}
                                    value="checkedA"
                                    classes={{
                                        switchBase: classes.switchBase,
                                        checked: classes.switchChecked,
                                        thumb: classes.switchIcon,
                                        track: classes.switchBar
                                    }}
                                />
                            }
                            classes={{
                                label: classes.label,
                                root: classes.labelRoot
                            }}
                            label=""
                        /></GridItem>
                </PrivateBox>
            </GridItem>}
        </GridContainer>
    </GridItem>
}