import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import AllTransactionsTable from "./Table";
import Slide from "@material-ui/core/Slide";
import extendedTablesStyle from "PNH/assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import modalStyles from "PNH/assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { Link } from "react-router-dom";




const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class TransactionsTable extends React.Component {
  
  render() {
    const { classes } = this.props;
    return (
      <div style={{ marginTop: 75 }}>
        <GridContainer>
          <GridItem>
            <h3 style={{ fontWeight: 900, marginTop: 30 }}>
              Transakcje
              <h6>
                <Link to="/account-settings">Konto</Link> > Transakcje
              </h6>
            </h3>
          </GridItem>
          <GridItem xs={12} md={3}>
            <Card>
              <CardBody>
                <h6 className={classes.cardCategory}></h6>
                <h4 className={classes.cardTitle}>
                  <h3>Transakcje</h3>
                  <p>Tutaj znajdziesz historię swoich płatności</p>
                </h4>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} md={9}>
            <AllTransactionsTable pagination={true} {...this.props}/>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

TransactionsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles({ ...modalStyles, ...extendedTablesStyle })(
  TransactionsTable
);
