import React, {Component} from "react";
/*eslint-disable*/
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Assignment from "@material-ui/icons/Assignment";
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";
import Slide from "@material-ui/core/Slide";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CustomInput from "components/CustomInput/CustomInput";
import {Formik} from "formik";
import * as Yup from "yup";
import {Api} from "PNH/services/Api";
import {mapValues} from "lodash";

import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles";
import {FBButton} from "./FBLogin";
import {Link} from "react-router-dom";
import CompleteProfileWizard from "./CompleteProfileWizard";
import Step1 from "./CompleteProfileSteps/step1";
import Step2 from "./CompleteProfileSteps/step2";
import Step3 from "./CompleteProfileSteps/step3";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class CompleteProfileModal extends Component {
    constructor(props) {
        super(props);

        const autoOpenCompleteModal = () => {
            if(props.data.currentUser && [false, 'false'].includes(localStorage.getItem('autoAccountCompleteModal'))) {
                localStorage.setItem('autoAccountCompleteModal', true);
                return  !props.data.currentUser.account_completed
            }
        }

        this.state = {
            signupModal: autoOpenCompleteModal(),
        };
        this.props.getStateUpdates()["openCompleteAccountModal"] =  () => this.handleClickOpen('signupModal')
    }

    handleToggle(value) {
        const {checked} = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            checked: newChecked,
        });
    }

    handleClickOpen(modal) {
        var x = [];
        x[modal] = true;
        this.setState(x);
    }

    handleClose(toggle) {
        this.setState({signupModal: toggle});
    }

    handleClosePopover(state) {
        this.setState({
            [state]: false,
        });
    }

    handleClickButton(state) {
        this.setState({
            [state]: true,
        });
    }

    form() {
        const {classes, buttonClasses} = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.wizardModal,
                }}
                open={this.state.signupModal}  onClose={() => this.handleClose(false)} maxWidth={"md"} fullWidth={true} style={{padding: '10px', backgroundColor: "transparent!important"}} >
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        style={{ position: "absolute", color: '#999', top: "80px", right: "60px", zIndex: "1" }}
                        onClick={() => this.handleClose(false)}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <CompleteProfileWizard
                        color={"primary"}
                        steps={[
                            {stepName: "Profil", stepComponent: Step1, stepId: "about", title: "Uzupełnij swój profil", subtitle: "Podaj nam podstawowe dane na swój temat i ciesz się zajęciami w Twojej okolicy."},
                            {stepName: "Telefon", stepComponent: Step2, stepId: "phone", title: "Uzupełnij swój profil", subtitle: "Podaj nam podstawowe dane na swój temat i ciesz się zajęciami w Twojej okolicy."},
                            {stepName: "Instrukcja", stepComponent: Step3, stepId: "benefits", title: 'Witamy w PNH!', subtitle: "Poniżej znajdziesz wskazówki jak korzystać z portalu."},
                        ]}
                        data={this.props.data}
                        finishButtonText={""}
                        title="Uzupełnij swój profil"
                        subtitle="Podaj nam podstawowe dane na swój temat i ciesz się zajęciami w Twojej okolicy."
                        hideFinishButton={true}
                    />
            </Dialog>
        );
    }

    render() {
        return this.form();

    }
}

export default withStyles(javascriptStyles)(CompleteProfileModal);
