import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table";

import Button from "components/CustomButtons/Button";
import TablePagination from "@material-ui/core/TablePagination";
import { Api } from "PNH/services/Api";

import Slide from "@material-ui/core/Slide";
import extendedTablesStyle from "PNH/assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import modalStyles from "PNH/assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Primary from "components/Typography/Primary";
import Danger from "components/Typography/Danger";
import PageviewIcon from "@material-ui/icons/Pageview";
import { Link } from "react-router-dom";
import { I18n } from "PNH/components/I18n"

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class AllTransactionsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            page: 0,
            limit: this.props.limit || 10,
            total: 0,

            activeTransaction: false,
            resignModal: false,
        };
        this.getData();
    }
    getData = async () => {
        Api.transactions.currentUser
            .index({
                page: this.state.page,
                limit: this.state.limit,
            })
            .then((response) => {
                const tableContent = response.data.rows.map(this.mapRows);
                this.setState({ data: tableContent, total: response.data.total });
            });
    };

    cancelTransaction = async (id) => {
        Api.transactions.currentUser
            .cancelUnpaidTransaction(id)
            .then((response) => {
                if (response.ok) {
                    window.setNotifications([{ type: "success", msg: "Anulowano" }]);
                    this.getData();
                    this.setState({ activeTransaction: false })
                } else {
                    window.setNotifications([
                        { type: "alert", msg: "Nie udało się anulować transakcji" },
                    ]);
                }
                this.resignModal(false)
            });
    };

    resignModal = (open) => {
        this.setState({ resignModal: open });
    };


    mapRows = (row) => {
        const { classes } = this.props;

        return [
            <Button
                simple
                color="primary"
                onClick={() => this.setState({ activeTransaction: row })}
            >
                <b>{row.identifier}</b>
            </Button>,
            <span style={{ whiteSpace: "nowrap" }}>{row.created_at}</span>,
            I18n.t(row.transaction_type),
            I18n.t(row.payment_method),
            row.status === "paid" || row.status === "canceled" ? (
                <Primary>
                    <b>{I18n.t(row.status)}</b>
                </Primary>
            ) : (
                <Danger>
                    <b>Nieopłacone</b>
                </Danger>
            ),
            <Button
                simple
                color="github"
                onClick={() => this.setState({ activeTransaction: row })}
            >
                <PageviewIcon />
            </Button>,
        ];
    };
    changePage = (event, page) => {
        this.setState({ page: page }, this.getData);
    };

    onChangeRowsPerPage = (event) => {
        this.setState({ limit: event.target.value, page: 0 }, this.getData);
    };

    handleChange = (event) => {
        this.setState(
            { [event.target.name]: event.target.value, page: 0 },
            this.getData
        );
    };

    getPrice(bi) {
        if (bi.payment_method === "benefit" || bi.payment_method === "ok_system") {
            return bi.supplement
        }
        return bi.value
    }

    renderModal = () => {
        const { classes } = this.props;

        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal,
                }}
                open={this.state.resignModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.resignModal(false)}
            >
                <DialogTitle disableTypography className={classes.modalHeader}>
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => this.resignModal(false)}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                </DialogTitle>
                <DialogContent className={classes.modalBody} style={{ width: "100%" }}>
                    <Primary>
                        <h5>
                            <b>
                                Czy na pewno chcesz anulować transakcję?
                            </b>
                        </h5>
                    </Primary>
                </DialogContent>
                <DialogActions
                    className={classes.modalFooter + " " + classes.modalFooterCenter}
                >
                    <Button
                        onClick={() => this.resignModal(false)}
                        color="transparent"
                        className={classes.modalSmallFooterFirstButton}
                    >
                        Nie
                    </Button>
                    <Button
                        onClick={() => { this.cancelTransaction(this.state.activeTransaction.id) }}
                        color="success"
                        simple
                        className={
                            classes.modalSmallFooterFirstButton +
                            " " +
                            classes.modalSmallFooterSecondButton
                        }
                    >
                        Tak
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    renderTransaction = () => {
        const { classes } = this.props;
        const { activeTransaction } = this.state;
        return (
            <>
                <h6>
                    {" "}
                    <a href="#" onClick={(e) => {
                        e.preventDefault()
                        this.setState({ activeTransaction: "" })}}>
                        {"< Powrót"}{" "}
                    </a>
                </h6>
                <h4>
                    Transakcja <b>#{activeTransaction.identifier}</b>
                    <h6>{activeTransaction.created_at}</h6>
                </h4>

                <GridContainer style={{ fontSize: 12 }}>
                    <GridItem xs={12} md={6}>
                        <Card>
                            <CardBody>
                                <h6 className={classes.cardCategory}>Informacje</h6>
                                <GridContainer justify="space-beetwen">
                                    <GridItem xs={6}>Płatność:</GridItem>
                                    <GridItem xs={6}>
                                        {I18n.t(activeTransaction.payment_method)}
                                    </GridItem>
                                    <GridItem xs={6}>Status:</GridItem>
                                    <GridItem xs={6}>
                                        {I18n.t(activeTransaction.status)}
                                    </GridItem>
                                    <GridItem xs={6}>Typ: </GridItem>
                                    <GridItem xs={6}>
                                        {I18n.t(activeTransaction.transaction_type)}
                                    </GridItem>

                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>

                    <GridItem md={6}>
                        <GridContainer justifyContent="center" alignItems="flex-end" direction="row" style={{ height: "90%" }}>
                            <GridItem xs={6}>
                                {activeTransaction.value > 0 &&
                                    activeTransaction.status === "unpaid" && (
                                        <Button

                                            color="primary"

                                            href={`/dotpay/${activeTransaction.id}`}
                                        >
                                            {" "}
                                            Opłać transakcję
                                        </Button>
                                    )}
                            </GridItem>
                            <GridItem xs={6}>
                                {activeTransaction.value > 0 &&
                                    activeTransaction.status === "unpaid" && (
                                        <Button
                                            simple
                                            color="danger"
                                            onClick={() => { this.resignModal(true) }}
                                        >
                                            {" "}
                                            Anuluj transakcje
                                        </Button>
                                    )}
                            </GridItem>
                        </GridContainer>
                    </GridItem>

                    <GridItem xs={12}>
                        <Card>
                            <CardBody>
                                <h6 className={classes.cardCategory}>
                                    Zajęcia
                                </h6>
                                <Table
                                    striped
                                    tableHead={[
                                        <b style={{ fontSize: 12 }}>Użytkownik</b>,
                                        <b style={{ fontSize: 12 }}>Typ</b>,
                                        <b style={{ fontSize: 12 }}>Wartość</b>,
                                        <b style={{ fontSize: 12 }}>Dopłata</b>,
                                        <b style={{ fontSize: 12 }}>Kod</b>,
                                    ]}
                                    tableData={
                                        activeTransaction !== false
                                            ? activeTransaction.items.map((row) => {
                                                if (!(row.item_type === "buy_activity" || row.item_type === "resign_activity")) {
                                                    return [];
                                                };
                                                return [
                                                    `${row.user.first_name} ${row.user.last_name}`,
                                                    row.item_type === "buy_activity" ||
                                                        row.item_type === "resign_activity" ? (
                                                        <>
                                                            {I18n.t(row.item_type)}
                                                            <br />
                                                            <Link
                                                                to={
                                                                    row.targetable.hidden
                                                                        ? row.targetable.hidden_url
                                                                        : row.targetable.url
                                                                }
                                                            >
                                                                {row.targetable.label} 
                                                            </Link>
                                                        </>
                                                    ) : (
                                                        I18n.t(row.item_type)
                                                    ),
                                                    this.getPrice(row) + " PLN",
                                                    row.supplement > 0 ? 'Tak' : 'Nie',
                                                    row.transaction_item_promo_code ? row.transaction_item_promo_code.code : null
                                                ];
                                            })
                                            : []
                                    }
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12}>
                        <Card>
                            <CardBody>
                                <h6 className={classes.cardCategory}>
                                    Opłaty/Doładowania
                                </h6>
                                <Table
                                    striped
                                    tableHead={[
                                        <b style={{ fontSize: 12 }}>Użytkownik</b>,
                                        <b style={{ fontSize: 12 }}>Typ</b>,
                                        <b style={{ fontSize: 12 }}>Wartość</b>,
                                    ]}
                                    tableData={
                                        activeTransaction !== false
                                            ? activeTransaction.items.map((row) => {
                                                if ((row.item_type === "buy_activity" || row.item_type === "resign_activity")) {
                                                    return [];
                                                };
                                                return [
                                                    `${row.user.first_name} ${row.user.last_name}`,
                                                    row.item_type === "buy_activity" ||
                                                        row.item_type === "resign_activity" ? (
                                                        <>
                                                            {I18n.t(row.item_type)}
                                                            <br />
                                                            <Link
                                                                to={
                                                                    row.targetable.hidden
                                                                        ? row.targetable.hidden_url
                                                                        : row.targetable.url
                                                                }
                                                            >
                                                                {row.targetable.sport.name} -{" "}
                                                                {row.targetable.date}{" "}
                                                                {row.targetable.start_at}
                                                            </Link>
                                                        </>
                                                    ) : (
                                                        I18n.t(row.item_type)
                                                    ),
                                                    row.supplement > 0 ? row.supplement + " PLN" : row.value + " PLN",
                                                ];
                                            })
                                            : []
                                    }
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <Card>
                            <CardBody>
                                <h6 className={classes.cardCategory}>Stan Konta Przed:</h6>
                                <b>Główne:</b> {activeTransaction.account_balance_before}zł,{" "}
                                <b>Bonus:</b> {activeTransaction.account_bonus_before}zł,{" "}
                                <b>Kaucja:</b> {activeTransaction.account_deposit_before}zł
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <Card>
                            <CardBody>
                                <h6 className={classes.cardCategory}>Stan Konta Po:</h6>
                                <b>Główne:</b> {activeTransaction.account_balance_after}zł,{" "}
                                <b>Bonus:</b> {activeTransaction.account_bonus_after}zł,{" "}
                                <b>Kaucja:</b> {activeTransaction.account_deposit_after}zł
                            </CardBody>
                        </Card>
                    </GridItem>

                    <GridItem xs={12}>
                        <Card>
                            <CardBody>
                                <h6 className={classes.cardCategory}>
                                    Komentarze do transakcji:
                                    <hr />
                                </h6>
                                {activeTransaction.comments.map((row) => {
                                    return (
                                        <div key={row.id}>
                                            <p>
                                                <b>{` ${row.user.first_name} ${row.user.last_name}`}</b>{" "}
                                                <Primary>
                                                    <b style={{ fontSize: 10 }}>{row.created_at} </b>
                                                </Primary>
                                            </p>

                                            {row.comment}
                                            <hr />
                                        </div>
                                    );
                                })}
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                {this.renderModal()}
            </>
        );
    };

    renderTable = () => {
        const {pagination} = this.props
        return <>
            <Table
                tableHead={[
                    <b>Nr Transakcji</b>,
                    <b>Data</b>,
                    <b>Typ</b>,
                    <b>Płatność</b>,
                    <b>Status</b>,
                    "",
                ]}
                striped
                tableData={this.state.data}
            />
            {pagination && <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                page={this.state.page}
                rowsPerPage={this.state.limit}
                count={this.state.total}
                onChangePage={this.changePage}
                onChangeRowsPerPage={this.onChangeRowsPerPage}
                labelRowsPerPage="Ilość na stronie"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} z ${count}`}
            /> }
        </>
    };

    render() {
        const { classes } = this.props;
        const { activeTransaction } = this.state;
        return (
            <>
                {!activeTransaction && this.renderTable()}
                {activeTransaction && this.renderTransaction()}

            </>
        );
    }
}

AllTransactionsTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles({ ...modalStyles, ...extendedTablesStyle })(
    AllTransactionsTable
);
