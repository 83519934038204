
/*eslint-disable*/
import React from "react";
import Button from "components/CustomButtons/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import { I18n } from "PNH/components/I18n";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Api } from "PNH/services/Api";
import { SimilarActivitiesSlider } from "./SimilarActivities";
import { Hidden } from "@material-ui/core";

const RenderDialogWrongLevel = ({ toggleModal, nextStep, activity, classes, userLevel, afterSave = () => { }, data, refreshHeader, ...props }) => (
    <>
        
        <DialogContent className={classes.modalBody} style={{ width: "100%", paddingTop: 0 }}>
            <h3 style={{ textAlign: "center" }}><strong>Jesteś pewna/y?</strong></h3>
            <span style={{ position: 'absolute', top: '10px', right: '10px' }}>
                <Button
                    justIcon
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    color="transparent"
                    onClick={() => toggleModal(false)}
                >
                    <Close className={classes.modalClose} />
                </Button>
            </span>
            <GridContainer>
                <GridItem xs={12} style={{ textAlign: "center", marginTop: 20 }}>
                    <p>Zapisujesz się na listę rezerwowych do zajęć dla osób grających na poziomie <strong>"{I18n.t(activity.lv)}"</strong>. Swój poziom oceniłaś/eś jako <strong>“{I18n.t(userLevel?.level) || 'brak wybranego poziomu'}”</strong>.
                        Czy na pewno chcesz się zapisać na listę rezerwowych? </p>
                </GridItem>
                <GridItem xs={12} style={{ textAlign: "center", marginTop: 20 }}>
                    <Button type="submit" color="primary" link href="/s/poziom-sportowy" target='blank'>
                        Zobacz szczegółowe opisy poziomów
                    </Button>
                </GridItem>
                <GridItem xs={12}>
                    <div style={{ width: '370px', marginLeft: 'auto', marginRight: 'auto' }}>
                        <Hidden mdDown implementation="css" className={classes.hidden}>
                            <SimilarActivitiesSlider key={`r2-${activity.id}`} activity={activity} userLevel={userLevel} showItems="1" data={data} refreshHeader={refreshHeader} classes={classes} />
                        </Hidden>
                    </div>
                </GridItem>
                <GridItem xs={12} style={{ textAlign: "center", marginTop: 20 }}>
                    <Button type="submit" round color="primary" href={`/szukaj?with_sport_id=${activity.sport.id}&with_lv=${userLevel?.level}&with_tags=${activity.localization.city}`} target='blank'>
                        Zobacz inne zajęcia
                    </Button>
                </GridItem>
                <GridItem xs={12} style={{ textAlign: "center", marginTop: 20 }}>
                    <Button
                        onClick={() => {
                            Api.activities.reserveList
                                .create(activity.id)
                                .then((response) => {
                                    if (response.ok) {
                                        window.setNotifications([
                                            { type: "success", msg: "Zapisano na listę rezerwowych" },
                                        ]);
                                        afterSave();
                                        toggleModal(false);
                                    } else {
                                        toggleModal(false);
                                        window.setNotifications([
                                            {
                                                type: "alert",
                                                msg: "Nie udało się zapisać na listę rezerwowych",
                                            },
                                        ]);
                                    }
                                });
                        }}
                        color="transparent"
                        simple
                        className={
                            classes.modalSmallFooterFirstButton +
                            " " +
                            classes.modalSmallFooterSecondButton
                        }
                    >
                        Tak, zapisuję się na listę!
                    </Button>
                </GridItem>
            </GridContainer>
        </DialogContent>
        <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
        </DialogActions>
    </>
)

export function ReserveListWrongLevelStep
    (props) {
    return (
        <RenderDialogWrongLevel {...props} />
    );
}


const RenderDialog = ({ toggleModal, nextStep, activity, classes, userLevel, afterSave = () => { }, data, refreshHeader, ...props }) => (
    <>
        <DialogTitle disableTypography className={classes.modalHeader} style={{ textAlign: 'right', paddingBottom: 0 }}>
            <Button
                justIcon
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={() => toggleModal(false)}
            >
                <Close className={classes.modalClose} />
            </Button>
        </DialogTitle>
        <DialogContent className={classes.modalBody} style={{ width: "100%", paddingTop: 0 }}>
            <h3 style={{ textAlign: "center" }}><strong>Czy na pewno chcesz się zapisać na listę rezerwowych?</strong></h3>

            <GridContainer>
                <GridItem xs={12}>
                    <div style={{ width: '370px', marginLeft: 'auto', marginRight: 'auto' }}>
                        <Hidden mdDown implementation="css" className={classes.hidden}>
                            <SimilarActivitiesSlider key={`r2-${activity.id}`} activity={activity} userLevel={userLevel} showItems="1" data={data} refreshHeader={refreshHeader} classes={classes} />
                        </Hidden>
                    </div>
                </GridItem>
                <GridItem xs={12} style={{ textAlign: "center", marginTop: 20 }}>
                    <Button type="submit" round color="primary" href={`/szukaj?with_sport_id=${activity.sport.id}&with_lv=${userLevel?.level}&with_tags=${activity.localization.city}`} target='blank'>
                        Zobacz inne zajęcia
                    </Button>
                </GridItem>
                <GridItem xs={12} style={{ textAlign: "center", marginTop: 20 }}>
                    <Button
                        onClick={() => {
                            Api.activities.reserveList
                                .create(activity.id)
                                .then((response) => {
                                    if (response.ok) {
                                        window.setNotifications([
                                            { type: "success", msg: "Zapisano na listę rezerwowych" },
                                        ]);
                                        afterSave();
                                        toggleModal(false);
                                    } else {
                                        toggleModal(false);
                                        window.setNotifications([
                                            {
                                                type: "alert",
                                                msg: "Nie udało się zapisać na listę rezerwowych",
                                            },
                                        ]);
                                    }
                                });
                        }}
                        color="transparent"
                        simple
                        className={
                            classes.modalSmallFooterFirstButton +
                            " " +
                            classes.modalSmallFooterSecondButton
                        }
                    >
                        Tak, zapisuję się na listę!
                    </Button>
                </GridItem>
            </GridContainer>
        </DialogContent>
    </>
)

export function ReserveListStep
    (props) {
    return (
        <RenderDialog {...props} />
    );
}
