import {withFormik} from "formik";
import validationSchema from "./ValidationSchema";
import {Form} from "./Form";
import {Api} from "PNH/services/Api";
import moment from "moment";

export const Functions = {
    create: (values, formik) => {
        return Api.competition_groups
            .create({competition_group: values})
            .then((response) => {
                if (response.ok) {
                    window.setNotifications([
                        {type: "success", msg: "Dodano"},
                    ]);
                    let url = `/moje-grupy`;
                    formik.props.history.push(url)
                } else {
                    window.setNotifications([
                        {type: "alert", msg: "Nie udało się zapisać zmian"},
                    ]);
                }
            });
    },
    update: (values, formik) => {
        return Api.competition_groups
            .update(values.id, {competition_group: values})
            .then((response) => {
                if (response.ok) {
                    window.setNotifications([
                        {type: "success", msg: "Dodano"},
                    ]);
                    let url = `/moje-grupy/${values.id}`;
                    formik.props.history.push(url)
                } else {
                    window.setNotifications([
                        {type: "alert", msg: "Nie udało się zapisać zmian"},
                    ]);
                }
            });
    },
    delete: (formik) => {
        return Api.activity_templates.delete(formik.values.id).then((response) => {
            if (response.ok) {
                window.setNotifications([
                    {type: "success", msg: "Usunięto"},
                ]);
                // let url = `/activities`;
                // formik.history.push(url)
            } else {
                window.setNotifications([
                    {type: "alert", msg: "Nie udało się usunąć"},
                ]);
            }
        });
    },

    defaultValues: (props) => {
        console.log(props)
        const defaultValues = {
            id: props.match.params.id || null,
            name: '',
            description: '',
            competition_group_type: "",
            afterSave: () => {
            },
        };
        return {...defaultValues, ...props.defaults};
    },
};

export const CompetitionGroupsForm = withFormik({
    validationSchema: validationSchema,
    enableReinitialize: false,
    mapPropsToValues: (props) => {
        return {...Functions.defaultValues(props)};
    },
    handleSubmit: (values, formikProps) => {
        formikProps.setSubmitting(true);
        if (values.id) {
            Functions.update(values, formikProps)
                .then((response) => {
                    values.afterSave(response);
                    formikProps.setFieldValue("afterSave", () => {
                    });
                })
                .then(() => {
                    formikProps.setTouched({});
                    formikProps.setSubmitting(false);
                });
        } else {
            Functions.create(values, formikProps)
                .then((response) => {
                    values.afterSave(response);
                    formikProps.setFieldValue("afterSave", () => {
                    });
                })
                .then(() => {
                    formikProps.setTouched({});
                    formikProps.setSubmitting(false);
                });
        }

    },
})(Form);