import GridContainer from "../../../../components/Grid/GridContainer";
import {DynamicTile} from "./DynamicTiles";
import React from "react";
import {TitleSection} from "./TitleSection";
import GridItem from "../../../../components/Grid/GridItem";

export const BlogSection = ({tiles = [], section = 0, classes, data}) => {

    const sectionTiles = tiles.filter((tile) => tile.section === section)
    if(sectionTiles.length  === 0) {
        return null
    }

    const title = data.settings.find((setting) => setting.name === "title_for_section_"+section )
    return   <GridContainer alignItems="center" justify="center" style={{ marginTop: '60px' }}>
        <GridItem xs={12} sm={8} md={8} lg={8}>
            <TitleSection title={title ? title.value : ""}/>
            <GridContainer>
                <DynamicTile tiles={sectionTiles}/>
            </GridContainer>
        </GridItem>
    </GridContainer>
}