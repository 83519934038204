import React from "react";

import {UsersForm} from "../../../views/Users/EditProfile/Form";



const Step1 = React.forwardRef((props, ref) => {
    React.useImperativeHandle(ref, () => ({
        isValidated: () => {
            return isValidated();
        },
        sendState: () => {
            return sendState();
        },
        state: {},
    }));
    const sendState = () => {
        return {};
    };

    const isValidated = () => {
        return true;
    };
    return <><UsersForm modalButton={true} {...props}/></>;
});

Step1.displayName = "Step1";

export default Step1;