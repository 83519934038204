import React from "react";
import { Api } from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import classNames from "classnames";
import formRegular from "PNH/assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "PNH/assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "PNH/assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import { UsersFunctions } from "./index";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { AllowRegisterForm } from "./AllowedToRegister/index";
import { Link } from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';

const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
class Form extends React.Component {
  state = {
    deleteModal: false,
  };

  constructor(props) {
    super(props);
    this.fetch();
  }
  deleteModal = (open) => {
    this.setState({ deleteModal: open });
  };

  fetch = () => {
    if (this.props.values.id) {
      Api.users.show(this.props.values.id).then((response) => {
        if (response.ok) {
          this.props.setValues({
            ...UsersFunctions.defaultValues(this.props),
            ...{
              id: response.data.id,
              hide_last_name: response.data.hide_last_name,
              identifier: response.data.identifier,
              password: "",
              password_confirmation: "",
              facebook_uid: response.data.facebook_uid,
            },
          });
        } else {
          window.setNotifications([
            { type: "alert", msg: "Coś poszło nie tak" },
          ]);
        }
      });
    }
  };
  render() {
    const {
      classes,
      values,
      errors,
      handleChange,
      setFieldValue,
      handleSubmit,
    } = this.props;
    return (
      <GridContainer style={{ marginTop: 75 }}>
        <div className={classNames(classes.main)} style={{ padding: 15 }}>
          <GridItem xs={12} style={{ marginBottom: 0 }}>
            <GridContainer justify="center">
              <GridItem
                xs={12}
                sm={8}
                md={6}
                lg={4}
                style={{ marginBottom: 110 }}
              >
                <h3
                  style={{
                    fontWeight: 900,
                    marginBottom: 40,
                    marginTop: 30,
                  }}
                >
                  Bezpieczeństwo
                  <h6>
                    <Link to="/account-settings">Konto</Link> > Bezpieczeństwo
                  </h6>
                </h3>
                <GridContainer>
                  <GridItem xs={12}>
                    <h4
                      style={{
                        fontWeight: 900,
                        marginTop: 40,
                        marginBottom: 20,
                      }}
                    >
                      Widoczność danych osobowych
                    </h4>
                  </GridItem>

                  <GridItem xs={12}>
                    <GridItem xs={12}>
                      <CustomInput
                        noErrorIcon
                        labelText="Identyfikator Konta"
                        error={errors.identifier}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: values.identifier,
                          name: "identifier",
                          onChange: handleChange,
                          autocomplete: "new-pidentifier",
                          endAdornment: (
                              <InputAdornment position="end" className={classes.inputAdornment}>
                                <PersonIcon className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                          ),
                        }}
                      />
                    </GridItem>
                  </GridItem>

                  <GridItem xs={12}>
                    <GridItem>
                      <FormControlLabel
                        control={
                          <Checkbox
                            tabIndex={-1}
                            checked={values.hide_last_name}
                            onClick={() =>
                              setFieldValue(
                                "hide_last_name",
                                !values.hide_last_name
                              )
                            }
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot,
                        }}
                        label="Ukryj nazwisko"
                      />
                    </GridItem>
                  </GridItem>
                  <GridItem xs={12}>
                    <GridContainer >
                      <GridItem xs={12} style={{textAlign: 'center'}}>
                        <Button type="submit" color="primary" onClick={handleSubmit} round={true}>
                          Zapisz
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridItem>

                  <GridItem xs={12}>
                    <h4
                      style={{
                        fontWeight: 900,
                        marginTop: 40,
                        marginBottom: 20,
                      }}
                    >
                      Zmień hasło
                    </h4>
                  </GridItem>

                  <GridItem xs={12}>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText="Nowe Hasło"
                        noErrorIcon
                        error={errors.password}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: values.password,
                          name: "password",
                          onChange: handleChange,
                          type: "password",
                          autoComplete: "new-password",
                          endAdornment: (
                              <InputAdornment position="end" className={classes.inputAdornment}>
                                <LockIcon className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                          ),
                        }}
                      />
                    </GridItem>
                  </GridItem>
                  <GridItem xs={12}>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText="Powtórz hasło"
                        noErrorIcon
                        error={errors.password_confirmation}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: values.password_confirmation,
                          name: "password_confirmation",
                          onChange: handleChange,
                          type: "password",
                          endAdornment: (
                              <InputAdornment position="end" className={classes.inputAdornment}>
                                <LockIcon className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                          ),
                        }}
                      />
                    </GridItem>
                  </GridItem>
                  <GridItem xs={12}>
                    <GridContainer >
                      <GridItem xs={12} style={{textAlign: 'center'}}>
                        <Button type="submit" color="primary" onClick={handleSubmit} round={true}>
                          Zapisz
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12}>
                    <h4
                      style={{
                        fontWeight: 900,
                        marginTop: 40,
                        marginBottom: 20,
                      }}
                    >
                      Konta społecznościowe
                    </h4>
                  </GridItem>

                  <GridItem xs={12}>
                    <GridItem>
                      <h5 style={{ fontWeight: 900 }}>Facbook:</h5>
                    </GridItem>
                  </GridItem>
                  <GridItem xs={12}>
                    <GridItem>
                      {!values.facebook_uid && (
                        <>
                          <h6 style={{ color: "rgba(0,0,0,0.7)" }}>
                            Brak Połączenia
                          </h6>
                        </>
                      )}
                      {values.facebook_uid && (
                        <GridContainer>
                          <GridItem xs>
                            <h6 style={{ color: "rgba(0,0,0,0.7)" }}>
                              Połączono
                            </h6>
                          </GridItem>
                          <GridItem xs>
                            <Button
                              simple
                              color="primary"
                              onClick={() => {
                                setFieldValue("facebook_uid", "");
                              }}
                            >
                              Rozłącz
                            </Button>
                          </GridItem>
                        </GridContainer>
                      )}
                    </GridItem>
                  </GridItem>
                  <GridItem xs={12}>
                    <hr />
                  </GridItem>

                  <GridItem xs={12}>
                    <AllowRegisterForm {...this.props} />
                  </GridItem>

                  <GridItem xs={12}>
                    <hr />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={4} md={4} lg={2}>
                <Card>
                  <CardBody>
                    <h6 className={classes.cardCategory}></h6>
                    <h4 className={classes.cardTitle}>
                      <Link to="/edit-profile">
                        <h3>Bezpieczeństwo i prywatność</h3>
                        <p>
                          Czym jest identyfikator konta? Identyfikator służy do
                          wyszukiwania użytkowników oraz jest widoczny na liście
                          wydarzenia. Generuje się automatycznie. Można go
                          edytować. Za jego pomocą w w bardzo prosty sposób
                          znajdziesz znajomego, z którym ostatnio trenowałeś.
                          PilkaNaHali.pl dba o Twoją prywatność. Osoby
                          niezalogowane na liście wydarzenia widzą tylko
                          inicjały. Osoby zalogowane widzą identyfikator wraz z
                          imieniem i pierwszą literą nazwiska, chyba, że w tym
                          miejscu zmieniłeś widoczność danych.
                        </p>
                      </Link>
                    </h4>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </GridItem>
        </div>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(Form);
