import React, {useEffect, useState} from "react";
import {Api} from "../../../../../services/Api";
import GridItem from "../../../../../../components/Grid/GridItem";
import {TeamsForm} from "./Form";
import {I18n} from "../../../../../components/I18n";
import GridContainer from "../../../../../../components/Grid/GridContainer";
import Table from "../../../../../../components/Table/Table";
import {Link} from "react-router-dom";


export const TeamsTab = ({competition_group_id}) => {
    const [teams, setTeams] = useState([])
    const [group, setGroup] = useState({})

    const fetchTeams = () => {
        if (competition_group_id) {
            Api.competition_groups.show(competition_group_id).then((response) => {
                if (response.ok) {
                    setGroup(response.data)
                    const tmp = response.data.teams.map((team) => {

                        return [
                            <p style={{minWidth: '200px'}}> {team.name}</p>, I18n.t(team.gender),
                            <TeamsForm key={`${team.name}${team.gender}`} competition_group_id={competition_group_id}
                                       id={team.id} name={team.name} gender={team.gender} afterSave={fetchTeams}/>,
                            <Link to={`/moje-grupy/${competition_group_id}/druzyna/${team.id}`}>Zobacz szczegóły</Link>,
                        ]


                    })
                    setTeams(tmp)
                } else {
                    window.setNotifications([
                        {type: "alert", msg: "Coś poszło nie tak"},
                    ]);
                }
            });
        }
    }

    useEffect(fetchTeams, [])

    return <>
        <GridContainer alignItems={"center"}>
            <GridItem xs={12} md={10}>
                <h6>Lista drużyn ({teams.length}/{group.max_teams})</h6>
            </GridItem>
            <GridItem xs={12} md={2} style={{textAlign: "right"}}>
                {teams.length < group.max_teams && <GridContainer justify="flex-end">
                    <GridItem> <TeamsForm competition_group_id={competition_group_id} afterSave={fetchTeams}/></GridItem>
                </GridContainer>}
            </GridItem>
        </GridContainer>

        <GridItem>
            <Table
                striped
                tableHead={[
                    <b>Nazwa</b>,
                    <b>Płeć</b>,
                    "",
                    "",
                ]}
                tableData={teams}
            />

        </GridItem>
    </>
}