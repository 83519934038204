import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";

export const UsersFunctions = {
  create: (values, formik) => {
    return Api.users.sport_levels.create({ user_sport_level: values }).then((response) => {
      if (response.ok) {
        values.afterSave()
        formik.setValues({
          ...UsersFunctions.defaultValues(formik.props)
        });
        window.setNotifications([{ type: "success", msg: "Zapisano" }]);
      } else {
        window.setNotifications([
          { type: "alert", msg: "Nie udało się zapisać zmian" },
        ]);
      }
    });
  },

  defaultValues: (props) => {
    return {
      hideSportSelector: props.hideSportSelector || false,
      sport_id: props.sport_id || '',
      sport: props.sport || {},
      level: "",
      afterSave: props.afterSave,
    };
  },
};

export const SportLevelForm = withFormik({
  enableReinitialize: false,
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...UsersFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
    UsersFunctions.create(values, formikProps).then(() => {
      formikProps.setTouched({});
    });
  },
})(Form);
