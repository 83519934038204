import React, { useCallback, useEffect, useState } from "react";
import { Api } from "PNH/services/Api";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import formRegular from "PNH/assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "PNH/assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import SportsFootballIcon from '@material-ui/icons/SportsFootball';
import { Avatar } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import VerifiedIcon from '@material-ui/icons/VerifiedUser';
import Primary from "components/Typography/Primary";
import { I18n } from "PNH/components/I18n";
import { UsersFunctions } from "./index";

const styles = {
  ...formRegular,
  ...stylesExtended,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const updateValue = (index, values, setFieldValue, value) => {
  const objects_attributes = [...values.user_level_opinion];
  objects_attributes[index]['level'] = value;
  setFieldValue("user_level_opinion", objects_attributes);
}


const FormWrapper = ({
  values,
  errors,
  setFieldValue,
  handleSubmit,
  currentUser,
}) => {
  const [readOnly, setReadOnly] = useState(!UsersFunctions.canAddOpinion(values))
  const [hide, setHide] = useState(true)
  const [participant, setParticipant] = useState({})

  const getData = useCallback(() => {
    const currentUserOnActivity = values.activity.activity_participants.filter((pRow) => {
      return pRow.user.id == currentUser.id
    })
    if (currentUserOnActivity.length == 0) {
      setHide(true)
      return false
    }

    setParticipant(currentUserOnActivity[0])
    Api.activities.participants.opinions.index(values.activity_id).then(response => {
      if (response.data.total == 0) {
        setHide(false)
        setFieldValue("user_level_opinion", values.activity.activity_participants.filter((pRow) => {
          return pRow.user.id !== currentUser.id
        }).map((row) => {
          return { level: values.activity.lv, activity_participant_id: row.id, activity_participant: row.user }
        }))
      } else {
        setHide(false)
        setFieldValue("user_level_opinion", response.data.rows)
        setReadOnly(true)
      }
    })

  })


  useEffect(() => {
    getData()
    setFieldValue('afterSave', () => {window.location.reload()})
  }, [])

  if (hide) {
    return null
  }

  return <>
    <form onSubmit={handleSubmit} >
      <GridContainer style={{
        backgroundColor: '#62CEA4',
        color: 'white',
        borderTopLeftRadius: '16px',
        borderTopRightRadius: '16px'
      }}
        justifyContent="center"
        alignItems="center">
        <GridItem xs="2" justifyContent="center"
          alignItems="center" container >
          <Avatar style={{
            backgroundColor: 'white',
            borderRadius: '16px',
            width: '56',
            color: '#62CEA4',
            height: '56'
          }}> <SportsFootballIcon /></Avatar>

        </GridItem>
        <GridItem xs="10" style={{ textAlign: 'left' }}>
          {!participant.opinion_added && UsersFunctions.canAddOpinion(values) && <h3><strong>Oceń poziom gry!</strong></h3>}
          {!participant.opinion_added && !UsersFunctions.canAddOpinion(values) && <h3><strong>Minął czas na dodanie opinii</strong></h3>}
          {participant.opinion_added && <h3><strong>Dziękujemy za ocenę!</strong></h3>}
          {!participant.opinion_added && !UsersFunctions.canAddOpinion(values) && <p>Na dodanie opinii masz 14 dni od daty zajęć</p>}
          <p>Pomóż nam ulepszyć zajęcia i oceń anonimowo poziom gry innych uczestników.</p>
        </GridItem>
      </GridContainer>


      <GridContainer style={{
        border: '1px #DDDDDD solid',
        borderBottomLeftRadius: '16px',
        borderBottomRightRadius: '16px',
        padding: '16px',
        paddingTop: "30px",
      }}>
        {values.user_level_opinion.map((row, index) => formRow(row, values, index, setFieldValue, readOnly, currentUser))}

        <GridItem xs={12} style={{ textAlign: "center", paddingLeft: 0, paddingRight: 0 }}>
          <Button type="submit" color="primary" link href="/s/poziom-sportowy" target='blank' style={{  paddingLeft: 10, paddingRight: 10 }}>
            Zobacz szczegółowe opisy poziomów
          </Button>
        </GridItem>
        {!participant.opinion_added && UsersFunctions.canAddOpinion(values) && <GridItem xs={12} style={{ textAlign: "center", marginTop: 30 }}>
          <Button type="submit" color="primary" round>
            Oceń poziom gry
          </Button>
        </GridItem>}
      </GridContainer>
    </form >
  </>
}

const formRow = (participant, values, index, setFieldValue, readOnly, currentUser) => {
  let userLevel = false;
  if (!!values.sport) {
    userLevel = participant.activity_participant.user_sport_levels_attributes.filter((row) => values.sport.id == row.sport_id)[0]
  } else {
    userLevel = false
  }

  if(participant.activity_participant.id == currentUser.id) {
    return null
  }

  return <>
    <GridItem xs={12} style={{ marginTop: 15, paddingLeft: 0, marginBottom: 5, textAlign: 'left' }}>
      {participant.activity_participant.label}&nbsp;{userLevel && userLevel.verified && userLevel.level == values.activity.lv && <Tooltip title={`Poziom zweryfikowany przez PNH`} classes={{ tooltip: this.props.classes.tooltip }}>
        <span><Primary ><VerifiedIcon style={{ fontSize: 13 }} /></Primary></span>
      </Tooltip>}
    </GridItem>
    {['beginners', "medium", "intermediate", "advanced",].map((g) => (
      <GridItem xs={3} style={{ marginBottom: 10, paddingLeft: 0, paddingRight: 0 }}>
        <div style={{ backgroundColor: '#F5F5F5', margin: 0, padding: 0 }}>
          <Button disabled={readOnly} fullWidth color={participant.level == g ? "primary" : 'transparent'} onClick={() => updateValue(index, values, setFieldValue, g)} style={{ margin: 0, fontSize: 10 }}>{I18n.t(g)}</Button>
        </div>
      </GridItem>
    ))}
  </>
}


export const Form = withStyles(styles)(FormWrapper);