import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";

export const UsersFunctions = {
  update: (values, formik) => {
    return Api.users.update(values.id, { user: values }).then((response) => {
      if (response.ok) {
        formik.setValues({
          ...UsersFunctions.defaultValues(formik.props),
          ...{
            id: response.data.id,
            hide_last_name: response.data.hide_last_name,
            identifier: response.data.identifier,
            password: "",
            password_confirmation: "",
            facebook_uid: response.data.facebook_uid,
          },
        });
        window.setNotifications([{ type: "success", msg: "Zapisano" }]);
      } else {
        window.setNotifications([
          { type: "alert", msg: "Nie udało się zapisać zmian" },
        ]);
      }
    });
  },

  defaultValues: (props) => {
    return {
      id: props.data.currentUser.id,
      hide_last_name: false,
      identifier: "",
      password: "",
      password_confirmation: "",
      facebook_uid: "",
      afterSave: () => {},
    };
  },
};

export const SecurityForm = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...UsersFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);

    UsersFunctions.update(values, formikProps)
      .then((response) => {
        values.afterSave(response);
        formikProps.setFieldValue("afterSave", () => {});
      })
      .then(() => {
        formikProps.setTouched({});
      });
  },
})(Form);
