import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Button from "../../components/CustomButtons/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(basicsStyle);
const GA_COOCKIES = "ga_coockies"
const HOTJAR_COOCKIES = "hotjar_coockies"
export const CookiesBar = () => {
    const [openOptions, setOpenOptions] = useState(false)
    const [accepted, setAccepted] = useState(localStorage.getItem("COOKIES_ACCPETED") == 'true')
    const [gaCoockies, setGaCookies] = useState(localStorage.getItem(GA_COOCKIES))
    const [hotjarCoockies, setHotjarCookies] = useState(localStorage.getItem(HOTJAR_COOCKIES))

    useEffect(()=> {
        if(!localStorage.getItem(GA_COOCKIES)) {
            localStorage.setItem(GA_COOCKIES, true)
            setGaCookies(true)
        }
        if(!localStorage.getItem(HOTJAR_COOCKIES)) {
            localStorage.setItem(HOTJAR_COOCKIES, true)
            setHotjarCookies(true)
        }
    }, [])

    const updateGaCoockiesOptions = () => {
        const currentState = localStorage.getItem(GA_COOCKIES) === 'true'
        localStorage.setItem(GA_COOCKIES, !currentState)
        setGaCookies(!currentState)
    }

    const updateHotjarCoockiesOptions = () => {
        const currentState = localStorage.getItem(HOTJAR_COOCKIES) === 'true'
        localStorage.setItem(HOTJAR_COOCKIES, !currentState)
        setHotjarCookies(!currentState)
    }


    const acceptedCookies = () => {
        setAccepted(true)
        localStorage.setItem("COOKIES_ACCPETED", true)
    }

    if(accepted) {
        return null
    }

    return <div style={{
        background: '#fff',
        zIndex: '999999999999',
        fontSize: '16px',
        color: '#333',
        padding: '16px 40px',
        fontWeight: '400',
        position: 'fixed',
        bottom: '40px',
        width: '80%',
        borderRadius: '8px',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        boxShadow: '0 13px 27px -5px rgba(50,50,93,0.25), 0 8px 16px -8px rgba(0,0,0,0.3), 0 -6px 16px -6px rgba(0,0,0,0.025)',
    }}>

        {!openOptions && <CookiesBanerText setOpenOptions={setOpenOptions} setAccepted={acceptedCookies}/>}
        {openOptions && <CookiesOptionsText updateGaCoockiesOptions={updateGaCoockiesOptions} gaCoockies={gaCoockies} setAccepted={acceptedCookies} updateHotjarCoockiesOptions={updateHotjarCoockiesOptions} hotjarCoockies={hotjarCoockies}/>}

    </div>
}

const CookiesBanerText = ({setOpenOptions, setAccepted}) => {
   return <GridContainer>
        <GridItem xs={12} md={12} style={{textAlign: 'left'}}><h5>Twoja prywatność</h5></GridItem>
        <GridItem xs={12} md={9} style={{textAlign: 'left'}}><p>Używamy plików cookie i podobnych technologii, aby personalizować treści, oraz zapewniać lepszą jakość naszych usług. <br /> Klikając Akceptuję lub włączając opcję w Ustawienia plików cookie, wyrażasz na to zgodę zgodnie z naszą
            <Link to={'s/polityka-prywatnosci'}> Polityką plików cookie</Link>. Aby zmienić preferencje lub wycofać zgodę, zaktualizuj swoje Ustawienia plików cookie.</p></GridItem>
        <GridItem xs={12} md={3} style={{textAlign: 'center'}}><Button round link onClick={()=>setOpenOptions(true)}>Ustawienia plikók cookies</Button> <Button onClick={()=>setAccepted()} round color={"primary"}>Akceptuj</Button></GridItem>
    </GridContainer>
}

const CookiesOptionsText = ({updateGaCoockiesOptions, gaCoockies, setAccepted, hotjarCoockies, updateHotjarCoockiesOptions}) => {
    const classes = useStyles();



    return <GridContainer>
        <GridItem xs={12} style={{textAlign: 'left'}}><h5>Wymagane cookies</h5></GridItem>
        <GridItem xs={12} style={{textAlign: 'left'}}><p>Pliki te są niezbędne do działania strony internetowej i nie mogą być wyłączone.</p></GridItem>
        <GridItem xs={12} style={{textAlign: 'left', paddingLeft: "50px"}}>
            <FormControlLabel
                control={
                    <Switch
                        disabled={true}
                        checked={true}
                        classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            thumb: classes.switchIcon,
                            track: classes.switchBar
                        }}
                    />
                }
                classes={{
                    label: classes.label,
                    root: classes.labelRoot
                }}
                labelPlacement={"start"}
                label="PilkaNaHali.pl"
            />
        </GridItem>
        <GridItem xs={12} style={{textAlign: 'left', paddingLeft: "50px"}}>
            <FormControlLabel
                control={
                    <Switch
                        disabled={true}
                        checked={true}
                        classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            thumb: classes.switchIcon,
                            track: classes.switchBar
                        }}
                    />
                }
                classes={{
                    label: classes.label,
                    root: classes.labelRoot
                }}
                labelPlacement={"start"}
                label="Wishpond.net"
            />
        </GridItem>
        <GridItem xs={12} style={{textAlign: 'left', paddingLeft: "50px"}}>
            <FormControlLabel
                control={
                    <Switch
                        disabled={true}
                        checked={true}
                        classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            thumb: classes.switchIcon,
                            track: classes.switchBar
                        }}
                    />
                }
                classes={{
                    label: classes.label,
                    root: classes.labelRoot
                }}
                labelPlacement={"start"}
                label="Hubspot.com"
            />
        </GridItem>
        <GridItem xs={12} style={{textAlign: 'left', paddingLeft: "50px"}}>
            <FormControlLabel
                control={
                    <Switch
                        disabled={true}
                        checked={true}
                        classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            thumb: classes.switchIcon,
                            track: classes.switchBar
                        }}
                    />
                }
                classes={{
                    label: classes.label,
                    root: classes.labelRoot
                }}
                labelPlacement={"start"}
                label="Hsforms.com"
            />
        </GridItem>
        <GridItem xs={12} style={{textAlign: 'left'}}><h5>Pliki cookie wydajności</h5></GridItem>
        <GridItem xs={12} style={{textAlign: 'left'}}><p>Pliki cookie pozwalają nam śledzić liczbę odwiedzin i źródła ruchu, dzięki czemu możemy mierzyć i poprawiać wydajność naszej witryny.
            Pomagają nam dowiedzieć się, które strony są najbardziej, a które najmniej popularne, i zobaczyć, jak użytkownicy poruszają się po naszej witrynie.
            Jeśli nie wyrazisz zgody na pliki cookie, informacje w nich zawarte nie będą wykorzystywane do poprawienia wydajności witryny.</p></GridItem>
        <GridItem xs={12} style={{textAlign: 'left', paddingLeft: "50px"}}>
            <FormControlLabel
                control={
                    <Switch
                        checked={gaCoockies}
                        onClick={updateGaCoockiesOptions}
                        classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            thumb: classes.switchIcon,
                            track: classes.switchBar
                        }}
                    />
                }
                classes={{
                    label: classes.label,
                    root: classes.labelRoot
                }}
                labelPlacement={"start"}
                label="google.com"
            />
        </GridItem>
        <GridItem xs={12} style={{textAlign: 'left', paddingLeft: "50px"}}>
            <FormControlLabel
                control={
                    <Switch
                        checked={hotjarCoockies}
                        onClick={updateHotjarCoockiesOptions}
                        classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            thumb: classes.switchIcon,
                            track: classes.switchBar
                        }}
                    />
                }
                classes={{
                    label: classes.label,
                    root: classes.labelRoot
                }}
                labelPlacement={"start"}
                label="hotjar.com"
            />
        </GridItem>
        <GridItem xs={12} style={{textAlign: 'right'}}><Button onClick={()=>setAccepted()} round color={"primary"}>Zakończ edycję</Button></GridItem>
    </GridContainer>
}