import React from "react";
import GridItem from "components/Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Datetime from "react-datetime";
import moment from "moment";
import Dates from "../Dates";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export const DateAndTimeSection = ({ handleChange, classes, values, errors, setFieldValue, ...props }) => {
    return <>
        <GridItem xs={12}>
            <hr />
        </GridItem>
        <GridItem xs={12} md={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                error={errors.week_days}
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                Dzień
              </InputLabel>
              <Select
                multiple
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={values.week_days}
                onChange={handleChange}
                inputProps={{
                  name: "week_days",
                  error: errors.week_days
                }}
              >
                {["Nd", "Pn", "Wt", "Śr", "Czw", "Pt", "Sob"].map(
                  (day, index) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={index}
                    >
                      {day}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </GridItem>

        <GridItem md={3}>
            <InputLabel error={!!errors.start_at} className={classes.label}>
                Czas rozpoczęcia
            </InputLabel>
            <br />
            <FormControl fullWidth style={{ zIndex: 10 }}>
                <Datetime
                    utc
                    value={values.start_at}
                    dateFormat={false}
                    timeFormat={"H:mm"}
                    onChange={(value) => {
                        const format = moment(value).format("H:mm");
                        const time = format === "Invalid date" ? value : format;
                        setFieldValue("start_at", time);
                    }}
                    inputProps={{ name: "start_at", autocomplete: "off" }}
                />
            </FormControl>
        </GridItem>
        <GridItem md={3}>
            <InputLabel error={!!errors.stop_at} className={classes.label}>
                Czas zakończenia
            </InputLabel>
            <br />
            <FormControl fullWidth style={{ zIndex: 10 }}>
                <Datetime
                    utc
                    value={values.stop_at}
                    dateFormat={false}
                    timeFormat={"H:mm"}
                    onChange={(value) => {
                        const format = moment(value).format("H:mm");
                        const time = format === "Invalid date" ? value : format;
                        setFieldValue("stop_at", time);
                    }}
                    inputProps={{ name: "stop_at", autocomplete: "off" }}
                />
            </FormControl>
        </GridItem>
    </>
}