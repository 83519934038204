import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import TrendingUp from "@material-ui/icons/TrendingUp";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Info from "components/Typography/Info.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import Button from "components/CustomButtons/Button.js";

import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.js";


import blog6 from "assets/img/examples/blog6.jpg";
import Primary from "../../../../../components/Typography/Primary";
import {Link} from "react-router-dom";


const useStyles = makeStyles(blogsStyle);

export default function BigFull({tile, ...rest }) {
    const classes = useStyles();
    return (
        <Card plain blog className={classes.card4}>
            <CardHeader image plain>
                <a href={tile.url}>
                    <img src={process.env.REACT_APP_API_URL+tile.picture} alt="..." />
                </a>
                <div
                    className={classes.coloredShadow}
                    style={{
                        backgroundImage: `url(${process.env.REACT_APP_API_URL}${tile.picture})`,
                        opacity: "1"
                    }}
                />
            </CardHeader>
            <CardBody plain>
                <Primary>
                    <h6 className={classes.cardCategory}>{tile.category}</h6>
                </Primary>
                <h3 className={classes.cardTitle}>
                    <a href={tile.url}>
                        {tile.name}
                    </a>
                </h3>
                <h5 className={classes.description}>
                    {tile.description}
                </h5>
                <Button href={tile.url} round color="primary">
                    {tile.button_text || "Więcej"}
                </Button>
            </CardBody>
        </Card>
    );
}
