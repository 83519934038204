import React from "react";
import * as Yup from "yup";
import moment from "moment";

const ValidationSchema = Yup.object().shape({
  date_from: Yup.date().when(["id"], {
    is: (id) => id === undefined,
    then: Yup.date().required("Pole Wymagane"),
    otherwise: Yup.date(),
  }),
  date_to: Yup.date().when(["id", "date_from"], {
    is: (id) => id === undefined,
    then: Yup.date()
      .required("Pole Wymagane")
      .when(
        "date_from",
        (date_from) =>
          date_from &&
          Yup.date()
            .required("Pole Wymagane")
            .min(date_from)
            .max(Yup.ref("expired_at"))
      ),
    otherwise: Yup.date(),
  }),

  days: Yup.array().when(["id"], {
    is: (id) => id === undefined,
    then: Yup.array().required("Pole Wymagane"),
    otherwise: Yup.array(),
  }),
});

export default ValidationSchema;
