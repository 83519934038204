import React, {Component} from "react";
import moment from "moment";
import {Link, withRouter} from 'react-router-dom';

import withStyles from "@material-ui/core/styles/withStyles";

import Place from "@material-ui/icons/Place";
import SportsVolleyballIcon from "@material-ui/icons/SportsVolleyball";
import EventIcon from "@material-ui/icons/Event";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Primary from "components/Typography/Primary";
import Danger from "components/Typography/Danger";
import Warning from "components/Typography/Warning";
import Info from "components/Typography/Info";
import Button from "components/CustomButtons/Button";
import ListAltIcon from "@material-ui/icons/ListAlt";
// core components
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// core components
import dashboardStyle from "PNH/assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import PnhLogo from "PNH/assets/img/pnh.png";
import {AddToBasket} from "../../views/ActivityPage/AddToBasket/index";
import {I18n} from "../I18n";
import LockIcon from "@material-ui/icons/Lock";
import {EmojiEvents} from "@material-ui/icons";

const days = ["Ndz.", "Pon.", "Wt.", "Śr.", "Czw.", "Pt.", "Sob."];

const canAddOpinion = (activity) => {
    const acDateValidation = moment(activity.date, "YYYY-MM-DD").add(14, 'days')
    return acDateValidation >= moment() && !activity.opinion_added
}

class ActivityCardFull extends Component {
    render() {
        const {classes, activity} = this.props;
        const url = activity.url;

        const showProperPrice = () => {
            if (activity.event_type === 'third_type')
                return `Max: ${activity.max_price} zł`;
            return activity.price > 0
                ? `${activity.price} zł`
                : "Darmowe";
        };

        return (
            <div>
                <Card product style={{height: "100%"}}>
                    <CardHeader image>


                        <Link to={url}> <img src={process.env.REACT_APP_API_URL + activity.thumb_image} alt="..."/>
                            <div style={{
                                background: "linear-gradient( rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.8) )",
                                position: 'absolute',
                                width: '100%',
                                height: '100px',
                                bottom: 0
                            }}>
                                <h3 style={{
                                    textAlign: "center",
                                    left: 'auto',
                                    right: 'auto',
                                    width: '100%',
                                    bottom: '0px',
                                    fontWeight: '400',
                                    // top: '-15px',
                                    position: "absolute",
                                    boxShadow: "none",
                                    filter: "brightness(0) invert(1)",
                                    fontSize: "18px"
                                }}>
                                    {activity.competition_type == "activity" && "Zajęcia"}
                                    {activity.competition_type == "sparing" && "Sparing"}
                                    {activity.activity_type == "organizer" && " Organizator"}
                                    {activity.activity_type == "pnh" && " PilkaNaHali.pl"}
                                </h3>

                            </div>
                        </Link>

                        <div
                            style={{
                                right: 10,
                                top: 5,
                                position: "absolute",
                            }}
                        >
                            {!activity.canceled && !activity.past && activity.free_spots != 0 && (
                                <AddToBasket
                                    simple
                                    activity={activity}
                                    afterSave={this.props.afterSave}
                                    {...this.props}
                                />
                            )}
                            {activity.coordinated && (
                                <Button
                                    color="primary"
                                    justIcon
                                    onClick={() => {
                                        window.location.href = `/lista-obecnosci/${activity.id}`;
                                    }}
                                    key={`edit_${activity.id}`}
                                    className={classes.actionButton}
                                >
                                    <ListAltIcon className={classes.icon}/>
                                </Button>
                            )}
                        </div>


                    </CardHeader>
                    <Link to={url}>
                        <CardBody>
                            <GridContainer
                                alignItems="center"
                                style={{minHeight: 60}}
                            >
                                <GridItem>
                                    <h4 className={classes.cardProductTitle}>
                                        <Place/> {activity.localization.city},{" "}
                                        {activity.localization.street}
                                    </h4>
                                </GridItem>
                            </GridContainer>
                            <p className={classes.cardProductDesciprion}>
                                <GridContainer
                                    onClick={() => {
                                        this.props.history.push(url)
                                    }}
                                    justify="space-between"
                                    alignItems="center"
                                    style={{
                                        whiteSpace: "nowrap",
                                        fontSize: "14px",
                                    }}
                                >
                                    <GridItem xs={12}>
                                        <hr/>
                                    </GridItem>
                                    <GridItem xs style={{paddingRight: 0}}>
                                        <div className={` ${classes.iconText}`}>
                                            <EventIcon/> {days[moment(activity.date).day()]}{" "}
                                            {activity.date.split("-")[2]}.{activity.date.split("-")[1]}
                                        </div>
                                    </GridItem>
                                    <GridItem xs style={{paddingLeft: 0, paddingRight: 0}}>
                                        <div className={` ${classes.iconText}`}>
                                            <AccessTimeIcon/>
                                            {activity.start_at}
                                        </div>
                                    </GridItem>
                                    <GridItem xs style={{paddingLeft: 0}}>
                                        {activity.canceled && (
                                            <div className={` ${classes.iconText}`}>
                                                <SupervisorAccountIcon/> 0/
                                                {activity.max_players} (min: {activity.min_players})
                                            </div>
                                        )}
                                        {!activity.canceled && (
                                            <div
                                                className={` ${classes.iconText}`}
                                                style={{
                                                    fontWeight:
                                                        activity.all_reserved_spots < activity.min_players
                                                            ? "100"
                                                            : "900",
                                                    color:
                                                        activity.all_reserved_spots < activity.min_players
                                                            ? "inherit"
                                                            : "#1ad1a2",
                                                }}
                                            >
                                                <SupervisorAccountIcon/> {activity.all_reserved_spots}/
                                                {activity.max_players} (min: {activity.min_players})
                                            </div>
                                        )}
                                    </GridItem>

                                    <GridItem xs={12}>
                                        <hr/>
                                    </GridItem>
                                    <GridItem xs={12}>
                                        {activity.canceled && (
                                            <Danger>
                                                <h3 style={{margin: 0}}>
                                                    <b>Odwołane</b>
                                                </h3>
                                            </Danger>
                                        )}

                                        {!activity.canceled && !activity.past && (
                                            <Primary>
                                                <h3 style={{margin: 0}}>
                                                    {!activity.bought ? (
                                                        <b>
                                                            {showProperPrice()}{" "}
                                                        </b>
                                                    ) : (
                                                        <b>Wykupione</b>
                                                    )}
                                                </h3>
                                            </Primary>
                                        )}

                                        {!activity.canceled && activity.past && activity.bought && canAddOpinion(activity) && (
                                            <Primary>
                                                <h3 style={{margin: 0}}>
                                                    <b>Zostaw opinie</b>
                                                </h3>
                                            </Primary>
                                        )}

                                        {!activity.canceled && activity.past && ((activity.bought && !canAddOpinion(activity)) || !activity.bought) && (
                                            <Warning>
                                                <h3 style={{margin: 0}}>
                                                    <b>Archiwum</b>
                                                </h3>
                                            </Warning>
                                        )}
                                    </GridItem>

                                    <GridItem xs={12}>
                                        <hr/>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer
                                    justify={"space-between"}
                                    onClick={() => {
                                        this.props.history.push(url)
                                    }}
                                >
                                    <GridItem
                                        xs={5}
                                        className={` ${classes.iconText}`}
                                        style={{color: "black", whiteSpace: "nowrap"}}
                                    >
                                        <SportsVolleyballIcon/>
                                        {activity.sport.name}
                                    </GridItem>

                                    <GridItem
                                        xs={2}
                                        className={` ${classes.iconText}`}
                                        style={{
                                            color: "black",
                                            whiteSpace: "nowrap",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Info>
                                            {activity.gender === "males" && (
                                                <i className="fas fa-mars" style={{top: 2}}/>
                                            )}
                                            {activity.gender === "females" && (
                                                <i class="fas fa-venus" style={{top: 5}}/>
                                            )}
                                            {(activity.gender === "unisex" || !activity.gender) && (
                                                <i class="fas fa-venus-mars" style={{top: 2}}/>
                                            )}
                                        </Info>
                                    </GridItem>
                                    <GridItem
                                        xs={5}
                                        className={` ${classes.iconText}`}
                                        style={{
                                            color: "black",
                                            whiteSpace: "nowrap",
                                            justifyContent: "flex-end",
                                            fontSize: "12px"
                                        }}
                                    >
                                        <b>{I18n.t(activity.lv || "free")}</b>
                                    </GridItem>
                                </GridContainer>
                            </p>
                        </CardBody>
                    </Link>
                </Card>
            </div>
        );
    }
}

export default withRouter(withStyles(dashboardStyle)(ActivityCardFull));
