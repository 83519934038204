import React from "react";
import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
  phone: Yup.string()
    .nullable()
    .required("Pole Wymagane"),
});

export default ValidationSchema;
