/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
// react components for routing our app without refresh
// core components

import withStyles from "@material-ui/core/styles/withStyles";


import pricingStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/pricingStyle";

import { Link } from "react-router-dom";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";
import AddIcon from '@material-ui/icons/Add';
class AddActivityCard extends React.Component {

  render() {
    const { classes } = this.props;
   
      return <Card pricing plain raised>
              <CardBody pricing plain >
                <h3
                  className={
                    classes.cardCategory + " " + classes.marginBottom20
                  }
                  style={{fontWeight: 900}}
                >
                <Link to="">Organizator</Link>
                </h3>
                <div className={classes.icon}>
                  <AddIcon className={classes.iconRose} />
                </div>
                <h3
                  className={
                    classes.cardTitle + " " + classes.marginTop30
                  }
                >
                  Za darmo!
                </h3>
                <p className={classes.cardCategory}>
                  <strong>Organizuj i zarządzaj zajęciami sportowymi</strong>
                </p>

                <Button  
                  href="/dodaj-zajecia"
                  target="_blank" 
                  round 
                  color="primary">
                  Dodaj zajęcia
                </Button>
              </CardBody>
            </Card>
    
    
    
  }
}

AddActivityCard.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(pricingStyle)(AddActivityCard);
