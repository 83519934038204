/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import pricingStyle from "assets/jss/material-kit-pro-react/views/pricingSections/pricingStyle";

function SectionPricing(props) {
  const { classes } = props;

  return (
    <GridContainer>
      <h2 style={{ fontWeight: 900, marginTop: 40 }}>Doładowanie konta</h2>
      <p>
        Pakiet złotówek to idealne rozwiązanie dla osób, które regularnie
        uprawiają sport. Grasz i zyskujesz dodatkowe środki na zajęcia. Poznaj
        naszą ofertę, nie ograniczaj się do jednej dyscypliny i czerp radość z
        każdej z nich!
      </p>
      <GridItem md={4} xs={12} sm={6}>
        <Card plain pricing>
          <CardBody pricing>
            <h1 className={classes.cardTitle}>
              <small></small>20 <small>zł</small>
            </h1>
            <ul>
              <li>
                <b>0 zł</b> Bonus
              </li>
              <li>
                Przy zasileniu konta jednorazową kwotą <b>20 zł</b> - możesz
                wykorzystać na zajęcia <b>20 zł </b>
              </li>
            </ul>
            <Button
              href="#pablo"
              color="primary"
              round
              onClick={() =>
                props.data.currentUser.id
                  ? (window.location.href = "/doladowanie-konta/20")
                  : document.getElementById("login-button").click()
              }
            >
              Doładuj konto
            </Button>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem md={4} xs={12} sm={6}>
        <Card raised pricing color="primary">
          <CardBody pricing>
            <h6 className={classes.cardCategory}>Polecamy</h6>
            <h1 className={classes.cardTitleWhite}>
              <small></small>100 <small>zł</small>
            </h1>
            <ul>
              <li>
                <b>20zł</b> Bonus
              </li>
              <li>
                Przy zasileniu konta jednorazową kwotą <b>100 zł</b> – możesz
                wykorzystać na zajęcia pakiet <b>120 zł</b>
              </li>
            </ul>
            <Button
              href="#pablo"
              color="white"
              round
              onClick={() =>
                props.data.currentUser.id
                  ? (window.location.href = "/doladowanie-konta/100")
                  : document.getElementById("login-button").click()
              }
            >
              Doładuj konto
            </Button>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem md={4} xs={12} sm={6}>
        <Card plain pricing>
          <CardBody pricing>
            <h1 className={classes.cardTitle}>
              <small></small>200 <small>zł</small>
            </h1>
            <ul>
              <li>
                <b>55zł</b> Bonus
              </li>
              <li>
                Przy zasileniu konta jednorazową kwotą <b>200 zł</b> – możesz
                wykorzystać na zajęcia pakiet <b>255 zł</b>
              </li>
            </ul>
            <Button
              href="#pablo"
              color="primary"
              round
              onClick={() =>
                props.data.currentUser.id
                  ? (window.location.href = "/doladowanie-konta/200")
                  : document.getElementById("login-button").click()
              }
            >
              Doładuj konto
            </Button>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem
        xs={12}
        className={classes.textCenter}
        style={{ marginTop: 20 }}
      >
        <h5 style={{ fontWeight: 900 }}>
          Nie znalazłeś odpowiedniej kwoty doładowania?
        </h5>
        <p>
          Doładuj konto dowolną kwotą i odbierz bonus nawet do 30% zwiększenia
          środków.
        </p>
        <p>
          <Button
            href="#pablo"
            color="primary"
            round
            onClick={() =>
              props.data.currentUser.id
                ? (window.location.href = "/doladowanie-konta")
                : document.getElementById("login-button").click()
            }
          >
            Doładuj konto dowolną kwotą
          </Button>
        </p>
      </GridItem>
    </GridContainer>
  );
}

SectionPricing.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(pricingStyle)(SectionPricing);
