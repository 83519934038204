import React from "react";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import DialogActions from "@material-ui/core/DialogActions";
import Card from "../../../../../../../components/Card/Card";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles";
import {RadioComboSelector} from "../../../../../../components/radiocomboselector";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class Form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
        };
    }

    toggleModal = (open) => {
        this.setState({modal: open});
    };


    form() {
        const {
            classes,
            values,
            errors,
            handleChange,
            setFieldValue,
            handleSubmit,
            submitForm
        } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <GridContainer>
                    <GridItem xs={12}>
                        <CustomInput
                            labelText="Nazwa"
                            error={errors.name}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: values.name,
                                name: "name",
                                onChange: handleChange,
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} style={{marginTop: '30px'}}>
                        <FormControl fullWidth className={classes.selectFormControl}>
                            <InputLabel
                                shrink
                                htmlFor="simple-select"
                                className={classes.selectLabel}
                                error={errors.gender}
                            >
                                Płeć
                            </InputLabel>
                            <RadioComboSelector options={["males", "females", "unisex"]} labelKey={"addTeamForm"} value={values.gender} name={"gender"} setFieldValue={setFieldValue} />
                        </FormControl>
                    </GridItem>
                </GridContainer>
            </form>
        );
    }

    render() {
        const {
            classes,
            values,
            submitForm
        } = this.props;
        return <>
            <Dialog
                maxWidth={'sm'}
                fullWidth={true}
                open={this.state.modal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.toggleModal(false)}

            >
                <Card plain className={classes.modalSignupCard}>
                <DialogTitle
                    disableTypography
                    className={classes.modalHeader}
                    style={{marginTop: "-40px"}}
                >
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => this.toggleModal(false)}
                    >
                        <Close className={classes.modalClose}/>
                    </Button>
                    <center>
                        {values.id && <h4>Edytuj drużunę</h4>}
                        {!values.id && <h4><strong>Stwórz drużynę</strong></h4>}
                    </center>
                </DialogTitle>
                <DialogContent className={classes.modalBody}>
                    {this.form()}
                </DialogContent>
                <DialogActions  className={
                    classes.modalFooter + " " + classes.justifyContentCenter
                }>
                    <GridContainer>
                        <GridItem xs={12} className={classes.textCenter}>
                            <Button
                                color="primary"
                                round
                                onClick={submitForm}
                                style={{marginTop: '40px'}}
                            >
                                {values.id && "Edytuj drużunę"}
                                {!values.id && "Stwórz drużynę"}
                            </Button>
                        </GridItem>
                        <GridItem xs={12} className={classes.textCenter}>
                            <Button
                                simple
                                color="transparent"
                                style={{marginTop: '30px'}}
                                onClick={() => this.toggleModal(false)}
                            >
                                Anuluj
                            </Button>
                        </GridItem>
                    </GridContainer>
                </DialogActions>
                </Card>
            </Dialog>
            {values.id && !values.editForm && <Button color={"primary"}  simple={true} onClick={() => this.toggleModal(true)}><EditIcon /> Edycja</Button>}
            {values.id && values.editForm && <Button color={"primary"}  size={"sm"} onClick={() => this.toggleModal(true)}><EditIcon /> Edytuj</Button>}
            <GridContainer>
                <GridItem xs={12} style={{textAlign: "right"}}>
                    {!values.id && <Button  color={"primary"} size={"sm"}  onClick={() => this.toggleModal(true)}>Stwórz drużynę</Button>}
                </GridItem>
            </GridContainer>

        </>

    }
}

export default withStyles(javascriptStyles)(Form);
