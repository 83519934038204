export const I18n = {t: (key) =>  PL[key]}


var PL = {
  "w-0": "niedziela",
  "w-1": "poniedziałek",
  "w-2": "wtorek",
  "w-3": "środa",
  "w-4": "czwartek",
  "w-5": "piątek",
  "w-6": "sobota",
  "sign_in": "Logowanie",
  "login_form": {
    "header": "Logowanie",
    "subheader": "Lub Klasycznie",
    "inputs": {
      "email": "Email...",
      "password": "Password...",
    },
    "submit": "Zaloguj",
  },
  "recharge": "Doładowanie środków",
  "withdrawal": "Pobranie środków",
  "prepaid": "Prepaid",
  "pay_by_prepaid": "Prepaid",
  "online_transfer": "Przelew online",
  "pay_by_online_transfer": "Przelew online",
  "benefit": "MultiSport",
  "fitprofit": "FitProfit",
  "pay_by_benefit": "MultiSport",
  "ok_system": "Medicover Sport",
  "pay_by_ok_system": "Medicover Sport",
  "by_admin": "Administrator",
  "pay_by_cash": "Gotówka",
  "pay_by_fitprofit": "Fitprofit",
  "cash": "Gotówka",
  "unpaid": "Niepołacone",
  "paid": "Opłacone",
  "recharge_balance": 'Doładowanie konta głównego',
  "recharge_deposit": 'Doładowanie kaucji',
  "recharge_bonus": 'Doładowanie konta bonusowego',
  "buy_activity": 'Wykupione miejsce na zajęcia',
  "withdrawal_balance": 'Pobranie z konta głównego',
  "withdrawal_deposit": 'Pobranie z kaucji',
  "withdrawal_bonus": 'Pobranie z konta bonusowego',
  "withdrawal_supplement": 'Pobranie dopłaty',
  "hall": 'Hala sportowa',
  "baloon": 'Balon',
  "court": 'Otwarty obiekt',
  "stadium": 'Stadion',
  "gym": 'Sala gimnastyczna',
  "dance_room": 'Sala taneczna',
  "other": 'Inne',
  "terminal": 'Terminal',
  "report": 'Raport',
  "canceled": 'Odwołano',
  "deposit_disabled": 'Wyłącz kaucje',
  "coach": 'Trener',
  "hidden": 'Ukryte',
  "confirmed": 'Potwierdzone',
  "males": 'Mężczyźni',
  "females": 'Kobiety',
  "unisex": 'Mix',
  "free": 'Dowolny',
  "beginners": 'Rekreacyjny',
  "medium": 'Średni',
  "intermediate": 'Śr. zaaw.',
  "advanced": 'Zaaw.',
  "operation_costs": 'Koszty rezygnacji',
  "resign_activity": 'Rezygnacja z zajęć',
  "pnh": 'PNH',
  "organizer": 'Organizator',
  "promo": 'Promocja',
  "true": 'Tak',
  "false": 'Nie',
  "archive": 'Archiwum',
  "blocked": 'Zablokowany',
  'with_archive': 'Archiwalne', 
  'with_coach': 'Z trenerem', 
  'with_promo': 'Promocja', 
  'with_pay_by_ok_system': 'Z Medicover Sport', 
  'with_pay_by_benefit': 'Z Multisport',
  'with_pay_by_fitprofit': 'Z FitProfit',
  'with_min_participants': 'Z minimalną ilością graczy',
  'min_participants': 'Z minimalną ilością graczy',
  'with_free_spots': 'Z wolnymi miejscami',
  'free_spots': 'Z wolnymi miejscami',
  'verify_lv': 'Weryfikacja poziomu gracza',
  'first_type': "Pakiet dla firm",
  'second_type': 'Pakiet na medal',
  'third_type': 'Pakiet standard',
  'deposit_disabled_on_publish': "Wyłącz kaucję po odkryciu",
  'third_type_copy': "Dla grup które na własną rękę organizują zajęcia sportowe, wynajmują i opłacają obiekty sportowe, koordynują wydarzenia. W tym pakiecie cena wydarzenia i dopłata do kart Multisport zależna jest od ostatecznej frekwencji na zajęciach. Więcej o projekcie organizator znajdziesz",
  "second_type_copy": "Dla grup które na własną rękę organizują zajęcia sportowe, wynajmują i opłacają obiekty sportowe, koordynują wydarzenia. W tym pakiecie cena wydarzenia jest wyliczana od wcześniej ustalonego minimum Graczy. Więcej o projekcie organizator znajdziesz",
  "first_type_copy": "Dla grup i firm, które korzystają z naszej bazy obiektów (np. organizując zajęcia dla swoich pracowników lub znajomych) oraz dla firm sportowych, które organizują komercyjne zajęcia ze sportów drużynowych.  Więcej o projekcie organizator znajdziesz",
  'sparing': "Sparing",
  'activity': 'Zajęcia',
  'with_coach_true': 'Z trenerem',
  'with_coach_false': 'Bez trenera',
  'local_group': 'Grupa lokalna',
  "club": "Klub",
  "tournament": "Turniej",
  "league": "Liga",
  "b2b": "B2B",
  "open_event": "Otwarte wydarzenie",
  "addTeamFormmales": "Mężczyźni",
  "addTeamFormfemales": "Kobiety",
  "addTeamFormunisex": "Drużyna mieszana"
}