import React from "react";
import _ from 'lodash'
import { Api } from "PNH/services/Api";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import formRegular from "PNH/assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "PNH/assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "PNH/assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import Table from "components/Table/Table";
import Primary from "components/Typography/Primary";
import Warning from "components/Typography/Warning";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import moment from "moment";
import {I18n} from "PNH/components/I18n"

const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};


class Form extends React.Component {
  constructor(props) {
    super(props);
    this.fetch();
  }

  fetch = () => {
    if (this.props.activityID) {
      Api.activities.participants
        .index(this.props.activityID, { limit: 1000 })
        .then((response) => {
          if (response.ok) {
            this.props.setFieldValue("data", response.data.rows);
          } else {
            window.setNotifications([
              { type: "alert", msg: "Coś poszło nie tak" },
            ]);
          }
        });
    }
  };

  update = (row, params) => {
    Api.activities.participants
      .update(row.activity_id, row.id, { participant: params })
      .then((response) => {
        if (response.ok) {
          this.props.setFieldValue("data", response.data.rows);
        } else {
          window.setNotifications([
            { type: "alert", msg: "Coś poszło nie tak" },
          ]);
        }
      });
  };

  render() {
    const {
      classes,
      values,
      errors,
      handleChange,
      setFieldValue,
      handleSubmit,
    } = this.props;
    const { activity } = values
    const disabled =
      moment()
        .utc()
        .isBefore(moment(this.props.activity.date).format("YYYY-MM-DD")) ||
      activity.list_verified;

    return (
      <GridContainer>
        <GridItem xs={12}>
          <GridContainer jusify="space-between">
            <GridItem xs={12}>
              <GridContainer jusify="space-between">
                {!values.data[0] && (
                  <GridItem xs={12}>
                    <Primary>
                      <h5>
                        <b>Brak Zapisów</b>
                      </h5>
                    </Primary>
                  </GridItem>
                )}
                {values.data[0] && (
                  <GridItem xs={12}>
                    <Table
                      striped
                      tableHead={[
                        "Użytkownik",
                        "Data",
                        "Opłacone",
                        "Płatność",
                        "Obecność",
                        "Karta",
                      ]}
                      tableData={values.data.map((row) => {
                        return [
                          row.user.first_name + " " + row.user.last_name,
                          row.created_at,
                          row.paid ? "Tak" : "Nie",
                          I18n.t(row.payment_method),
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Obecność
                            </InputLabel>
                            <Select
                              disabled={disabled}
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={row.presence_check}
                              onChange={(e) => {
                                this.update(row, {
                                  presence_check: e.target.value,
                                });
                              }}
                              inputProps={{
                                name: "presence",
                              }}
                            >
                              <MenuItem
                                key={`par-${row.id}`}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value={true}
                              >
                                Tak
                              </MenuItem>
                              <MenuItem
                                key={`par-no-${row.id}`}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value={false}
                              >
                                Nie
                              </MenuItem>
                            </Select>
                          </FormControl>,

                          row.payment_method === "benefit" || row.payment_method === "fitprofit" ? (
                            <FormControl
                              fullWidth
                              className={classes.selectFormControl}
                            >
                              <InputLabel
                                htmlFor="simple-select"
                                className={classes.selectLabel}
                              >
                                Karta
                              </InputLabel>
                              <Select
                                disabled={disabled}
                                MenuProps={{
                                  className: classes.selectMenu,
                                }}
                                classes={{
                                  select: classes.select,
                                }}
                                value={row.card_check}
                                onChange={(e) => {
                                  this.update(row, {
                                    card_check: e.target.value,
                                  });
                                }}
                                inputProps={{
                                  name: "card_check",
                                }}
                              >
                                <MenuItem
                                  key={`par-${row.id}`}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  value={true}
                                >
                                  Tak
                                </MenuItem>
                                <MenuItem
                                  key={`par-no-${row.id}`}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  value={false}
                                >
                                  Nie
                                </MenuItem>
                              </Select>
                            </FormControl>
                          ) : (
                            "n/d"
                          ),
                        ];
                      })}
                    />
                  </GridItem>
                )}
              </GridContainer>
            </GridItem>

            <GridItem xs={12} style={{ display: "flex", paddingTop: 20 }}>
              <GridContainer>
                <GridItem xs={12}>
                  <b>
                    Zatwierdzenie listy:{" "}
                    <Primary>
                      <b>
                        {activity.list_verified_at ||
                          activity.list_verification_date}
                      </b>
                    </Primary>
                  </b>
                </GridItem>
                <GridItem xs={12}>
                  <b>
                    Sprawdzono listę:{" "}
                    <Primary>
                      <b>{activity.list_checked ? "Tak" : "Nie"}</b>
                    </Primary>
                  </b>
                </GridItem>
                <GridItem xs={12}>
                  <h6>
                    <Warning>
                      Do czasu zatwierdzenia listy ciągle można wprowadzać
                      zmiany
                    </Warning>
                  </h6>
                </GridItem>
                {!activity.list_checked && (
                  <GridItem xs={12} style={{ textAlign: "right" }}>
                    <Button
                      color="primary"
                      disabled={disabled}
                      onClick={handleSubmit}
                    >
                      Zatwierdź listę
                    </Button>
                    <h6>
                      <Warning>
                        Po 12 godzinach od zatwierdzenia lista zostanie
                        zablokowana oraz zostaną naliczone opłaty.
                      </Warning>
                    </h6>
                  </GridItem>
                )}
              </GridContainer>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(Form);
