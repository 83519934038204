import React, { Component } from "react";
import moment from "moment";
import { Link, withRouter } from 'react-router-dom';
import { I18n } from "../I18n";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Place from "@material-ui/icons/Place";
import withStyles from "@material-ui/core/styles/withStyles";
import SportsVolleyballIcon from "@material-ui/icons/SportsVolleyball";
import EventIcon from "@material-ui/icons/Event";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Primary from "components/Typography/Primary";
import Danger from "components/Typography/Danger";
import Warning from "components/Typography/Warning";
import Info from "components/Typography/Info";
import Button from "components/CustomButtons/Button";
import ListAltIcon from "@material-ui/icons/ListAlt";
import Edit from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import dashboardStyle from "PNH/assets/jss/material-dashboard-pro-react/views/dashboardStyle";

const days = ["Ndz.", "Pon.", "Wt.", "Śr.", "Czw.", "Pt.", "Sob."];

const canAddOpinion = (activity) => {
    const acDateValidation = moment(activity.date, "YYYY-MM-DD").add(14, 'days')
    return acDateValidation >= moment() && !activity.opinion_added
}

const showProperPrice = (activity) => {
    if (activity.event_type === 'third_type')
        return `Max: ${activity.max_price} zł`;
    return activity.price > 0
        ? `${activity.price} zł`
        : "Darmowe";
};

const ActivityCard = ({ activity, classes, organizator = false, coordinator = false }) => {
    const url = activity.url;

    return <Card >
        <CardBody>
            <Link to={url}>
                <GridContainer  style={{ background: '#62CEA5', marginRight: '-30px', height: '42px', marginLeft: '-30px', marginTop: '-15px', marginBottom: '15px', borderTopLeftRadius: '6px', borderTopRightRadius: '6px', textColor: "white", alignItems: 'center'}}>
                    <GridItem xs='12'>
                        <h4 className={classes.cardProductTitle} style={{color:"white", fontWeight: '400'}}>
                            {I18n.t(activity.competition_type)}
                            {activity.activity_type == "organizer" && " Organizator"}
                            {activity.activity_type == "pnh" && " PilkaNaHali.pl"}
                        </h4>
                    </GridItem>
                </GridContainer>
                <GridContainer alignItems="center" justify="center">
                    <GridItem xs={12}>
                        <h4 className={classes.cardProductTitle}>
                            <Place /> {activity.localization.city},{" "}
                            {activity.localization.street}
                        </h4>
                    </GridItem>
                    <GridItem xs={12}>
                        <hr />
                    </GridItem>
                    <GridItem xs="6" style={{ textAlign: 'center' }}>
                        <div className={` ${classes.iconText}`}>
                            <EventIcon />&nbsp;{days[moment(activity.date).day()]}&nbsp;{activity.date.split("-")[2]}.{activity.date.split("-")[1]}
                        </div>
                    </GridItem>
                    <GridItem xs="6" style={{ textAlign: 'center' }}>
                        <div className={` ${classes.iconText}`}>
                            <AccessTimeIcon />
                            {activity.start_at}
                        </div>
                    </GridItem>
                    <GridItem xs="12" style={{ textAlign: 'center' }}>
                        {activity.canceled && (
                            <div className={` ${classes.iconText}`}>
                                <SupervisorAccountIcon /> 0/
                                {activity.max_players} (min: {activity.min_players})
                            </div>
                        )}
                        {!activity.canceled && (
                            <div
                                className={` ${classes.iconText}`}
                                style={{
                                    fontWeight:
                                        activity.all_reserved_spots < activity.min_players
                                            ? "100"
                                            : "900",
                                    color:
                                        activity.all_reserved_spots < activity.min_players
                                            ? "inherit"
                                            : "#1ad1a2",
                                }}
                            >
                                <SupervisorAccountIcon /> {activity.all_reserved_spots}/
                                {activity.max_players} (min: {activity.min_players})
                            </div>
                        )}
                    </GridItem>
                    <GridItem xs={12}>
                        <hr />
                    </GridItem>
                    <GridItem xs={12} style={{ textAlign: 'center' }}>
                        {activity.canceled && (
                            <Danger>
                                <h3 style={{ margin: 0 }}>
                                    <b>Odwołane</b>
                                </h3>
                            </Danger>
                        )}
                        {!activity.canceled && activity.past  && !canAddOpinion(activity) && (
                            <Warning>
                                <h3 style={{ margin: 0 }}>
                                    <b>Archiwum</b>
                                </h3>
                            </Warning>
                        )}
                        {!activity.canceled && activity.past && activity.bought &&  canAddOpinion(activity) && (
                            <Primary>
                                <h3 style={{ margin: 0 }}>
                                    <b>Zostaw opinie</b>
                                </h3>
                            </Primary>
                        )}
                        {!activity.canceled && !activity.past && (
                            <Primary>
                                <h3 style={{ margin: 0 }}>
                                    {!activity.bought ? (
                                        <b>
                                            {showProperPrice(activity)}{" "}
                                        </b>
                                    ) : (
                                        <b>Wykupione</b>
                                    )}
                                </h3>
                            </Primary>
                        )}
                        {!activity.canceled && activity.past  && !activity.bought && (
                            <Warning>
                                <h3 style={{ margin: 0 }}>
                                    <b>Archiwum</b>
                                </h3>
                            </Warning>
                        )}
                    </GridItem>

                    <GridItem xs={12}>
                        <hr />
                    </GridItem>
                    <GridItem
                        xs={5}
                        className={` ${classes.iconText}`}
                        style={{ color: "black", whiteSpace: "nowrap" }}
                    >
                        <SportsVolleyballIcon />
                        {activity.sport.name}
                    </GridItem>

                    <GridItem
                        xs={2}
                        className={` ${classes.iconText}`}
                        style={{
                            color: "black",
                            whiteSpace: "nowrap",
                            justifyContent: "center",
                        }}
                    >
                        <Info>
                            {activity.gender === "males" && (
                                <i className="fas fa-mars" style={{ top: 2 }} />
                            )}
                            {activity.gender === "females" && (
                                <i class="fas fa-venus" style={{ top: 5 }} />
                            )}
                            {(activity.gender === "unisex" || !activity.gender) && (
                                <i class="fas fa-venus-mars" style={{ top: 2 }} />
                            )}
                        </Info>
                    </GridItem>
                    <GridItem
                        xs={5}
                        className={` ${classes.iconText}`}
                        style={{
                            color: "black",
                            whiteSpace: "nowrap",
                            justifyContent: "flex-end",
                            fontSize: "12px"
                        }}
                    >
                        <b>{I18n.t(activity.lv || "free")}</b>
                    </GridItem>
                </GridContainer>
            </Link>
            {organizator && <GridContainer style={{ background: '#62CEA5', marginRight: '-30px', marginLeft: '-30px', marginTop: '10px', marginBottom: '-15px', borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', alignItems: 'center' }}>
                <GridItem xs='4' style={{textAlign: "center"}}>
                    <Tooltip
                        id="tooltip-top"
                        title="Lista obecności"
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}

                    >
                        <Button
                            color="white"
                            simple
                            icon
                            href={`/lista-obecnosci/${activity.id}`}
                            key={`edit_${activity.id}`}
                            className={classes.actionButton}
                        >
                            <ListAltIcon className={classes.icon} />
                        </Button>
                    </Tooltip>
                </GridItem>
                <GridItem xs='4'  style={{textAlign: "center"}}>
                    {!activity.read_only && (<Tooltip
                        id="tooltip-top"
                        title="Edytuj"
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                    ><Button
                        color="white"
                        simple
                        icon
                        href={`/zajecia-edycja/${activity.id}`}
                        key={`edit_${activity.id}`}
                        className={classes.actionButton}
                    >
                            <Edit className={classes.icon} />
                        </Button>
                    </Tooltip>)}
                </GridItem>
                <GridItem xs='4'  style={{textAlign: "center"}}>
                    <Tooltip
                        id="tooltip-top"
                        title="Kopiuj"
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                    >
                        <Button
                            color="white"
                            simple
                            icon
                            href={`/dodaj-zajecia/${activity.id}`}
                            key={`edit_${activity.id}`}
                            className={classes.actionButton}
                        >
                            <FileCopyIcon className={classes.icon} />
                        </Button>
                    </Tooltip>
                </GridItem>
            </GridContainer>}
            {coordinator && <GridContainer style={{ background: '#62CEA5', marginRight: '-30px', marginLeft: '-30px', marginTop: '10px', marginBottom: '-15px', borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px' }}>
                <GridItem xs='12'>
                    <Button
                        color="white"
                        fullWidth
                        simple
                        icon
                        href={`/lista-obecnosci/${activity.id}`}
                        key={`edit_${activity.id}`}
                        className={classes.actionButton}
                    >
                        <ListAltIcon /> Sprawdź obecność
                    </Button>
                </GridItem>
            </GridContainer>
            }
        </CardBody>
    </Card>
}

export const ActivityCardLight = withRouter(withStyles(dashboardStyle)(ActivityCard));