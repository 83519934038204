import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from "@material-ui/icons/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import TablePagination from "@material-ui/core/TablePagination";
import { Api } from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import {Link, withRouter} from 'react-router-dom';
import Tooltip from "@material-ui/core/Tooltip";

import moment from "moment";
import ListAltIcon from "@material-ui/icons/ListAlt";
import Primary from "components/Typography/Primary";

import extendedTablesStyle from "PNH/assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import { CreateActivitiesFromTemplateModal } from "../CreateActivities/modal";

class ActivityTemplatesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 0,
      limit: 10,
      total: 0,
      with_tags: "",
      show_modal: false,
    };
  }

  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    const params = () => {
      if (this.props.creator) {
        return { created: true };
      }
      return { coordinated: true };
    };

    Api.activity_templates
      .index({
        page: this.state.page,
        limit: this.state.limit,
        with_date_less: this.state.with_date_less,
        with_date_greater: this.state.with_date_greater,
        with_tags: this.state.with_tags,
        with_archived: false,
        ...params(),
      })
      .then((response) => {
        const tableContent = response.data.rows.map(this.mapRows);
        this.setState({ data: tableContent, total: response.data.total });
      });
  };
  mapRows = (row) => {
    const { classes } = this.props;
    return [
      <span style={{ whiteSpace: "nowrap" }}>
        {row.name}
      </span>,
      <span style={{ whiteSpace: "nowrap" }}>
        {row.start_at}
        {"-"}
        {row.stop_at}
      </span>,
      <span style={{ whiteSpace: "nowrap" }}>
        <Primary>
          <b>{row.sport.name}</b>
        </Primary>
      </span>,
      <div style={{ minWidth: 250 }}>
        {row.localization.name} <br /> {row.localization.street}{" "}
        {row.localization.city}
      </div>,
      row.approved_by_admin ? "Zweryfikowano" : "Weryfikacja",
      <span style={{ whiteSpace: "nowrap" }}>
        <CreateActivitiesFromTemplateModal templateID={row.id} {...this.props} />

      </span>,
      <Button
        color="danger"
        simple
        onClick={() => {
          if(window.confirm("Czy na pewno chcesz usunąć szablon?")) {
            this.deleteTemplate(row.id)
          }
        }}
        key={`edit_${row.id}`}
        className={classes.actionButton}
      >
        <DeleteIcon className={classes.icon} />
      </Button>
    ];
  };


  deleteTemplate = (id) => {
    Api.activity_templates.delete(id).then((response) => {
      if (response.ok) {
        window.setNotifications([
          { type: "success", msg: "Usunięto" },
        ]);
        this.getData();
      } else {
        window.setNotifications([
          { type: "alert", msg: "Nie udało się usunąć" },
        ]);
      }
    })
  }


  changePage = (event, page) => {
    this.setState({ page: page }, this.getData);
  };

  onChangeRowsPerPage = (event) => {
    this.setState({ limit: event.target.value, page: 0 }, this.getData);
  };

  handleChange = (event) => {
    this.setState(
      { [event.target.name]: event.target.value, page: 0 },
      this.getData
    );
  };

  filters = () => {
    const { classes, creator = false } = this.props;
    return (
      <>

        <GridItem xs={12}>
          <Button
            style={{ float: "right", marginBottom: "30px" }}
            color="success"
            onClick={() => {
              this.props.history.push(`/dodaj-szablon`)
            }}
          >
            Dodaj szablon
          </Button>
          <Card>
            <CardBody >
              <GridContainer>
                <GridItem xs={12} >
                  <CustomInput
                    id={`with_tags`}
                    labelText="Szukaj..."
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      placeholder: "Szukaj warszawa, siatkówka...",
                      type: "text",
                      name: `with_tags`,
                      value: this.state[`with_tags`],
                      onChange: this.handleChange,
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </>
    );
  };
  render() {
    const { classes, filters } = this.props;
    return (
      <GridContainer>
        <GridItem>
          <h5>
            <b>Czym są szablony?</b>
          </h5>
          Stwórz swój szablon zajęć, aby móc dodawać zajęcia bez oczekiwania na ich weryfikację. Posiadając
          zweryfikowany szablon zajęć, będziesz mógł dodawać zajęcia cyklicznie nawet na wiele tygodni
          do przodu lub na cały sezon. Więc informacji o szablonach znajdziesz&nbsp;<Link
            to="s/szablony-do-zajec-organizatorskich">tutaj</Link>
        </GridItem>
        {!filters && this.filters()}
        <GridItem xs={12}>
          <Table
            striped
            tableHead={[
              <b>Nazwa</b>,
              <b>Godzina</b>,
              <b>Dyscyplina</b>,
              <b>Lokalizacja</b>,
              <b>Status&nbsp;Szablonu</b>,
              "",
            ]}
            tableData={this.state.data}
          />
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            page={this.state.page}
            rowsPerPage={this.state.limit}
            count={this.state.total}
            onChangePage={this.changePage}
            onChangeRowsPerPage={this.onChangeRowsPerPage}
            labelRowsPerPage="Ilość"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} z ${count}`
            }
          />

        </GridItem>
      </GridContainer>
    );
  }
}

ActivityTemplatesTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(extendedTablesStyle)(ActivityTemplatesTable));
