import React from "react";
import { Api } from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import formRegular from "PNH/assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "PNH/assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "PNH/assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Datetime from "react-datetime";
import LocationsModal from "./Locations";
import Danger from "components/Typography/Danger";
import { ActivitiesFunctions } from "./index";
import moment from "moment";
import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Warning from "components/Typography/Warning";
import {I18n} from "PNH/components/I18n"
const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
class Form extends React.Component {
  constructor(props) {
    super(props);
    this.fetch();
    this.state = {
      deleteModal: false,
      fetchActivity: !!props.values.id,
      fetchSports: true,
      fetchLocations: true,
    };
    this.fetchLocalizations();
    Api.sports.index().then((response) => {
      if (response.ok) {
        this.props.setFieldValue("sports", response.data.rows);
        this.setState({ fetchSports: false });
      }
    });
  }

  fetchLocalizations = () => {
    Api.locations.index().then((response) => {
      if (response.ok) {
        this.props.setFieldValue("localizations", response.data.rows);
        this.setState({ fetchLocations: false });
      }
    });
  };

  deleteModal = (open) => {
    this.setState({ deleteModal: open });
  };

  fetch = () => {
    if (this.props.values.id) {
      Api.activities.show(this.props.values.id).then((response) => {
        if (response.ok) {
          const data =
            this.props.new
              ? {
                  ...response.data,
                  id: null,
                  date: "",
                  publish_date: moment().format("YYYY-MM-DD H:m"),
                }
              : response.data;
          Object.keys(data).map((k) => {
            this.props.setFieldValue(k, data[k]);
          });
          this.setState({ fetchActivity: false });
        } else {
          window.setNotifications([
            { type: "alert", msg: "Coś poszło nie tak" },
          ]);
        }
      });
    }
  };

  getPaymentsConfig = () => {
    const available_roles = this.props.data.currentUser.roles.filter((r) => r.name.startsWith("user_role_payments"))
    const fields = available_roles.map(role => role.name.replace('user_role_payments', 'pay_by'))
    const paymentMethods = ['pay_by_cash'].concat(fields)
    return [...new Set(paymentMethods)]
  };

  form() {
    const {
      classes,
      values,
      errors,
      handleChange,
      setFieldValue,
      handleSubmit,
    } = this.props;
    const payments = this.getPaymentsConfig();
    return (
      <>
        {!this.props.data.currentUser.account_completed && (
          <Card>
            <CardBody>
              <h4 className={classes.textCenter}>
                <Warning>
                  <b>
                    Aby dodawać zajęcia dokończ proces rejestracji. Uzupełnij
                    swój profil i potwierdź nr telefonu
                  </b>
                </Warning>
                <h6 className={classes.textCenter}>
                  {" "}
                  <Button color="primary" href="/edit-profile">
                    Dokończ proces rejestracji
                  </Button>
                </h6>
              </h4>
            </CardBody>
          </Card>
        )}
        {this.props.data.currentUser.account_completed && (
          <form onSubmit={handleSubmit}>
            <GridContainer justify="flex-end">
              <GridItem xs={4}>
                <Dialog
                  classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal + " " + classes.modalSmall,
                  }}
                  open={this.state.deleteModal}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => this.deleteModal(false)}
                  aria-labelledby="small-modal-slide-title"
                  aria-describedby="small-modal-slide-description"
                >
                  <DialogTitle
                    id="small-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <Button
                      justIcon
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      color="transparent"
                      onClick={() => this.deleteModal(false)}
                    >
                      <Close className={classes.modalClose} />
                    </Button>
                  </DialogTitle>
                  <DialogContent
                    id="small-modal-slide-description"
                    className={classes.modalBody + " " + classes.modalSmallBody}
                  >
                    <h5>Na pewno chcesz usunąć?</h5>
                  </DialogContent>
                  <DialogActions
                    className={
                      classes.modalFooter + " " + classes.modalFooterCenter
                    }
                  >
                    <Button
                      onClick={() => this.deleteModal(false)}
                      color="transparent"
                      className={classes.modalSmallFooterFirstButton}
                    >
                      Nie
                    </Button>
                    <Button
                      onClick={() => ActivitiesFunctions.delete(this.props)}
                      color="success"
                      simple
                      className={
                        classes.modalSmallFooterFirstButton +
                        " " +
                        classes.modalSmallFooterSecondButton
                      }
                    >
                      Tak
                    </Button>
                  </DialogActions>
                </Dialog>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <h4>
                  <b>Zajęcia: {values.id ? "Edycja" : "Dodaj nowe"}</b>
                </h4>
              </GridItem>
              <GridItem xs={12} md={6}>
                <AsyncSelector
                  apiMethod={Api.sports.index}
                  apiShowMethod={Api.sports.show}
                  labelKey="name"
                  label="Dyscyplina"
                  name="sports"
                  defaultValue={values.sport_id}
                  value={
                    values.sport && {
                      id: values.sport.id,
                      label: values.sport.name,
                    }
                  }
                  onChange={(value, data) => {
                    setFieldValue("sport", data);
                    setFieldValue("sport_id", value);
                  }}
                />
              </GridItem>
              <GridItem xs={12}></GridItem>
              <GridItem xs={12} md={6}>
                <AsyncSelector
                  apiMethod={Api.locations.index}
                  apiShowMethod={Api.locations.show}
                  labelKey="label"
                  label="Lokalizacja"
                  name="locations"
                  defaultValue={values.localization_id}
                  value={
                    values.localization && {
                      id: values.localization.id,
                      label: values.localization.label,
                    }
                  }
                  onChange={(value, data) => {
                    setFieldValue("localization", data);
                    setFieldValue("localization_id", value);
                    if (values.localization_id !== value) {
                      setFieldValue("location_object_id", "");
                    }
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    error={!!errors.location_object_id}
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Obiekt
                  </InputLabel>
                  <Select
                    key={`as-loc-ob-${values.localization &&
                      values.localization.id}`}
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={values.location_object_id}
                    onChange={handleChange}
                    inputProps={{
                      name: "location_object_id",
                    }}
                  >
                    {values.localization &&
                      values.localization.location_objects_attributes &&
                      values.localization.location_objects_attributes.map(
                        (lObject) => (
                          <MenuItem
                            key={`lo-${lObject.id}`}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={lObject.id}
                          >
                            {lObject.description} <br />
                            <b>{I18n.t(lObject.object_type)}</b>
                          </MenuItem>
                        )
                      )}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12}>
                <LocationsModal
                  updateLocation={(response) => {
                    setFieldValue("localization", response.data);
                    setFieldValue("localization_id", response.data.id);
                  }}
                  locationID={values.localization_id}
                  {...this.props}
                />
              </GridItem>
              <GridItem xs={12}>
                <hr />
              </GridItem>
              <GridItem md={3}>
                <InputLabel error={!!errors.date} className={classes.label}>
                  Data zajęć
                </InputLabel>
                <br />
                <FormControl fullWidth style={{ zIndex: 10 }}>
                  <Datetime
                    utc
                    locale={moment.locale('pl')}
                    value={values.date}
                    dateFormat={"YYYY-MM-DD"}
                    timeFormat={false}
                    onChange={(value) => setFieldValue("date", value)}
                    inputProps={{ name: "date", autocomplete: "off" }}
                  />
                </FormControl>
              </GridItem>
              <GridItem md={3}>
                <InputLabel
                  error={!!errors.publish_date}
                  className={classes.label}
                >
                  Data publikacji
                </InputLabel>
                <br />
                <FormControl fullWidth style={{ zIndex: 50 }}>
                  <Datetime
                    locale={moment.locale('pl')}
                    value={values.publish_date}
                    dateFormat={"YYYY-MM-DD"}
                    timeFormat={"H:mm"}
                    onChange={(value) => setFieldValue("publish_date", value)}
                    inputProps={{ name: "publish_date", autocomplete: "off" }}
                  />
                </FormControl>
              </GridItem>
              <GridItem md={3}>
                <InputLabel error={!!errors.start_at} className={classes.label}>
                  Czas rozpoczęcia
                </InputLabel>
                <br />
                <FormControl fullWidth style={{ zIndex: 45 }}>
                  <Datetime
                    utc
                    value={values.start_at}
                    dateFormat={false}
                    timeFormat={"H:mm"}
                    onChange={(value) => {
                      const format = moment(value).format("H:mm");
                      const time = format === "Invalid date" ? value : format;
                      setFieldValue("start_at", time);
                    }}
                    inputProps={{ name: "start_at", autocomplete: "off" }}
                  />
                </FormControl>
              </GridItem>
              <GridItem md={3}>
                <InputLabel error={!!errors.stop_at} className={classes.label}>
                  Czas zakończenia
                </InputLabel>
                <br />
                <FormControl fullWidth style={{ zIndex: 40 }}>
                  <Datetime
                    utc
                    value={values.stop_at}
                    dateFormat={false}
                    timeFormat={"H:mm"}
                    onChange={(value) => {
                      const format = moment(value).format("H:mm");
                      const time = format === "Invalid date" ? value : format;
                      setFieldValue("stop_at", time);
                    }}
                    inputProps={{ name: "stop_at", autocomplete: "off" }}
                  />
                </FormControl>
              </GridItem>

              <GridItem xs={12}></GridItem>
              <GridItem xs={12} md={3}>
                <CustomInput
                  labelText="Cena"
                  error={errors.price}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "number",
                    value: values.price,
                    name: "price",
                    onChange: handleChange,
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={3}>
                <CustomInput
                  labelText="Kwota wynajmu"
                  error={errors.rental_price}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "number",
                    value: values.rental_price,
                    name: "rental_price",
                    onChange: handleChange,
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={3}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Płeć
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={values.gender}
                    onChange={handleChange}
                    inputProps={{
                      name: "gender",
                    }}
                  >
                    {["males", "females", "unisex"].map((g) => (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={g}
                      >
                        {I18n.t(g)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} md={3}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Poziom
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={values.lv}
                    onChange={handleChange}
                    inputProps={{
                      name: "lv",
                    }}
                  >
                    {[
                      "free",
                      "beginners",
                      "medium",
                      "intermediate",
                      "advanced",
                    ].map((lv) => (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={lv}
                      >
                        {I18n.t(lv)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12}></GridItem>
              <GridItem xs={12} md={4}>
                <CustomInput
                  labelText="Min. ilość graczy"
                  error={errors.min_players}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "number",
                    value: values.min_players,
                    name: "min_players",
                    onChange: handleChange,
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={4}>
                <CustomInput
                  labelText="Ilość miejsc"
                  error={errors.max_players}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "number",
                    value: values.max_players,
                    name: "max_players",
                    onChange: handleChange,
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={4}>
                <CustomInput
                  labelText="Zarezerwowane miejsca"
                  error={errors.reserved_spots}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "number",
                    value: values.reserved_spots,
                    name: "reserved_spots",
                    onChange: handleChange,
                  }}
                />
              </GridItem>
              <GridItem xs={12}>
                <h5>
                  <b>Płatności<Danger>*</Danger></b>
                </h5>
                {!!errors["pay_by_cash"]  && <Danger>Wybierz formę płatności</Danger>}
              </GridItem>
              {payments.map((p) => (
                <GridItem xs={12} md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={values[p]}
                        onClick={() => setFieldValue(p, !values[p])}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot,
                    }}
                    label={I18n.t(p)}
                  />
                  <br />
                </GridItem>
              ))}
              
              <GridItem xs={12}>
                <hr />
              </GridItem>
              <GridItem xs={12}>
                <CustomInput
                  labelText="Opis"
                  error={errors.description}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    multiline: true,
                    value: values.description,
                    name: "description",
                    onChange: handleChange,
                  }}
                />
              </GridItem>
              <GridItem xs={12}>
                <CustomInput
                  labelText="Notatki"
                  error={errors.notes}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    multiline: true,
                    value: values.notes,
                    name: "notes",
                    onChange: handleChange,
                  }}
                />
              </GridItem>

              <GridItem xs={12}>
                <h5>
                  <b>Ustawienia</b>
                </h5>
                <hr />
              </GridItem>
              {["coach", "hidden", "canceled"].map((p) => (
                <GridItem xs={12} sm={6} md={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={values[p]}
                        onClick={() => setFieldValue(p, !values[p])}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot,
                    }}
                    label={I18n.t(p)}
                  />
                </GridItem>
              ))}
              <GridItem xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      checked={values.terms}
                      onClick={() => setFieldValue("terms", !values.terms)}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label={
                    <span
                      style={{
                        color: !!errors["terms"] ? "red" : "inherit",
                      }}
                    >
                      Akceptuję{" "}
                      <a
                        href="https://pnh-public.s3.eu-central-1.amazonaws.com/regulamin.pdf"
                        target="_blank"
                      >
                        postanowienia i regulamin
                      </a>
                      . *
                    </span>
                  }
                />
              </GridItem>
            </GridContainer>
            <GridContainer
              justify="space-between"
              style={{ marginTop: 40, paddingBottom: 20 }}
            >
              <GridItem xs={12} sm>
                {this.props.values.id && (
                  <Button
                    color="danger"
                    className={classes.marginRight}
                    onClick={() => this.deleteModal(true)}
                  >
                    Usuń
                  </Button>
                )}
              </GridItem>
              <GridItem xs={12} sm style={{ textAlign: "right" }}>
                <Button
                  type="submit"
                  color="success"
                  disabled={this.props.isSubmitting}
                >
                  Zapisz
                </Button>
              </GridItem>
            </GridContainer>
          </form>
        )}
      </>
    );
  }

  render() {
    const { fetchActivity, fetchLocalizations, fetchLocations } = this.state;
    if (!fetchActivity && !fetchLocalizations && !fetchLocations) {
      return this.form();
    } else {
      return <h4>Ładowanie...</h4>;
    }
  }
}

export default withStyles(styles)(Form);
