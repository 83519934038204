import React from "react";
import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
  sport_id: Yup.string()
    .required("Pole Wymagane"),
  level: Yup.string()
    .required("Pole Wymagane"),
});

export default ValidationSchema;
