import React from "react";
import CustomInput from "components/CustomInput/CustomInput";
import GridItem from "components/Grid/GridItem";


export const FreespotsSection = ({ handleChange, classes, values, errors, setFieldValue }) => {
    return <>
          <GridItem xs={12}>
            <hr />
          </GridItem>
          <GridItem xs={12} md={4}>
            <CustomInput
              labelText="Min. ilość graczy"
              error={errors.min_players}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "number",
                value: values.min_players,
                name: "min_players",
                onChange: handleChange,
              }}
            />
          </GridItem>
          <GridItem xs={12} md={4}>
            <CustomInput
              labelText="Ilość miejsc"
              error={errors.max_players}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "number",
                value: values.max_players,
                name: "max_players",
                onChange: handleChange,
              }}
            />
          </GridItem>
          <GridItem xs={12} md={4}>
            <CustomInput
              labelText="Zarezerwowane miejsca"
              error={errors.reserved_spots}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "number",
                value: values.reserved_spots,
                name: "reserved_spots",
                onChange: handleChange,
              }}
            />
          </GridItem>
    </>
}