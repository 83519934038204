import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";

export const howKnowAboutUsValues = ["Od znajomych", "Z pracy - firmowe zajęcia",
  "Z reklamy w internecie",
  "Z reklamy w komunikacji miejskiej",
  "Z reklamy na autobusie",
  "Z billboardów",
  "Z wyszukiwarki partnerów benefitowych",
  "Z voucherów",
  "Szukałam/szukałem zajęć tego typu",
  "Z social mediów",
  "Z ulotek",
  "Inne",].map(f=>{ return f.toUpperCase(); });
export const UsersFunctions = {
  update: (values, formik) => {
    return Api.users.update(values.id, { user: values }).then((response) => {
      if (response.ok) {
        formik.setValues({
          ...UsersFunctions.defaultValues(formik.props),
          ...response.data,
        });

        const howKnowAboutUsHolder = howKnowAboutUsValues.includes(response.data.how_know_about_us) ? response.data.how_know_about_us : "INNE"
        formik.setFieldValue("how_know_about_us_holder", howKnowAboutUsHolder)
        formik.setFieldValue("show_how_know_about_us", howKnowAboutUsHolder == "INNE")

        window.setNotifications([{ type: "success", msg: "Zapisano" }]);
      } else {
        window.setNotifications([
          { type: "alert", msg: "Nie udało się zapisać zmian" },
        ]);
      }
    });
  },

  defaultValues: (props) => {
    return {
      id: props.data.currentUser.id,
      first_name: "",
      last_name: "",
      email: "",
      birth_date: "",
      city: "",
      gender: "",
      how_know_about_us: "",
      how_know_about_us_holder: "",
      show_how_know_about_us: false,
      afterSave: () => {},
    };
  },
};

export const UsersForm = withFormik({
  enableReinitialize: false,
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...UsersFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);

    UsersFunctions.update(values, formikProps).then(() => {
      formikProps.setTouched({});
    });
  },
})(Form);
