import GridItem from "../../components/Grid/GridItem";
import {I18n} from "./I18n";
import React from "react";
import Button from "../../components/CustomButtons/Button";
import GridContainer from "../../components/Grid/GridContainer";




export const RadioComboSelector = ({options=[], value, name, setFieldValue, labelKey = ""}) => {
    return <GridContainer key={`rcs${name}${value}`} style={{
        padding: '10px',
        paddingTop: "20px",
    }}>
        {options.map((g) => (
            <GridItem xs={3} md={12/options.length} style={{ marginBottom: 10, paddingLeft: 0, paddingRight: 0 }}>
                <div style={{ backgroundColor: '#F5F5F5', margin: 0, padding: 0 }}>
                    <Button fullWidth color={value === g ? "primary" : 'transparent'} onClick={() => setFieldValue(name, g)} style={{ margin: 0, fontSize: 8 }}>{I18n.t(`${labelKey}${g}`)}</Button>
                </div>
            </GridItem>
        ))}
    </GridContainer>
}