import React from "react";
import { Api } from "PNH/services/Api";
import notificationsModal from "PNH/assets/jss/material-dashboard-pro-react/modalStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import Primary from "components/Typography/Primary";
import { ActiveStep } from "./ModalSteps/ActiveStep";
import { ReserveListStep, ReserveListWrongLevelStep } from "./ModalSteps/ReserveListStep";
import { AddSportLevelStep } from "./ModalSteps/AddSportLevelStep";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { SimilarActivitiesSlider } from "./ModalSteps/SimilarActivities";
import sectionsStyle from "assets/jss/material-kit-pro-react/views/presentationSections/sectionsStyle";
import carouselStyle from "assets/jss/material-kit-pro-react/views/componentsSections/carouselStyle";
import { Hidden } from "@material-ui/core";

const styles = {
  ...notificationsModal,
  ...sectionsStyle,
  ...carouselStyle,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class ResignModal extends React.Component {
  state = {
    activeStep: 0,
    userLevel: {},
    resignModal: false,
    addModal: false,
  };

  resignModal = (open) => {
    this.setState({ resignModal: open });
  };

  setActiveStep = async (props) => {
    ActiveStep(props, (params) => this.setState(params))
  }

  addModal = (open) => {
    this.setState({ addModal: open }, () => this.setActiveStep(this.props));
  };

  renderAddModal = () => {
    const { classes, activity, afterSave, data, refreshHeader } = this.props;
    const { activeStep, userLevel } = this.state;
    return (
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal,
        }}
        open={this.state.addModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.addModal(false)}
      >
        {activeStep == 1 && <AddSportLevelStep toggleModal={this.addModal} afterSave={() => { this.setActiveStep(this.props) }} activity={activity} classes={classes} />}
        {activeStep == 2 && <ReserveListWrongLevelStep data={data} refreshHeader={refreshHeader} toggleModal={this.addModal} activity={activity} afterSave={afterSave} classes={classes} userLevel={userLevel} />}
        {activeStep == 3 && <ReserveListStep data={data} refreshHeader={refreshHeader} toggleModal={this.addModal} activity={activity} afterSave={afterSave} classes={classes} userLevel={userLevel} />}
      </Dialog>
    );
  };
  renderModal = () => {
    const { classes, activity, afterSave, data, refreshHeader } = this.props;
    const { userLevel } = this.state
    return (
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal,
        }}
        open={this.state.resignModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.resignModal(false)}
      >
        <DialogTitle disableTypography className={classes.modalHeader} style={{ textAlign: 'right', paddingBottom: 0 }}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => this.resignModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
        </DialogTitle>

        <DialogContent className={classes.modalBody} style={{ width: "100%", paddingTop: 0 }}>
          <h3 style={{ textAlign: "center" }}><strong>Czy na pewno chcesz się wypisać z listy rezerwowych?</strong></h3>

          <GridContainer>
            <GridItem xs={12}>
              <div style={{ width: '370px', marginLeft: 'auto', marginRight: 'auto' }}>
                <Hidden mdDown implementation="css" className={classes.hidden}>
                  <SimilarActivitiesSlider key={`r2-${activity.id}`} activity={activity} userLevel={userLevel} showItems="1" data={data} refreshHeader={refreshHeader} classes={classes} />
                </Hidden>
              </div>
            </GridItem>
            <GridItem xs={12} style={{ textAlign: "center", marginTop: 20 }}>
              <Button type="submit" round color="primary" href={`/szukaj?with_sport_id=${activity.sport.id}&with_lv=${userLevel && userLevel.level}&with_tags=${activity.localization.city}`} target='blank'>
                Zobacz inne zajęcia
              </Button>
            </GridItem>
            <GridItem xs={12} style={{ textAlign: "center", marginTop: 20 }}>
              <Button
                onClick={() => {
                  Api.activities.reserveList
                    .resign(activity.id)
                    .then((response) => {
                      if (response.ok) {
                        window.setNotifications([
                          { type: "success", msg: "Wypisano z listy rezerwowych" },
                        ]);
                        afterSave();
                        this.resignModal(false);
                      } else {
                        this.resignModal(false);
                        window.setNotifications([
                          {
                            type: "alert",
                            msg: "Nie udało się wypisać z listy rezerwowych",
                          },
                        ]);
                      }
                    });
                }}
                color="transparent"
                simple
                className={
                  classes.modalSmallFooterFirstButton +
                  " " +
                  classes.modalSmallFooterSecondButton
                }
              >
                Tak, wypisuję się z listy
              </Button>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  };

  render() {
    const { activity } = this.props;

    return (
      <>
        {activity.free_spots === 0 && !this.props.data.currentUser.id && (
            <>
              <hr/>
              <Button
                  color="primary"
                  round
                  fullWidth={true}
                  onClick={() => {
                    this.props.getStateUpdates().openLoginModal()
                  }}
              >
                Zapisz się na listę rezerwowych
              </Button>
              {this.renderAddModal()}
            </>
        )}
        {activity.free_spots === 0 && this.props.data.currentUser.id && !activity.on_reserve && (
          <>
            <hr/>
            <Button
              color="primary"
              round
              fullWidth={true}
              onClick={() => {
                this.addModal(true);
              }}
            >
              Zapisz się na listę rezerwowych
            </Button>
            {this.renderAddModal()}
          </>
        )}
        {activity.on_reserve && (
          <>
            <Button
              color="rose"
              fullWidth={true}
              round
              onClick={() => {
                this.resignModal(true);
              }}
            >
              Wypisz się z listy rezerwowych
            </Button>
            {this.renderModal()}
          </>
        )}
      </>
    );
  }
}

export default withStyles(styles)(ResignModal);
