import React, { useEffect, useState } from "react"
import { Api } from "PNH/services/Api"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle";
import Button from "components/CustomButtons/Button";
import {SimpleTile} from "./Tiles/Simple";
import WideLeft from "./Tiles/WideLeft";
import WideRight from "./Tiles/WideRight";
import SmallFull from "./Tiles/SmallFull";
import BigFull from "./Tiles/BigFull";

const Tiles = ({ tiles, classes }) => {


  const getTileComponent = (tile) => {
    switch (tile.tile_type) {
      case "simple":
        return <SimpleTile tile={tile} />
      case "wideLeft":
        return <WideLeft tile={tile} />
      case "wideRight":
        return <WideRight tile={tile} />
      case "fullSmall":
        return <SmallFull tile={tile} />
      case "fullBig":
        return <BigFull tile={tile} />
      default:
        return <SimpleTile tile={tile} />
    }
  }

  return tiles.map((tile) => <GridItem xs={tile.xs} sm={tile.sm} md={tile.md} lg={tile.lg} xl={tile.xl}>
    {getTileComponent(tile)}
  </GridItem>)

}

export const DynamicTile = withStyles(profilePageStyle)(Tiles)