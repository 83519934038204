/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// eslint-disable-next-line
import React, {Suspense}  from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// core components
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import sectionsStyle from "assets/jss/material-kit-pro-react/views/presentationSections/sectionsStyle";
import carouselStyle from "assets/jss/material-kit-pro-react/views/componentsSections/carouselStyle";

// images array used in rendering a function for this section
import ActivityCard from "PNH/components/Activity/ActivityCard";
import { Api } from "PNH/services/Api";
import Carousel from "react-slick";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import Placeholder from "assets/img/image_placeholder.jpg"
import { Link } from "react-router-dom";

class PromoSlider extends React.Component {
  state = {
    firstSection: [{},{},{},{},{},{},{}],
  };

  componentDidMount() {
    this.fetch();
  }

  fetch =  () => {
    Api.activities
    .index({ limit: 12, with_tags: "promocja", with_canceled: false })
    .then(({ ok, data }) => {
        if (ok) {
        this.setState({ firstSection: data.rows });
        }
    })
  };

  renderContainerFluid(collection, cssClass) {
    if (!collection) {
      return null;
    }
    const { classes } = this.props;

    const show = () => {
      if (isWidthUp("xl", this.props.width)) {
        return 3;
      }
      if (isWidthUp("lg", this.props.width)) {
        return 3;
      }
      if (isWidthUp("md", this.props.width)) {
        return 3;
      }
      if (isWidthUp("sm", this.props.width)) {
        return 2;
      }
      if (isWidthUp("xs", this.props.width)) {
        return 1;
      }
      return 1;
    };

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: show(),
      slidesToScroll: 1,
      autoplay: false,
      swipe: true,
    };

    return (
      <GridContainer>
        <GridItem xs={12} className={classes.marginAuto}>
          <Carousel {...settings}>
            {collection.map((el, index) => {
                if(!el.id){
                    return <div style={{paddingLeft: 15, paddingRight: 15}}><img src={Placeholder} alt="placeholder" key={`pl-${index}`} style={{maxWidth: "95%"}}/></div>
                }
                return (
                    <GridItem key={el.id}>
                    <ActivityCard
                        activity={el}
                        {...this.props}
                        afterSave={this.props.refreshHeader}
                    />
                    </GridItem>
                );
                })}
          </Carousel>
        </GridItem>
      </GridContainer>
    );
  }
  render() {
    const { classes } = this.props;
    if (this.state.firstSection.length === 0) {
      return null
    }
    return <GridContainer alignItems="center" justify="center" style={{ marginTop: '60px' }}>
      <GridItem xs={12} sm={8} md={8} lg={8}>
        <GridContainer justifyContent="space-between" alignItems="center">
          <GridItem sm><h3 style={{ fontWeight: '900' }}>Promocje</h3></GridItem>
          <GridItem sm style={{ textAlign: 'right' }}><Link to="/szukaj?with_tags=promocja" ><b>Zobacz wszystkie</b></Link></GridItem>
        </GridContainer>
      </GridItem>
      <GridItem xs={11} sm={10} md={8}>
        <Suspense fallback={<img src={Placeholder} alt="placeholder" />}>
          <>
            {this.renderContainerFluid(
              this.state.firstSection,
              classes.photoGallery
            )}
          </>
        </Suspense>
      </GridItem>
    </GridContainer>
  }
}

PromoSlider.propTypes = {
  classes: PropTypes.object,
};

export default withStyles({ ...sectionsStyle, ...carouselStyle })(
  withWidth()(PromoSlider)
);
