import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import SectionProducts from "./SectionProducts";
import { Api, serializeParams } from "PNH/services/Api";
import moment from "moment";

export const ActivitiesFunctions = {
  index: (values, formik) => {
    return Api.activities
      .index({ ...values, ...{ data: [] } })
      .then((response) => {
        if (response.ok) {
          formik.setFieldValue("data", response.data);
          const params = { ...values, ...{ data: [] } };
          // eslint-disable-next-line 
          history.pushState(
            {},
            "moja-lista-rezerwowych",
            "/moja-lista-rezerwowych?" + serializeParams(params)
          );
          window.scrollTo(0, 0);
        }
      });
  },

  defaultValues: (props) => {
    const urlParams = props.data.urlParams;
    return {
      page: urlParams.page || 0,
      limit: 8,
      data: [],
      observed_activities: "true",
      with_tags: urlParams.with_tags || "",
      with_price_less: urlParams.with_price_less || 500,
      with_price_greater: urlParams.with_price_greater || 0,
      with_date_greater:
        urlParams.with_date_greater ||
        moment()
          .utc()
          .format("YYYY-MM-DD"),
      with_date_less: urlParams.with_date_less || "",
      with_localization_id: urlParams.with_localization_id || "",
      localization: {},
      with_sport_id: urlParams.with_sport_id || "",
      sport: {},
      with_start_at_greater: urlParams.with_start_at_greater || "",
      with_start_at_less: urlParams.with_start_at_less || "",
      afterSave: props.afterSave,
    };
  },
};

export const ObservedActivities = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...ActivitiesFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
    ActivitiesFunctions.index(values, formikProps)
      .then((response) => {})
      .then(() => {
        formikProps.setTouched({});
      });
  },
})(SectionProducts);
