import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import { Form } from "./Form";
import { Api } from "PNH/services/Api";
import moment from "moment";

export const UsersFunctions = {
  canAddOpinion: (values) => {
    const acDateValidation =  moment(values.activity.date, "YYYY-MM-DD").add(14, 'days')
    return acDateValidation >= moment()
  },

  create: (values, formik) => {
    if(!UsersFunctions.canAddOpinion(values)) { 
      window.setNotifications([
        { type: "alert", msg: "Od zajęć minęło więcej niż 14 dni" },
      ]);
      return false;
    }
    return Api.activities.participants.opinions.create(values.activity.id, { activity_opinions: values }).then((response) => {
      if (response.ok) {
        values.afterSave()
        window.setNotifications([{ type: "success", msg: "Zapisano" }]);
      } else {
        values.afterSave()
        window.setNotifications([
          { type: "alert", msg: "Nie udało się zapisać zmian" },
        ]);
      }
    });
  },

  defaultValues: (props) => {
    return {
      activity_id: props.activity_id || '',
      activity: props.activity || '',
      user_level_opinion: [],
      level: "",
      afterSave: props.afterSave,
    };
  },
};

export const SportLevelOpinionForm = withFormik({
  enableReinitialize: false,
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...UsersFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
    UsersFunctions.create(values, formikProps).then(() => {
      formikProps.setTouched({});
    });
  },
})(Form);
