/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {withRouter} from 'react-router-dom';
// core components
import Button from "components/CustomButtons/Button";
import Primary from "components/Typography/Primary";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle";


function SectionAboutUs({ ...props }) {
  const { classes, ...rest } = props;
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.container}>
        {/* Feature 1 START */}
        <div className={classes.features1}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <h2 className={classes.title}>Kto tworzy PilkaNaHali.pl?</h2>
              <h5>
                Liczymy <b>10 osób</b>, ale to Wy działacie z nami każdego dnia
                ;) Niedługo wrzucimy tu nasze fotki i się bardziej przedstawimy!
                <br />
                Dodatkowo wyróżniamy blisko <b>
                  200 aktywnych Koordynatorów
                </b>{" "}
                zajęć, którzy oprócz grania z nami, pomagają nam na miejscu
                sprawić, aby trening był jak najprzyjemniejszy, zgodny z
                zapisami i zasadami PilkaNaHali.pl. Ponad <b>100 osób</b> wrzuca
                swoje treningi na naszą stronę: co oznacza nowe terminy,
                lokalizacje i ludzi do wspólnej gry!
              </h5>
              <Primary>
                <h3>
                  <b>{"Dziękujemy, że jesteście z nami <3!"}</b>
                </h3>
              </Primary>
              <br />
              <Button color="rose" target="_blank" onClick={()=> props.history.push('/s/o-nas')} >
                Więce o nas - nasza misja i liczby
              </Button>
            </GridItem>
          </GridContainer>
        </div>
        {/* Feature 1 END */}
      </div>
    </div>
  );
}

SectionAboutUs.propTypes = {
  classes: PropTypes.object,
};

export default withRouter(withStyles(featuresStyle)(SectionAboutUs));
