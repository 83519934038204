/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import {withRouter} from 'react-router-dom';
// @material-ui/icons

import SearchIcon from "@material-ui/icons/Search";
import GroupIcon from "@material-ui/icons/Group";
import SportsHandballIcon from "@material-ui/icons/SportsHandball";
import Check from "@material-ui/icons/Check";
// core components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";
import Primary from "components/Typography/Primary";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import InfoArea from "components/InfoArea/InfoArea";

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle";


function SectionFeatures({ ...props }) {
  const { classes, ...rest } = props;
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.container}>
        {/* Feature 1 START */}
        <div className={classes.features1}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <h2 className={classes.title}>
                Jak działa portal PilkaNaHali.pl?
              </h2>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={SearchIcon}
                title="Znajdź trening"
                description="Znajdź zajęcia w Twojej okolicy i ciesz się aktywnością każdego dnia. Oferujemy szeroki wybór treningów zwłaszcza w dyscyplinach drużynowych tj. siatkówka, piłka nożna, koszykówka i zajęcia z trenerem. Znajdziesz tu zajęcia organizowane przez nasz Zespół, oznaczone są logo PNH oraz Organizatorów, osób, które  wynajmują obiekty do gry ze znajomymi :)"
                iconColor="info"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={GroupIcon}
                title="Dołącz do drużyny"
                description="Znalazłeś wydarzenie, czas na krok 2. Zarezerwuj miejsce i opłać trening. Nie wiesz czy danego dnia zdążysz na grę wieczorem, nasze narzędzie Ci to ułatwi, zapisać się możesz o każdej porze. Chcemy, aby rekreacyjnie wyjście na piłkę nożną, koszykówkę czy siatkówkę, były proste niczym wyjście do kina, czy na siłownie. Oferujemy różne metody płatności - szczegóły znajdziesz, przy danym wydarzeniu."
                iconColor="success"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={SportsHandballIcon}
                title="Graj z PNH!"
                description="Miejsce zarezerwowane, super! Cieszymy się, że dołączyłeś do drużyny. Pamiętaj, frekwencja w sportach drużynowych jest bardzo ważna, zalecamy aby pojawić się na miejscu już na 10 min. przed treningiem. Na miejscu wydarzenia znajdź Koordynatora, zapozna Cię z zasadami. Do zobaczenia na boisku!"
                iconColor="danger"
              />
            </GridItem>
          </GridContainer>
        </div>
        {/* Feature 1 END */}
        <div className={classes.features1} style={{ paddingTop: 0 }}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <h2 className={classes.title}>
                Organizujesz zajęcia dla znajomych?
              </h2>
            </GridItem>
            <GridItem xs={12}></GridItem>
            <GridItem
              xs={12}
              sm={8}
              md={5}
              lg={4}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <h5>
                Dodaj swoje wydarzenie i korzystaj z darmowego narzędzia
                wspierającego organizację składu i trening. Cieszyć się grą w
                pełnym składzie!
              </h5>
              <Button color="primary" onClick={()=> props.history.push('/s/zasady-dodawania-treningow')} >
                Dodaj zajęcia
              </Button>
            </GridItem>
            <GridItem
              xs={12}
              sm={8}
              md={5}
              lg={6}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <Primary>
                <h3>
                  <b style={{ color: "#1ad1a2" }}>
                    Jakie korzyści daje nasze narzędzie?
                  </b>
                </h3>
              </Primary>
              <Card
                plain
                className={classNames(classes.card, classes.cardPricing)}
                style={{ marginTop: "0px", marginLeft: -20, textAlign: "left" }}
              >
                <CardBody plain>
                  <ul
                    style={{
                      maxWidth: "100%",
                      marginTop: 0,
                      listStyle: "none",
                    }}
                  >
                    <li>
                      <b>30000+</b> Graczy
                    </li>

                    <li>
                      <Check
                        className={classNames(
                          classes.cardIcons,
                          classes.successColor
                        )}
                      />{" "}
                      Funkcjonalne narzędzie do organizacji treningów
                    </li>
                    <li>
                      <Check
                        className={classNames(
                          classes.cardIcons,
                          classes.successColor
                        )}
                      />{" "}
                      Możliwość honorowania karty MultiSport na Twoim treningu
                    </li>
                    <li>
                      <Check
                        className={classNames(
                          classes.cardIcons,
                          classes.successColor
                        )}
                      />{" "}
                      System powiadomień
                    </li>
                    <li>
                      <Check
                        className={classNames(
                          classes.cardIcons,
                          classes.successColor
                        )}
                      />{" "}
                      Wysoka frekwencja na zajęciach
                    </li>
                  </ul>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}

SectionFeatures.propTypes = {
  classes: PropTypes.object,
};

export default withRouter(withStyles(featuresStyle)(SectionFeatures));
