import React from "react";
import * as Yup from "yup";
import moment from "moment";

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Błędny adres email")
    .required("Pole Wymagane"),
  password: Yup.lazy((value) =>
    !value
      ? Yup.string()
      : Yup.string()
          .min(6, "Min 6 znaków")
          .required("Pole Wymagane")
  ),
  birth_date: Yup.date()
    .typeError("Błędny format daty")
    .max(
      moment
        .utc()
        .subtract(18, "years")
        .format("YYYY-MM-DD"),
      `Musisz mieć ukończone 18 lat`
    )
    .required("Pole Wymagane"),
  city: Yup.string().required("Pole Wymagane"),
  first_name: Yup.string().required("Pole Wymagane"),
  last_name: Yup.string().required("Pole Wymagane"),
  password_confirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Hasła muszą do siebie pasować"
  ),
});

export default ValidationSchema;
