import React, {useCallback, useEffect, useState} from "react";
import {Api} from "../../../services/Api";
import TablePagination from "@material-ui/core/TablePagination";
import Card from "../../../../components/Card/Card";
import Table from "../../../../components/Table/Table";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import Primary from "../../../../components/Typography/Primary";
import Button from "../../../../components/CustomButtons/Button";
import CardBody from "../../../../components/Card/CardBody";
import CardHeader from "../../../../components/Card/CardHeader";

export const Invitations = ({id, ...props}) => {
    const [invitations, setInvitations] = useState([])
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(10)
    const [total, setTotal] = useState(0)

    const fetch = useCallback(() => {
        Api.competition_groups
            .invitationsCurrentUser({
                page: page,
                limit: limit,
            })
            .then((response) => {
                setInvitations(response.data.rows)
                setTotal(response.data.total)
            });
    }, [])

    useEffect(fetch, [])

    const changePage = (event, page) => {
        setPage(page)
        fetch()
    };

    const onChangeRowsPerPage = (event) => {
        setLimit(event.target.value)
        setPage(0)
        fetch()
    };

    const acceptInvitation = (id) => {
        Api.competition_groups
            .accept_invitation(id, {})
            .then((response) => {
               fetch()
            });
    }

    const leaveGroup = (id) => {
        Api.competition_groups
            .leave_group(id, {})
            .then((response) => {
                fetch()
            });
    }

    const rows = () => {
        return invitations.map((invitation) => {
            return [
                <GridContainer><GridItem xs={12}><Primary><strong>{invitation.name}</strong></Primary></GridItem><GridItem xs={12}>{invitation.description.substring(0, 120)}{invitation.description.length >120 ? '...' : ''}</GridItem></GridContainer>,
                <Button color={"danger"} simple onClick={()=>leaveGroup(invitation.id)}>Odrzuć</Button>,
                <Button color={"primary"} size={"sm"} onClick={()=>acceptInvitation(invitation.id)}>Zaakceptuj</Button>
            ]
        })
    }


    if (total === 0) {
        return null
    }

    return <Card >
        <CardBody>
            <h4 style={{marginBottom: '20px'}}><strong>Zaproszenia do grup ({total})</strong></h4>
            <Table
                striped
                tableData={rows()}
            />
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                page={page}
                rowsPerPage={limit}
                count={total}
                onChangePage={changePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                labelRowsPerPage="Ilość"
                labelDisplayedRows={({from, to, count}) =>
                    `${from}-${to} z ${count}`
                }
            />
        </CardBody>
    </Card>
}