/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, {Suspense, useCallback, useEffect, useState} from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "PNH/components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import {PlaceHolder} from "PNH/components/placeholder"
// sections for this page
import {Footer} from "PNH/layouts/Footer";

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
import Prefooter from "./Sections/SectionPreFooter";
import {AddOpinionModal} from "./AddOpinionForm/Modal";
import Button from "components/CustomButtons/Button";
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import preFooter from "assets/jss/material-kit-pro-react/views/componentsSections/preFooter";
import bg7 from "PNH/assets/img/banner-v.png";
import CoordinatedSlider from "./Sections/ActivitiesSliders/CoordinatedSlider";
import BoughtSlider from "./Sections/ActivitiesSliders/BoughtSlider";
import RecomendedSlider from "./Sections/ActivitiesSliders/RecomendedSlider";
import CreatedActivitiesSlider from './Sections/ActivitiesSliders/CreatedActivitiesSlider'
import LastWatchedSlider from "./Sections/ActivitiesSliders/LastWatchedSlider";
import AllTransactionsTable from "../Users/TransactionsPage/Table";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import {Link} from "react-router-dom";
import {WithoutOpinionTable} from "./Sections/Opinions";
import PromoSlider from "./Sections/ActivitiesSliders/PromoSlider";
import NewActivitiesSlider from "./Sections/ActivitiesSliders/NewActivitiesSlider";
import {DynamicTile} from "./Sections/DynamicTiles";
import MainSearchBar from "./Sections/MainSearchBar";
import OrganizerSection from "./Sections/OrganizerSection";
import PartnersSection from "./Sections/PartnersSection";
import {BlogSection} from "./Sections/BlogSection";
import {Api} from "../../services/Api";
import Warning from "../../../components/Typography/Warning";

const styles = {
    ...preFooter,
    ...presentationStyle
}

const LoggedInMainPage = ({...props}) => {
    const [fetch, setFetch] = useState(1)
    const [tiles, setTiles] = useState([])

    useEffect(() => {
        Api.mainPageTiles.index({limit: 50}).then(r => {
            setTiles(r.data.rows)
        })
    }, [])

    const refreshHeader = useCallback(
        () => {
            setFetch(fetch + 1)
        }
    )

    const {classes, data, ...rest} = props;
    const {currentUser} = data
    const accountBalance = parseFloat(currentUser.account_balance) + parseFloat(currentUser.account_bonus_value)

    return (
        <>
            <Header
                key={`ac-he-${fetch}`}
                brand="Klikasz. Grasz."
                fixed
                color="white"
                changeColorOnScroll={{
                    height: 200,
                    color: "white",
                }}
                data={data}
                {...rest}
            />

            <div className={classNames(classes.main)}>
                <GridContainer alignItems="center" justify="center" style={{marginTop: '120px'}}>
                    <GridItem xs={11} sm={10} md={8}>
                        <GridContainer>
                            <GridItem xs={12} sm={8} md={9} lg={10}>
                                <h2 style={{marginTop: 0}}>
                                    <b style={{
                                        fontSize: 36,
                                        fontWeight: 700
                                    }}>Cześć {currentUser.first_name}!</b>
                                </h2>
                                <h5 style={{fontSize: 18, fontWeight: 400, marginTop: -10}}>
                                    W co chcesz zagrać?
                                </h5>
                            </GridItem>
                            <GridItem xs={12} sm={4} md={3} lg={2}><Button href="/cennik" size='sm' color='primary'
                                                                           style={{marginBottom: 20}}>
                                <AccountBalanceWalletIcon/> Stan
                                konta: {accountBalance.toFixed(2)} PLN</Button></GridItem>
                        </GridContainer>
                    </GridItem>
                </GridContainer>
                {!currentUser.account_completed && (
                    <GridContainer alignItems="center" justify="center">
                    <GridItem xs={11} sm={10} md={8}>
                        <Card>
                            <CardBody>
                                <h4 className={classes.textCenter}>
                                    <Warning>
                                        <b>
                                            Aby zapisać się na zajęcia, dokończ proces rejestracji.
                                            Uzupełnij swój profil i potwierdź nr telefonu.
                                        </b>
                                    </Warning>
                                    <h6 className={classes.textCenter}>
                                        {" "}
                                        <Button round={true} color="primary" onClick={() => {
                                            props.getStateUpdates().openCompleteAccountModal()
                                        }}>
                                            Dokończ proces rejestracji
                                        </Button>
                                    </h6>
                                </h4>
                            </CardBody>
                        </Card>
                    </GridItem>
                    </GridContainer>
                )}
                <GridContainer alignItems="center" justify="center">
                    <GridItem xs={11} sm={10} md={8}>
                        <Suspense fallback={<div></div>}>
                            <MainSearchBar/>
                        </Suspense>
                    </GridItem>
                </GridContainer>
            </div>
            <BlogSection tiles={tiles} section="logged_in_1" {...props}/>
            <CreatedActivitiesSlider refreshHeader={refreshHeader} {...props} />
            <BlogSection tiles={tiles} section="logged_in_2" {...props}/>
            <CoordinatedSlider refreshHeader={refreshHeader} {...props} />
            <BlogSection tiles={tiles} section="logged_in_3" {...props}/>
            <BoughtSlider refreshHeader={refreshHeader} {...props} />
            <BlogSection tiles={tiles} section="logged_in_4" {...props}/>
            <div className={classNames(classes.main)}>
                <GridContainer alignItems="center" justify="center" style={{marginTop: '60px', padding: '20px',}}>
                    <GridItem xs={12} sm={10} md={8}>
                        <WithoutOpinionTable {...props} />
                    </GridItem>
                </GridContainer>
            </div>
            <BlogSection tiles={tiles} section="logged_in_5" {...props}/>
            <LastWatchedSlider xlItems="3" lgItems="3" refreshHeader={refreshHeader} {...props} />
            <BlogSection tiles={tiles} section="logged_in_6" {...props}/>
            <RecomendedSlider xlItems="3" lgItems="3" refreshHeader={refreshHeader} {...props} />
            <BlogSection tiles={tiles} section="logged_in_7" {...props}/>
            <PromoSlider {...props} />
            <BlogSection tiles={tiles} section="logged_in_8" {...props}/>
            <NewActivitiesSlider {...props} />
            <BlogSection tiles={tiles} section="logged_in_9" {...props}/>

            <div className={classNames(classes.main)}>
                <GridContainer alignItems="center" justify="center" style={{marginTop: '60px', padding: '20px'}}>
                    <GridItem xs={12} sm={10} md={8}>
                        <GridContainer>
                            <GridItem className={"rounded-container " + classNames(
                                classes.subscribeLine,
                                classes.subscribeLineImage
                            )}
                                      style={{
                                          padding: '55px',
                                          backgroundImage: `url(${bg7})`,
                                          backgroundRepeat: 'no-repeat',
                                          backgroundSize: 'cover',
                                          backgroundPosition: '50% 75%'
                                      }}
                            >
                                <Suspense fallback={<div></div>}>
                                    <OrganizerSection {...props} />
                                </Suspense>
                            </GridItem>

                        </GridContainer>
                    </GridItem>
                </GridContainer>
            </div>
            <BlogSection tiles={tiles} section="logged_in_10" {...props}/>

            <div className={classNames(classes.main)}>
                <GridContainer alignItems="center" justify="center" style={{marginTop: '60px'}}>
                    <GridItem xs={12} sm={10} md={8}>
                        <Card style={{width: '100%'}}>
                            <CardHeader>
                                <GridContainer justifyContent="space-between" alignItems="center"
                                               style={{marginTop: 30}}>
                                    <GridItem sm><h3 style={{fontWeight: '900'}}>Płatności</h3></GridItem>
                                    <GridItem sm style={{textAlign: 'right'}}><Link to="/transakcje"><b>Zobacz
                                        wszystkie</b></Link></GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody>
                                <AllTransactionsTable pagination={false} limit={5} {...props} />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
            <BlogSection tiles={tiles} section="logged_in_11" {...props}/>

            <Suspense fallback={<div></div>}>
                <Prefooter/>
            </Suspense>
            <Suspense fallback={<PlaceHolder/>}>
                <PartnersSection/>
            </Suspense>
            <hr/>
            <Footer {...props} />
            {currentUser.id && <AddOpinionModal hideButton={true} {...props} />}
        </>
    );

}
export default withStyles(styles)(LoggedInMainPage);
