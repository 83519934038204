import React from "react";
import { Api } from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import formRegular from "PNH/assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "PNH/assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "PNH/assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { LocationsFunctions } from "./index";
import {I18n} from "PNH/components/I18n"
const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
class Form extends React.Component {
  state = {
    deleteModal: false,
  };

  constructor(props) {
    super(props);
    this.fetch();
  }
  deleteModal = (open) => {
    this.setState({ deleteModal: open });
  };

  fetch = () => {
    if (this.props.values.id) {
      Api.locations.show(this.props.values.id).then((response) => {
        if (response.ok) {
          this.props.setValues({
            ...LocationsFunctions.defaultValues(this.props),
            ...response.data,
          });
        } else {
          window.setNotifications([
            { type: "alert", msg: "Coś poszło nie tak" },
          ]);
        }
      });
    }
  };
  render() {
    const {
      classes,
      values,
      errors,
      handleChange,
      setFieldValue,
      handleSubmit,
    } = this.props;
    const disabled = !!values.id;
    return (
      <form onSubmit={handleSubmit}>
        <GridContainer>
          <GridItem xs={12}>
            <h4>
              <b>Lokalizacja</b>
            </h4>
          </GridItem>
          <GridItem xs={12} md={6}>
            <GridItem xs={12}>
              <CustomInput
                disabled={disabled}
                labelText="Nazwa"
                error={errors.name}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: values.name,
                  name: "name",
                  onChange: handleChange,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                disabled={disabled}
                labelText="Ulica"
                error={errors.street}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: values.street,
                  name: "street",
                  onChange: handleChange,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                disabled={disabled}
                labelText="Miasto"
                error={errors.city}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: values.city,
                  name: "city",
                  onChange: handleChange,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                disabled={disabled}
                labelText="Kod pocztowy"
                error={errors.post_code}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: values.post_code,
                  name: "post_code",
                  onChange: handleChange,
                }}
              />
            </GridItem>
          </GridItem>
          <GridItem xs={12} md={6}>
            <GridItem xs={12}>
              <CustomInput
                disabled={disabled}
                labelText="Opis"
                error={errors.description}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  multiline: true,
                  value: values.description,
                  name: "description",
                  onChange: handleChange,
                }}
              />
            </GridItem>
          </GridItem>
          <GridItem xs={9} style={{ marginTop: 20 }}>
            <h4>
              <b>Obiekty</b>
            </h4>
          </GridItem>
          <GridItem xs={3} style={{ textAlign: "right" }}>
            <Button
              color="primary"
              simple
              size="sm"
              onClick={() => {
                LocationsFunctions.addObject(this.props);
              }}
            >
              + Dodaj obiekt
            </Button>
          </GridItem>

          <GridItem xs={12}>
            <GridContainer>
              {values.location_objects_attributes &&
                values.location_objects_attributes.map(
                  (location_object, index) => {
                    if (location_object.hidden === true) {
                      return null;
                    }
                    return (
                      <GridItem key={location_object.id + "-" + index} xs={12}>
                        <GridContainer>
                          <GridItem xs={8}>
                            <CustomInput
                              disabled={!!location_object.id}
                              labelText="Opis"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                multiline: true,
                                value: location_object.description,
                                onChange: (e) => {
                                  LocationsFunctions.updateObject(
                                    this.props,
                                    index,
                                    "description",
                                    e.target.value
                                  );
                                },
                              }}
                            />
                          </GridItem>
                          <GridItem xs={4}>
                            <FormControl
                              fullWidth
                              className={classes.selectFormControl}
                            >
                              <InputLabel
                                htmlFor="simple-select"
                                className={classes.selectLabel}
                              >
                                Rodzaj
                              </InputLabel>
                              <Select
                                disabled={!!location_object.id}
                                MenuProps={{
                                  className: classes.selectMenu,
                                }}
                                classes={{
                                  select: classes.select,
                                }}
                                value={location_object.object_type || "hall"}
                                onChange={(e) => {
                                  LocationsFunctions.updateObject(
                                    this.props,
                                    index,
                                    "object_type",
                                    e.target.value
                                  );
                                }}
                                inputProps={{
                                  name: "location_type",
                                }}
                              >
                                {[
                                  "hall",
                                  "court",
                                  "stadium",
                                  "gym",
                                  "dance_room",
                                  "baloon",
                                  "other",
                                ].map((type) => (
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected,
                                    }}
                                    value={type}
                                  >
                                    {I18n.t(type)}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </GridItem>
                        </GridContainer>

                        <hr />
                      </GridItem>
                    );
                  }
                )}
            </GridContainer>
          </GridItem>
        </GridContainer>
        <GridContainer justify="space-between">
          <GridItem xs={12} sm style={{ textAlign: "right" }}>
            <Button type="submit" color="success">
              Zapisz
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    );
  }
}

export default withStyles(styles)(Form);
