/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import {Link} from "react-router-dom";
// nodejs library that concatenates classes
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "PNH/components/Header/Header";
// sections for this page

import {Footer} from "PNH/layouts/Footer";

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle";
import ActivitiesTable from "./Table";
import CustomTabs from "components/CustomTabs/CustomTabs";
import ActivityTemplatesTable from "../ActivitiesTemplate/Search/Table"
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

class ActivitiesTablePage extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }


    renderTabs = () => {
        const {creator = false, templates = false} = this.props
        const activeTab = () => {
            if (creator) return 0
            if (templates) return 2
            return 1
        }
        return <CustomTabs
            headerColor="primary"
            activeTab={activeTab()}
            tabs={[
                {tabName: "Dodane zajęcia", tabContent: <ActivitiesTable {...this.props} />},
                {tabName: "Koordynowane zajęcia", tabContent: <ActivitiesTable creator={false} {...this.props} />},
                {tabName: "Szablony zajęć", tabContent: <ActivityTemplatesTable {...this.props} />}

            ]}
        />
    }

    render() {
        const {classes, creator = false} = this.props;
        return (
            <>
                <Header brand="PNH" fixed color="white" {...this.props} />
                <div className={classNames(classes.main)} style={{marginTop: 75}}>
                    <div className={classes.container}>
                        <GridContainer>
                            <GridItem>
                                <h3
                                    style={{
                                        fontWeight: 900,
                                        marginBottom: 40,
                                        marginTop: 30,
                                    }}
                                >
                                    <>
                                        Zajęcia
                                        <h6>Dodawaj i zarządzaj zajęciami</h6>
                                    </>
                                    <h6>
                                        <Link to="/account-settings">Konto</Link> >{" "}Zajęcia

                                    </h6>
                                </h3>
                            </GridItem>
                        </GridContainer>
                        {this.renderTabs()}
                    </div>
                </div>

                <Footer {...this.props} />
            </>
        );
    }
}

ActivitiesTablePage.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(presentationStyle)(ActivitiesTablePage);
