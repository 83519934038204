import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";
import { withRouter } from 'react-router-dom';

export const BalanceFunctions = {
  add: async (values, _formik) => {
    const response = await Api.users.balanceAdd({ balance: values });
    if (response.ok) {
      _formik.props.history.push(`/dotpay/${response.data.id}`);
    }
  },

  getBonusValue: (value, formik) => {
    Api.users.bonusValue(value).then((response) => {
      if (response.ok) {
        formik.setFieldValue("bonus", response.data.value);
      }
    });
  },

  defaultValues: (props) => {
    return {
      value: props.match.params.id || 0,
      terms: false,
      bonus: 0,
      afterSave: () => {},
    };
  },
};

export const RechargeBalanceForm = withRouter(withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...BalanceFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);

    BalanceFunctions.add(values, formikProps)
      .then((response) => {
        values.afterSave(response);
        formikProps.setFieldValue("afterSave", () => {});
      })
      .then(() => {
        formikProps.setTouched({});
        formikProps.setSubmitting(false);
      });
  },
})(Form));
