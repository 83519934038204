/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import classNames from "classnames";
// nodejs library that concatenates classes
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "PNH/components/Header/Header";
// sections for this page
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Footer } from "PNH/layouts/Footer";
import PricingPage from "./PricingPage";
import Parallax from "components/Parallax/Parallax";
import presentationStyle from "assets/jss/material-kit-pro-react/views/pricingStyle";

class RechargeAccount extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Header brand="PNH" fixed color="white" {...this.props} />
        <Parallax
          image={require("PNH/assets/img/banner.jpg")}
          filter="dark"
          className={classes.parallax}
          small
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                md={8}
                sm={8}
                className={classNames(
                  classes.mlAuto,
                  classes.mrAuto,
                  classes.textCenter
                )}
              >
                <h1 className={classes.title}>Zacznij trenować z nami!</h1>
                <h4>
                  Gwarantujemy wysokojakościowe treningi i najniższe ceny! Bądź
                  w formie przez cały rok i skorzystaj ze specjalnie
                  przygotowanych pakietów, które pomogą Ci trenować jeszcze
                  taniej!
                </h4>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>

        <PricingPage {...this.props} />

        <Footer {...this.props} />
      </>
    );
  }
}

RechargeAccount.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(presentationStyle)(RechargeAccount);
