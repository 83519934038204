import React from "react";
import { Api } from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Primary from "components/Typography/Primary";
import Danger from "components/Typography/Danger";

import formRegular from "PNH/assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "PNH/assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "PNH/assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";

import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import {Link} from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import ShortTextIcon from '@material-ui/icons/ShortText';
import {hasUserRole} from "../../../../services/Helpers";
import Warning from "../../../../../components/Typography/Warning";

const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
class Form extends React.Component {
  state = {
    deleteModal: false,
  };

  constructor(props) {
    super(props);
    this.fetch();
  }
  deleteModal = (open) => {
    this.setState({ deleteModal: open });
  };

  fetch = () => {
    if (this.props.values.id) {
      Api.users.show(this.props.values.id).then((response) => {
        if (response.ok) {
          this.props.setValues({
            id: response.data.id,
            benefit_id: response.data.benefit_id,
            second_line: response.data.second_line,
            first_line: response.data.first_line,
            benefit_active: response.data.benefit_active,
            ok_system_active: response.data.ok_system_active,
          });
        } else {
          window.setNotifications([
            { type: "alert", msg: "Coś poszło nie tak" },
          ]);
        }
      });
    }
  };
  render() {
    const {
      classes,
      values,
      errors,
      handleChange,
      setFieldValue,
      handleSubmit,
    } = this.props;

    const disabled = !hasUserRole(this.props.data.currentUser, "user_role_edit_benefit_card")

    return (
      <form onSubmit={handleSubmit}>
        <GridContainer>
          <GridItem xs={12}>
            <h4>MultiSport
            {values.benefit_id && (
              <>
                &nbsp;-&nbsp;
                {values.benefit_active ? (
                  <Primary>
                    <h6>
                      <b> Karta Aktywna</b>
                    </h6>
                  </Primary>
                ) : (
                  <Danger>
                    <h6>
                      <b>Karta nieaktywna</b>
                    </h6>
                  </Danger>
                )}
              </>
            )}
            </h4>
            
          </GridItem>
          <GridItem xs={12}>
            <CustomInput
              labelText="Nr karty"
              error={errors.benefit_id}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: values.benefit_id,
                name: "benefit_id",
                onChange: handleChange,
                disabled: disabled,
                type: "benefit_id",
                endAdornment: (
                    <InputAdornment position="end" className={classes.inputAdornment}>
                      <CardGiftcardIcon className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                ),
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            <CustomInput
              labelText="Pierwsza linia na karcie (zazwyczaj imię)"
              error={errors.first_line}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: values.first_line,
                name: "first_line",
                onChange: handleChange,
                disabled: disabled,
                type: "first_line",
                endAdornment: (
                    <InputAdornment position="end" className={classes.inputAdornment}>
                      <ShortTextIcon className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                ),
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            <CustomInput
              labelText="Druga linia na karcie (zazwyczaj nazwisko)"
              error={errors.second_line}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: values.second_line,
                name: "second_line",
                onChange: handleChange,
                disabled: disabled,
                type: "second_line",
                endAdornment: (
                    <InputAdornment position="end" className={classes.inputAdornment}>
                      <ShortTextIcon className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                ),
              }}
            />
          </GridItem>
          </GridContainer>
          <GridContainer >
            { disabled && <GridItem xs={12} style={{textAlign: 'center'}}>
               Posiadasz rezerwacje dokonane za pomocą karty MultiSport. W celu zmiany danych, prosimy o  <Link to={"s/kontakt"}> kontakt z obsługą klienta</Link>.
            </GridItem> }
            <GridItem xs={12} style={{textAlign: 'center'}}>
              {!disabled && <Button type="submit" disabled={disabled} color="primary" round={true}>
                Zapisz
              </Button> }
            </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} style={{ marginTop: 40 }}>
            <h4>Medicover Sport</h4>
          </GridItem>
          <GridItem xs={12}>
            {this.props.data.currentUser.confirmed_phone ? 
              (<>
                <h6>Konto przypisane do nr {this.props.data.currentUser.phone} jest&nbsp;
                  {!values.ok_system_active && <Danger><strong>nieaktywne</strong></Danger>}
                  {values.ok_system_active && <Primary><strong>aktywne</strong></Primary>}
                </h6>
              </>) : 
            (<>
              <Danger>Aby sprawdzić status potwierdź swój nr telefonu</Danger> <Link to={"/edit-profile"}>tutaj</Link>
            </>)}
          </GridItem>
        </GridContainer>
      </form>
    );
  }
}

export default withStyles(styles)(Form);
