import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import TrendingUp from "@material-ui/icons/TrendingUp";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Info from "components/Typography/Info.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import Button from "components/CustomButtons/Button.js";

import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.js";

import cardBlog4 from "assets/img/examples/card-blog4.jpg";
import office2 from "assets/img/office2.jpg";
import blog5 from "assets/img/examples/blog5.jpg";
import blog6 from "assets/img/examples/blog6.jpg";
import blog7 from "assets/img/examples/blog7.jpg";
import blog8 from "assets/img/examples/blog8.jpg";
import bg5 from "assets/img/bg5.jpg";
import {Link} from "react-router-dom";
import Primary from "../../../../../components/Typography/Primary";

const useStyles = makeStyles(blogsStyle);

export default function WideLeft({ tile, ...rest }) {
    const classes = useStyles();
    return (
        <Card plain blog className={classes.card}>
            <GridContainer>
                <GridItem xs={12} sm={5} md={5}>
                    <CardHeader image plain>
                        <a href={tile.url}>
                            <img src={process.env.REACT_APP_API_URL+tile.picture} alt="..." />
                        </a>
                        <div
                            className={classes.coloredShadow}
                            style={{
                                backgroundImage: `url(${process.env.REACT_APP_API_URL}${tile.picture})`,
                                opacity: "1"
                            }}
                        />
                    </CardHeader>
                </GridItem>
                <GridItem xs={12} sm={7} md={7}>
                    <Primary>
                        <h6 className={classes.cardCategory}>{tile.category}</h6>
                    </Primary>
                    <h3 className={classes.cardTitle}>
                        <a href={tile.url}>
                            {tile.name}
                        </a>
                    </h3>
                    <p className={classes.description1}>
                        {tile.description}

                    </p>
                    <Button fullWidth href={tile.url} round color="primary">
                        {tile.button_text || "Więcej"}
                    </Button>
                </GridItem>
            </GridContainer>
        </Card>
    );
}
