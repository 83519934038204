/*eslint-disable*/
import React from "react";

// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle";
import { Link } from "react-router-dom";

import { LevelTable } from "./Table";


class LevelPage extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <> <h3
                style={{ fontWeight: 900, marginBottom: 40, marginTop: 30 }}
            >
                Poziom gry
                <h6>
                    <Link to="/account-settings">Konto</Link> > Poziom gry
                </h6>
            </h3>
           
            <LevelTable {...this.props}/>
            </>
        );
    }
}

LevelPage.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(presentationStyle)(LevelPage);
