import React, {useCallback, useEffect, useState} from "react";
import Header from "PNH/components/Header/Header";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer";
import {Link} from "react-router-dom";
import {Footer} from "PNH/layouts/Footer";
import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle";
import {makeStyles} from "@material-ui/core/styles";
import {Api} from "PNH/services/Api";

import Accordion from "components/Accordion/Accordion";

import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card";
import CustomTabs from "components/CustomTabs/CustomTabs";
import {I18n} from "../../../../../components/I18n";
import {TeamsForm} from "./Form";
import {UsersForm} from "./Players";
import ForTeamSlider from "./Activities/ForTeamSlider";
import TeamActivitiesTab from "./Activities";



const useStyles = makeStyles(presentationStyle);
export const TeamProfile = ({...props}) => {
    const classes = useStyles();
    const [competitionGroupId, setCompetitionGroupId] = useState(props.match.params.competition_group_id)
    const [id, setId] = useState(props.match.params.id)
    const [group, setGroup] = useState({})
    const [team, setTeam] = useState({})


    useEffect(() => {
        if (competitionGroupId) {
            Api.competition_groups.show(competitionGroupId).then((response) => {
                if (response.ok) {
                    setGroup(response.data)
                } else {
                    props.history.push("/404")
                }
            });
        }
    }, [])

    const fetchTeam = useCallback(() => {
        if (competitionGroupId) {
            Api.competition_groups.teams.show(id, competitionGroupId).then((response) => {
                if (response.ok) {
                    setTeam(response.data)
                } else {
                    props.history.push("/404")
                }
            });
        }
    }, [])

    useEffect(fetchTeam, [group])

    return <>
        <Header brand="PNH" fixed color="white" classes={classes} {...props} />

        <div className={classNames(classes.main)} style={{marginTop: 75}}>
            <div className={classes.container}>
                <GridContainer alignItems={"center"}>
                    <GridItem xs={12} md={10}>
                        <h3
                            style={{
                                fontWeight: 900,
                                marginBottom: 40,
                                marginTop: 30,
                            }}
                        >
                            {" "}
                            {team.name}
                            <h6>
                                <Link to="/account-settings">Konto</Link> >{" "}
                                <Link to="/moje-grupy/">Moje Grupy</Link> > {" "}
                                <Link to={`/moje-grupy/${competitionGroupId}`}>{group.name}</Link> > {" "}
                                Drużyna - Szczegóły
                            </h6>
                        </h3>
                    </GridItem>
                    <GridItem xs={12} md={2} style={{textAlign: "right"}}>
                        <TeamsForm key={`${team.name}${team.gender}`} editForm competition_group_id={competitionGroupId}
                                   id={team.id} name={team.name} gender={team.gender} afterSave={fetchTeam}/>,
                    </GridItem>
                </GridContainer>


                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        <h5><strong>Szczegóły</strong></h5>
                                    </GridItem>
                                    <GridItem xs={6} md={3}><p>
                                        <b>Grupa:</b>
                                        <br/>
                                        {group.name}
                                    </p></GridItem>
                                    <GridItem xs={6} md={3}>
                                        <b>Gracze:</b>
                                        <br/>
                                        {team.users?.length}/{group?.min_team_size}
                                    </GridItem>
                                    <GridItem xs={6} md={3}>
                                        <b>Płeć:</b>
                                        <br/>
                                        {I18n.t(`addTeamForm${team.gender}`)}
                                    </GridItem>
                                    <GridItem xs={6} md={3}>
                                        <b>Dodano:</b>
                                        <br/>
                                        {team.created_at}
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>

                                <Accordion nonBorder active={0} collapses={[
                                    {
                                        title: "Najbliższe zajęcia",
                                        content: team.id && <ForTeamSlider id={team.id}/>
                                    }
                                ]
                                }/>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem>
                        <CustomTabs
                            headerColor="primary"
                            tabs={[
                                {
                                    tabName: "Wszyscy gracze",
                                    tabContent: <UsersForm {...props}/>
                                },
                                {
                                    tabName: "Wszystkie zajęcia",
                                    tabContent: <TeamActivitiesTab id={team.id} {...props}/>
                                },
                            ]}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        </div>

        <Footer classes={classes} {...props} />
    </>
}