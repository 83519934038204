import React from "react";
import GridItem from "components/Grid/GridItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

import { I18n } from "PNH/components/I18n";

const getPaymentsConfig = (currentUser) => {
  const available_roles = currentUser.roles.filter((r) => r.name.startsWith("user_role_payments"))
  const fields = available_roles.map(role => role.name.replace('user_role_payments', 'pay_by'))
  const paymentMethods = ['pay_by_cash'].concat(fields)
  return [...new Set(paymentMethods)]
};

export const PaymentsSection = ({ handleChange, classes, values, errors, setFieldValue, currentUser }) => {
    return <>
          <GridItem xs={12}>
            <h5>
              <b>Płatności</b>
            </h5>
            <hr />
          </GridItem>
          {getPaymentsConfig(currentUser).map((p) => (
            <GridItem xs={12} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    tabIndex={-1}
                    checked={values[p]}
                    onClick={() => setFieldValue(p, !values[p])}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label={I18n.t(p)}
              />
            </GridItem>
          ))}

    </>
}