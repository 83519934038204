import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";

export const UsersFunctions = {
  add: (values, formik) => {
    return Api.users.allowToRegister
      .add({ user: { id: values.user_id } })
      .then((response) => {
        if (response.ok) {
          formik.setValues({
            ...UsersFunctions.defaultValues(formik.props),
            ...{
              users_allowed_to_registers:
                response.data.users_allowed_to_registers,
            },
          });
          window.setNotifications([{ type: "success", msg: "Dodano" }]);
        } else {
          window.setNotifications([
            { type: "alert", msg: "Nie udało się zapisać zmian" },
          ]);
        }
      });
  },
  remove: (values, formik) => {
    return Api.users.allowToRegister
      .remove({ user: { id: values.user_id } })
      .then((response) => {
        if (response.ok) {
          formik.setValues({
            ...UsersFunctions.defaultValues(formik.props),
            ...response.data,
          });
          window.setNotifications([{ type: "success", msg: "Usunięto" }]);
        } else {
          window.setNotifications([
            { type: "alert", msg: "Nie udało się usunąć" },
          ]);
        }
      });
  },

  defaultValues: (props) => {
    return {
      id: 1,
      action: "add",
      user_id: "",
      users_allowed_to_registers: [],
      user: {},
      afterSave: () => {},
    };
  },
};

export const AllowRegisterForm = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...UsersFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);

    UsersFunctions[values.action](values, formikProps)
      .then((response) => {
        values.afterSave(response);
        formikProps.setFieldValue("afterSave", () => {});
      })
      .then(() => {
        formikProps.setTouched({});
      });
  },
})(Form);
