import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import withStyles from "@material-ui/core/styles/withStyles";
// import get from "lodash/fp/get";
import customInputStyle from "assets/jss/material-kit-pro-react/components/customInputStyle";
import classNames from "classnames";

// const getError = (errors, name) => {
//   return get(name, errors);
// };

export const AsyncSelector = ({
  apiMethod = () => {},
  apiShowMethod = undefined,
  labelKey = "",
  defaultValue = "",
  label = "",
  disabled = false,
  required = false,
  size = "small",
  onChange = () => {},
  InputProps = {},
  StyledListProps = { variant: "normal" },
  name = "",
  error = "",
  success = "",
  touched = {},
  setTouched = () => {},
  classes,
  white,
  inputRootCustomClasses,
  formControlProps,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState("");

  useEffect(() => {
    setOptions([]);
    setSearch("");
  }, [apiMethod]);

  useEffect(() => {
    if (!apiShowMethod || !defaultValue) {
      return;
    }
    (async () => {
      const response = await apiShowMethod(defaultValue);
      if (!response.ok) {
        setOptions([]);
        return;
      }
      const r = await response.data;
      onChange(r.id, r);
    })();
  }, [defaultValue, apiShowMethod]);

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }
    (async () => {
      const scope = `with_${labelKey}`;
      const response = await apiMethod({ [scope]: search });
      if (!response.ok) {
        setOptions([]);
        return;
      }
      const r = await response.data.rows;

      if (active) {
        setOptions(
          r.map((row) => {
            return {
              id: row.id,
              label: row[labelKey],
              data: row,
            };
          })
        );
      }
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
      setSearch("");
    } else {
      setLoading(true);
    }
  }, [open]);

  useEffect(() => {
    setLoading(open);
  }, [search]);

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error,
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white,
  });
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
  });
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }

  return (
    <FormControl fullWidth {...formControlProps} className={formControlClasses}>
      <Autocomplete
        {...props}
        size={size}
        disabled={disabled}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        openOnFocus
        noOptionsText="Brak wyników"
        defaultValue={defaultValue}
        getOptionSelected={(option, selectedValue) =>
          option.id === selectedValue
        }
        getOptionLabel={(option) => option.label}
        getOptionDisabled={(option) => option.id === null}
        options={options}
        loading={loading}
        onChange={(e, selectedOption) => {
          onChange(
            selectedOption ? selectedOption.id : "",
            selectedOption && selectedOption.data
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={`${label}${required ? "*" : ""}` || null}
            error={error}
            onChange={(e) => {
              setSearch(e.target.value);
              setOptions([]);
            }}
            InputLabelProps={{
              className:
                classes.labelAsyncSelector +
                " " +
                classes.labelRoot +
                " " +
                labelClasses,
            }}
            InputProps={{
              ...InputProps,
              ...params.InputProps,
              shrink: "true",
              classes: {
                input: inputClasses,
                root: marginTop,
                disabled: classes.disabled,
                underline: underlineClasses,
              },
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
};

export default withStyles(customInputStyle)(AsyncSelector);
