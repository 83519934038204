import React, {useState} from "react";
import Button from "../../../../../../components/CustomButtons/Button";
import {ShareOutlined} from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import Card from "../../../../../../components/Card/Card";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "../../../../../../components/Grid/GridContainer";
import GridItem from "../../../../../../components/Grid/GridItem";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles";
import {makeStyles} from "@material-ui/core/styles";
import CustomInput from "../../../../../../components/CustomInput/CustomInput";
import Slide from "@material-ui/core/Slide";
import FileCopyIcon from "@material-ui/icons/FileCopy";

const useStyles = makeStyles(javascriptStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export const ShareButton = ({group, ...props}) => {
    const classes = useStyles();
    const [openModal, setOpenModal] = useState(false)
    const url = window.location.origin+group.url

    return <>
        <Dialog
            maxWidth={'sm'}
            fullWidth={true}
            open={openModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpenModal(false)}

        >
            <Card plain className={classes.modalSignupCard}>
                <DialogTitle
                    disableTypography
                    className={classes.modalHeader}
                    style={{marginTop: "-40px"}}
                >
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => setOpenModal(false)}
                    >
                        <Close className={classes.modalClose}/>
                    </Button>
                    <center>
                        {group.id && <h4>Link do grupy</h4>}
                    </center>
                </DialogTitle>
                <DialogContent className={classes.modalBody}>
                    <GridContainer alignItems={"center"} style={{padding: '20px'}}>
                        <GridItem xs={11}>
                            <CustomInput
                                labelText="Link"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    disabled: true,
                                    value: url,
                                    name: "name",
                                    onChange: () => {
                                    },
                                }}
                            />
                        </GridItem>
                        <GridItem xs={1}><Button justIcon={true} simple color={"primary"} onClick={() => {
                            navigator.clipboard.writeText(url)
                            window.setNotifications([{ type: "success", msg: "Dodano do schowka" }]);
                        }}><FileCopyIcon/></Button> </GridItem>
                    </GridContainer>
                </DialogContent>
                <DialogActions className={
                    classes.modalFooter + " " + classes.justifyContentCenter
                }>
                    <GridContainer>
                        <GridItem xs={12} className={classes.textCenter}>
                            <Button
                                simple
                                color="transparent"
                                style={{marginTop: '30px'}}
                                onClick={() => setOpenModal(false)}
                            >
                                Zamknij
                            </Button>
                        </GridItem>
                    </GridContainer>
                </DialogActions>
            </Card>
        </Dialog>
        <Button justIcon={true} color={"primary"} onClick={() => setOpenModal(true)}
                simple={true}><ShareOutlined/></Button>
    </>
}