import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";

export const UsersFunctions = {
  update: (values, formik) => {
    return Api.users.update(values.id, { user: values }).then((response) => {
      if (response.ok) {
        formik.setValues({
          ...UsersFunctions.defaultValues(formik.props),
          ...response.data,
        });
        window.setNotifications([{ type: "success", msg: "Zapisano" }]);
      } else {
        window.setNotifications([
          { type: "alert", msg: "Nie udało się zapisać zmian" },
        ]);
      }
    });
  },

  defaultValues: (props) => {
    return {
      id: props.data.currentUser.id,
      fitprofit_id: "",
      fitprofit_status: false,
      afterSave: () => {},
    };
  },
};

export const FitprofitForm = withFormik({
  enableReinitialize: false,
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...UsersFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);

    UsersFunctions.update(values, formikProps).then(() => {
      formikProps.setTouched({});
    });
  },
})(Form);
