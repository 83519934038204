import React from "react";
import CustomInput from "components/CustomInput/CustomInput";
import formRegular from "PNH/assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "PNH/assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import FormControl from "@material-ui/core/FormControl";
import Primary from "components/Typography/Primary";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import classNames from "classnames";
import Danger from "components/Typography/Danger";
// nodejs library that concatenates classes
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "PNH/components/Header/Header";
// sections for this page
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import { Footer } from "PNH/layouts/Footer";
import Parallax from "components/Parallax/Parallax";
import presentationStyle from "assets/jss/material-kit-pro-react/views/pricingStyle";
import { DepositFunctions } from "./index";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

const styles = {
  ...formRegular,
  ...stylesExtended,
  ...presentationStyle,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
class Form extends React.Component {
  constructor(props) {
    super(props);
    DepositFunctions.getDepositValue(props);
  }
  form = () => {
    const {
      values,
      errors,
      handleChange,
      classes,
      setFieldValue,
      handleSubmit,
    } = this.props;
    return (
      <GridContainer style={{ paddingTop: 30, paddingBottom: 30 }}>
        <GridItem>
          <h2 style={{ fontWeight: 900 }}>Doładowanie kaucji</h2>
          <h4 style={{ fontWeight: 900 }}>
            Właśnie tu uzupełnisz swoją kaucję czyli kwotę pieniężną
            zabezpieczającą przed nieobecnością, spóźnieniem, zbyt późnym
            wypisem z Wydarzeń przez Użytkownika lub nieaaktywnym pakietem
            benefitowym. Kwota, która widnieje poniżej jest różnicą pomiędzy
            <br />
            <br />
            Twoim stanem kaucji a pełnym uzupełnieniem.
          </h4>
        </GridItem>
        <GridItem>
          <GridContainer>
            <GridItem xs={10} sm={7} md={5} lg={3}>
              <CustomInput
                labelText="Kwota"
                noErrorIcon
                error={errors.value}
                formControlProps={{
                  fullWidth: true,
                }}
                labelProps={{ style: { fontSize: 20 } }}
                inputProps={{
                  size: "medium",
                  value: values.value,
                  name: "value",
                  disabled: true,
                  type: "number",
                  endAdornment: "PLN",
                  inputProps: {
                    max: "25",
                    style: {
                      fontSize: 25,
                      height: 30,
                      color: "rgb(0, 166, 153)",
                    },
                  },
                }}
              />
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem>
          <h4 style={{ fontWeight: 900 }}>
            Metoda płatności:
            <GridContainer>
              <GridItem xs={12}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={values.pay_method}
                    onChange={handleChange}
                    inputProps={{
                      name: "pay_method",
                    }}
                  >
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="online_transfer"
                    >
                      <span
                        style={{
                          fontSize: 16,
                        }}
                      >
                        Przelew Online - DotPay
                      </span>
                    </MenuItem>

                    <MenuItem
                      key={`v-${values.value}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      disabled={
                        Number(values.value) >
                        Number(this.props.data.currentUser.account_balance)
                      }
                      value="prepaid"
                    >
                      <span
                        style={{
                          fontSize: 16,
                        }}
                      >
                        Przelej z konta głównego
                      </span>
                      {Number(this.props.data.currentUser.account_balance) <
                        Number(values.value) && (
                        <div
                          style={{
                            fontSize: 10,
                          }}
                        >
                          <Danger>Brak środków na koncie głównym</Danger>
                        </div>
                      )}
                    </MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
          </h4>
        </GridItem>
      </GridContainer>
    );
  };

  render() {
    const { handleSubmit, classes, values, errors, setFieldValue } = this.props;
    return (
      <>
        <Header brand="PNH" fixed color="white" {...this.props} />
        <Parallax
          image={require("PNH/assets/img/banner.jpg")}
          filter="dark"
          className={classes.parallax}
          small
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                md={8}
                sm={8}
                className={classNames(
                  classes.mlAuto,
                  classes.mrAuto,
                  classes.textCenter
                )}
              >
                <h1 className={classes.title}>Zacznij trenować z nami!</h1>
                <h4>
                  W trosce o zapewnienie jak najniższych cen przygotowaliśmy
                  specjalne pakiety. Zapoznaj się z ofertą i zacznij trenować
                  taniej!
                </h4>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main)}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={8}>
                <form>{this.form()}</form>
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <Card>
                  <CardBody>
                    <h6 className={classes.cardCategory}></h6>
                    <h4 className={classes.cardTitle}>
                      <h3>Stan konta</h3>
                      <p>
                        Główne: {this.props.data.currentUser.account_balance} zł
                      </p>
                      <p>
                        Bonus: {this.props.data.currentUser.account_bonus_value}{" "}
                        zł
                      </p>
                      <p>
                        Kaucja:{" "}
                        {this.props.data.currentUser.account_deposit_value} zł
                      </p>
                    </h4>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <h6 className={classes.cardCategory}></h6>
                    <h4 className={classes.cardTitle}>
                      <h3>Jaka jest wymagana kwota kaucji? </h3>
                      <p>
                        Maksymalna kwota kaucji to 25 zł z tzw. buforem
                        bezpieczeństwa do 20 zł. Ważne!{" "}
                        <b>
                          Jeżeli cena danego Wydarzenia przewyższa kwotę kaucji,
                          portfel kaucji zostanie wyzerowany, konto nie zostanie
                          obciążone stanem minusowym.
                        </b>
                        <br />
                        <Button
                          fullWidth
                          color="primary"
                          simple
                          href="/s/kaucja"
                        >
                          <b>Czytaj więcej</b>
                        </Button>
                      </p>
                    </h4>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem>
                <hr />
              </GridItem>
              <GridItem style={{ paddingBottom: 50 }}>
                <GridContainer justify="flex-end">
                  {values.value && !errors.value && (
                    <GridItem style={{ width: "auto" }}>
                      <h4 style={{ fontWeight: 900 }}>
                        Do zapłaty:{" "}
                        <span style={{ color: "rgb(0, 166, 153)" }}>
                          {values.value} PLN
                        </span>
                      </h4>
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              tabIndex={-1}
                              checked={values.terms}
                              onClick={() =>
                                setFieldValue("terms", !values.terms)
                              }
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot,
                          }}
                          label={
                            <span
                              style={{
                                color: !!errors["terms"] ? "red" : "inherit",
                              }}
                            >
                              Akceptuję{" "}
                              <a
                                href="https://pnh-public.s3.eu-central-1.amazonaws.com/regulamin.pdf"
                                target="_blank"
                              >
                                postanowienia i regulamin
                              </a>
                              . *
                            </span>
                          }
                        />
                      </div>
                      <Button
                        color="primary"
                        onClick={handleSubmit}
                        disabled={!values.terms || this.props.isSubmitting}
                      >
                        Przezjdź do płatności
                      </Button>
                    </GridItem>
                  )}
                  {values.value === "0.00" && (
                    <h6>
                      <Primary>
                        <b>Twoja kaucja jest w pełni uzupełniona!</b>
                      </Primary>
                    </h6>
                  )}
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <Footer {...this.props} />
      </>
    );
  }
}

export default withStyles(styles)(Form);
