import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import Header from "PNH/components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import loadingPageStyle from "assets/jss/material-kit-pro-react/views/loadingPageStyles";

import image from "PNH/assets/img/404.jpeg";
import { Footer } from "PNH/layouts/Footer";
import CircularProgress from "@material-ui/core/CircularProgress";

class LoadingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [1],
    };
    this.handleToggle = this.handleToggle.bind(this);
  }
  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          absolute
          color="transparent"
          brand="Material Kit PRO React"
          {...rest}
        />
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center",
          }}
        >
          {/* <div className={classes.container}> */}
          <div className={classes.contentCenter}>
            <GridContainer>
              <GridItem md={12}>
                <CircularProgress size={100} thickness={4} className={classes.progress}/>
              </GridItem>
            </GridContainer>
          </div>
          {/* </div> */}
        </div>
        <Footer {...this.props} />
      </div>
    );
  }
}

LoadingPage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(loadingPageStyle)(LoadingPage);
