import React, { useState, useEffect, useCallback } from "react";
import Table from "components/Table/Table";
import TablePagination from "@material-ui/core/TablePagination";
import { Api } from "PNH/services/Api";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { I18n } from "PNH/components/I18n";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
import VerifiedIcon from '@material-ui/icons/VerifiedUser';
import { AddModal } from "./AddModal";
import Primary from "components/Typography/Primary";
import Warning from "components/Typography/Warning";

export function LevelTable({ classes, ...props }) {
    const [data, setData] = useState({ rows: [], total: 0 });
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);

    const getData = useCallback(() => {
        return Api.users.sport_levels.index().then((r) => {
            setData(r.data)
            return r
        })
    });


    useEffect(() => {
        getData()
    }, [limit, page]);

    return (
        <>
            <AddModal afterSave={getData} classes={classes} {...props} />
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardBody>
                            <Table
                                striped
                                tableHead={[

                                    <b>Dyscyplina</b>,
                                    <b>Poziom</b>,
                                    <b></b>,
                                    <b></b>,

                                ]}
                                tableData={data.rows.map((row) => mapRow(row, getData, classes))}
                            />
                            <TablePagination
                                rowsPerPageOptions={[10, 25]}
                                component="div"
                                page={page}
                                rowsPerPage={limit}
                                count={data.total}
                                onChangePage={(_e, page) => setPage(page)}
                                onChangeRowsPerPage={(e) => setLimit(e.target.value)}
                                labelRowsPerPage="Ilość"
                                labelDisplayedRows={({ from, to, count }) =>
                                    `${from}-${to} z ${count}`
                                }
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    );
}


const mapRow = (row, getData, classes) => {
    return [
        row.sport.name,
        <>{I18n.t(row.level)}&nbsp;{row.verified && <Tooltip title="Poziom zweryfikowany przez PNH" classes={{ tooltip: classes.tooltip }}>
            <span><Primary ><VerifiedIcon style={{ fontSize: 13 }} /></Primary></span>
        </Tooltip>}
        </>,
        <>
            {(row.edit_only_by_admin || row.verified) && <p><Warning>Edycja tylko przez administratora PNH</Warning></p>}
        </>,
        !row.edit_only_by_admin && !row.verified && <Button icon simple color="danger" disabled={row.edit_only_by_admin} onClick={() => deleteRow(row, getData)}>
            <Close />
        </Button>

    ]
}

const deleteRow = (row, getData) => {
    Api.users.sport_levels.destroy(row.id).then(() => {
        getData().then((response) => {
            if (response.ok) {
                window.setNotifications([{ type: "success", msg: "Usunięto" }]);
            } else {
                window.setNotifications([
                    { type: "alert", msg: "Nie udało się usunąć" },
                ]);
            }
        })
    })

}