import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";

export const LocationsFunctions = {
  addObject: (formik) => {
    const objects_attributes = [...formik.values.location_objects_attributes];
    objects_attributes.push({
      id: "",
      object_type: "hall",
      description: "",
      notes: "",
      hidden: false,
      checklist: "",
      _destroy: false,
    });
    formik.setFieldValue("location_objects_attributes", objects_attributes);
  },
  updateObject: (formik, index, field, value) => {
    const objects_attributes = [...formik.values.location_objects_attributes];
    objects_attributes[index][field] = value;
    formik.setFieldValue("location_objects_attributes", objects_attributes);
  },
  create: (values, formik) => {
    return Api.locations.create({ location: values }).then((response) => {
      if (response.ok) {
        formik.props.updateLocation(response);
        window.setNotifications([{ type: "success", msg: "Zapisano" }]);
      } else {
        window.setNotifications([
          { type: "alert", msg: "Nie udało się dodać lokalizacji. Błędne dane lub lokalizacja już istnieje." },
        ]);
      }
    });
  },
  update: (values, formik) => {
    return Api.locations
      .update(values.id, { location: values })
      .then((response) => {
        if (response.ok) {
          formik.props.updateLocation(response);
          formik.setValues({
            ...LocationsFunctions.defaultValues(formik.props),
            ...response.data,
          });
          window.setNotifications([{ type: "success", msg: "Zapisano" }]);
        } else {
          window.setNotifications([
            { type: "alert", msg: "Nie udało się zapisać zmian" },
          ]);
        }
      });
  },

  defaultValues: (props) => {
    return {
      id: props.locationID,
      name: "",
      street: "",
      city: "",
      post_code: "",
      lat: "",
      lang: "",
      description: "",
      notes: "",
      hidden: "",
      photo: "",
      photos: [],
      location_objects_attributes: [],
      modal: props.modal || false,
      afterSave: props.afterSave ? props.afterSave : () => {},
    };
  },
};

export const LocationsForm = withFormik({
  validationSchema: validationSchema,
  enableReinitialize: false,
  mapPropsToValues: (props) => {
    return { ...LocationsFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
    if (!values.id) {
      LocationsFunctions.create(values, formikProps)
        .then((response) => {
          formikProps.props.afterSave(response);
          formikProps.setFieldValue("afterSave", () => {});
        })
        .then(() => {
          formikProps.setTouched({});
        });
    } else {
      LocationsFunctions.update(values, formikProps).then(() => {
        formikProps.setTouched({});
      });
    }
  },
})(Form);
