/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Check from "@material-ui/icons/Check";
import Warning from "@material-ui/icons/Warning";
// core components
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import notificationsStyles from "assets/jss/material-kit-pro-react/views/componentsSections/notificationsStyles.js";

class SectionNotifications extends React.Component {
  componentDidMount() {
    let url = window.location.href;
    url = this.removeParam("notification_msg", url);
    url = this.removeParam("notification_type", url);
    window.history.pushState("", "", url);
  }

  removeParam = (key, sourceURL) => {
    var rtn = sourceURL.split("?")[0],
      param,
      params_arr = [],
      queryString =
        sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
      params_arr = queryString.split("&");
      for (var i = params_arr.length - 1; i >= 0; i -= 1) {
        param = params_arr[i].split("=")[0];
        if (param === key) {
          params_arr.splice(i, 1);
        }
      }
      rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
  };
  snackInfo = (msg) => (
    <SnackbarContent
      key={msg}
      message={<span>{msg}</span>}
      close
      color="info"
      icon="info_outline"
    />
  );
  snackSuccess = (msg) => (
    <SnackbarContent
      key={msg}
      message={<span>{msg}</span>}
      close
      color="success"
      icon={Check}
    />
  );

  snackWarning = (msg) => (
    <SnackbarContent
      key={msg}
      message={<span>{msg}</span>}
      close
      color="warning"
      icon={Warning}
    />
  );

  snackAlert = (msg) => (
    <SnackbarContent
      key={msg}
      message={<span>{msg}</span>}
      close
      color="danger"
      icon="info_outline"
    />
  );
  render() {
    const { notifications = [] } = this.props;
    return (
      <div
        style={{
          position: "fixed",
          top: "80px",
          zIndex: "2000",
          width: "100vw",
          left: 0,
        }}
      >
        {notifications.map((notification, index) => {
          return (
            <div key={`${notifications.type}-${index}`}>
              {notification.type === "info" && this.snackInfo(notification.msg)}
              {notification.type === "alert" &&
                this.snackAlert(notification.msg)}
              {notification.type === "success" &&
                this.snackSuccess(notification.msg)}
              {notification.type === "warning" &&
                this.snackWarning(notification.msg)}
            </div>
          );
        })}
      </div>
    );
  }
}

SectionNotifications.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(notificationsStyles)(SectionNotifications);
