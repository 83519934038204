import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";

export const DepositFunctions = {
  add: (values, _formik) => {
    return Api.users[
      values.pay_method === "online_transfer" ? "depositAdd" : "depositTransfer"
    ]({ balance: values }).then((response) => {
      if (response.ok) {
        if (values.pay_method === "online_transfer" ){
          window.location.href = "/dotpay/" + response.data.id 
        } else {
          window.location.href = "/account-settings?notification_msg=Doładowano kaucję&notification_type=success"
        } 
      }
    });
  },

  getDepositValue: (formik) => {
    Api.users.depositToAdd().then((response) => {
      if (response.ok) {
        formik.setFieldValue("value", response.data.value);
      }
    });
  },

  defaultValues: (props) => {
    return {
      value: 0,
      terms: false,
      pay_method: "online_transfer",
      afterSave: () => {},
    };
  },
};

export const RechargeDepositForm = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...DepositFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);

    DepositFunctions.add(values, formikProps)
      .then((response) => {
        values.afterSave(response);
        formikProps.setFieldValue("afterSave", () => {});
      })
      .then(() => {
        formikProps.setTouched({});
        formikProps.setSubmitting(false);
      });
  },
})(Form);
