import GridItem from "../../../../components/Grid/GridItem";
import GridContainer from "../../../../components/Grid/GridContainer";
import React from "react";

export const TitleSection = ({title}) => {
    return title && <GridItem xs={12} sm={8} md={8} lg={8}>
        <GridContainer justifyContent="space-between" alignItems="center">
            <GridItem sm><h3 style={{fontWeight: '900'}}>{title}</h3></GridItem>
        </GridContainer>
    </GridItem>
}